import { lazy } from 'react'

import { Combobox } from '@makeswift/runtime/controls'

import { getFieldOptions } from '@/lib/contentful/utils'
import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Contentful/common/ContentfulRichText/ContentfulRichText.makeswift'

runtime.registerComponent(
	lazy(() => import('./ThemeRichText').then((module) => ({ default: module.ThemeRichText }))),
	{
		type: 'marketplace-theme-rich-text',
		label: 'Contentful/Marketplace/Themes/Theme Rich Text',
		props: {
			...props,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'MarketplaceTheme',
						filter: (name) => name === 'RichText',
						query
					})
				}
			})
		}
	}
)
