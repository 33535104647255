import { lazy } from 'react'

import { Select, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { FolderStructure } from '@/utils/makeswift/constants'

export const props = {
	className: Style({ properties: Style.All }),
	dataTestId: TextInput({ defaultValue: 'authorCard', label: 'Data Test Id' }),
	type: Select({
		label: 'Type of Component',
		options: [
			{ value: 'card', label: 'Card' },
			{ value: 'inline', label: 'In-line' }
		],
		defaultValue: 'card'
	})
}

runtime.registerComponent(
	lazy(() => import('./AuthorCard').then((module) => ({ default: module.AuthorCard }))),
	{
		type: 'authorCard',
		label: `${FolderStructure.UnderDevelopment}/AuthorCard`,
		props
	}
)
