import { lazy } from 'react'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Breadcrumbs/Breadcrumbs.makeswift'

const { ...rest } = props

runtime.registerComponent(
	lazy(() => import('./AppBreadcrumbs').then((module) => ({ default: module.AppBreadcrumbs }))),
	{
		type: 'marketplace-app-breadcrumbs',
		label: 'Contentful/Marketplace/Apps/App Breadcrumbs',
		props: rest
	}
)
