import { lazy } from 'react'

import { Combobox, Select, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'

runtime.registerComponent(
	lazy(() =>
		import('./BlogPostPDFDownload').then((module) => ({ default: module.BlogPostPDFDownload }))
	),
	{
		type: 'blog-post-pdf-download',
		label: 'Contentful/Blog/Blog PDF Download',
		props: {
			className: Style(),
			section: Select({
				label: 'section',
				options: [
					{ value: 'blogPost', label: 'Blog Post' },
					{ value: 'article', label: 'Article' }
				],
				defaultValue: 'blogPost'
			}),
			formId: TextInput({ label: 'Form ID', selectAll: true }),
			thumbnailFieldPath: Combobox({
				label: 'Thumbnail Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'BlogPost',
						filter: (name) => name === 'Asset',
						query
					})
				}
			}),
			buttonText: TextInput({ label: 'Button text', defaultValue: 'Download PDF', selectAll: true })
		}
	}
)
