import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
	[_ in K]?: never
}
export type Incremental<T> =
	| T
	| { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string | number; output: string }
	String: { input: string; output: string }
	Boolean: { input: boolean; output: boolean }
	Int: { input: number; output: number }
	Float: { input: number; output: number }
	DateTime: { input: string; output: string }
	Dimension: { input: number; output: number }
	HexColor: { input: string; output: string }
	JSON: { input: { [key: string]: any }; output: { [key: string]: any } }
	Quality: { input: number; output: number }
}

/** Additional options not needed by every Person type [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/additionalPersonOptions) */
export type AdditionalPersonOptions = Entry &
	_Node & {
		__typename?: 'AdditionalPersonOptions'
		_id: Scalars['ID']['output']
		avatarOverride?: Maybe<Asset>
		bioSummary?: Maybe<AdditionalPersonOptionsBioSummary>
		contentfulMetadata: ContentfulMetadata
		fullBio?: Maybe<AdditionalPersonOptionsFullBio>
		heroBackground?: Maybe<Asset>
		internalName?: Maybe<Scalars['String']['output']>
		intro?: Maybe<AdditionalPersonOptionsIntro>
		linkedFrom?: Maybe<AdditionalPersonOptionsLinkingCollections>
		relatedNewsCollection?: Maybe<AdditionalPersonOptionsRelatedNewsCollection>
		sys: Sys
	}

/** Additional options not needed by every Person type [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/additionalPersonOptions) */
export type AdditionalPersonOptionsAvatarOverrideArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Additional options not needed by every Person type [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/additionalPersonOptions) */
export type AdditionalPersonOptionsBioSummaryArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Additional options not needed by every Person type [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/additionalPersonOptions) */
export type AdditionalPersonOptionsFullBioArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Additional options not needed by every Person type [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/additionalPersonOptions) */
export type AdditionalPersonOptionsHeroBackgroundArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Additional options not needed by every Person type [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/additionalPersonOptions) */
export type AdditionalPersonOptionsInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Additional options not needed by every Person type [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/additionalPersonOptions) */
export type AdditionalPersonOptionsIntroArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Additional options not needed by every Person type [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/additionalPersonOptions) */
export type AdditionalPersonOptionsLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Additional options not needed by every Person type [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/additionalPersonOptions) */
export type AdditionalPersonOptionsRelatedNewsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AdditionalPersonOptionsRelatedNewsCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<ExternalLinkFilter>
}

export type AdditionalPersonOptionsBioSummary = {
	__typename?: 'AdditionalPersonOptionsBioSummary'
	json: Scalars['JSON']['output']
	links: AdditionalPersonOptionsBioSummaryLinks
}

export type AdditionalPersonOptionsBioSummaryAssets = {
	__typename?: 'AdditionalPersonOptionsBioSummaryAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type AdditionalPersonOptionsBioSummaryEntries = {
	__typename?: 'AdditionalPersonOptionsBioSummaryEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type AdditionalPersonOptionsBioSummaryLinks = {
	__typename?: 'AdditionalPersonOptionsBioSummaryLinks'
	assets: AdditionalPersonOptionsBioSummaryAssets
	entries: AdditionalPersonOptionsBioSummaryEntries
	resources: AdditionalPersonOptionsBioSummaryResources
}

export type AdditionalPersonOptionsBioSummaryResources = {
	__typename?: 'AdditionalPersonOptionsBioSummaryResources'
	block: Array<AdditionalPersonOptionsBioSummaryResourcesBlock>
	hyperlink: Array<AdditionalPersonOptionsBioSummaryResourcesHyperlink>
	inline: Array<AdditionalPersonOptionsBioSummaryResourcesInline>
}

export type AdditionalPersonOptionsBioSummaryResourcesBlock = ResourceLink & {
	__typename?: 'AdditionalPersonOptionsBioSummaryResourcesBlock'
	sys: ResourceSys
}

export type AdditionalPersonOptionsBioSummaryResourcesHyperlink = ResourceLink & {
	__typename?: 'AdditionalPersonOptionsBioSummaryResourcesHyperlink'
	sys: ResourceSys
}

export type AdditionalPersonOptionsBioSummaryResourcesInline = ResourceLink & {
	__typename?: 'AdditionalPersonOptionsBioSummaryResourcesInline'
	sys: ResourceSys
}

export type AdditionalPersonOptionsCollection = {
	__typename?: 'AdditionalPersonOptionsCollection'
	items: Array<Maybe<AdditionalPersonOptions>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type AdditionalPersonOptionsFilter = {
	AND?: InputMaybe<Array<InputMaybe<AdditionalPersonOptionsFilter>>>
	OR?: InputMaybe<Array<InputMaybe<AdditionalPersonOptionsFilter>>>
	avatarOverride_exists?: InputMaybe<Scalars['Boolean']['input']>
	bioSummary_contains?: InputMaybe<Scalars['String']['input']>
	bioSummary_exists?: InputMaybe<Scalars['Boolean']['input']>
	bioSummary_not_contains?: InputMaybe<Scalars['String']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	fullBio_contains?: InputMaybe<Scalars['String']['input']>
	fullBio_exists?: InputMaybe<Scalars['Boolean']['input']>
	fullBio_not_contains?: InputMaybe<Scalars['String']['input']>
	heroBackground_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	intro_contains?: InputMaybe<Scalars['String']['input']>
	intro_exists?: InputMaybe<Scalars['Boolean']['input']>
	intro_not_contains?: InputMaybe<Scalars['String']['input']>
	relatedNews?: InputMaybe<CfExternalLinkNestedFilter>
	relatedNewsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	sys?: InputMaybe<SysFilter>
}

export type AdditionalPersonOptionsFullBio = {
	__typename?: 'AdditionalPersonOptionsFullBio'
	json: Scalars['JSON']['output']
	links: AdditionalPersonOptionsFullBioLinks
}

export type AdditionalPersonOptionsFullBioAssets = {
	__typename?: 'AdditionalPersonOptionsFullBioAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type AdditionalPersonOptionsFullBioEntries = {
	__typename?: 'AdditionalPersonOptionsFullBioEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type AdditionalPersonOptionsFullBioLinks = {
	__typename?: 'AdditionalPersonOptionsFullBioLinks'
	assets: AdditionalPersonOptionsFullBioAssets
	entries: AdditionalPersonOptionsFullBioEntries
	resources: AdditionalPersonOptionsFullBioResources
}

export type AdditionalPersonOptionsFullBioResources = {
	__typename?: 'AdditionalPersonOptionsFullBioResources'
	block: Array<AdditionalPersonOptionsFullBioResourcesBlock>
	hyperlink: Array<AdditionalPersonOptionsFullBioResourcesHyperlink>
	inline: Array<AdditionalPersonOptionsFullBioResourcesInline>
}

export type AdditionalPersonOptionsFullBioResourcesBlock = ResourceLink & {
	__typename?: 'AdditionalPersonOptionsFullBioResourcesBlock'
	sys: ResourceSys
}

export type AdditionalPersonOptionsFullBioResourcesHyperlink = ResourceLink & {
	__typename?: 'AdditionalPersonOptionsFullBioResourcesHyperlink'
	sys: ResourceSys
}

export type AdditionalPersonOptionsFullBioResourcesInline = ResourceLink & {
	__typename?: 'AdditionalPersonOptionsFullBioResourcesInline'
	sys: ResourceSys
}

export type AdditionalPersonOptionsIntro = {
	__typename?: 'AdditionalPersonOptionsIntro'
	json: Scalars['JSON']['output']
	links: AdditionalPersonOptionsIntroLinks
}

export type AdditionalPersonOptionsIntroAssets = {
	__typename?: 'AdditionalPersonOptionsIntroAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type AdditionalPersonOptionsIntroEntries = {
	__typename?: 'AdditionalPersonOptionsIntroEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type AdditionalPersonOptionsIntroLinks = {
	__typename?: 'AdditionalPersonOptionsIntroLinks'
	assets: AdditionalPersonOptionsIntroAssets
	entries: AdditionalPersonOptionsIntroEntries
	resources: AdditionalPersonOptionsIntroResources
}

export type AdditionalPersonOptionsIntroResources = {
	__typename?: 'AdditionalPersonOptionsIntroResources'
	block: Array<AdditionalPersonOptionsIntroResourcesBlock>
	hyperlink: Array<AdditionalPersonOptionsIntroResourcesHyperlink>
	inline: Array<AdditionalPersonOptionsIntroResourcesInline>
}

export type AdditionalPersonOptionsIntroResourcesBlock = ResourceLink & {
	__typename?: 'AdditionalPersonOptionsIntroResourcesBlock'
	sys: ResourceSys
}

export type AdditionalPersonOptionsIntroResourcesHyperlink = ResourceLink & {
	__typename?: 'AdditionalPersonOptionsIntroResourcesHyperlink'
	sys: ResourceSys
}

export type AdditionalPersonOptionsIntroResourcesInline = ResourceLink & {
	__typename?: 'AdditionalPersonOptionsIntroResourcesInline'
	sys: ResourceSys
}

export type AdditionalPersonOptionsLinkingCollections = {
	__typename?: 'AdditionalPersonOptionsLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
	personCollection?: Maybe<PersonCollection>
}

export type AdditionalPersonOptionsLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AdditionalPersonOptionsLinkingCollectionsPersonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<
		Array<InputMaybe<AdditionalPersonOptionsLinkingCollectionsPersonCollectionOrder>>
	>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum AdditionalPersonOptionsLinkingCollectionsPersonCollectionOrder {
	EmailAsc = 'email_ASC',
	EmailDesc = 'email_DESC',
	EnabledAsc = 'enabled_ASC',
	EnabledDesc = 'enabled_DESC',
	FullNameAsc = 'fullName_ASC',
	FullNameDesc = 'fullName_DESC',
	JobTitleAsc = 'jobTitle_ASC',
	JobTitleDesc = 'jobTitle_DESC',
	LinkedUrlAsc = 'linkedUrl_ASC',
	LinkedUrlDesc = 'linkedUrl_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum AdditionalPersonOptionsOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type AdditionalPersonOptionsRelatedNewsCollection = {
	__typename?: 'AdditionalPersonOptionsRelatedNewsCollection'
	items: Array<Maybe<ExternalLink>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum AdditionalPersonOptionsRelatedNewsCollectionOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	UrlAsc = 'url_ASC',
	UrlDesc = 'url_DESC'
}

/** Paths to run Amplitude Experiments [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/amplitudeExperiments) */
export type AmplitudeExperiments = Entry &
	_Node & {
		__typename?: 'AmplitudeExperiments'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		experimentFlag?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<AmplitudeExperimentsLinkingCollections>
		locale?: Maybe<Scalars['String']['output']>
		pathname?: Maybe<Scalars['String']['output']>
		sys: Sys
	}

/** Paths to run Amplitude Experiments [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/amplitudeExperiments) */
export type AmplitudeExperimentsExperimentFlagArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Paths to run Amplitude Experiments [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/amplitudeExperiments) */
export type AmplitudeExperimentsLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Paths to run Amplitude Experiments [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/amplitudeExperiments) */
export type AmplitudeExperimentsLocaleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Paths to run Amplitude Experiments [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/amplitudeExperiments) */
export type AmplitudeExperimentsPathnameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type AmplitudeExperimentsCollection = {
	__typename?: 'AmplitudeExperimentsCollection'
	items: Array<Maybe<AmplitudeExperiments>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type AmplitudeExperimentsFilter = {
	AND?: InputMaybe<Array<InputMaybe<AmplitudeExperimentsFilter>>>
	OR?: InputMaybe<Array<InputMaybe<AmplitudeExperimentsFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	experimentFlag?: InputMaybe<Scalars['String']['input']>
	experimentFlag_contains?: InputMaybe<Scalars['String']['input']>
	experimentFlag_exists?: InputMaybe<Scalars['Boolean']['input']>
	experimentFlag_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	experimentFlag_not?: InputMaybe<Scalars['String']['input']>
	experimentFlag_not_contains?: InputMaybe<Scalars['String']['input']>
	experimentFlag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	locale?: InputMaybe<Scalars['String']['input']>
	locale_contains?: InputMaybe<Scalars['String']['input']>
	locale_exists?: InputMaybe<Scalars['Boolean']['input']>
	locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	locale_not?: InputMaybe<Scalars['String']['input']>
	locale_not_contains?: InputMaybe<Scalars['String']['input']>
	locale_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	pathname?: InputMaybe<Scalars['String']['input']>
	pathname_contains?: InputMaybe<Scalars['String']['input']>
	pathname_exists?: InputMaybe<Scalars['Boolean']['input']>
	pathname_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	pathname_not?: InputMaybe<Scalars['String']['input']>
	pathname_not_contains?: InputMaybe<Scalars['String']['input']>
	pathname_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type AmplitudeExperimentsLinkingCollections = {
	__typename?: 'AmplitudeExperimentsLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
}

export type AmplitudeExperimentsLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum AmplitudeExperimentsOrder {
	ExperimentFlagAsc = 'experimentFlag_ASC',
	ExperimentFlagDesc = 'experimentFlag_DESC',
	LocaleAsc = 'locale_ASC',
	LocaleDesc = 'locale_DESC',
	PathnameAsc = 'pathname_ASC',
	PathnameDesc = 'pathname_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCaseStudy) */
export type AppCaseStudy = Entry &
	_Node & {
		__typename?: 'AppCaseStudy'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		description?: Maybe<AppCaseStudyDescription>
		linkedFrom?: Maybe<AppCaseStudyLinkingCollections>
		sys: Sys
		title?: Maybe<Scalars['String']['output']>
		url?: Maybe<Scalars['String']['output']>
	}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCaseStudy) */
export type AppCaseStudyDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCaseStudy) */
export type AppCaseStudyLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCaseStudy) */
export type AppCaseStudyTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCaseStudy) */
export type AppCaseStudyUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type AppCaseStudyCollection = {
	__typename?: 'AppCaseStudyCollection'
	items: Array<Maybe<AppCaseStudy>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type AppCaseStudyDescription = {
	__typename?: 'AppCaseStudyDescription'
	json: Scalars['JSON']['output']
	links: AppCaseStudyDescriptionLinks
}

export type AppCaseStudyDescriptionAssets = {
	__typename?: 'AppCaseStudyDescriptionAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type AppCaseStudyDescriptionEntries = {
	__typename?: 'AppCaseStudyDescriptionEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type AppCaseStudyDescriptionLinks = {
	__typename?: 'AppCaseStudyDescriptionLinks'
	assets: AppCaseStudyDescriptionAssets
	entries: AppCaseStudyDescriptionEntries
	resources: AppCaseStudyDescriptionResources
}

export type AppCaseStudyDescriptionResources = {
	__typename?: 'AppCaseStudyDescriptionResources'
	block: Array<AppCaseStudyDescriptionResourcesBlock>
	hyperlink: Array<AppCaseStudyDescriptionResourcesHyperlink>
	inline: Array<AppCaseStudyDescriptionResourcesInline>
}

export type AppCaseStudyDescriptionResourcesBlock = ResourceLink & {
	__typename?: 'AppCaseStudyDescriptionResourcesBlock'
	sys: ResourceSys
}

export type AppCaseStudyDescriptionResourcesHyperlink = ResourceLink & {
	__typename?: 'AppCaseStudyDescriptionResourcesHyperlink'
	sys: ResourceSys
}

export type AppCaseStudyDescriptionResourcesInline = ResourceLink & {
	__typename?: 'AppCaseStudyDescriptionResourcesInline'
	sys: ResourceSys
}

export type AppCaseStudyFilter = {
	AND?: InputMaybe<Array<InputMaybe<AppCaseStudyFilter>>>
	OR?: InputMaybe<Array<InputMaybe<AppCaseStudyFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	url?: InputMaybe<Scalars['String']['input']>
	url_contains?: InputMaybe<Scalars['String']['input']>
	url_exists?: InputMaybe<Scalars['Boolean']['input']>
	url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	url_not?: InputMaybe<Scalars['String']['input']>
	url_not_contains?: InputMaybe<Scalars['String']['input']>
	url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type AppCaseStudyLinkingCollections = {
	__typename?: 'AppCaseStudyLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
	marketplaceAppCollection?: Maybe<MarketplaceAppCollection>
}

export type AppCaseStudyLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AppCaseStudyLinkingCollectionsMarketplaceAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AppCaseStudyLinkingCollectionsMarketplaceAppCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum AppCaseStudyLinkingCollectionsMarketplaceAppCollectionOrder {
	AlternativeLogoAsc = 'alternativeLogo_ASC',
	AlternativeLogoDesc = 'alternativeLogo_DESC',
	AppRegistryIdAsc = 'appRegistryId_ASC',
	AppRegistryIdDesc = 'appRegistryId_DESC',
	AppRegistryUpdatedAtAsc = 'appRegistryUpdatedAt_ASC',
	AppRegistryUpdatedAtDesc = 'appRegistryUpdatedAt_DESC',
	ApprovedAtAsc = 'approvedAt_ASC',
	ApprovedAtDesc = 'approvedAt_DESC',
	CatalystCompatibleAsc = 'catalystCompatible_ASC',
	CatalystCompatibleDesc = 'catalystCompatible_DESC',
	CreatedOnAsc = 'createdOn_ASC',
	CreatedOnDesc = 'createdOn_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	FreeTrialDaysAsc = 'freeTrialDays_ASC',
	FreeTrialDaysDesc = 'freeTrialDays_DESC',
	FreeTrialAsc = 'freeTrial_ASC',
	FreeTrialDesc = 'freeTrial_DESC',
	InstallationGuideUrlAsc = 'installationGuideUrl_ASC',
	InstallationGuideUrlDesc = 'installationGuideUrl_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	LastHashAsc = 'lastHash_ASC',
	LastHashDesc = 'lastHash_DESC',
	LogoIconAsc = 'logoIcon_ASC',
	LogoIconDesc = 'logoIcon_DESC',
	LogoAsc = 'logo_ASC',
	LogoDesc = 'logo_DESC',
	MonthlyPriceAsc = 'monthlyPrice_ASC',
	MonthlyPriceDesc = 'monthlyPrice_DESC',
	MultiLocationAsc = 'multiLocation_ASC',
	MultiLocationDesc = 'multiLocation_DESC',
	MultiStorefrontAsc = 'multiStorefront_ASC',
	MultiStorefrontDesc = 'multiStorefront_DESC',
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	PricingTypeAsc = 'pricingType_ASC',
	PricingTypeDesc = 'pricingType_DESC',
	PricingAsc = 'pricing_ASC',
	PricingDesc = 'pricing_DESC',
	PrivacyPolicyUrlAsc = 'privacyPolicyUrl_ASC',
	PrivacyPolicyUrlDesc = 'privacyPolicyUrl_DESC',
	RankAsc = 'rank_ASC',
	RankDesc = 'rank_DESC',
	RatingAsc = 'rating_ASC',
	RatingDesc = 'rating_DESC',
	RecommendedOrderAsc = 'recommendedOrder_ASC',
	RecommendedOrderDesc = 'recommendedOrder_DESC',
	RecommendedAsc = 'recommended_ASC',
	RecommendedDesc = 'recommended_DESC',
	ReviewCountAsc = 'reviewCount_ASC',
	ReviewCountDesc = 'reviewCount_DESC',
	SingleClickAsc = 'singleClick_ASC',
	SingleClickDesc = 'singleClick_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SupportEmailAsc = 'supportEmail_ASC',
	SupportEmailDesc = 'supportEmail_DESC',
	SupportUrlAsc = 'supportUrl_ASC',
	SupportUrlDesc = 'supportUrl_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TermsOfServiceUrlAsc = 'termsOfServiceUrl_ASC',
	TermsOfServiceUrlDesc = 'termsOfServiceUrl_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC',
	UnifiedBillingAsc = 'unifiedBilling_ASC',
	UnifiedBillingDesc = 'unifiedBilling_DESC',
	UpfrontPriceAsc = 'upfrontPrice_ASC',
	UpfrontPriceDesc = 'upfrontPrice_DESC',
	UserGuideUrlAsc = 'userGuideUrl_ASC',
	UserGuideUrlDesc = 'userGuideUrl_DESC',
	VersionAsc = 'version_ASC',
	VersionDesc = 'version_DESC',
	WebsiteAsc = 'website_ASC',
	WebsiteDesc = 'website_DESC'
}

export enum AppCaseStudyOrder {
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	UrlAsc = 'url_ASC',
	UrlDesc = 'url_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCollection) */
export type AppCollection = Entry &
	_Node & {
		__typename?: 'AppCollection'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		description?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<AppCollectionLinkingCollections>
		name?: Maybe<Scalars['String']['output']>
		private?: Maybe<Scalars['Boolean']['output']>
		seoMetadata?: Maybe<SeoMetadata>
		slug?: Maybe<Scalars['String']['output']>
		sort?: Maybe<Scalars['Int']['output']>
		summary?: Maybe<Scalars['String']['output']>
		sys: Sys
		thumbnail?: Maybe<Scalars['String']['output']>
	}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCollection) */
export type AppCollectionDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCollection) */
export type AppCollectionLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCollection) */
export type AppCollectionNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCollection) */
export type AppCollectionPrivateArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCollection) */
export type AppCollectionSeoMetadataArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<SeoMetadataFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCollection) */
export type AppCollectionSlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCollection) */
export type AppCollectionSortArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCollection) */
export type AppCollectionSummaryArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appCollection) */
export type AppCollectionThumbnailArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type AppCollectionCollection = {
	__typename?: 'AppCollectionCollection'
	items: Array<Maybe<AppCollection>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type AppCollectionFilter = {
	AND?: InputMaybe<Array<InputMaybe<AppCollectionFilter>>>
	OR?: InputMaybe<Array<InputMaybe<AppCollectionFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description?: InputMaybe<Scalars['String']['input']>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	description_not?: InputMaybe<Scalars['String']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name?: InputMaybe<Scalars['String']['input']>
	name_contains?: InputMaybe<Scalars['String']['input']>
	name_exists?: InputMaybe<Scalars['Boolean']['input']>
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name_not?: InputMaybe<Scalars['String']['input']>
	name_not_contains?: InputMaybe<Scalars['String']['input']>
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	private?: InputMaybe<Scalars['Boolean']['input']>
	private_exists?: InputMaybe<Scalars['Boolean']['input']>
	private_not?: InputMaybe<Scalars['Boolean']['input']>
	seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sort?: InputMaybe<Scalars['Int']['input']>
	sort_exists?: InputMaybe<Scalars['Boolean']['input']>
	sort_gt?: InputMaybe<Scalars['Int']['input']>
	sort_gte?: InputMaybe<Scalars['Int']['input']>
	sort_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	sort_lt?: InputMaybe<Scalars['Int']['input']>
	sort_lte?: InputMaybe<Scalars['Int']['input']>
	sort_not?: InputMaybe<Scalars['Int']['input']>
	sort_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	summary?: InputMaybe<Scalars['String']['input']>
	summary_contains?: InputMaybe<Scalars['String']['input']>
	summary_exists?: InputMaybe<Scalars['Boolean']['input']>
	summary_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	summary_not?: InputMaybe<Scalars['String']['input']>
	summary_not_contains?: InputMaybe<Scalars['String']['input']>
	summary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	thumbnail?: InputMaybe<Scalars['String']['input']>
	thumbnail_contains?: InputMaybe<Scalars['String']['input']>
	thumbnail_exists?: InputMaybe<Scalars['Boolean']['input']>
	thumbnail_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	thumbnail_not?: InputMaybe<Scalars['String']['input']>
	thumbnail_not_contains?: InputMaybe<Scalars['String']['input']>
	thumbnail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type AppCollectionLinkingCollections = {
	__typename?: 'AppCollectionLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
	marketplaceAppCollection?: Maybe<MarketplaceAppCollection>
}

export type AppCollectionLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AppCollectionLinkingCollectionsMarketplaceAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<
		Array<InputMaybe<AppCollectionLinkingCollectionsMarketplaceAppCollectionOrder>>
	>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum AppCollectionLinkingCollectionsMarketplaceAppCollectionOrder {
	AlternativeLogoAsc = 'alternativeLogo_ASC',
	AlternativeLogoDesc = 'alternativeLogo_DESC',
	AppRegistryIdAsc = 'appRegistryId_ASC',
	AppRegistryIdDesc = 'appRegistryId_DESC',
	AppRegistryUpdatedAtAsc = 'appRegistryUpdatedAt_ASC',
	AppRegistryUpdatedAtDesc = 'appRegistryUpdatedAt_DESC',
	ApprovedAtAsc = 'approvedAt_ASC',
	ApprovedAtDesc = 'approvedAt_DESC',
	CatalystCompatibleAsc = 'catalystCompatible_ASC',
	CatalystCompatibleDesc = 'catalystCompatible_DESC',
	CreatedOnAsc = 'createdOn_ASC',
	CreatedOnDesc = 'createdOn_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	FreeTrialDaysAsc = 'freeTrialDays_ASC',
	FreeTrialDaysDesc = 'freeTrialDays_DESC',
	FreeTrialAsc = 'freeTrial_ASC',
	FreeTrialDesc = 'freeTrial_DESC',
	InstallationGuideUrlAsc = 'installationGuideUrl_ASC',
	InstallationGuideUrlDesc = 'installationGuideUrl_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	LastHashAsc = 'lastHash_ASC',
	LastHashDesc = 'lastHash_DESC',
	LogoIconAsc = 'logoIcon_ASC',
	LogoIconDesc = 'logoIcon_DESC',
	LogoAsc = 'logo_ASC',
	LogoDesc = 'logo_DESC',
	MonthlyPriceAsc = 'monthlyPrice_ASC',
	MonthlyPriceDesc = 'monthlyPrice_DESC',
	MultiLocationAsc = 'multiLocation_ASC',
	MultiLocationDesc = 'multiLocation_DESC',
	MultiStorefrontAsc = 'multiStorefront_ASC',
	MultiStorefrontDesc = 'multiStorefront_DESC',
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	PricingTypeAsc = 'pricingType_ASC',
	PricingTypeDesc = 'pricingType_DESC',
	PricingAsc = 'pricing_ASC',
	PricingDesc = 'pricing_DESC',
	PrivacyPolicyUrlAsc = 'privacyPolicyUrl_ASC',
	PrivacyPolicyUrlDesc = 'privacyPolicyUrl_DESC',
	RankAsc = 'rank_ASC',
	RankDesc = 'rank_DESC',
	RatingAsc = 'rating_ASC',
	RatingDesc = 'rating_DESC',
	RecommendedOrderAsc = 'recommendedOrder_ASC',
	RecommendedOrderDesc = 'recommendedOrder_DESC',
	RecommendedAsc = 'recommended_ASC',
	RecommendedDesc = 'recommended_DESC',
	ReviewCountAsc = 'reviewCount_ASC',
	ReviewCountDesc = 'reviewCount_DESC',
	SingleClickAsc = 'singleClick_ASC',
	SingleClickDesc = 'singleClick_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SupportEmailAsc = 'supportEmail_ASC',
	SupportEmailDesc = 'supportEmail_DESC',
	SupportUrlAsc = 'supportUrl_ASC',
	SupportUrlDesc = 'supportUrl_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TermsOfServiceUrlAsc = 'termsOfServiceUrl_ASC',
	TermsOfServiceUrlDesc = 'termsOfServiceUrl_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC',
	UnifiedBillingAsc = 'unifiedBilling_ASC',
	UnifiedBillingDesc = 'unifiedBilling_DESC',
	UpfrontPriceAsc = 'upfrontPrice_ASC',
	UpfrontPriceDesc = 'upfrontPrice_DESC',
	UserGuideUrlAsc = 'userGuideUrl_ASC',
	UserGuideUrlDesc = 'userGuideUrl_DESC',
	VersionAsc = 'version_ASC',
	VersionDesc = 'version_DESC',
	WebsiteAsc = 'website_ASC',
	WebsiteDesc = 'website_DESC'
}

export enum AppCollectionOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	PrivateAsc = 'private_ASC',
	PrivateDesc = 'private_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SortAsc = 'sort_ASC',
	SortDesc = 'sort_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	ThumbnailAsc = 'thumbnail_ASC',
	ThumbnailDesc = 'thumbnail_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appFeature) */
export type AppFeature = Entry &
	_Node & {
		__typename?: 'AppFeature'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		description?: Maybe<AppFeatureDescription>
		linkedFrom?: Maybe<AppFeatureLinkingCollections>
		sys: Sys
		title?: Maybe<Scalars['String']['output']>
	}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appFeature) */
export type AppFeatureDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appFeature) */
export type AppFeatureLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appFeature) */
export type AppFeatureTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type AppFeatureCollection = {
	__typename?: 'AppFeatureCollection'
	items: Array<Maybe<AppFeature>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type AppFeatureDescription = {
	__typename?: 'AppFeatureDescription'
	json: Scalars['JSON']['output']
	links: AppFeatureDescriptionLinks
}

export type AppFeatureDescriptionAssets = {
	__typename?: 'AppFeatureDescriptionAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type AppFeatureDescriptionEntries = {
	__typename?: 'AppFeatureDescriptionEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type AppFeatureDescriptionLinks = {
	__typename?: 'AppFeatureDescriptionLinks'
	assets: AppFeatureDescriptionAssets
	entries: AppFeatureDescriptionEntries
	resources: AppFeatureDescriptionResources
}

export type AppFeatureDescriptionResources = {
	__typename?: 'AppFeatureDescriptionResources'
	block: Array<AppFeatureDescriptionResourcesBlock>
	hyperlink: Array<AppFeatureDescriptionResourcesHyperlink>
	inline: Array<AppFeatureDescriptionResourcesInline>
}

export type AppFeatureDescriptionResourcesBlock = ResourceLink & {
	__typename?: 'AppFeatureDescriptionResourcesBlock'
	sys: ResourceSys
}

export type AppFeatureDescriptionResourcesHyperlink = ResourceLink & {
	__typename?: 'AppFeatureDescriptionResourcesHyperlink'
	sys: ResourceSys
}

export type AppFeatureDescriptionResourcesInline = ResourceLink & {
	__typename?: 'AppFeatureDescriptionResourcesInline'
	sys: ResourceSys
}

export type AppFeatureFilter = {
	AND?: InputMaybe<Array<InputMaybe<AppFeatureFilter>>>
	OR?: InputMaybe<Array<InputMaybe<AppFeatureFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type AppFeatureLinkingCollections = {
	__typename?: 'AppFeatureLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
	marketplaceAppCollection?: Maybe<MarketplaceAppCollection>
}

export type AppFeatureLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AppFeatureLinkingCollectionsMarketplaceAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AppFeatureLinkingCollectionsMarketplaceAppCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum AppFeatureLinkingCollectionsMarketplaceAppCollectionOrder {
	AlternativeLogoAsc = 'alternativeLogo_ASC',
	AlternativeLogoDesc = 'alternativeLogo_DESC',
	AppRegistryIdAsc = 'appRegistryId_ASC',
	AppRegistryIdDesc = 'appRegistryId_DESC',
	AppRegistryUpdatedAtAsc = 'appRegistryUpdatedAt_ASC',
	AppRegistryUpdatedAtDesc = 'appRegistryUpdatedAt_DESC',
	ApprovedAtAsc = 'approvedAt_ASC',
	ApprovedAtDesc = 'approvedAt_DESC',
	CatalystCompatibleAsc = 'catalystCompatible_ASC',
	CatalystCompatibleDesc = 'catalystCompatible_DESC',
	CreatedOnAsc = 'createdOn_ASC',
	CreatedOnDesc = 'createdOn_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	FreeTrialDaysAsc = 'freeTrialDays_ASC',
	FreeTrialDaysDesc = 'freeTrialDays_DESC',
	FreeTrialAsc = 'freeTrial_ASC',
	FreeTrialDesc = 'freeTrial_DESC',
	InstallationGuideUrlAsc = 'installationGuideUrl_ASC',
	InstallationGuideUrlDesc = 'installationGuideUrl_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	LastHashAsc = 'lastHash_ASC',
	LastHashDesc = 'lastHash_DESC',
	LogoIconAsc = 'logoIcon_ASC',
	LogoIconDesc = 'logoIcon_DESC',
	LogoAsc = 'logo_ASC',
	LogoDesc = 'logo_DESC',
	MonthlyPriceAsc = 'monthlyPrice_ASC',
	MonthlyPriceDesc = 'monthlyPrice_DESC',
	MultiLocationAsc = 'multiLocation_ASC',
	MultiLocationDesc = 'multiLocation_DESC',
	MultiStorefrontAsc = 'multiStorefront_ASC',
	MultiStorefrontDesc = 'multiStorefront_DESC',
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	PricingTypeAsc = 'pricingType_ASC',
	PricingTypeDesc = 'pricingType_DESC',
	PricingAsc = 'pricing_ASC',
	PricingDesc = 'pricing_DESC',
	PrivacyPolicyUrlAsc = 'privacyPolicyUrl_ASC',
	PrivacyPolicyUrlDesc = 'privacyPolicyUrl_DESC',
	RankAsc = 'rank_ASC',
	RankDesc = 'rank_DESC',
	RatingAsc = 'rating_ASC',
	RatingDesc = 'rating_DESC',
	RecommendedOrderAsc = 'recommendedOrder_ASC',
	RecommendedOrderDesc = 'recommendedOrder_DESC',
	RecommendedAsc = 'recommended_ASC',
	RecommendedDesc = 'recommended_DESC',
	ReviewCountAsc = 'reviewCount_ASC',
	ReviewCountDesc = 'reviewCount_DESC',
	SingleClickAsc = 'singleClick_ASC',
	SingleClickDesc = 'singleClick_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SupportEmailAsc = 'supportEmail_ASC',
	SupportEmailDesc = 'supportEmail_DESC',
	SupportUrlAsc = 'supportUrl_ASC',
	SupportUrlDesc = 'supportUrl_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TermsOfServiceUrlAsc = 'termsOfServiceUrl_ASC',
	TermsOfServiceUrlDesc = 'termsOfServiceUrl_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC',
	UnifiedBillingAsc = 'unifiedBilling_ASC',
	UnifiedBillingDesc = 'unifiedBilling_DESC',
	UpfrontPriceAsc = 'upfrontPrice_ASC',
	UpfrontPriceDesc = 'upfrontPrice_DESC',
	UserGuideUrlAsc = 'userGuideUrl_ASC',
	UserGuideUrlDesc = 'userGuideUrl_DESC',
	VersionAsc = 'version_ASC',
	VersionDesc = 'version_DESC',
	WebsiteAsc = 'website_ASC',
	WebsiteDesc = 'website_DESC'
}

export enum AppFeatureOrder {
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appTier) */
export type AppTier = Entry &
	_Node & {
		__typename?: 'AppTier'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		description?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<AppTierLinkingCollections>
		logo?: Maybe<Asset>
		name?: Maybe<Scalars['String']['output']>
		seoMetadata?: Maybe<SeoMetadata>
		slug?: Maybe<Scalars['String']['output']>
		sort?: Maybe<Scalars['Int']['output']>
		sys: Sys
	}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appTier) */
export type AppTierDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appTier) */
export type AppTierLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appTier) */
export type AppTierLogoArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appTier) */
export type AppTierNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appTier) */
export type AppTierSeoMetadataArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<SeoMetadataFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appTier) */
export type AppTierSlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/appTier) */
export type AppTierSortArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type AppTierCollection = {
	__typename?: 'AppTierCollection'
	items: Array<Maybe<AppTier>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type AppTierFilter = {
	AND?: InputMaybe<Array<InputMaybe<AppTierFilter>>>
	OR?: InputMaybe<Array<InputMaybe<AppTierFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description?: InputMaybe<Scalars['String']['input']>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	description_not?: InputMaybe<Scalars['String']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	logo_exists?: InputMaybe<Scalars['Boolean']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	name_contains?: InputMaybe<Scalars['String']['input']>
	name_exists?: InputMaybe<Scalars['Boolean']['input']>
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name_not?: InputMaybe<Scalars['String']['input']>
	name_not_contains?: InputMaybe<Scalars['String']['input']>
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sort?: InputMaybe<Scalars['Int']['input']>
	sort_exists?: InputMaybe<Scalars['Boolean']['input']>
	sort_gt?: InputMaybe<Scalars['Int']['input']>
	sort_gte?: InputMaybe<Scalars['Int']['input']>
	sort_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	sort_lt?: InputMaybe<Scalars['Int']['input']>
	sort_lte?: InputMaybe<Scalars['Int']['input']>
	sort_not?: InputMaybe<Scalars['Int']['input']>
	sort_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type AppTierLinkingCollections = {
	__typename?: 'AppTierLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
	marketplaceAppCollection?: Maybe<MarketplaceAppCollection>
}

export type AppTierLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AppTierLinkingCollectionsMarketplaceAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AppTierLinkingCollectionsMarketplaceAppCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum AppTierLinkingCollectionsMarketplaceAppCollectionOrder {
	AlternativeLogoAsc = 'alternativeLogo_ASC',
	AlternativeLogoDesc = 'alternativeLogo_DESC',
	AppRegistryIdAsc = 'appRegistryId_ASC',
	AppRegistryIdDesc = 'appRegistryId_DESC',
	AppRegistryUpdatedAtAsc = 'appRegistryUpdatedAt_ASC',
	AppRegistryUpdatedAtDesc = 'appRegistryUpdatedAt_DESC',
	ApprovedAtAsc = 'approvedAt_ASC',
	ApprovedAtDesc = 'approvedAt_DESC',
	CatalystCompatibleAsc = 'catalystCompatible_ASC',
	CatalystCompatibleDesc = 'catalystCompatible_DESC',
	CreatedOnAsc = 'createdOn_ASC',
	CreatedOnDesc = 'createdOn_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	FreeTrialDaysAsc = 'freeTrialDays_ASC',
	FreeTrialDaysDesc = 'freeTrialDays_DESC',
	FreeTrialAsc = 'freeTrial_ASC',
	FreeTrialDesc = 'freeTrial_DESC',
	InstallationGuideUrlAsc = 'installationGuideUrl_ASC',
	InstallationGuideUrlDesc = 'installationGuideUrl_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	LastHashAsc = 'lastHash_ASC',
	LastHashDesc = 'lastHash_DESC',
	LogoIconAsc = 'logoIcon_ASC',
	LogoIconDesc = 'logoIcon_DESC',
	LogoAsc = 'logo_ASC',
	LogoDesc = 'logo_DESC',
	MonthlyPriceAsc = 'monthlyPrice_ASC',
	MonthlyPriceDesc = 'monthlyPrice_DESC',
	MultiLocationAsc = 'multiLocation_ASC',
	MultiLocationDesc = 'multiLocation_DESC',
	MultiStorefrontAsc = 'multiStorefront_ASC',
	MultiStorefrontDesc = 'multiStorefront_DESC',
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	PricingTypeAsc = 'pricingType_ASC',
	PricingTypeDesc = 'pricingType_DESC',
	PricingAsc = 'pricing_ASC',
	PricingDesc = 'pricing_DESC',
	PrivacyPolicyUrlAsc = 'privacyPolicyUrl_ASC',
	PrivacyPolicyUrlDesc = 'privacyPolicyUrl_DESC',
	RankAsc = 'rank_ASC',
	RankDesc = 'rank_DESC',
	RatingAsc = 'rating_ASC',
	RatingDesc = 'rating_DESC',
	RecommendedOrderAsc = 'recommendedOrder_ASC',
	RecommendedOrderDesc = 'recommendedOrder_DESC',
	RecommendedAsc = 'recommended_ASC',
	RecommendedDesc = 'recommended_DESC',
	ReviewCountAsc = 'reviewCount_ASC',
	ReviewCountDesc = 'reviewCount_DESC',
	SingleClickAsc = 'singleClick_ASC',
	SingleClickDesc = 'singleClick_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SupportEmailAsc = 'supportEmail_ASC',
	SupportEmailDesc = 'supportEmail_DESC',
	SupportUrlAsc = 'supportUrl_ASC',
	SupportUrlDesc = 'supportUrl_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TermsOfServiceUrlAsc = 'termsOfServiceUrl_ASC',
	TermsOfServiceUrlDesc = 'termsOfServiceUrl_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC',
	UnifiedBillingAsc = 'unifiedBilling_ASC',
	UnifiedBillingDesc = 'unifiedBilling_DESC',
	UpfrontPriceAsc = 'upfrontPrice_ASC',
	UpfrontPriceDesc = 'upfrontPrice_DESC',
	UserGuideUrlAsc = 'userGuideUrl_ASC',
	UserGuideUrlDesc = 'userGuideUrl_DESC',
	VersionAsc = 'version_ASC',
	VersionDesc = 'version_DESC',
	WebsiteAsc = 'website_ASC',
	WebsiteDesc = 'website_DESC'
}

export enum AppTierOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SortAsc = 'sort_ASC',
	SortDesc = 'sort_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type Article = Entry &
	_Node & {
		__typename?: 'Article'
		_id: Scalars['ID']['output']
		additionalResourcesOverridesCollection?: Maybe<ArticleAdditionalResourcesOverridesCollection>
		author?: Maybe<Person>
		body?: Maybe<ArticleBody>
		cardSummary?: Maybe<Scalars['String']['output']>
		contentfulMetadata: ContentfulMetadata
		editStatus?: Maybe<Scalars['String']['output']>
		faqsCollection?: Maybe<ArticleFaqsCollection>
		faqsHeading?: Maybe<Scalars['String']['output']>
		featuredImage?: Maybe<Asset>
		gatedContentSettings?: Maybe<GatedContent>
		hero?: Maybe<Hero>
		internalName?: Maybe<Scalars['String']['output']>
		interstitialCta?: Maybe<CtaButton>
		interstitialHeadline?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<ArticleLinkingCollections>
		parentArticle?: Maybe<Article>
		popular?: Maybe<Scalars['Boolean']['output']>
		publishedDate?: Maybe<Scalars['DateTime']['output']>
		secondaryCtasCollection?: Maybe<ArticleSecondaryCtasCollection>
		secondaryHero?: Maybe<Hero>
		seoMetadata?: Maybe<SeoMetadata>
		slug?: Maybe<Scalars['String']['output']>
		summary?: Maybe<ArticleSummary>
		sys: Sys
		title?: Maybe<Scalars['String']['output']>
	}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleAdditionalResourcesOverridesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ArticleAdditionalResourcesOverridesCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<ArticleFilter>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleAuthorArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<PersonFilter>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleBodyArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleCardSummaryArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleEditStatusArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleFaqsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ArticleFaqsCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<FaqItemFilter>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleFaqsHeadingArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleFeaturedImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleGatedContentSettingsArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<GatedContentFilter>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleHeroArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<HeroFilter>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleInterstitialCtaArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<CtaButtonFilter>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleInterstitialHeadlineArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleParentArticleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<ArticleFilter>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticlePopularArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticlePublishedDateArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleSecondaryCtasCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ArticleSecondaryCtasCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<BasicContentCardFilter>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleSecondaryHeroArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<HeroFilter>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleSeoMetadataArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<SeoMetadataFilter>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleSlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleSummaryArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Article options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/article) */
export type ArticleTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type ArticleAdditionalResourcesOverridesCollection = {
	__typename?: 'ArticleAdditionalResourcesOverridesCollection'
	items: Array<Maybe<Article>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum ArticleAdditionalResourcesOverridesCollectionOrder {
	EditStatusAsc = 'editStatus_ASC',
	EditStatusDesc = 'editStatus_DESC',
	FaqsHeadingAsc = 'faqsHeading_ASC',
	FaqsHeadingDesc = 'faqsHeading_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	InterstitialHeadlineAsc = 'interstitialHeadline_ASC',
	InterstitialHeadlineDesc = 'interstitialHeadline_DESC',
	PopularAsc = 'popular_ASC',
	PopularDesc = 'popular_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export type ArticleBody = {
	__typename?: 'ArticleBody'
	json: Scalars['JSON']['output']
	links: ArticleBodyLinks
}

export type ArticleBodyAssets = {
	__typename?: 'ArticleBodyAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type ArticleBodyEntries = {
	__typename?: 'ArticleBodyEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type ArticleBodyLinks = {
	__typename?: 'ArticleBodyLinks'
	assets: ArticleBodyAssets
	entries: ArticleBodyEntries
	resources: ArticleBodyResources
}

export type ArticleBodyResources = {
	__typename?: 'ArticleBodyResources'
	block: Array<ArticleBodyResourcesBlock>
	hyperlink: Array<ArticleBodyResourcesHyperlink>
	inline: Array<ArticleBodyResourcesInline>
}

export type ArticleBodyResourcesBlock = ResourceLink & {
	__typename?: 'ArticleBodyResourcesBlock'
	sys: ResourceSys
}

export type ArticleBodyResourcesHyperlink = ResourceLink & {
	__typename?: 'ArticleBodyResourcesHyperlink'
	sys: ResourceSys
}

export type ArticleBodyResourcesInline = ResourceLink & {
	__typename?: 'ArticleBodyResourcesInline'
	sys: ResourceSys
}

export type ArticleCollection = {
	__typename?: 'ArticleCollection'
	items: Array<Maybe<Article>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type ArticleFaqsCollection = {
	__typename?: 'ArticleFaqsCollection'
	items: Array<Maybe<FaqItem>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum ArticleFaqsCollectionOrder {
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ArticleFilter = {
	AND?: InputMaybe<Array<InputMaybe<ArticleFilter>>>
	OR?: InputMaybe<Array<InputMaybe<ArticleFilter>>>
	additionalResourcesOverrides?: InputMaybe<CfArticleNestedFilter>
	additionalResourcesOverridesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	author?: InputMaybe<CfPersonNestedFilter>
	author_exists?: InputMaybe<Scalars['Boolean']['input']>
	body_contains?: InputMaybe<Scalars['String']['input']>
	body_exists?: InputMaybe<Scalars['Boolean']['input']>
	body_not_contains?: InputMaybe<Scalars['String']['input']>
	cardSummary?: InputMaybe<Scalars['String']['input']>
	cardSummary_contains?: InputMaybe<Scalars['String']['input']>
	cardSummary_exists?: InputMaybe<Scalars['Boolean']['input']>
	cardSummary_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	cardSummary_not?: InputMaybe<Scalars['String']['input']>
	cardSummary_not_contains?: InputMaybe<Scalars['String']['input']>
	cardSummary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	editStatus?: InputMaybe<Scalars['String']['input']>
	editStatus_contains?: InputMaybe<Scalars['String']['input']>
	editStatus_exists?: InputMaybe<Scalars['Boolean']['input']>
	editStatus_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	editStatus_not?: InputMaybe<Scalars['String']['input']>
	editStatus_not_contains?: InputMaybe<Scalars['String']['input']>
	editStatus_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	faqs?: InputMaybe<CfFaqItemNestedFilter>
	faqsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	faqsHeading?: InputMaybe<Scalars['String']['input']>
	faqsHeading_contains?: InputMaybe<Scalars['String']['input']>
	faqsHeading_exists?: InputMaybe<Scalars['Boolean']['input']>
	faqsHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	faqsHeading_not?: InputMaybe<Scalars['String']['input']>
	faqsHeading_not_contains?: InputMaybe<Scalars['String']['input']>
	faqsHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredImage_exists?: InputMaybe<Scalars['Boolean']['input']>
	gatedContentSettings?: InputMaybe<CfGatedContentNestedFilter>
	gatedContentSettings_exists?: InputMaybe<Scalars['Boolean']['input']>
	hero?: InputMaybe<CfHeroNestedFilter>
	hero_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	interstitialCta?: InputMaybe<CfCtaButtonNestedFilter>
	interstitialCta_exists?: InputMaybe<Scalars['Boolean']['input']>
	interstitialHeadline?: InputMaybe<Scalars['String']['input']>
	interstitialHeadline_contains?: InputMaybe<Scalars['String']['input']>
	interstitialHeadline_exists?: InputMaybe<Scalars['Boolean']['input']>
	interstitialHeadline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	interstitialHeadline_not?: InputMaybe<Scalars['String']['input']>
	interstitialHeadline_not_contains?: InputMaybe<Scalars['String']['input']>
	interstitialHeadline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	parentArticle?: InputMaybe<CfArticleNestedFilter>
	parentArticle_exists?: InputMaybe<Scalars['Boolean']['input']>
	popular?: InputMaybe<Scalars['Boolean']['input']>
	popular_exists?: InputMaybe<Scalars['Boolean']['input']>
	popular_not?: InputMaybe<Scalars['Boolean']['input']>
	publishedDate?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_exists?: InputMaybe<Scalars['Boolean']['input']>
	publishedDate_gt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_gte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	publishedDate_lt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_lte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	secondaryCtas?: InputMaybe<CfBasicContentCardNestedFilter>
	secondaryCtasCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	secondaryHero?: InputMaybe<CfHeroNestedFilter>
	secondaryHero_exists?: InputMaybe<Scalars['Boolean']['input']>
	seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	summary_contains?: InputMaybe<Scalars['String']['input']>
	summary_exists?: InputMaybe<Scalars['Boolean']['input']>
	summary_not_contains?: InputMaybe<Scalars['String']['input']>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ArticleLinkingCollections = {
	__typename?: 'ArticleLinkingCollections'
	articleCollection?: Maybe<ArticleCollection>
	entryCollection?: Maybe<EntryCollection>
	resourceCollection?: Maybe<ResourceCollection>
}

export type ArticleLinkingCollectionsArticleCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ArticleLinkingCollectionsArticleCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type ArticleLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type ArticleLinkingCollectionsResourceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ArticleLinkingCollectionsResourceCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum ArticleLinkingCollectionsArticleCollectionOrder {
	EditStatusAsc = 'editStatus_ASC',
	EditStatusDesc = 'editStatus_DESC',
	FaqsHeadingAsc = 'faqsHeading_ASC',
	FaqsHeadingDesc = 'faqsHeading_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	InterstitialHeadlineAsc = 'interstitialHeadline_ASC',
	InterstitialHeadlineDesc = 'interstitialHeadline_DESC',
	PopularAsc = 'popular_ASC',
	PopularDesc = 'popular_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum ArticleLinkingCollectionsResourceCollectionOrder {
	FormButtonTextAsc = 'formButtonText_ASC',
	FormButtonTextDesc = 'formButtonText_DESC',
	FormIdAsc = 'formId_ASC',
	FormIdDesc = 'formId_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum ArticleOrder {
	EditStatusAsc = 'editStatus_ASC',
	EditStatusDesc = 'editStatus_DESC',
	FaqsHeadingAsc = 'faqsHeading_ASC',
	FaqsHeadingDesc = 'faqsHeading_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	InterstitialHeadlineAsc = 'interstitialHeadline_ASC',
	InterstitialHeadlineDesc = 'interstitialHeadline_DESC',
	PopularAsc = 'popular_ASC',
	PopularDesc = 'popular_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export type ArticleSecondaryCtasCollection = {
	__typename?: 'ArticleSecondaryCtasCollection'
	items: Array<Maybe<BasicContentCard>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum ArticleSecondaryCtasCollectionOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	ThemeAsc = 'theme_ASC',
	ThemeDesc = 'theme_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export type ArticleSummary = {
	__typename?: 'ArticleSummary'
	json: Scalars['JSON']['output']
	links: ArticleSummaryLinks
}

export type ArticleSummaryAssets = {
	__typename?: 'ArticleSummaryAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type ArticleSummaryEntries = {
	__typename?: 'ArticleSummaryEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type ArticleSummaryLinks = {
	__typename?: 'ArticleSummaryLinks'
	assets: ArticleSummaryAssets
	entries: ArticleSummaryEntries
	resources: ArticleSummaryResources
}

export type ArticleSummaryResources = {
	__typename?: 'ArticleSummaryResources'
	block: Array<ArticleSummaryResourcesBlock>
	hyperlink: Array<ArticleSummaryResourcesHyperlink>
	inline: Array<ArticleSummaryResourcesInline>
}

export type ArticleSummaryResourcesBlock = ResourceLink & {
	__typename?: 'ArticleSummaryResourcesBlock'
	sys: ResourceSys
}

export type ArticleSummaryResourcesHyperlink = ResourceLink & {
	__typename?: 'ArticleSummaryResourcesHyperlink'
	sys: ResourceSys
}

export type ArticleSummaryResourcesInline = ResourceLink & {
	__typename?: 'ArticleSummaryResourcesInline'
	sys: ResourceSys
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
	__typename?: 'Asset'
	contentType?: Maybe<Scalars['String']['output']>
	contentfulMetadata: ContentfulMetadata
	description?: Maybe<Scalars['String']['output']>
	fileName?: Maybe<Scalars['String']['output']>
	height?: Maybe<Scalars['Int']['output']>
	linkedFrom?: Maybe<AssetLinkingCollections>
	size?: Maybe<Scalars['Int']['output']>
	sys: Sys
	title?: Maybe<Scalars['String']['output']>
	url?: Maybe<Scalars['String']['output']>
	width?: Maybe<Scalars['Int']['output']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	transform?: InputMaybe<ImageTransformOptions>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type AssetCollection = {
	__typename?: 'AssetCollection'
	items: Array<Maybe<Asset>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

/** A wrapper for assets linked from an external url [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/assetExternal) */
export type AssetExternal = Entry &
	_Node & {
		__typename?: 'AssetExternal'
		_id: Scalars['ID']['output']
		altText?: Maybe<Scalars['String']['output']>
		assetType?: Maybe<Scalars['String']['output']>
		contentfulMetadata: ContentfulMetadata
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<AssetExternalLinkingCollections>
		sys: Sys
		url?: Maybe<Scalars['String']['output']>
	}

/** A wrapper for assets linked from an external url [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/assetExternal) */
export type AssetExternalAltTextArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** A wrapper for assets linked from an external url [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/assetExternal) */
export type AssetExternalAssetTypeArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** A wrapper for assets linked from an external url [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/assetExternal) */
export type AssetExternalInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** A wrapper for assets linked from an external url [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/assetExternal) */
export type AssetExternalLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** A wrapper for assets linked from an external url [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/assetExternal) */
export type AssetExternalUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type AssetExternalCollection = {
	__typename?: 'AssetExternalCollection'
	items: Array<Maybe<AssetExternal>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type AssetExternalFilter = {
	AND?: InputMaybe<Array<InputMaybe<AssetExternalFilter>>>
	OR?: InputMaybe<Array<InputMaybe<AssetExternalFilter>>>
	altText?: InputMaybe<Scalars['String']['input']>
	altText_contains?: InputMaybe<Scalars['String']['input']>
	altText_exists?: InputMaybe<Scalars['Boolean']['input']>
	altText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	altText_not?: InputMaybe<Scalars['String']['input']>
	altText_not_contains?: InputMaybe<Scalars['String']['input']>
	altText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	assetType?: InputMaybe<Scalars['String']['input']>
	assetType_contains?: InputMaybe<Scalars['String']['input']>
	assetType_exists?: InputMaybe<Scalars['Boolean']['input']>
	assetType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	assetType_not?: InputMaybe<Scalars['String']['input']>
	assetType_not_contains?: InputMaybe<Scalars['String']['input']>
	assetType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	url?: InputMaybe<Scalars['String']['input']>
	url_contains?: InputMaybe<Scalars['String']['input']>
	url_exists?: InputMaybe<Scalars['Boolean']['input']>
	url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	url_not?: InputMaybe<Scalars['String']['input']>
	url_not_contains?: InputMaybe<Scalars['String']['input']>
	url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type AssetExternalLinkingCollections = {
	__typename?: 'AssetExternalLinkingCollections'
	caseStudyCollection?: Maybe<CaseStudyCollection>
	entryCollection?: Maybe<EntryCollection>
	heroCollection?: Maybe<HeroCollection>
	pressCollection?: Maybe<PressCollection>
	showcaseCollection?: Maybe<ShowcaseCollection>
}

export type AssetExternalLinkingCollectionsCaseStudyCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AssetExternalLinkingCollectionsCaseStudyCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetExternalLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetExternalLinkingCollectionsHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AssetExternalLinkingCollectionsHeroCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetExternalLinkingCollectionsPressCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AssetExternalLinkingCollectionsPressCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetExternalLinkingCollectionsShowcaseCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AssetExternalLinkingCollectionsShowcaseCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum AssetExternalLinkingCollectionsCaseStudyCollectionOrder {
	FeaturedAuthorNameAsc = 'featuredAuthorName_ASC',
	FeaturedAuthorNameDesc = 'featuredAuthorName_DESC',
	FeaturedAuthorTitleAsc = 'featuredAuthorTitle_ASC',
	FeaturedAuthorTitleDesc = 'featuredAuthorTitle_DESC',
	FeaturedLinkTextAsc = 'featuredLinkText_ASC',
	FeaturedLinkTextDesc = 'featuredLinkText_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	WhitepaperStandinAsc = 'whitepaperStandin_ASC',
	WhitepaperStandinDesc = 'whitepaperStandin_DESC'
}

export enum AssetExternalLinkingCollectionsHeroCollectionOrder {
	BcLogoAsc = 'bcLogo_ASC',
	BcLogoDesc = 'bcLogo_DESC',
	HeadlineAsc = 'headline_ASC',
	HeadlineDesc = 'headline_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum AssetExternalLinkingCollectionsPressCollectionOrder {
	EnabledAsc = 'enabled_ASC',
	EnabledDesc = 'enabled_DESC',
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum AssetExternalLinkingCollectionsShowcaseCollectionOrder {
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum AssetExternalOrder {
	AltTextAsc = 'altText_ASC',
	AltTextDesc = 'altText_DESC',
	AssetTypeAsc = 'assetType_ASC',
	AssetTypeDesc = 'assetType_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	UrlAsc = 'url_ASC',
	UrlDesc = 'url_DESC'
}

export type AssetFilter = {
	AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>
	OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>
	contentType?: InputMaybe<Scalars['String']['input']>
	contentType_contains?: InputMaybe<Scalars['String']['input']>
	contentType_exists?: InputMaybe<Scalars['Boolean']['input']>
	contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contentType_not?: InputMaybe<Scalars['String']['input']>
	contentType_not_contains?: InputMaybe<Scalars['String']['input']>
	contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description?: InputMaybe<Scalars['String']['input']>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	description_not?: InputMaybe<Scalars['String']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	fileName?: InputMaybe<Scalars['String']['input']>
	fileName_contains?: InputMaybe<Scalars['String']['input']>
	fileName_exists?: InputMaybe<Scalars['Boolean']['input']>
	fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	fileName_not?: InputMaybe<Scalars['String']['input']>
	fileName_not_contains?: InputMaybe<Scalars['String']['input']>
	fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	height?: InputMaybe<Scalars['Int']['input']>
	height_exists?: InputMaybe<Scalars['Boolean']['input']>
	height_gt?: InputMaybe<Scalars['Int']['input']>
	height_gte?: InputMaybe<Scalars['Int']['input']>
	height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	height_lt?: InputMaybe<Scalars['Int']['input']>
	height_lte?: InputMaybe<Scalars['Int']['input']>
	height_not?: InputMaybe<Scalars['Int']['input']>
	height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	size?: InputMaybe<Scalars['Int']['input']>
	size_exists?: InputMaybe<Scalars['Boolean']['input']>
	size_gt?: InputMaybe<Scalars['Int']['input']>
	size_gte?: InputMaybe<Scalars['Int']['input']>
	size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	size_lt?: InputMaybe<Scalars['Int']['input']>
	size_lte?: InputMaybe<Scalars['Int']['input']>
	size_not?: InputMaybe<Scalars['Int']['input']>
	size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	url?: InputMaybe<Scalars['String']['input']>
	url_contains?: InputMaybe<Scalars['String']['input']>
	url_exists?: InputMaybe<Scalars['Boolean']['input']>
	url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	url_not?: InputMaybe<Scalars['String']['input']>
	url_not_contains?: InputMaybe<Scalars['String']['input']>
	url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	width?: InputMaybe<Scalars['Int']['input']>
	width_exists?: InputMaybe<Scalars['Boolean']['input']>
	width_gt?: InputMaybe<Scalars['Int']['input']>
	width_gte?: InputMaybe<Scalars['Int']['input']>
	width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	width_lt?: InputMaybe<Scalars['Int']['input']>
	width_lte?: InputMaybe<Scalars['Int']['input']>
	width_not?: InputMaybe<Scalars['Int']['input']>
	width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

/** A wrapper for assets linked internally [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/assetInternal) */
export type AssetInternal = Entry &
	_Node & {
		__typename?: 'AssetInternal'
		_id: Scalars['ID']['output']
		altText?: Maybe<Scalars['String']['output']>
		contentfulMetadata: ContentfulMetadata
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<AssetInternalLinkingCollections>
		media?: Maybe<Asset>
		sys: Sys
	}

/** A wrapper for assets linked internally [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/assetInternal) */
export type AssetInternalAltTextArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** A wrapper for assets linked internally [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/assetInternal) */
export type AssetInternalInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** A wrapper for assets linked internally [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/assetInternal) */
export type AssetInternalLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** A wrapper for assets linked internally [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/assetInternal) */
export type AssetInternalMediaArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type AssetInternalCollection = {
	__typename?: 'AssetInternalCollection'
	items: Array<Maybe<AssetInternal>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type AssetInternalFilter = {
	AND?: InputMaybe<Array<InputMaybe<AssetInternalFilter>>>
	OR?: InputMaybe<Array<InputMaybe<AssetInternalFilter>>>
	altText?: InputMaybe<Scalars['String']['input']>
	altText_contains?: InputMaybe<Scalars['String']['input']>
	altText_exists?: InputMaybe<Scalars['Boolean']['input']>
	altText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	altText_not?: InputMaybe<Scalars['String']['input']>
	altText_not_contains?: InputMaybe<Scalars['String']['input']>
	altText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	media_exists?: InputMaybe<Scalars['Boolean']['input']>
	sys?: InputMaybe<SysFilter>
}

export type AssetInternalLinkingCollections = {
	__typename?: 'AssetInternalLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
	heroCollection?: Maybe<HeroCollection>
	pressCollection?: Maybe<PressCollection>
	showcaseCollection?: Maybe<ShowcaseCollection>
}

export type AssetInternalLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetInternalLinkingCollectionsHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AssetInternalLinkingCollectionsHeroCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetInternalLinkingCollectionsPressCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AssetInternalLinkingCollectionsPressCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetInternalLinkingCollectionsShowcaseCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AssetInternalLinkingCollectionsShowcaseCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum AssetInternalLinkingCollectionsHeroCollectionOrder {
	BcLogoAsc = 'bcLogo_ASC',
	BcLogoDesc = 'bcLogo_DESC',
	HeadlineAsc = 'headline_ASC',
	HeadlineDesc = 'headline_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum AssetInternalLinkingCollectionsPressCollectionOrder {
	EnabledAsc = 'enabled_ASC',
	EnabledDesc = 'enabled_DESC',
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum AssetInternalLinkingCollectionsShowcaseCollectionOrder {
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum AssetInternalOrder {
	AltTextAsc = 'altText_ASC',
	AltTextDesc = 'altText_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type AssetLinkingCollections = {
	__typename?: 'AssetLinkingCollections'
	additionalPersonOptionsCollection?: Maybe<AdditionalPersonOptionsCollection>
	appTierCollection?: Maybe<AppTierCollection>
	articleCollection?: Maybe<ArticleCollection>
	assetInternalCollection?: Maybe<AssetInternalCollection>
	backgroundCollection?: Maybe<BackgroundCollection>
	basicContentCardCollection?: Maybe<BasicContentCardCollection>
	blogPostCollection?: Maybe<BlogPostCollection>
	caseStudyCollection?: Maybe<CaseStudyCollection>
	ctaMatPatternCollection?: Maybe<CtaMatPatternCollection>
	entryCollection?: Maybe<EntryCollection>
	eventCollection?: Maybe<EventCollection>
	gatedContentCollection?: Maybe<GatedContentCollection>
	glossaryCollection?: Maybe<GlossaryCollection>
	heroCollection?: Maybe<HeroCollection>
	personCollection?: Maybe<PersonCollection>
	resourceCollection?: Maybe<ResourceCollection>
	seoMetadataCollection?: Maybe<SeoMetadataCollection>
	speakerCardCollection?: Maybe<SpeakerCardCollection>
}

export type AssetLinkingCollectionsAdditionalPersonOptionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsAppTierCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsArticleCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsAssetInternalCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsBackgroundCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsBasicContentCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsBlogPostCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsCaseStudyCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsCtaMatPatternCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsEventCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsGatedContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsGlossaryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsPersonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsResourceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsSeoMetadataCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type AssetLinkingCollectionsSpeakerCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum AssetOrder {
	ContentTypeAsc = 'contentType_ASC',
	ContentTypeDesc = 'contentType_DESC',
	FileNameAsc = 'fileName_ASC',
	FileNameDesc = 'fileName_DESC',
	HeightAsc = 'height_ASC',
	HeightDesc = 'height_DESC',
	SizeAsc = 'size_ASC',
	SizeDesc = 'size_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	UrlAsc = 'url_ASC',
	UrlDesc = 'url_DESC',
	WidthAsc = 'width_ASC',
	WidthDesc = 'width_DESC'
}

/** Additional options for background colors, gradient, images and text color. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/background) */
export type Background = Entry &
	_Node & {
		__typename?: 'Background'
		_id: Scalars['ID']['output']
		backgroundImage?: Maybe<Asset>
		contentfulMetadata: ContentfulMetadata
		gradient?: Maybe<Scalars['String']['output']>
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<BackgroundLinkingCollections>
		solidColor?: Maybe<Scalars['String']['output']>
		sys: Sys
		textColor?: Maybe<Scalars['String']['output']>
	}

/** Additional options for background colors, gradient, images and text color. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/background) */
export type BackgroundBackgroundImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Additional options for background colors, gradient, images and text color. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/background) */
export type BackgroundGradientArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Additional options for background colors, gradient, images and text color. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/background) */
export type BackgroundInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Additional options for background colors, gradient, images and text color. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/background) */
export type BackgroundLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Additional options for background colors, gradient, images and text color. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/background) */
export type BackgroundSolidColorArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Additional options for background colors, gradient, images and text color. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/background) */
export type BackgroundTextColorArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type BackgroundCollection = {
	__typename?: 'BackgroundCollection'
	items: Array<Maybe<Background>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type BackgroundFilter = {
	AND?: InputMaybe<Array<InputMaybe<BackgroundFilter>>>
	OR?: InputMaybe<Array<InputMaybe<BackgroundFilter>>>
	backgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	gradient?: InputMaybe<Scalars['String']['input']>
	gradient_contains?: InputMaybe<Scalars['String']['input']>
	gradient_exists?: InputMaybe<Scalars['Boolean']['input']>
	gradient_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	gradient_not?: InputMaybe<Scalars['String']['input']>
	gradient_not_contains?: InputMaybe<Scalars['String']['input']>
	gradient_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	solidColor?: InputMaybe<Scalars['String']['input']>
	solidColor_contains?: InputMaybe<Scalars['String']['input']>
	solidColor_exists?: InputMaybe<Scalars['Boolean']['input']>
	solidColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	solidColor_not?: InputMaybe<Scalars['String']['input']>
	solidColor_not_contains?: InputMaybe<Scalars['String']['input']>
	solidColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	textColor?: InputMaybe<Scalars['String']['input']>
	textColor_contains?: InputMaybe<Scalars['String']['input']>
	textColor_exists?: InputMaybe<Scalars['Boolean']['input']>
	textColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	textColor_not?: InputMaybe<Scalars['String']['input']>
	textColor_not_contains?: InputMaybe<Scalars['String']['input']>
	textColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type BackgroundLinkingCollections = {
	__typename?: 'BackgroundLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
	heroCollection?: Maybe<HeroCollection>
}

export type BackgroundLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type BackgroundLinkingCollectionsHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<BackgroundLinkingCollectionsHeroCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum BackgroundLinkingCollectionsHeroCollectionOrder {
	BcLogoAsc = 'bcLogo_ASC',
	BcLogoDesc = 'bcLogo_DESC',
	HeadlineAsc = 'headline_ASC',
	HeadlineDesc = 'headline_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BackgroundOrder {
	GradientAsc = 'gradient_ASC',
	GradientDesc = 'gradient_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SolidColorAsc = 'solidColor_ASC',
	SolidColorDesc = 'solidColor_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TextColorAsc = 'textColor_ASC',
	TextColorDesc = 'textColor_DESC'
}

/** Used to wrap information. Different fields can be left empty to build a card that matches the situation. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/basicContentCard) */
export type BasicContentCard = Entry &
	_Node & {
		__typename?: 'BasicContentCard'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		ctaButtonCollection?: Maybe<BasicContentCardCtaButtonCollection>
		description?: Maybe<Scalars['String']['output']>
		image?: Maybe<Asset>
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<BasicContentCardLinkingCollections>
		overline?: Maybe<Scalars['String']['output']>
		sys: Sys
		theme?: Maybe<Scalars['String']['output']>
		title?: Maybe<Scalars['String']['output']>
	}

/** Used to wrap information. Different fields can be left empty to build a card that matches the situation. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/basicContentCard) */
export type BasicContentCardCtaButtonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<BasicContentCardCtaButtonCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CtaButtonFilter>
}

/** Used to wrap information. Different fields can be left empty to build a card that matches the situation. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/basicContentCard) */
export type BasicContentCardDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used to wrap information. Different fields can be left empty to build a card that matches the situation. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/basicContentCard) */
export type BasicContentCardImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Used to wrap information. Different fields can be left empty to build a card that matches the situation. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/basicContentCard) */
export type BasicContentCardInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used to wrap information. Different fields can be left empty to build a card that matches the situation. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/basicContentCard) */
export type BasicContentCardLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Used to wrap information. Different fields can be left empty to build a card that matches the situation. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/basicContentCard) */
export type BasicContentCardOverlineArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used to wrap information. Different fields can be left empty to build a card that matches the situation. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/basicContentCard) */
export type BasicContentCardThemeArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used to wrap information. Different fields can be left empty to build a card that matches the situation. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/basicContentCard) */
export type BasicContentCardTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type BasicContentCardCollection = {
	__typename?: 'BasicContentCardCollection'
	items: Array<Maybe<BasicContentCard>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type BasicContentCardCtaButtonCollection = {
	__typename?: 'BasicContentCardCtaButtonCollection'
	items: Array<Maybe<CtaButton>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum BasicContentCardCtaButtonCollectionOrder {
	ColorAsc = 'color_ASC',
	ColorDesc = 'color_DESC',
	DisplayTextAsc = 'displayText_ASC',
	DisplayTextDesc = 'displayText_DESC',
	FormIdAsc = 'formId_ASC',
	FormIdDesc = 'formId_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	OpenInNewTabAsc = 'openInNewTab_ASC',
	OpenInNewTabDesc = 'openInNewTab_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	VariantAsc = 'variant_ASC',
	VariantDesc = 'variant_DESC'
}

export type BasicContentCardFilter = {
	AND?: InputMaybe<Array<InputMaybe<BasicContentCardFilter>>>
	OR?: InputMaybe<Array<InputMaybe<BasicContentCardFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	ctaButton?: InputMaybe<CfCtaButtonNestedFilter>
	ctaButtonCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	description?: InputMaybe<Scalars['String']['input']>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	description_not?: InputMaybe<Scalars['String']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	overline?: InputMaybe<Scalars['String']['input']>
	overline_contains?: InputMaybe<Scalars['String']['input']>
	overline_exists?: InputMaybe<Scalars['Boolean']['input']>
	overline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	overline_not?: InputMaybe<Scalars['String']['input']>
	overline_not_contains?: InputMaybe<Scalars['String']['input']>
	overline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	theme?: InputMaybe<Scalars['String']['input']>
	theme_contains?: InputMaybe<Scalars['String']['input']>
	theme_exists?: InputMaybe<Scalars['Boolean']['input']>
	theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	theme_not?: InputMaybe<Scalars['String']['input']>
	theme_not_contains?: InputMaybe<Scalars['String']['input']>
	theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type BasicContentCardLinkingCollections = {
	__typename?: 'BasicContentCardLinkingCollections'
	articleCollection?: Maybe<ArticleCollection>
	entryCollection?: Maybe<EntryCollection>
	resourceCollection?: Maybe<ResourceCollection>
}

export type BasicContentCardLinkingCollectionsArticleCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<BasicContentCardLinkingCollectionsArticleCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type BasicContentCardLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type BasicContentCardLinkingCollectionsResourceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<BasicContentCardLinkingCollectionsResourceCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum BasicContentCardLinkingCollectionsArticleCollectionOrder {
	EditStatusAsc = 'editStatus_ASC',
	EditStatusDesc = 'editStatus_DESC',
	FaqsHeadingAsc = 'faqsHeading_ASC',
	FaqsHeadingDesc = 'faqsHeading_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	InterstitialHeadlineAsc = 'interstitialHeadline_ASC',
	InterstitialHeadlineDesc = 'interstitialHeadline_DESC',
	PopularAsc = 'popular_ASC',
	PopularDesc = 'popular_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum BasicContentCardLinkingCollectionsResourceCollectionOrder {
	FormButtonTextAsc = 'formButtonText_ASC',
	FormButtonTextDesc = 'formButtonText_DESC',
	FormIdAsc = 'formId_ASC',
	FormIdDesc = 'formId_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum BasicContentCardOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	ThemeAsc = 'theme_ASC',
	ThemeDesc = 'theme_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPost = Entry &
	_Node & {
		__typename?: 'BlogPost'
		_id: Scalars['ID']['output']
		author?: Maybe<Person>
		body?: Maybe<BlogPostBody>
		categoriesCollection?: Maybe<BlogPostCategoriesCollection>
		contentfulMetadata: ContentfulMetadata
		ctaMatPattern?: Maybe<CtaMatPattern>
		featuredImage?: Maybe<Asset>
		gatedContentSettings?: Maybe<GatedContent>
		hideOnHomepage?: Maybe<Scalars['Boolean']['output']>
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<BlogPostLinkingCollections>
		publishedDate?: Maybe<Scalars['DateTime']['output']>
		seoMetadata?: Maybe<SeoMetadata>
		showPostHero?: Maybe<Scalars['Boolean']['output']>
		showTableOfContents?: Maybe<Scalars['Boolean']['output']>
		slug?: Maybe<Scalars['String']['output']>
		status?: Maybe<Scalars['Boolean']['output']>
		summary?: Maybe<BlogPostSummary>
		sys: Sys
		tagsCollection?: Maybe<BlogPostTagsCollection>
		title?: Maybe<Scalars['String']['output']>
	}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostAuthorArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<PersonFilter>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostBodyArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostCategoriesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<BlogPostCategoriesCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CategoryFilter>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostCtaMatPatternArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<CtaMatPatternFilter>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostFeaturedImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostGatedContentSettingsArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<GatedContentFilter>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostHideOnHomepageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostPublishedDateArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostSeoMetadataArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<SeoMetadataFilter>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostShowPostHeroArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostShowTableOfContentsArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostSlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostStatusArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostSummaryArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostTagsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<BlogPostTagsCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<TagFilter>
}

/** Blog Post options in addition to what's in the Resource type. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/blogPost) */
export type BlogPostTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type BlogPostBody = {
	__typename?: 'BlogPostBody'
	json: Scalars['JSON']['output']
	links: BlogPostBodyLinks
}

export type BlogPostBodyAssets = {
	__typename?: 'BlogPostBodyAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type BlogPostBodyEntries = {
	__typename?: 'BlogPostBodyEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type BlogPostBodyLinks = {
	__typename?: 'BlogPostBodyLinks'
	assets: BlogPostBodyAssets
	entries: BlogPostBodyEntries
	resources: BlogPostBodyResources
}

export type BlogPostBodyResources = {
	__typename?: 'BlogPostBodyResources'
	block: Array<BlogPostBodyResourcesBlock>
	hyperlink: Array<BlogPostBodyResourcesHyperlink>
	inline: Array<BlogPostBodyResourcesInline>
}

export type BlogPostBodyResourcesBlock = ResourceLink & {
	__typename?: 'BlogPostBodyResourcesBlock'
	sys: ResourceSys
}

export type BlogPostBodyResourcesHyperlink = ResourceLink & {
	__typename?: 'BlogPostBodyResourcesHyperlink'
	sys: ResourceSys
}

export type BlogPostBodyResourcesInline = ResourceLink & {
	__typename?: 'BlogPostBodyResourcesInline'
	sys: ResourceSys
}

export type BlogPostCategoriesCollection = {
	__typename?: 'BlogPostCategoriesCollection'
	items: Array<Maybe<Category>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum BlogPostCategoriesCollectionOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type BlogPostCollection = {
	__typename?: 'BlogPostCollection'
	items: Array<Maybe<BlogPost>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type BlogPostFilter = {
	AND?: InputMaybe<Array<InputMaybe<BlogPostFilter>>>
	OR?: InputMaybe<Array<InputMaybe<BlogPostFilter>>>
	author?: InputMaybe<CfPersonNestedFilter>
	author_exists?: InputMaybe<Scalars['Boolean']['input']>
	body_contains?: InputMaybe<Scalars['String']['input']>
	body_exists?: InputMaybe<Scalars['Boolean']['input']>
	body_not_contains?: InputMaybe<Scalars['String']['input']>
	categories?: InputMaybe<CfCategoryNestedFilter>
	categoriesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	ctaMatPattern?: InputMaybe<CfCtaMatPatternNestedFilter>
	ctaMatPattern_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredImage_exists?: InputMaybe<Scalars['Boolean']['input']>
	gatedContentSettings?: InputMaybe<CfGatedContentNestedFilter>
	gatedContentSettings_exists?: InputMaybe<Scalars['Boolean']['input']>
	hideOnHomepage?: InputMaybe<Scalars['Boolean']['input']>
	hideOnHomepage_exists?: InputMaybe<Scalars['Boolean']['input']>
	hideOnHomepage_not?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	publishedDate?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_exists?: InputMaybe<Scalars['Boolean']['input']>
	publishedDate_gt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_gte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	publishedDate_lt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_lte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	showPostHero?: InputMaybe<Scalars['Boolean']['input']>
	showPostHero_exists?: InputMaybe<Scalars['Boolean']['input']>
	showPostHero_not?: InputMaybe<Scalars['Boolean']['input']>
	showTableOfContents?: InputMaybe<Scalars['Boolean']['input']>
	showTableOfContents_exists?: InputMaybe<Scalars['Boolean']['input']>
	showTableOfContents_not?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	status?: InputMaybe<Scalars['Boolean']['input']>
	status_exists?: InputMaybe<Scalars['Boolean']['input']>
	status_not?: InputMaybe<Scalars['Boolean']['input']>
	summary_contains?: InputMaybe<Scalars['String']['input']>
	summary_exists?: InputMaybe<Scalars['Boolean']['input']>
	summary_not_contains?: InputMaybe<Scalars['String']['input']>
	sys?: InputMaybe<SysFilter>
	tags?: InputMaybe<CfTagNestedFilter>
	tagsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type BlogPostLinkingCollections = {
	__typename?: 'BlogPostLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
	resourceCollection?: Maybe<ResourceCollection>
}

export type BlogPostLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type BlogPostLinkingCollectionsResourceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<BlogPostLinkingCollectionsResourceCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum BlogPostLinkingCollectionsResourceCollectionOrder {
	FormButtonTextAsc = 'formButtonText_ASC',
	FormButtonTextDesc = 'formButtonText_DESC',
	FormIdAsc = 'formId_ASC',
	FormIdDesc = 'formId_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum BlogPostOrder {
	HideOnHomepageAsc = 'hideOnHomepage_ASC',
	HideOnHomepageDesc = 'hideOnHomepage_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	ShowPostHeroAsc = 'showPostHero_ASC',
	ShowPostHeroDesc = 'showPostHero_DESC',
	ShowTableOfContentsAsc = 'showTableOfContents_ASC',
	ShowTableOfContentsDesc = 'showTableOfContents_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export type BlogPostSummary = {
	__typename?: 'BlogPostSummary'
	json: Scalars['JSON']['output']
	links: BlogPostSummaryLinks
}

export type BlogPostSummaryAssets = {
	__typename?: 'BlogPostSummaryAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type BlogPostSummaryEntries = {
	__typename?: 'BlogPostSummaryEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type BlogPostSummaryLinks = {
	__typename?: 'BlogPostSummaryLinks'
	assets: BlogPostSummaryAssets
	entries: BlogPostSummaryEntries
	resources: BlogPostSummaryResources
}

export type BlogPostSummaryResources = {
	__typename?: 'BlogPostSummaryResources'
	block: Array<BlogPostSummaryResourcesBlock>
	hyperlink: Array<BlogPostSummaryResourcesHyperlink>
	inline: Array<BlogPostSummaryResourcesInline>
}

export type BlogPostSummaryResourcesBlock = ResourceLink & {
	__typename?: 'BlogPostSummaryResourcesBlock'
	sys: ResourceSys
}

export type BlogPostSummaryResourcesHyperlink = ResourceLink & {
	__typename?: 'BlogPostSummaryResourcesHyperlink'
	sys: ResourceSys
}

export type BlogPostSummaryResourcesInline = ResourceLink & {
	__typename?: 'BlogPostSummaryResourcesInline'
	sys: ResourceSys
}

export type BlogPostTagsCollection = {
	__typename?: 'BlogPostTagsCollection'
	items: Array<Maybe<Tag>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum BlogPostTagsCollectionOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudy = Entry &
	_Node & {
		__typename?: 'CaseStudy'
		_id: Scalars['ID']['output']
		caseStudyStatsCollection?: Maybe<CaseStudyCaseStudyStatsCollection>
		competitorsListCollection?: Maybe<CaseStudyCompetitorsListCollection>
		contentfulMetadata: ContentfulMetadata
		extraSection?: Maybe<CaseStudyExtraSection>
		featuredAuthorName?: Maybe<Scalars['String']['output']>
		featuredAuthorTitle?: Maybe<Scalars['String']['output']>
		featuredContent?: Maybe<CaseStudyFeaturedContent>
		featuredImage?: Maybe<Asset>
		featuredLinkText?: Maybe<Scalars['String']['output']>
		featuredLogo?: Maybe<Asset>
		featuredOrder?: Maybe<Scalars['Int']['output']>
		featuredVideo?: Maybe<AssetExternal>
		hero?: Maybe<Hero>
		image?: Maybe<Asset>
		industriesListCollection?: Maybe<CaseStudyIndustriesListCollection>
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<CaseStudyLinkingCollections>
		publishedDate?: Maybe<Scalars['DateTime']['output']>
		quote?: Maybe<CaseStudyQuote>
		quoteImage?: Maybe<Asset>
		relatedCaseStudiesCollection?: Maybe<CaseStudyRelatedCaseStudiesCollection>
		section1?: Maybe<CaseStudySection1>
		section2?: Maybe<CaseStudySection2>
		section3?: Maybe<CaseStudySection3>
		section4?: Maybe<CaseStudySection4>
		section5?: Maybe<CaseStudySection5>
		seoMetadata?: Maybe<SeoMetadata>
		slug?: Maybe<Scalars['String']['output']>
		statsDescription?: Maybe<CaseStudyStatsDescription>
		statsSubtitle?: Maybe<CaseStudyStatsSubtitle>
		statsTitle?: Maybe<CaseStudyStatsTitle>
		status?: Maybe<Scalars['Boolean']['output']>
		summary?: Maybe<CaseStudySummary>
		sys: Sys
		title?: Maybe<Scalars['String']['output']>
		topicsListCollection?: Maybe<CaseStudyTopicsListCollection>
		whitepaperStandin?: Maybe<Scalars['Boolean']['output']>
	}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyCaseStudyStatsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CaseStudyCaseStudyStatsCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<KeyValueFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyCompetitorsListCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CaseStudyCompetitorsListCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CaseStudyPropertyFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyExtraSectionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyFeaturedAuthorNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyFeaturedAuthorTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyFeaturedContentArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyFeaturedImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyFeaturedLinkTextArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyFeaturedLogoArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyFeaturedOrderArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyFeaturedVideoArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<AssetExternalFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyHeroArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<HeroFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyIndustriesListCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CaseStudyIndustriesListCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CaseStudyPropertyFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyPublishedDateArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyQuoteArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyQuoteImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyRelatedCaseStudiesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CaseStudyRelatedCaseStudiesCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CaseStudyFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudySection1Args = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudySection2Args = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudySection3Args = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudySection4Args = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudySection5Args = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudySeoMetadataArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<SeoMetadataFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudySlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyStatsDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyStatsSubtitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyStatsTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyStatusArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudySummaryArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyTopicsListCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CaseStudyTopicsListCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CaseStudyPropertyFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudy) */
export type CaseStudyWhitepaperStandinArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type CaseStudyCaseStudyStatsCollection = {
	__typename?: 'CaseStudyCaseStudyStatsCollection'
	items: Array<Maybe<KeyValue>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum CaseStudyCaseStudyStatsCollectionOrder {
	KeyAsc = 'key_ASC',
	KeyDesc = 'key_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	ValueAsc = 'value_ASC',
	ValueDesc = 'value_DESC'
}

export type CaseStudyCollection = {
	__typename?: 'CaseStudyCollection'
	items: Array<Maybe<CaseStudy>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type CaseStudyCompetitorsListCollection = {
	__typename?: 'CaseStudyCompetitorsListCollection'
	items: Array<Maybe<CaseStudyProperty>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum CaseStudyCompetitorsListCollectionOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export type CaseStudyExtraSection = {
	__typename?: 'CaseStudyExtraSection'
	json: Scalars['JSON']['output']
	links: CaseStudyExtraSectionLinks
}

export type CaseStudyExtraSectionAssets = {
	__typename?: 'CaseStudyExtraSectionAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type CaseStudyExtraSectionEntries = {
	__typename?: 'CaseStudyExtraSectionEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type CaseStudyExtraSectionLinks = {
	__typename?: 'CaseStudyExtraSectionLinks'
	assets: CaseStudyExtraSectionAssets
	entries: CaseStudyExtraSectionEntries
	resources: CaseStudyExtraSectionResources
}

export type CaseStudyExtraSectionResources = {
	__typename?: 'CaseStudyExtraSectionResources'
	block: Array<CaseStudyExtraSectionResourcesBlock>
	hyperlink: Array<CaseStudyExtraSectionResourcesHyperlink>
	inline: Array<CaseStudyExtraSectionResourcesInline>
}

export type CaseStudyExtraSectionResourcesBlock = ResourceLink & {
	__typename?: 'CaseStudyExtraSectionResourcesBlock'
	sys: ResourceSys
}

export type CaseStudyExtraSectionResourcesHyperlink = ResourceLink & {
	__typename?: 'CaseStudyExtraSectionResourcesHyperlink'
	sys: ResourceSys
}

export type CaseStudyExtraSectionResourcesInline = ResourceLink & {
	__typename?: 'CaseStudyExtraSectionResourcesInline'
	sys: ResourceSys
}

export type CaseStudyFeaturedContent = {
	__typename?: 'CaseStudyFeaturedContent'
	json: Scalars['JSON']['output']
	links: CaseStudyFeaturedContentLinks
}

export type CaseStudyFeaturedContentAssets = {
	__typename?: 'CaseStudyFeaturedContentAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type CaseStudyFeaturedContentEntries = {
	__typename?: 'CaseStudyFeaturedContentEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type CaseStudyFeaturedContentLinks = {
	__typename?: 'CaseStudyFeaturedContentLinks'
	assets: CaseStudyFeaturedContentAssets
	entries: CaseStudyFeaturedContentEntries
	resources: CaseStudyFeaturedContentResources
}

export type CaseStudyFeaturedContentResources = {
	__typename?: 'CaseStudyFeaturedContentResources'
	block: Array<CaseStudyFeaturedContentResourcesBlock>
	hyperlink: Array<CaseStudyFeaturedContentResourcesHyperlink>
	inline: Array<CaseStudyFeaturedContentResourcesInline>
}

export type CaseStudyFeaturedContentResourcesBlock = ResourceLink & {
	__typename?: 'CaseStudyFeaturedContentResourcesBlock'
	sys: ResourceSys
}

export type CaseStudyFeaturedContentResourcesHyperlink = ResourceLink & {
	__typename?: 'CaseStudyFeaturedContentResourcesHyperlink'
	sys: ResourceSys
}

export type CaseStudyFeaturedContentResourcesInline = ResourceLink & {
	__typename?: 'CaseStudyFeaturedContentResourcesInline'
	sys: ResourceSys
}

export type CaseStudyFilter = {
	AND?: InputMaybe<Array<InputMaybe<CaseStudyFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CaseStudyFilter>>>
	caseStudyStats?: InputMaybe<CfKeyValueNestedFilter>
	caseStudyStatsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	competitorsList?: InputMaybe<CfCaseStudyPropertyNestedFilter>
	competitorsListCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	extraSection_contains?: InputMaybe<Scalars['String']['input']>
	extraSection_exists?: InputMaybe<Scalars['Boolean']['input']>
	extraSection_not_contains?: InputMaybe<Scalars['String']['input']>
	featuredAuthorName?: InputMaybe<Scalars['String']['input']>
	featuredAuthorName_contains?: InputMaybe<Scalars['String']['input']>
	featuredAuthorName_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredAuthorName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredAuthorName_not?: InputMaybe<Scalars['String']['input']>
	featuredAuthorName_not_contains?: InputMaybe<Scalars['String']['input']>
	featuredAuthorName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredAuthorTitle?: InputMaybe<Scalars['String']['input']>
	featuredAuthorTitle_contains?: InputMaybe<Scalars['String']['input']>
	featuredAuthorTitle_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredAuthorTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredAuthorTitle_not?: InputMaybe<Scalars['String']['input']>
	featuredAuthorTitle_not_contains?: InputMaybe<Scalars['String']['input']>
	featuredAuthorTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredContent_contains?: InputMaybe<Scalars['String']['input']>
	featuredContent_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredContent_not_contains?: InputMaybe<Scalars['String']['input']>
	featuredImage_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredLinkText?: InputMaybe<Scalars['String']['input']>
	featuredLinkText_contains?: InputMaybe<Scalars['String']['input']>
	featuredLinkText_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredLinkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredLinkText_not?: InputMaybe<Scalars['String']['input']>
	featuredLinkText_not_contains?: InputMaybe<Scalars['String']['input']>
	featuredLinkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredLogo_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredOrder?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredOrder_gt?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_gte?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	featuredOrder_lt?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_lte?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_not?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	featuredVideo?: InputMaybe<CfAssetExternalNestedFilter>
	featuredVideo_exists?: InputMaybe<Scalars['Boolean']['input']>
	hero?: InputMaybe<CfHeroNestedFilter>
	hero_exists?: InputMaybe<Scalars['Boolean']['input']>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	industriesList?: InputMaybe<CfCaseStudyPropertyNestedFilter>
	industriesListCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	publishedDate?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_exists?: InputMaybe<Scalars['Boolean']['input']>
	publishedDate_gt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_gte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	publishedDate_lt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_lte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	quoteImage_exists?: InputMaybe<Scalars['Boolean']['input']>
	quote_contains?: InputMaybe<Scalars['String']['input']>
	quote_exists?: InputMaybe<Scalars['Boolean']['input']>
	quote_not_contains?: InputMaybe<Scalars['String']['input']>
	relatedCaseStudies?: InputMaybe<CfCaseStudyNestedFilter>
	relatedCaseStudiesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	section1_contains?: InputMaybe<Scalars['String']['input']>
	section1_exists?: InputMaybe<Scalars['Boolean']['input']>
	section1_not_contains?: InputMaybe<Scalars['String']['input']>
	section2_contains?: InputMaybe<Scalars['String']['input']>
	section2_exists?: InputMaybe<Scalars['Boolean']['input']>
	section2_not_contains?: InputMaybe<Scalars['String']['input']>
	section3_contains?: InputMaybe<Scalars['String']['input']>
	section3_exists?: InputMaybe<Scalars['Boolean']['input']>
	section3_not_contains?: InputMaybe<Scalars['String']['input']>
	section4_contains?: InputMaybe<Scalars['String']['input']>
	section4_exists?: InputMaybe<Scalars['Boolean']['input']>
	section4_not_contains?: InputMaybe<Scalars['String']['input']>
	section5_contains?: InputMaybe<Scalars['String']['input']>
	section5_exists?: InputMaybe<Scalars['Boolean']['input']>
	section5_not_contains?: InputMaybe<Scalars['String']['input']>
	seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	statsDescription_contains?: InputMaybe<Scalars['String']['input']>
	statsDescription_exists?: InputMaybe<Scalars['Boolean']['input']>
	statsDescription_not_contains?: InputMaybe<Scalars['String']['input']>
	statsSubtitle_contains?: InputMaybe<Scalars['String']['input']>
	statsSubtitle_exists?: InputMaybe<Scalars['Boolean']['input']>
	statsSubtitle_not_contains?: InputMaybe<Scalars['String']['input']>
	statsTitle_contains?: InputMaybe<Scalars['String']['input']>
	statsTitle_exists?: InputMaybe<Scalars['Boolean']['input']>
	statsTitle_not_contains?: InputMaybe<Scalars['String']['input']>
	status?: InputMaybe<Scalars['Boolean']['input']>
	status_exists?: InputMaybe<Scalars['Boolean']['input']>
	status_not?: InputMaybe<Scalars['Boolean']['input']>
	summary_contains?: InputMaybe<Scalars['String']['input']>
	summary_exists?: InputMaybe<Scalars['Boolean']['input']>
	summary_not_contains?: InputMaybe<Scalars['String']['input']>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	topicsList?: InputMaybe<CfCaseStudyPropertyNestedFilter>
	topicsListCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	whitepaperStandin?: InputMaybe<Scalars['Boolean']['input']>
	whitepaperStandin_exists?: InputMaybe<Scalars['Boolean']['input']>
	whitepaperStandin_not?: InputMaybe<Scalars['Boolean']['input']>
}

export type CaseStudyIndustriesListCollection = {
	__typename?: 'CaseStudyIndustriesListCollection'
	items: Array<Maybe<CaseStudyProperty>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum CaseStudyIndustriesListCollectionOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export type CaseStudyLinkingCollections = {
	__typename?: 'CaseStudyLinkingCollections'
	caseStudyCollection?: Maybe<CaseStudyCollection>
	entryCollection?: Maybe<EntryCollection>
	resourceCollection?: Maybe<ResourceCollection>
}

export type CaseStudyLinkingCollectionsCaseStudyCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CaseStudyLinkingCollectionsCaseStudyCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type CaseStudyLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type CaseStudyLinkingCollectionsResourceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CaseStudyLinkingCollectionsResourceCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum CaseStudyLinkingCollectionsCaseStudyCollectionOrder {
	FeaturedAuthorNameAsc = 'featuredAuthorName_ASC',
	FeaturedAuthorNameDesc = 'featuredAuthorName_DESC',
	FeaturedAuthorTitleAsc = 'featuredAuthorTitle_ASC',
	FeaturedAuthorTitleDesc = 'featuredAuthorTitle_DESC',
	FeaturedLinkTextAsc = 'featuredLinkText_ASC',
	FeaturedLinkTextDesc = 'featuredLinkText_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	WhitepaperStandinAsc = 'whitepaperStandin_ASC',
	WhitepaperStandinDesc = 'whitepaperStandin_DESC'
}

export enum CaseStudyLinkingCollectionsResourceCollectionOrder {
	FormButtonTextAsc = 'formButtonText_ASC',
	FormButtonTextDesc = 'formButtonText_DESC',
	FormIdAsc = 'formId_ASC',
	FormIdDesc = 'formId_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum CaseStudyOrder {
	FeaturedAuthorNameAsc = 'featuredAuthorName_ASC',
	FeaturedAuthorNameDesc = 'featuredAuthorName_DESC',
	FeaturedAuthorTitleAsc = 'featuredAuthorTitle_ASC',
	FeaturedAuthorTitleDesc = 'featuredAuthorTitle_DESC',
	FeaturedLinkTextAsc = 'featuredLinkText_ASC',
	FeaturedLinkTextDesc = 'featuredLinkText_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	WhitepaperStandinAsc = 'whitepaperStandin_ASC',
	WhitepaperStandinDesc = 'whitepaperStandin_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudyProperty) */
export type CaseStudyProperty = Entry &
	_Node & {
		__typename?: 'CaseStudyProperty'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		linkedFrom?: Maybe<CaseStudyPropertyLinkingCollections>
		name?: Maybe<Scalars['String']['output']>
		sys: Sys
		type?: Maybe<Scalars['String']['output']>
	}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudyProperty) */
export type CaseStudyPropertyLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudyProperty) */
export type CaseStudyPropertyNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/caseStudyProperty) */
export type CaseStudyPropertyTypeArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type CaseStudyPropertyCollection = {
	__typename?: 'CaseStudyPropertyCollection'
	items: Array<Maybe<CaseStudyProperty>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type CaseStudyPropertyFilter = {
	AND?: InputMaybe<Array<InputMaybe<CaseStudyPropertyFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CaseStudyPropertyFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	name?: InputMaybe<Scalars['String']['input']>
	name_contains?: InputMaybe<Scalars['String']['input']>
	name_exists?: InputMaybe<Scalars['Boolean']['input']>
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name_not?: InputMaybe<Scalars['String']['input']>
	name_not_contains?: InputMaybe<Scalars['String']['input']>
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	type?: InputMaybe<Scalars['String']['input']>
	type_contains?: InputMaybe<Scalars['String']['input']>
	type_exists?: InputMaybe<Scalars['Boolean']['input']>
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	type_not?: InputMaybe<Scalars['String']['input']>
	type_not_contains?: InputMaybe<Scalars['String']['input']>
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CaseStudyPropertyLinkingCollections = {
	__typename?: 'CaseStudyPropertyLinkingCollections'
	caseStudyCollection?: Maybe<CaseStudyCollection>
	entryCollection?: Maybe<EntryCollection>
}

export type CaseStudyPropertyLinkingCollectionsCaseStudyCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CaseStudyPropertyLinkingCollectionsCaseStudyCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type CaseStudyPropertyLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum CaseStudyPropertyLinkingCollectionsCaseStudyCollectionOrder {
	FeaturedAuthorNameAsc = 'featuredAuthorName_ASC',
	FeaturedAuthorNameDesc = 'featuredAuthorName_DESC',
	FeaturedAuthorTitleAsc = 'featuredAuthorTitle_ASC',
	FeaturedAuthorTitleDesc = 'featuredAuthorTitle_DESC',
	FeaturedLinkTextAsc = 'featuredLinkText_ASC',
	FeaturedLinkTextDesc = 'featuredLinkText_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	WhitepaperStandinAsc = 'whitepaperStandin_ASC',
	WhitepaperStandinDesc = 'whitepaperStandin_DESC'
}

export enum CaseStudyPropertyOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export type CaseStudyQuote = {
	__typename?: 'CaseStudyQuote'
	json: Scalars['JSON']['output']
	links: CaseStudyQuoteLinks
}

export type CaseStudyQuoteAssets = {
	__typename?: 'CaseStudyQuoteAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type CaseStudyQuoteEntries = {
	__typename?: 'CaseStudyQuoteEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type CaseStudyQuoteLinks = {
	__typename?: 'CaseStudyQuoteLinks'
	assets: CaseStudyQuoteAssets
	entries: CaseStudyQuoteEntries
	resources: CaseStudyQuoteResources
}

export type CaseStudyQuoteResources = {
	__typename?: 'CaseStudyQuoteResources'
	block: Array<CaseStudyQuoteResourcesBlock>
	hyperlink: Array<CaseStudyQuoteResourcesHyperlink>
	inline: Array<CaseStudyQuoteResourcesInline>
}

export type CaseStudyQuoteResourcesBlock = ResourceLink & {
	__typename?: 'CaseStudyQuoteResourcesBlock'
	sys: ResourceSys
}

export type CaseStudyQuoteResourcesHyperlink = ResourceLink & {
	__typename?: 'CaseStudyQuoteResourcesHyperlink'
	sys: ResourceSys
}

export type CaseStudyQuoteResourcesInline = ResourceLink & {
	__typename?: 'CaseStudyQuoteResourcesInline'
	sys: ResourceSys
}

export type CaseStudyRelatedCaseStudiesCollection = {
	__typename?: 'CaseStudyRelatedCaseStudiesCollection'
	items: Array<Maybe<CaseStudy>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum CaseStudyRelatedCaseStudiesCollectionOrder {
	FeaturedAuthorNameAsc = 'featuredAuthorName_ASC',
	FeaturedAuthorNameDesc = 'featuredAuthorName_DESC',
	FeaturedAuthorTitleAsc = 'featuredAuthorTitle_ASC',
	FeaturedAuthorTitleDesc = 'featuredAuthorTitle_DESC',
	FeaturedLinkTextAsc = 'featuredLinkText_ASC',
	FeaturedLinkTextDesc = 'featuredLinkText_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	WhitepaperStandinAsc = 'whitepaperStandin_ASC',
	WhitepaperStandinDesc = 'whitepaperStandin_DESC'
}

export type CaseStudySection1 = {
	__typename?: 'CaseStudySection1'
	json: Scalars['JSON']['output']
	links: CaseStudySection1Links
}

export type CaseStudySection1Assets = {
	__typename?: 'CaseStudySection1Assets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type CaseStudySection1Entries = {
	__typename?: 'CaseStudySection1Entries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type CaseStudySection1Links = {
	__typename?: 'CaseStudySection1Links'
	assets: CaseStudySection1Assets
	entries: CaseStudySection1Entries
	resources: CaseStudySection1Resources
}

export type CaseStudySection1Resources = {
	__typename?: 'CaseStudySection1Resources'
	block: Array<CaseStudySection1ResourcesBlock>
	hyperlink: Array<CaseStudySection1ResourcesHyperlink>
	inline: Array<CaseStudySection1ResourcesInline>
}

export type CaseStudySection1ResourcesBlock = ResourceLink & {
	__typename?: 'CaseStudySection1ResourcesBlock'
	sys: ResourceSys
}

export type CaseStudySection1ResourcesHyperlink = ResourceLink & {
	__typename?: 'CaseStudySection1ResourcesHyperlink'
	sys: ResourceSys
}

export type CaseStudySection1ResourcesInline = ResourceLink & {
	__typename?: 'CaseStudySection1ResourcesInline'
	sys: ResourceSys
}

export type CaseStudySection2 = {
	__typename?: 'CaseStudySection2'
	json: Scalars['JSON']['output']
	links: CaseStudySection2Links
}

export type CaseStudySection2Assets = {
	__typename?: 'CaseStudySection2Assets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type CaseStudySection2Entries = {
	__typename?: 'CaseStudySection2Entries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type CaseStudySection2Links = {
	__typename?: 'CaseStudySection2Links'
	assets: CaseStudySection2Assets
	entries: CaseStudySection2Entries
	resources: CaseStudySection2Resources
}

export type CaseStudySection2Resources = {
	__typename?: 'CaseStudySection2Resources'
	block: Array<CaseStudySection2ResourcesBlock>
	hyperlink: Array<CaseStudySection2ResourcesHyperlink>
	inline: Array<CaseStudySection2ResourcesInline>
}

export type CaseStudySection2ResourcesBlock = ResourceLink & {
	__typename?: 'CaseStudySection2ResourcesBlock'
	sys: ResourceSys
}

export type CaseStudySection2ResourcesHyperlink = ResourceLink & {
	__typename?: 'CaseStudySection2ResourcesHyperlink'
	sys: ResourceSys
}

export type CaseStudySection2ResourcesInline = ResourceLink & {
	__typename?: 'CaseStudySection2ResourcesInline'
	sys: ResourceSys
}

export type CaseStudySection3 = {
	__typename?: 'CaseStudySection3'
	json: Scalars['JSON']['output']
	links: CaseStudySection3Links
}

export type CaseStudySection3Assets = {
	__typename?: 'CaseStudySection3Assets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type CaseStudySection3Entries = {
	__typename?: 'CaseStudySection3Entries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type CaseStudySection3Links = {
	__typename?: 'CaseStudySection3Links'
	assets: CaseStudySection3Assets
	entries: CaseStudySection3Entries
	resources: CaseStudySection3Resources
}

export type CaseStudySection3Resources = {
	__typename?: 'CaseStudySection3Resources'
	block: Array<CaseStudySection3ResourcesBlock>
	hyperlink: Array<CaseStudySection3ResourcesHyperlink>
	inline: Array<CaseStudySection3ResourcesInline>
}

export type CaseStudySection3ResourcesBlock = ResourceLink & {
	__typename?: 'CaseStudySection3ResourcesBlock'
	sys: ResourceSys
}

export type CaseStudySection3ResourcesHyperlink = ResourceLink & {
	__typename?: 'CaseStudySection3ResourcesHyperlink'
	sys: ResourceSys
}

export type CaseStudySection3ResourcesInline = ResourceLink & {
	__typename?: 'CaseStudySection3ResourcesInline'
	sys: ResourceSys
}

export type CaseStudySection4 = {
	__typename?: 'CaseStudySection4'
	json: Scalars['JSON']['output']
	links: CaseStudySection4Links
}

export type CaseStudySection4Assets = {
	__typename?: 'CaseStudySection4Assets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type CaseStudySection4Entries = {
	__typename?: 'CaseStudySection4Entries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type CaseStudySection4Links = {
	__typename?: 'CaseStudySection4Links'
	assets: CaseStudySection4Assets
	entries: CaseStudySection4Entries
	resources: CaseStudySection4Resources
}

export type CaseStudySection4Resources = {
	__typename?: 'CaseStudySection4Resources'
	block: Array<CaseStudySection4ResourcesBlock>
	hyperlink: Array<CaseStudySection4ResourcesHyperlink>
	inline: Array<CaseStudySection4ResourcesInline>
}

export type CaseStudySection4ResourcesBlock = ResourceLink & {
	__typename?: 'CaseStudySection4ResourcesBlock'
	sys: ResourceSys
}

export type CaseStudySection4ResourcesHyperlink = ResourceLink & {
	__typename?: 'CaseStudySection4ResourcesHyperlink'
	sys: ResourceSys
}

export type CaseStudySection4ResourcesInline = ResourceLink & {
	__typename?: 'CaseStudySection4ResourcesInline'
	sys: ResourceSys
}

export type CaseStudySection5 = {
	__typename?: 'CaseStudySection5'
	json: Scalars['JSON']['output']
	links: CaseStudySection5Links
}

export type CaseStudySection5Assets = {
	__typename?: 'CaseStudySection5Assets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type CaseStudySection5Entries = {
	__typename?: 'CaseStudySection5Entries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type CaseStudySection5Links = {
	__typename?: 'CaseStudySection5Links'
	assets: CaseStudySection5Assets
	entries: CaseStudySection5Entries
	resources: CaseStudySection5Resources
}

export type CaseStudySection5Resources = {
	__typename?: 'CaseStudySection5Resources'
	block: Array<CaseStudySection5ResourcesBlock>
	hyperlink: Array<CaseStudySection5ResourcesHyperlink>
	inline: Array<CaseStudySection5ResourcesInline>
}

export type CaseStudySection5ResourcesBlock = ResourceLink & {
	__typename?: 'CaseStudySection5ResourcesBlock'
	sys: ResourceSys
}

export type CaseStudySection5ResourcesHyperlink = ResourceLink & {
	__typename?: 'CaseStudySection5ResourcesHyperlink'
	sys: ResourceSys
}

export type CaseStudySection5ResourcesInline = ResourceLink & {
	__typename?: 'CaseStudySection5ResourcesInline'
	sys: ResourceSys
}

export type CaseStudyStatsDescription = {
	__typename?: 'CaseStudyStatsDescription'
	json: Scalars['JSON']['output']
	links: CaseStudyStatsDescriptionLinks
}

export type CaseStudyStatsDescriptionAssets = {
	__typename?: 'CaseStudyStatsDescriptionAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type CaseStudyStatsDescriptionEntries = {
	__typename?: 'CaseStudyStatsDescriptionEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type CaseStudyStatsDescriptionLinks = {
	__typename?: 'CaseStudyStatsDescriptionLinks'
	assets: CaseStudyStatsDescriptionAssets
	entries: CaseStudyStatsDescriptionEntries
	resources: CaseStudyStatsDescriptionResources
}

export type CaseStudyStatsDescriptionResources = {
	__typename?: 'CaseStudyStatsDescriptionResources'
	block: Array<CaseStudyStatsDescriptionResourcesBlock>
	hyperlink: Array<CaseStudyStatsDescriptionResourcesHyperlink>
	inline: Array<CaseStudyStatsDescriptionResourcesInline>
}

export type CaseStudyStatsDescriptionResourcesBlock = ResourceLink & {
	__typename?: 'CaseStudyStatsDescriptionResourcesBlock'
	sys: ResourceSys
}

export type CaseStudyStatsDescriptionResourcesHyperlink = ResourceLink & {
	__typename?: 'CaseStudyStatsDescriptionResourcesHyperlink'
	sys: ResourceSys
}

export type CaseStudyStatsDescriptionResourcesInline = ResourceLink & {
	__typename?: 'CaseStudyStatsDescriptionResourcesInline'
	sys: ResourceSys
}

export type CaseStudyStatsSubtitle = {
	__typename?: 'CaseStudyStatsSubtitle'
	json: Scalars['JSON']['output']
	links: CaseStudyStatsSubtitleLinks
}

export type CaseStudyStatsSubtitleAssets = {
	__typename?: 'CaseStudyStatsSubtitleAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type CaseStudyStatsSubtitleEntries = {
	__typename?: 'CaseStudyStatsSubtitleEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type CaseStudyStatsSubtitleLinks = {
	__typename?: 'CaseStudyStatsSubtitleLinks'
	assets: CaseStudyStatsSubtitleAssets
	entries: CaseStudyStatsSubtitleEntries
	resources: CaseStudyStatsSubtitleResources
}

export type CaseStudyStatsSubtitleResources = {
	__typename?: 'CaseStudyStatsSubtitleResources'
	block: Array<CaseStudyStatsSubtitleResourcesBlock>
	hyperlink: Array<CaseStudyStatsSubtitleResourcesHyperlink>
	inline: Array<CaseStudyStatsSubtitleResourcesInline>
}

export type CaseStudyStatsSubtitleResourcesBlock = ResourceLink & {
	__typename?: 'CaseStudyStatsSubtitleResourcesBlock'
	sys: ResourceSys
}

export type CaseStudyStatsSubtitleResourcesHyperlink = ResourceLink & {
	__typename?: 'CaseStudyStatsSubtitleResourcesHyperlink'
	sys: ResourceSys
}

export type CaseStudyStatsSubtitleResourcesInline = ResourceLink & {
	__typename?: 'CaseStudyStatsSubtitleResourcesInline'
	sys: ResourceSys
}

export type CaseStudyStatsTitle = {
	__typename?: 'CaseStudyStatsTitle'
	json: Scalars['JSON']['output']
	links: CaseStudyStatsTitleLinks
}

export type CaseStudyStatsTitleAssets = {
	__typename?: 'CaseStudyStatsTitleAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type CaseStudyStatsTitleEntries = {
	__typename?: 'CaseStudyStatsTitleEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type CaseStudyStatsTitleLinks = {
	__typename?: 'CaseStudyStatsTitleLinks'
	assets: CaseStudyStatsTitleAssets
	entries: CaseStudyStatsTitleEntries
	resources: CaseStudyStatsTitleResources
}

export type CaseStudyStatsTitleResources = {
	__typename?: 'CaseStudyStatsTitleResources'
	block: Array<CaseStudyStatsTitleResourcesBlock>
	hyperlink: Array<CaseStudyStatsTitleResourcesHyperlink>
	inline: Array<CaseStudyStatsTitleResourcesInline>
}

export type CaseStudyStatsTitleResourcesBlock = ResourceLink & {
	__typename?: 'CaseStudyStatsTitleResourcesBlock'
	sys: ResourceSys
}

export type CaseStudyStatsTitleResourcesHyperlink = ResourceLink & {
	__typename?: 'CaseStudyStatsTitleResourcesHyperlink'
	sys: ResourceSys
}

export type CaseStudyStatsTitleResourcesInline = ResourceLink & {
	__typename?: 'CaseStudyStatsTitleResourcesInline'
	sys: ResourceSys
}

export type CaseStudySummary = {
	__typename?: 'CaseStudySummary'
	json: Scalars['JSON']['output']
	links: CaseStudySummaryLinks
}

export type CaseStudySummaryAssets = {
	__typename?: 'CaseStudySummaryAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type CaseStudySummaryEntries = {
	__typename?: 'CaseStudySummaryEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type CaseStudySummaryLinks = {
	__typename?: 'CaseStudySummaryLinks'
	assets: CaseStudySummaryAssets
	entries: CaseStudySummaryEntries
	resources: CaseStudySummaryResources
}

export type CaseStudySummaryResources = {
	__typename?: 'CaseStudySummaryResources'
	block: Array<CaseStudySummaryResourcesBlock>
	hyperlink: Array<CaseStudySummaryResourcesHyperlink>
	inline: Array<CaseStudySummaryResourcesInline>
}

export type CaseStudySummaryResourcesBlock = ResourceLink & {
	__typename?: 'CaseStudySummaryResourcesBlock'
	sys: ResourceSys
}

export type CaseStudySummaryResourcesHyperlink = ResourceLink & {
	__typename?: 'CaseStudySummaryResourcesHyperlink'
	sys: ResourceSys
}

export type CaseStudySummaryResourcesInline = ResourceLink & {
	__typename?: 'CaseStudySummaryResourcesInline'
	sys: ResourceSys
}

export type CaseStudyTopicsListCollection = {
	__typename?: 'CaseStudyTopicsListCollection'
	items: Array<Maybe<CaseStudyProperty>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum CaseStudyTopicsListCollectionOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

/** Hierarchical descriptor for use on many content types [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/category) */
export type Category = Entry &
	_Node & {
		__typename?: 'Category'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		description?: Maybe<CategoryDescription>
		linkedFrom?: Maybe<CategoryLinkingCollections>
		name?: Maybe<Scalars['String']['output']>
		parentCategory?: Maybe<Category>
		seoMetadata?: Maybe<SeoMetadata>
		slug?: Maybe<Scalars['String']['output']>
		sys: Sys
	}

/** Hierarchical descriptor for use on many content types [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/category) */
export type CategoryDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Hierarchical descriptor for use on many content types [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/category) */
export type CategoryLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Hierarchical descriptor for use on many content types [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/category) */
export type CategoryNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Hierarchical descriptor for use on many content types [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/category) */
export type CategoryParentCategoryArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<CategoryFilter>
}

/** Hierarchical descriptor for use on many content types [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/category) */
export type CategorySeoMetadataArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<SeoMetadataFilter>
}

/** Hierarchical descriptor for use on many content types [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/category) */
export type CategorySlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type CategoryCollection = {
	__typename?: 'CategoryCollection'
	items: Array<Maybe<Category>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type CategoryDescription = {
	__typename?: 'CategoryDescription'
	json: Scalars['JSON']['output']
	links: CategoryDescriptionLinks
}

export type CategoryDescriptionAssets = {
	__typename?: 'CategoryDescriptionAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type CategoryDescriptionEntries = {
	__typename?: 'CategoryDescriptionEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type CategoryDescriptionLinks = {
	__typename?: 'CategoryDescriptionLinks'
	assets: CategoryDescriptionAssets
	entries: CategoryDescriptionEntries
	resources: CategoryDescriptionResources
}

export type CategoryDescriptionResources = {
	__typename?: 'CategoryDescriptionResources'
	block: Array<CategoryDescriptionResourcesBlock>
	hyperlink: Array<CategoryDescriptionResourcesHyperlink>
	inline: Array<CategoryDescriptionResourcesInline>
}

export type CategoryDescriptionResourcesBlock = ResourceLink & {
	__typename?: 'CategoryDescriptionResourcesBlock'
	sys: ResourceSys
}

export type CategoryDescriptionResourcesHyperlink = ResourceLink & {
	__typename?: 'CategoryDescriptionResourcesHyperlink'
	sys: ResourceSys
}

export type CategoryDescriptionResourcesInline = ResourceLink & {
	__typename?: 'CategoryDescriptionResourcesInline'
	sys: ResourceSys
}

export type CategoryFilter = {
	AND?: InputMaybe<Array<InputMaybe<CategoryFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CategoryFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	name_contains?: InputMaybe<Scalars['String']['input']>
	name_exists?: InputMaybe<Scalars['Boolean']['input']>
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name_not?: InputMaybe<Scalars['String']['input']>
	name_not_contains?: InputMaybe<Scalars['String']['input']>
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	parentCategory?: InputMaybe<CfCategoryNestedFilter>
	parentCategory_exists?: InputMaybe<Scalars['Boolean']['input']>
	seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type CategoryLinkingCollections = {
	__typename?: 'CategoryLinkingCollections'
	blogPostCollection?: Maybe<BlogPostCollection>
	categoryCollection?: Maybe<CategoryCollection>
	entryCollection?: Maybe<EntryCollection>
	glossaryCollection?: Maybe<GlossaryCollection>
	marketplaceAppCollection?: Maybe<MarketplaceAppCollection>
	showcaseCollection?: Maybe<ShowcaseCollection>
}

export type CategoryLinkingCollectionsBlogPostCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CategoryLinkingCollectionsBlogPostCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type CategoryLinkingCollectionsCategoryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CategoryLinkingCollectionsCategoryCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type CategoryLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type CategoryLinkingCollectionsGlossaryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CategoryLinkingCollectionsGlossaryCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type CategoryLinkingCollectionsMarketplaceAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CategoryLinkingCollectionsMarketplaceAppCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type CategoryLinkingCollectionsShowcaseCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CategoryLinkingCollectionsShowcaseCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum CategoryLinkingCollectionsBlogPostCollectionOrder {
	HideOnHomepageAsc = 'hideOnHomepage_ASC',
	HideOnHomepageDesc = 'hideOnHomepage_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	ShowPostHeroAsc = 'showPostHero_ASC',
	ShowPostHeroDesc = 'showPostHero_DESC',
	ShowTableOfContentsAsc = 'showTableOfContents_ASC',
	ShowTableOfContentsDesc = 'showTableOfContents_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum CategoryLinkingCollectionsCategoryCollectionOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CategoryLinkingCollectionsGlossaryCollectionOrder {
	EnabledAsc = 'enabled_ASC',
	EnabledDesc = 'enabled_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum CategoryLinkingCollectionsMarketplaceAppCollectionOrder {
	AlternativeLogoAsc = 'alternativeLogo_ASC',
	AlternativeLogoDesc = 'alternativeLogo_DESC',
	AppRegistryIdAsc = 'appRegistryId_ASC',
	AppRegistryIdDesc = 'appRegistryId_DESC',
	AppRegistryUpdatedAtAsc = 'appRegistryUpdatedAt_ASC',
	AppRegistryUpdatedAtDesc = 'appRegistryUpdatedAt_DESC',
	ApprovedAtAsc = 'approvedAt_ASC',
	ApprovedAtDesc = 'approvedAt_DESC',
	CatalystCompatibleAsc = 'catalystCompatible_ASC',
	CatalystCompatibleDesc = 'catalystCompatible_DESC',
	CreatedOnAsc = 'createdOn_ASC',
	CreatedOnDesc = 'createdOn_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	FreeTrialDaysAsc = 'freeTrialDays_ASC',
	FreeTrialDaysDesc = 'freeTrialDays_DESC',
	FreeTrialAsc = 'freeTrial_ASC',
	FreeTrialDesc = 'freeTrial_DESC',
	InstallationGuideUrlAsc = 'installationGuideUrl_ASC',
	InstallationGuideUrlDesc = 'installationGuideUrl_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	LastHashAsc = 'lastHash_ASC',
	LastHashDesc = 'lastHash_DESC',
	LogoIconAsc = 'logoIcon_ASC',
	LogoIconDesc = 'logoIcon_DESC',
	LogoAsc = 'logo_ASC',
	LogoDesc = 'logo_DESC',
	MonthlyPriceAsc = 'monthlyPrice_ASC',
	MonthlyPriceDesc = 'monthlyPrice_DESC',
	MultiLocationAsc = 'multiLocation_ASC',
	MultiLocationDesc = 'multiLocation_DESC',
	MultiStorefrontAsc = 'multiStorefront_ASC',
	MultiStorefrontDesc = 'multiStorefront_DESC',
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	PricingTypeAsc = 'pricingType_ASC',
	PricingTypeDesc = 'pricingType_DESC',
	PricingAsc = 'pricing_ASC',
	PricingDesc = 'pricing_DESC',
	PrivacyPolicyUrlAsc = 'privacyPolicyUrl_ASC',
	PrivacyPolicyUrlDesc = 'privacyPolicyUrl_DESC',
	RankAsc = 'rank_ASC',
	RankDesc = 'rank_DESC',
	RatingAsc = 'rating_ASC',
	RatingDesc = 'rating_DESC',
	RecommendedOrderAsc = 'recommendedOrder_ASC',
	RecommendedOrderDesc = 'recommendedOrder_DESC',
	RecommendedAsc = 'recommended_ASC',
	RecommendedDesc = 'recommended_DESC',
	ReviewCountAsc = 'reviewCount_ASC',
	ReviewCountDesc = 'reviewCount_DESC',
	SingleClickAsc = 'singleClick_ASC',
	SingleClickDesc = 'singleClick_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SupportEmailAsc = 'supportEmail_ASC',
	SupportEmailDesc = 'supportEmail_DESC',
	SupportUrlAsc = 'supportUrl_ASC',
	SupportUrlDesc = 'supportUrl_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TermsOfServiceUrlAsc = 'termsOfServiceUrl_ASC',
	TermsOfServiceUrlDesc = 'termsOfServiceUrl_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC',
	UnifiedBillingAsc = 'unifiedBilling_ASC',
	UnifiedBillingDesc = 'unifiedBilling_DESC',
	UpfrontPriceAsc = 'upfrontPrice_ASC',
	UpfrontPriceDesc = 'upfrontPrice_DESC',
	UserGuideUrlAsc = 'userGuideUrl_ASC',
	UserGuideUrlDesc = 'userGuideUrl_DESC',
	VersionAsc = 'version_ASC',
	VersionDesc = 'version_DESC',
	WebsiteAsc = 'website_ASC',
	WebsiteDesc = 'website_DESC'
}

export enum CategoryLinkingCollectionsShowcaseCollectionOrder {
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum CategoryOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ContentfulMetadata = {
	__typename?: 'ContentfulMetadata'
	concepts: Array<Maybe<TaxonomyConcept>>
	tags: Array<Maybe<ContentfulTag>>
}

export type ContentfulMetadataConceptsDescendantsFilter = {
	id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ContentfulMetadataConceptsFilter = {
	descendants?: InputMaybe<ContentfulMetadataConceptsDescendantsFilter>
	id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ContentfulMetadataFilter = {
	concepts?: InputMaybe<ContentfulMetadataConceptsFilter>
	concepts_exists?: InputMaybe<Scalars['Boolean']['input']>
	tags?: InputMaybe<ContentfulMetadataTagsFilter>
	tags_exists?: InputMaybe<Scalars['Boolean']['input']>
}

export type ContentfulMetadataTagsFilter = {
	id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
	__typename?: 'ContentfulTag'
	id?: Maybe<Scalars['String']['output']>
	name?: Maybe<Scalars['String']['output']>
}

/** CTA Button or link used throughout the site. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaButton) */
export type CtaButton = Entry &
	_Node & {
		__typename?: 'CtaButton'
		_id: Scalars['ID']['output']
		color?: Maybe<Scalars['String']['output']>
		contentfulMetadata: ContentfulMetadata
		displayText?: Maybe<Scalars['String']['output']>
		formId?: Maybe<Scalars['String']['output']>
		internalName?: Maybe<Scalars['String']['output']>
		link?: Maybe<ExternalLink>
		linkedFrom?: Maybe<CtaButtonLinkingCollections>
		openInNewTab?: Maybe<Scalars['Boolean']['output']>
		sys: Sys
		variant?: Maybe<Scalars['String']['output']>
	}

/** CTA Button or link used throughout the site. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaButton) */
export type CtaButtonColorArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** CTA Button or link used throughout the site. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaButton) */
export type CtaButtonDisplayTextArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** CTA Button or link used throughout the site. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaButton) */
export type CtaButtonFormIdArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** CTA Button or link used throughout the site. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaButton) */
export type CtaButtonInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** CTA Button or link used throughout the site. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaButton) */
export type CtaButtonLinkArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<ExternalLinkFilter>
}

/** CTA Button or link used throughout the site. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaButton) */
export type CtaButtonLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** CTA Button or link used throughout the site. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaButton) */
export type CtaButtonOpenInNewTabArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** CTA Button or link used throughout the site. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaButton) */
export type CtaButtonVariantArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type CtaButtonCollection = {
	__typename?: 'CtaButtonCollection'
	items: Array<Maybe<CtaButton>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type CtaButtonFilter = {
	AND?: InputMaybe<Array<InputMaybe<CtaButtonFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CtaButtonFilter>>>
	color?: InputMaybe<Scalars['String']['input']>
	color_contains?: InputMaybe<Scalars['String']['input']>
	color_exists?: InputMaybe<Scalars['Boolean']['input']>
	color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	color_not?: InputMaybe<Scalars['String']['input']>
	color_not_contains?: InputMaybe<Scalars['String']['input']>
	color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	displayText?: InputMaybe<Scalars['String']['input']>
	displayText_contains?: InputMaybe<Scalars['String']['input']>
	displayText_exists?: InputMaybe<Scalars['Boolean']['input']>
	displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	displayText_not?: InputMaybe<Scalars['String']['input']>
	displayText_not_contains?: InputMaybe<Scalars['String']['input']>
	displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	formId?: InputMaybe<Scalars['String']['input']>
	formId_contains?: InputMaybe<Scalars['String']['input']>
	formId_exists?: InputMaybe<Scalars['Boolean']['input']>
	formId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	formId_not?: InputMaybe<Scalars['String']['input']>
	formId_not_contains?: InputMaybe<Scalars['String']['input']>
	formId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	link?: InputMaybe<CfExternalLinkNestedFilter>
	link_exists?: InputMaybe<Scalars['Boolean']['input']>
	openInNewTab?: InputMaybe<Scalars['Boolean']['input']>
	openInNewTab_exists?: InputMaybe<Scalars['Boolean']['input']>
	openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>
	sys?: InputMaybe<SysFilter>
	variant?: InputMaybe<Scalars['String']['input']>
	variant_contains?: InputMaybe<Scalars['String']['input']>
	variant_exists?: InputMaybe<Scalars['Boolean']['input']>
	variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	variant_not?: InputMaybe<Scalars['String']['input']>
	variant_not_contains?: InputMaybe<Scalars['String']['input']>
	variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CtaButtonLinkingCollections = {
	__typename?: 'CtaButtonLinkingCollections'
	articleCollection?: Maybe<ArticleCollection>
	basicContentCardCollection?: Maybe<BasicContentCardCollection>
	entryCollection?: Maybe<EntryCollection>
	heroCollection?: Maybe<HeroCollection>
	resourceCollection?: Maybe<ResourceCollection>
}

export type CtaButtonLinkingCollectionsArticleCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CtaButtonLinkingCollectionsArticleCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type CtaButtonLinkingCollectionsBasicContentCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CtaButtonLinkingCollectionsBasicContentCardCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type CtaButtonLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type CtaButtonLinkingCollectionsHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CtaButtonLinkingCollectionsHeroCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type CtaButtonLinkingCollectionsResourceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CtaButtonLinkingCollectionsResourceCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum CtaButtonLinkingCollectionsArticleCollectionOrder {
	EditStatusAsc = 'editStatus_ASC',
	EditStatusDesc = 'editStatus_DESC',
	FaqsHeadingAsc = 'faqsHeading_ASC',
	FaqsHeadingDesc = 'faqsHeading_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	InterstitialHeadlineAsc = 'interstitialHeadline_ASC',
	InterstitialHeadlineDesc = 'interstitialHeadline_DESC',
	PopularAsc = 'popular_ASC',
	PopularDesc = 'popular_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum CtaButtonLinkingCollectionsBasicContentCardCollectionOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	ThemeAsc = 'theme_ASC',
	ThemeDesc = 'theme_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum CtaButtonLinkingCollectionsHeroCollectionOrder {
	BcLogoAsc = 'bcLogo_ASC',
	BcLogoDesc = 'bcLogo_DESC',
	HeadlineAsc = 'headline_ASC',
	HeadlineDesc = 'headline_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaButtonLinkingCollectionsResourceCollectionOrder {
	FormButtonTextAsc = 'formButtonText_ASC',
	FormButtonTextDesc = 'formButtonText_DESC',
	FormIdAsc = 'formId_ASC',
	FormIdDesc = 'formId_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum CtaButtonOrder {
	ColorAsc = 'color_ASC',
	ColorDesc = 'color_DESC',
	DisplayTextAsc = 'displayText_ASC',
	DisplayTextDesc = 'displayText_DESC',
	FormIdAsc = 'formId_ASC',
	FormIdDesc = 'formId_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	OpenInNewTabAsc = 'openInNewTab_ASC',
	OpenInNewTabDesc = 'openInNewTab_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	VariantAsc = 'variant_ASC',
	VariantDesc = 'variant_DESC'
}

/** Used to wrap CTA content in the blog [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaMatPattern) */
export type CtaMatPattern = Entry &
	_Node & {
		__typename?: 'CtaMatPattern'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		ctaText?: Maybe<Scalars['String']['output']>
		ctaUrl?: Maybe<Scalars['String']['output']>
		enabled?: Maybe<Scalars['Boolean']['output']>
		heading?: Maybe<Scalars['String']['output']>
		image?: Maybe<Asset>
		linkedFrom?: Maybe<CtaMatPatternLinkingCollections>
		subheading?: Maybe<Scalars['String']['output']>
		sys: Sys
	}

/** Used to wrap CTA content in the blog [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaMatPattern) */
export type CtaMatPatternCtaTextArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used to wrap CTA content in the blog [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaMatPattern) */
export type CtaMatPatternCtaUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used to wrap CTA content in the blog [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaMatPattern) */
export type CtaMatPatternEnabledArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used to wrap CTA content in the blog [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaMatPattern) */
export type CtaMatPatternHeadingArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used to wrap CTA content in the blog [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaMatPattern) */
export type CtaMatPatternImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Used to wrap CTA content in the blog [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaMatPattern) */
export type CtaMatPatternLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Used to wrap CTA content in the blog [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/ctaMatPattern) */
export type CtaMatPatternSubheadingArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type CtaMatPatternCollection = {
	__typename?: 'CtaMatPatternCollection'
	items: Array<Maybe<CtaMatPattern>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type CtaMatPatternFilter = {
	AND?: InputMaybe<Array<InputMaybe<CtaMatPatternFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CtaMatPatternFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	ctaText?: InputMaybe<Scalars['String']['input']>
	ctaText_contains?: InputMaybe<Scalars['String']['input']>
	ctaText_exists?: InputMaybe<Scalars['Boolean']['input']>
	ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	ctaText_not?: InputMaybe<Scalars['String']['input']>
	ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
	ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	ctaUrl?: InputMaybe<Scalars['String']['input']>
	ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
	ctaUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	ctaUrl_not?: InputMaybe<Scalars['String']['input']>
	ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	enabled?: InputMaybe<Scalars['Boolean']['input']>
	enabled_exists?: InputMaybe<Scalars['Boolean']['input']>
	enabled_not?: InputMaybe<Scalars['Boolean']['input']>
	heading?: InputMaybe<Scalars['String']['input']>
	heading_contains?: InputMaybe<Scalars['String']['input']>
	heading_exists?: InputMaybe<Scalars['Boolean']['input']>
	heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	heading_not?: InputMaybe<Scalars['String']['input']>
	heading_not_contains?: InputMaybe<Scalars['String']['input']>
	heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	subheading?: InputMaybe<Scalars['String']['input']>
	subheading_contains?: InputMaybe<Scalars['String']['input']>
	subheading_exists?: InputMaybe<Scalars['Boolean']['input']>
	subheading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	subheading_not?: InputMaybe<Scalars['String']['input']>
	subheading_not_contains?: InputMaybe<Scalars['String']['input']>
	subheading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type CtaMatPatternLinkingCollections = {
	__typename?: 'CtaMatPatternLinkingCollections'
	blogPostCollection?: Maybe<BlogPostCollection>
	entryCollection?: Maybe<EntryCollection>
}

export type CtaMatPatternLinkingCollectionsBlogPostCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CtaMatPatternLinkingCollectionsBlogPostCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type CtaMatPatternLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum CtaMatPatternLinkingCollectionsBlogPostCollectionOrder {
	HideOnHomepageAsc = 'hideOnHomepage_ASC',
	HideOnHomepageDesc = 'hideOnHomepage_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	ShowPostHeroAsc = 'showPostHero_ASC',
	ShowPostHeroDesc = 'showPostHero_DESC',
	ShowTableOfContentsAsc = 'showTableOfContents_ASC',
	ShowTableOfContentsDesc = 'showTableOfContents_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum CtaMatPatternOrder {
	CtaTextAsc = 'ctaText_ASC',
	CtaTextDesc = 'ctaText_DESC',
	CtaUrlAsc = 'ctaUrl_ASC',
	CtaUrlDesc = 'ctaUrl_DESC',
	EnabledAsc = 'enabled_ASC',
	EnabledDesc = 'enabled_DESC',
	HeadingAsc = 'heading_ASC',
	HeadingDesc = 'heading_DESC',
	SubheadingAsc = 'subheading_ASC',
	SubheadingDesc = 'subheading_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Entry = {
	contentfulMetadata: ContentfulMetadata
	sys: Sys
}

export type EntryCollection = {
	__typename?: 'EntryCollection'
	items: Array<Maybe<Entry>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type EntryFilter = {
	AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>
	OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	sys?: InputMaybe<SysFilter>
}

export enum EntryOrder {
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Events items displayed on the /events page. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/event) */
export type Event = Entry &
	_Node & {
		__typename?: 'Event'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		endDate?: Maybe<Scalars['DateTime']['output']>
		featured?: Maybe<Scalars['Boolean']['output']>
		image?: Maybe<Asset>
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<EventLinkingCollections>
		recurringEvent?: Maybe<Scalars['Boolean']['output']>
		startDate?: Maybe<Scalars['DateTime']['output']>
		status?: Maybe<Scalars['Boolean']['output']>
		summary?: Maybe<Scalars['String']['output']>
		sys: Sys
		title?: Maybe<Scalars['String']['output']>
		url?: Maybe<ExternalLink>
	}

/** Events items displayed on the /events page. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/event) */
export type EventEndDateArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Events items displayed on the /events page. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/event) */
export type EventFeaturedArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Events items displayed on the /events page. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/event) */
export type EventImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Events items displayed on the /events page. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/event) */
export type EventInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Events items displayed on the /events page. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/event) */
export type EventLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Events items displayed on the /events page. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/event) */
export type EventRecurringEventArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Events items displayed on the /events page. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/event) */
export type EventStartDateArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Events items displayed on the /events page. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/event) */
export type EventStatusArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Events items displayed on the /events page. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/event) */
export type EventSummaryArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Events items displayed on the /events page. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/event) */
export type EventTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Events items displayed on the /events page. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/event) */
export type EventUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<ExternalLinkFilter>
}

export type EventCollection = {
	__typename?: 'EventCollection'
	items: Array<Maybe<Event>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type EventFilter = {
	AND?: InputMaybe<Array<InputMaybe<EventFilter>>>
	OR?: InputMaybe<Array<InputMaybe<EventFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	endDate?: InputMaybe<Scalars['DateTime']['input']>
	endDate_exists?: InputMaybe<Scalars['Boolean']['input']>
	endDate_gt?: InputMaybe<Scalars['DateTime']['input']>
	endDate_gte?: InputMaybe<Scalars['DateTime']['input']>
	endDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	endDate_lt?: InputMaybe<Scalars['DateTime']['input']>
	endDate_lte?: InputMaybe<Scalars['DateTime']['input']>
	endDate_not?: InputMaybe<Scalars['DateTime']['input']>
	endDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	featured?: InputMaybe<Scalars['Boolean']['input']>
	featured_exists?: InputMaybe<Scalars['Boolean']['input']>
	featured_not?: InputMaybe<Scalars['Boolean']['input']>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	recurringEvent?: InputMaybe<Scalars['Boolean']['input']>
	recurringEvent_exists?: InputMaybe<Scalars['Boolean']['input']>
	recurringEvent_not?: InputMaybe<Scalars['Boolean']['input']>
	startDate?: InputMaybe<Scalars['DateTime']['input']>
	startDate_exists?: InputMaybe<Scalars['Boolean']['input']>
	startDate_gt?: InputMaybe<Scalars['DateTime']['input']>
	startDate_gte?: InputMaybe<Scalars['DateTime']['input']>
	startDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	startDate_lt?: InputMaybe<Scalars['DateTime']['input']>
	startDate_lte?: InputMaybe<Scalars['DateTime']['input']>
	startDate_not?: InputMaybe<Scalars['DateTime']['input']>
	startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	status?: InputMaybe<Scalars['Boolean']['input']>
	status_exists?: InputMaybe<Scalars['Boolean']['input']>
	status_not?: InputMaybe<Scalars['Boolean']['input']>
	summary?: InputMaybe<Scalars['String']['input']>
	summary_contains?: InputMaybe<Scalars['String']['input']>
	summary_exists?: InputMaybe<Scalars['Boolean']['input']>
	summary_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	summary_not?: InputMaybe<Scalars['String']['input']>
	summary_not_contains?: InputMaybe<Scalars['String']['input']>
	summary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	url?: InputMaybe<CfExternalLinkNestedFilter>
	url_exists?: InputMaybe<Scalars['Boolean']['input']>
}

export type EventLinkingCollections = {
	__typename?: 'EventLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
}

export type EventLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum EventOrder {
	EndDateAsc = 'endDate_ASC',
	EndDateDesc = 'endDate_DESC',
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	RecurringEventAsc = 'recurringEvent_ASC',
	RecurringEventDesc = 'recurringEvent_DESC',
	StartDateAsc = 'startDate_ASC',
	StartDateDesc = 'startDate_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

/** Used for External urls and Makeswift urls or internal paths. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/externalLink) */
export type ExternalLink = Entry &
	_Node & {
		__typename?: 'ExternalLink'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<ExternalLinkLinkingCollections>
		sys: Sys
		url?: Maybe<Scalars['String']['output']>
	}

/** Used for External urls and Makeswift urls or internal paths. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/externalLink) */
export type ExternalLinkInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for External urls and Makeswift urls or internal paths. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/externalLink) */
export type ExternalLinkLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Used for External urls and Makeswift urls or internal paths. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/externalLink) */
export type ExternalLinkUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type ExternalLinkCollection = {
	__typename?: 'ExternalLinkCollection'
	items: Array<Maybe<ExternalLink>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type ExternalLinkFilter = {
	AND?: InputMaybe<Array<InputMaybe<ExternalLinkFilter>>>
	OR?: InputMaybe<Array<InputMaybe<ExternalLinkFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	url?: InputMaybe<Scalars['String']['input']>
	url_contains?: InputMaybe<Scalars['String']['input']>
	url_exists?: InputMaybe<Scalars['Boolean']['input']>
	url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	url_not?: InputMaybe<Scalars['String']['input']>
	url_not_contains?: InputMaybe<Scalars['String']['input']>
	url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ExternalLinkLinkingCollections = {
	__typename?: 'ExternalLinkLinkingCollections'
	additionalPersonOptionsCollection?: Maybe<AdditionalPersonOptionsCollection>
	ctaButtonCollection?: Maybe<CtaButtonCollection>
	entryCollection?: Maybe<EntryCollection>
	eventCollection?: Maybe<EventCollection>
	pressCollection?: Maybe<PressCollection>
	showcaseCollection?: Maybe<ShowcaseCollection>
}

export type ExternalLinkLinkingCollectionsAdditionalPersonOptionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<
		Array<InputMaybe<ExternalLinkLinkingCollectionsAdditionalPersonOptionsCollectionOrder>>
	>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type ExternalLinkLinkingCollectionsCtaButtonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ExternalLinkLinkingCollectionsCtaButtonCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type ExternalLinkLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type ExternalLinkLinkingCollectionsEventCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ExternalLinkLinkingCollectionsEventCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type ExternalLinkLinkingCollectionsPressCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ExternalLinkLinkingCollectionsPressCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type ExternalLinkLinkingCollectionsShowcaseCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ExternalLinkLinkingCollectionsShowcaseCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum ExternalLinkLinkingCollectionsAdditionalPersonOptionsCollectionOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ExternalLinkLinkingCollectionsCtaButtonCollectionOrder {
	ColorAsc = 'color_ASC',
	ColorDesc = 'color_DESC',
	DisplayTextAsc = 'displayText_ASC',
	DisplayTextDesc = 'displayText_DESC',
	FormIdAsc = 'formId_ASC',
	FormIdDesc = 'formId_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	OpenInNewTabAsc = 'openInNewTab_ASC',
	OpenInNewTabDesc = 'openInNewTab_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	VariantAsc = 'variant_ASC',
	VariantDesc = 'variant_DESC'
}

export enum ExternalLinkLinkingCollectionsEventCollectionOrder {
	EndDateAsc = 'endDate_ASC',
	EndDateDesc = 'endDate_DESC',
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	RecurringEventAsc = 'recurringEvent_ASC',
	RecurringEventDesc = 'recurringEvent_DESC',
	StartDateAsc = 'startDate_ASC',
	StartDateDesc = 'startDate_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum ExternalLinkLinkingCollectionsPressCollectionOrder {
	EnabledAsc = 'enabled_ASC',
	EnabledDesc = 'enabled_DESC',
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum ExternalLinkLinkingCollectionsShowcaseCollectionOrder {
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum ExternalLinkOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	UrlAsc = 'url_ASC',
	UrlDesc = 'url_DESC'
}

/** Used on Articles and Blog posts to store FAQs [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/faqItem) */
export type FaqItem = Entry &
	_Node & {
		__typename?: 'FaqItem'
		_id: Scalars['ID']['output']
		answer?: Maybe<FaqItemAnswer>
		contentfulMetadata: ContentfulMetadata
		linkedFrom?: Maybe<FaqItemLinkingCollections>
		question?: Maybe<Scalars['String']['output']>
		sys: Sys
	}

/** Used on Articles and Blog posts to store FAQs [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/faqItem) */
export type FaqItemAnswerArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used on Articles and Blog posts to store FAQs [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/faqItem) */
export type FaqItemLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Used on Articles and Blog posts to store FAQs [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/faqItem) */
export type FaqItemQuestionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type FaqItemAnswer = {
	__typename?: 'FaqItemAnswer'
	json: Scalars['JSON']['output']
	links: FaqItemAnswerLinks
}

export type FaqItemAnswerAssets = {
	__typename?: 'FaqItemAnswerAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type FaqItemAnswerEntries = {
	__typename?: 'FaqItemAnswerEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type FaqItemAnswerLinks = {
	__typename?: 'FaqItemAnswerLinks'
	assets: FaqItemAnswerAssets
	entries: FaqItemAnswerEntries
	resources: FaqItemAnswerResources
}

export type FaqItemAnswerResources = {
	__typename?: 'FaqItemAnswerResources'
	block: Array<FaqItemAnswerResourcesBlock>
	hyperlink: Array<FaqItemAnswerResourcesHyperlink>
	inline: Array<FaqItemAnswerResourcesInline>
}

export type FaqItemAnswerResourcesBlock = ResourceLink & {
	__typename?: 'FaqItemAnswerResourcesBlock'
	sys: ResourceSys
}

export type FaqItemAnswerResourcesHyperlink = ResourceLink & {
	__typename?: 'FaqItemAnswerResourcesHyperlink'
	sys: ResourceSys
}

export type FaqItemAnswerResourcesInline = ResourceLink & {
	__typename?: 'FaqItemAnswerResourcesInline'
	sys: ResourceSys
}

export type FaqItemCollection = {
	__typename?: 'FaqItemCollection'
	items: Array<Maybe<FaqItem>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type FaqItemFilter = {
	AND?: InputMaybe<Array<InputMaybe<FaqItemFilter>>>
	OR?: InputMaybe<Array<InputMaybe<FaqItemFilter>>>
	answer_contains?: InputMaybe<Scalars['String']['input']>
	answer_exists?: InputMaybe<Scalars['Boolean']['input']>
	answer_not_contains?: InputMaybe<Scalars['String']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	question?: InputMaybe<Scalars['String']['input']>
	question_contains?: InputMaybe<Scalars['String']['input']>
	question_exists?: InputMaybe<Scalars['Boolean']['input']>
	question_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	question_not?: InputMaybe<Scalars['String']['input']>
	question_not_contains?: InputMaybe<Scalars['String']['input']>
	question_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type FaqItemLinkingCollections = {
	__typename?: 'FaqItemLinkingCollections'
	articleCollection?: Maybe<ArticleCollection>
	entryCollection?: Maybe<EntryCollection>
}

export type FaqItemLinkingCollectionsArticleCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<FaqItemLinkingCollectionsArticleCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type FaqItemLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum FaqItemLinkingCollectionsArticleCollectionOrder {
	EditStatusAsc = 'editStatus_ASC',
	EditStatusDesc = 'editStatus_DESC',
	FaqsHeadingAsc = 'faqsHeading_ASC',
	FaqsHeadingDesc = 'faqsHeading_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	InterstitialHeadlineAsc = 'interstitialHeadline_ASC',
	InterstitialHeadlineDesc = 'interstitialHeadline_DESC',
	PopularAsc = 'popular_ASC',
	PopularDesc = 'popular_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum FaqItemOrder {
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Used to define values related to a blogs gated content. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/gatedContent) */
export type GatedContent = Entry &
	_Node & {
		__typename?: 'GatedContent'
		_id: Scalars['ID']['output']
		calloutDescription?: Maybe<Scalars['String']['output']>
		calloutTitle?: Maybe<Scalars['String']['output']>
		contentfulMetadata: ContentfulMetadata
		gatedAsset?: Maybe<Asset>
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<GatedContentLinkingCollections>
		providePrintVersion?: Maybe<Scalars['Boolean']['output']>
		sys: Sys
	}

/** Used to define values related to a blogs gated content. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/gatedContent) */
export type GatedContentCalloutDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used to define values related to a blogs gated content. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/gatedContent) */
export type GatedContentCalloutTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used to define values related to a blogs gated content. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/gatedContent) */
export type GatedContentGatedAssetArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Used to define values related to a blogs gated content. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/gatedContent) */
export type GatedContentInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used to define values related to a blogs gated content. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/gatedContent) */
export type GatedContentLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Used to define values related to a blogs gated content. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/gatedContent) */
export type GatedContentProvidePrintVersionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type GatedContentCollection = {
	__typename?: 'GatedContentCollection'
	items: Array<Maybe<GatedContent>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type GatedContentFilter = {
	AND?: InputMaybe<Array<InputMaybe<GatedContentFilter>>>
	OR?: InputMaybe<Array<InputMaybe<GatedContentFilter>>>
	calloutDescription?: InputMaybe<Scalars['String']['input']>
	calloutDescription_contains?: InputMaybe<Scalars['String']['input']>
	calloutDescription_exists?: InputMaybe<Scalars['Boolean']['input']>
	calloutDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	calloutDescription_not?: InputMaybe<Scalars['String']['input']>
	calloutDescription_not_contains?: InputMaybe<Scalars['String']['input']>
	calloutDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	calloutTitle?: InputMaybe<Scalars['String']['input']>
	calloutTitle_contains?: InputMaybe<Scalars['String']['input']>
	calloutTitle_exists?: InputMaybe<Scalars['Boolean']['input']>
	calloutTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	calloutTitle_not?: InputMaybe<Scalars['String']['input']>
	calloutTitle_not_contains?: InputMaybe<Scalars['String']['input']>
	calloutTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	gatedAsset_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	providePrintVersion?: InputMaybe<Scalars['Boolean']['input']>
	providePrintVersion_exists?: InputMaybe<Scalars['Boolean']['input']>
	providePrintVersion_not?: InputMaybe<Scalars['Boolean']['input']>
	sys?: InputMaybe<SysFilter>
}

export type GatedContentLinkingCollections = {
	__typename?: 'GatedContentLinkingCollections'
	articleCollection?: Maybe<ArticleCollection>
	blogPostCollection?: Maybe<BlogPostCollection>
	entryCollection?: Maybe<EntryCollection>
}

export type GatedContentLinkingCollectionsArticleCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<GatedContentLinkingCollectionsArticleCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type GatedContentLinkingCollectionsBlogPostCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<GatedContentLinkingCollectionsBlogPostCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type GatedContentLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum GatedContentLinkingCollectionsArticleCollectionOrder {
	EditStatusAsc = 'editStatus_ASC',
	EditStatusDesc = 'editStatus_DESC',
	FaqsHeadingAsc = 'faqsHeading_ASC',
	FaqsHeadingDesc = 'faqsHeading_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	InterstitialHeadlineAsc = 'interstitialHeadline_ASC',
	InterstitialHeadlineDesc = 'interstitialHeadline_DESC',
	PopularAsc = 'popular_ASC',
	PopularDesc = 'popular_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum GatedContentLinkingCollectionsBlogPostCollectionOrder {
	HideOnHomepageAsc = 'hideOnHomepage_ASC',
	HideOnHomepageDesc = 'hideOnHomepage_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	ShowPostHeroAsc = 'showPostHero_ASC',
	ShowPostHeroDesc = 'showPostHero_DESC',
	ShowTableOfContentsAsc = 'showTableOfContents_ASC',
	ShowTableOfContentsDesc = 'showTableOfContents_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum GatedContentOrder {
	CalloutTitleAsc = 'calloutTitle_ASC',
	CalloutTitleDesc = 'calloutTitle_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	ProvidePrintVersionAsc = 'providePrintVersion_ASC',
	ProvidePrintVersionDesc = 'providePrintVersion_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/glossary) */
export type Glossary = Entry &
	_Node & {
		__typename?: 'Glossary'
		_id: Scalars['ID']['output']
		body?: Maybe<GlossaryBody>
		categoryCollection?: Maybe<GlossaryCategoryCollection>
		contentfulMetadata: ContentfulMetadata
		enabled?: Maybe<Scalars['Boolean']['output']>
		featuredImage?: Maybe<Asset>
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<GlossaryLinkingCollections>
		publishedDate?: Maybe<Scalars['DateTime']['output']>
		seoMetadata?: Maybe<SeoMetadata>
		slug?: Maybe<Scalars['String']['output']>
		summary?: Maybe<GlossarySummary>
		sys: Sys
		title?: Maybe<Scalars['String']['output']>
	}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/glossary) */
export type GlossaryBodyArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/glossary) */
export type GlossaryCategoryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<GlossaryCategoryCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CategoryFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/glossary) */
export type GlossaryEnabledArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/glossary) */
export type GlossaryFeaturedImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/glossary) */
export type GlossaryInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/glossary) */
export type GlossaryLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/glossary) */
export type GlossaryPublishedDateArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/glossary) */
export type GlossarySeoMetadataArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<SeoMetadataFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/glossary) */
export type GlossarySlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/glossary) */
export type GlossarySummaryArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/glossary) */
export type GlossaryTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type GlossaryBody = {
	__typename?: 'GlossaryBody'
	json: Scalars['JSON']['output']
	links: GlossaryBodyLinks
}

export type GlossaryBodyAssets = {
	__typename?: 'GlossaryBodyAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type GlossaryBodyEntries = {
	__typename?: 'GlossaryBodyEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type GlossaryBodyLinks = {
	__typename?: 'GlossaryBodyLinks'
	assets: GlossaryBodyAssets
	entries: GlossaryBodyEntries
	resources: GlossaryBodyResources
}

export type GlossaryBodyResources = {
	__typename?: 'GlossaryBodyResources'
	block: Array<GlossaryBodyResourcesBlock>
	hyperlink: Array<GlossaryBodyResourcesHyperlink>
	inline: Array<GlossaryBodyResourcesInline>
}

export type GlossaryBodyResourcesBlock = ResourceLink & {
	__typename?: 'GlossaryBodyResourcesBlock'
	sys: ResourceSys
}

export type GlossaryBodyResourcesHyperlink = ResourceLink & {
	__typename?: 'GlossaryBodyResourcesHyperlink'
	sys: ResourceSys
}

export type GlossaryBodyResourcesInline = ResourceLink & {
	__typename?: 'GlossaryBodyResourcesInline'
	sys: ResourceSys
}

export type GlossaryCategoryCollection = {
	__typename?: 'GlossaryCategoryCollection'
	items: Array<Maybe<Category>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum GlossaryCategoryCollectionOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type GlossaryCollection = {
	__typename?: 'GlossaryCollection'
	items: Array<Maybe<Glossary>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type GlossaryFilter = {
	AND?: InputMaybe<Array<InputMaybe<GlossaryFilter>>>
	OR?: InputMaybe<Array<InputMaybe<GlossaryFilter>>>
	body_contains?: InputMaybe<Scalars['String']['input']>
	body_exists?: InputMaybe<Scalars['Boolean']['input']>
	body_not_contains?: InputMaybe<Scalars['String']['input']>
	category?: InputMaybe<CfCategoryNestedFilter>
	categoryCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	enabled?: InputMaybe<Scalars['Boolean']['input']>
	enabled_exists?: InputMaybe<Scalars['Boolean']['input']>
	enabled_not?: InputMaybe<Scalars['Boolean']['input']>
	featuredImage_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	publishedDate?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_exists?: InputMaybe<Scalars['Boolean']['input']>
	publishedDate_gt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_gte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	publishedDate_lt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_lte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	summary_contains?: InputMaybe<Scalars['String']['input']>
	summary_exists?: InputMaybe<Scalars['Boolean']['input']>
	summary_not_contains?: InputMaybe<Scalars['String']['input']>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type GlossaryLinkingCollections = {
	__typename?: 'GlossaryLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
}

export type GlossaryLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum GlossaryOrder {
	EnabledAsc = 'enabled_ASC',
	EnabledDesc = 'enabled_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export type GlossarySummary = {
	__typename?: 'GlossarySummary'
	json: Scalars['JSON']['output']
	links: GlossarySummaryLinks
}

export type GlossarySummaryAssets = {
	__typename?: 'GlossarySummaryAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type GlossarySummaryEntries = {
	__typename?: 'GlossarySummaryEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type GlossarySummaryLinks = {
	__typename?: 'GlossarySummaryLinks'
	assets: GlossarySummaryAssets
	entries: GlossarySummaryEntries
	resources: GlossarySummaryResources
}

export type GlossarySummaryResources = {
	__typename?: 'GlossarySummaryResources'
	block: Array<GlossarySummaryResourcesBlock>
	hyperlink: Array<GlossarySummaryResourcesHyperlink>
	inline: Array<GlossarySummaryResourcesInline>
}

export type GlossarySummaryResourcesBlock = ResourceLink & {
	__typename?: 'GlossarySummaryResourcesBlock'
	sys: ResourceSys
}

export type GlossarySummaryResourcesHyperlink = ResourceLink & {
	__typename?: 'GlossarySummaryResourcesHyperlink'
	sys: ResourceSys
}

export type GlossarySummaryResourcesInline = ResourceLink & {
	__typename?: 'GlossarySummaryResourcesInline'
	sys: ResourceSys
}

/** Hero section that is used for certain pages even some dynamic such as Articles. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/hero) */
export type Hero = Entry &
	_Node & {
		__typename?: 'Hero'
		_id: Scalars['ID']['output']
		backgroundOptions?: Maybe<Background>
		bcLogo?: Maybe<Scalars['Boolean']['output']>
		contentfulMetadata: ContentfulMetadata
		cta?: Maybe<CtaButton>
		featuredAsset?: Maybe<HeroFeaturedAsset>
		headline?: Maybe<Scalars['String']['output']>
		image?: Maybe<Asset>
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<HeroLinkingCollections>
		subheading?: Maybe<HeroSubheading>
		sys: Sys
	}

/** Hero section that is used for certain pages even some dynamic such as Articles. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/hero) */
export type HeroBackgroundOptionsArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<BackgroundFilter>
}

/** Hero section that is used for certain pages even some dynamic such as Articles. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/hero) */
export type HeroBcLogoArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Hero section that is used for certain pages even some dynamic such as Articles. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/hero) */
export type HeroCtaArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<CtaButtonFilter>
}

/** Hero section that is used for certain pages even some dynamic such as Articles. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/hero) */
export type HeroFeaturedAssetArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Hero section that is used for certain pages even some dynamic such as Articles. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/hero) */
export type HeroHeadlineArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Hero section that is used for certain pages even some dynamic such as Articles. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/hero) */
export type HeroImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Hero section that is used for certain pages even some dynamic such as Articles. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/hero) */
export type HeroInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Hero section that is used for certain pages even some dynamic such as Articles. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/hero) */
export type HeroLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Hero section that is used for certain pages even some dynamic such as Articles. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/hero) */
export type HeroSubheadingArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type HeroCollection = {
	__typename?: 'HeroCollection'
	items: Array<Maybe<Hero>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type HeroFeaturedAsset = AssetExternal | AssetInternal

export type HeroFilter = {
	AND?: InputMaybe<Array<InputMaybe<HeroFilter>>>
	OR?: InputMaybe<Array<InputMaybe<HeroFilter>>>
	backgroundOptions?: InputMaybe<CfBackgroundNestedFilter>
	backgroundOptions_exists?: InputMaybe<Scalars['Boolean']['input']>
	bcLogo?: InputMaybe<Scalars['Boolean']['input']>
	bcLogo_exists?: InputMaybe<Scalars['Boolean']['input']>
	bcLogo_not?: InputMaybe<Scalars['Boolean']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	cta?: InputMaybe<CfCtaButtonNestedFilter>
	cta_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredAsset_exists?: InputMaybe<Scalars['Boolean']['input']>
	headline?: InputMaybe<Scalars['String']['input']>
	headline_contains?: InputMaybe<Scalars['String']['input']>
	headline_exists?: InputMaybe<Scalars['Boolean']['input']>
	headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	headline_not?: InputMaybe<Scalars['String']['input']>
	headline_not_contains?: InputMaybe<Scalars['String']['input']>
	headline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	subheading_contains?: InputMaybe<Scalars['String']['input']>
	subheading_exists?: InputMaybe<Scalars['Boolean']['input']>
	subheading_not_contains?: InputMaybe<Scalars['String']['input']>
	sys?: InputMaybe<SysFilter>
}

export type HeroLinkingCollections = {
	__typename?: 'HeroLinkingCollections'
	articleCollection?: Maybe<ArticleCollection>
	caseStudyCollection?: Maybe<CaseStudyCollection>
	entryCollection?: Maybe<EntryCollection>
	resourceCollection?: Maybe<ResourceCollection>
}

export type HeroLinkingCollectionsArticleCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<HeroLinkingCollectionsArticleCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type HeroLinkingCollectionsCaseStudyCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<HeroLinkingCollectionsCaseStudyCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type HeroLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type HeroLinkingCollectionsResourceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<HeroLinkingCollectionsResourceCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum HeroLinkingCollectionsArticleCollectionOrder {
	EditStatusAsc = 'editStatus_ASC',
	EditStatusDesc = 'editStatus_DESC',
	FaqsHeadingAsc = 'faqsHeading_ASC',
	FaqsHeadingDesc = 'faqsHeading_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	InterstitialHeadlineAsc = 'interstitialHeadline_ASC',
	InterstitialHeadlineDesc = 'interstitialHeadline_DESC',
	PopularAsc = 'popular_ASC',
	PopularDesc = 'popular_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum HeroLinkingCollectionsCaseStudyCollectionOrder {
	FeaturedAuthorNameAsc = 'featuredAuthorName_ASC',
	FeaturedAuthorNameDesc = 'featuredAuthorName_DESC',
	FeaturedAuthorTitleAsc = 'featuredAuthorTitle_ASC',
	FeaturedAuthorTitleDesc = 'featuredAuthorTitle_DESC',
	FeaturedLinkTextAsc = 'featuredLinkText_ASC',
	FeaturedLinkTextDesc = 'featuredLinkText_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	WhitepaperStandinAsc = 'whitepaperStandin_ASC',
	WhitepaperStandinDesc = 'whitepaperStandin_DESC'
}

export enum HeroLinkingCollectionsResourceCollectionOrder {
	FormButtonTextAsc = 'formButtonText_ASC',
	FormButtonTextDesc = 'formButtonText_DESC',
	FormIdAsc = 'formId_ASC',
	FormIdDesc = 'formId_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum HeroOrder {
	BcLogoAsc = 'bcLogo_ASC',
	BcLogoDesc = 'bcLogo_DESC',
	HeadlineAsc = 'headline_ASC',
	HeadlineDesc = 'headline_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type HeroSubheading = {
	__typename?: 'HeroSubheading'
	json: Scalars['JSON']['output']
	links: HeroSubheadingLinks
}

export type HeroSubheadingAssets = {
	__typename?: 'HeroSubheadingAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type HeroSubheadingEntries = {
	__typename?: 'HeroSubheadingEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type HeroSubheadingLinks = {
	__typename?: 'HeroSubheadingLinks'
	assets: HeroSubheadingAssets
	entries: HeroSubheadingEntries
	resources: HeroSubheadingResources
}

export type HeroSubheadingResources = {
	__typename?: 'HeroSubheadingResources'
	block: Array<HeroSubheadingResourcesBlock>
	hyperlink: Array<HeroSubheadingResourcesHyperlink>
	inline: Array<HeroSubheadingResourcesInline>
}

export type HeroSubheadingResourcesBlock = ResourceLink & {
	__typename?: 'HeroSubheadingResourcesBlock'
	sys: ResourceSys
}

export type HeroSubheadingResourcesHyperlink = ResourceLink & {
	__typename?: 'HeroSubheadingResourcesHyperlink'
	sys: ResourceSys
}

export type HeroSubheadingResourcesInline = ResourceLink & {
	__typename?: 'HeroSubheadingResourcesInline'
	sys: ResourceSys
}

export enum ImageFormat {
	Avif = 'AVIF',
	/** JPG image format. */
	Jpg = 'JPG',
	/**
	 * Progressive JPG format stores multiple passes of an image in progressively higher detail.
	 *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
	 *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
	 *         early as possible to make the layout look as designed.
	 */
	JpgProgressive = 'JPG_PROGRESSIVE',
	/** PNG image format */
	Png = 'PNG',
	/**
	 * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
	 *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
	 */
	Png8 = 'PNG8',
	/** WebP image format. */
	Webp = 'WEBP'
}

export enum ImageResizeFocus {
	/** Focus the resizing on the bottom. */
	Bottom = 'BOTTOM',
	/** Focus the resizing on the bottom left. */
	BottomLeft = 'BOTTOM_LEFT',
	/** Focus the resizing on the bottom right. */
	BottomRight = 'BOTTOM_RIGHT',
	/** Focus the resizing on the center. */
	Center = 'CENTER',
	/** Focus the resizing on the largest face. */
	Face = 'FACE',
	/** Focus the resizing on the area containing all the faces. */
	Faces = 'FACES',
	/** Focus the resizing on the left. */
	Left = 'LEFT',
	/** Focus the resizing on the right. */
	Right = 'RIGHT',
	/** Focus the resizing on the top. */
	Top = 'TOP',
	/** Focus the resizing on the top left. */
	TopLeft = 'TOP_LEFT',
	/** Focus the resizing on the top right. */
	TopRight = 'TOP_RIGHT'
}

export enum ImageResizeStrategy {
	/** Crops a part of the original image to fit into the specified dimensions. */
	Crop = 'CROP',
	/** Resizes the image to the specified dimensions, cropping the image if needed. */
	Fill = 'FILL',
	/** Resizes the image to fit into the specified dimensions. */
	Fit = 'FIT',
	/**
	 * Resizes the image to the specified dimensions, padding the image if needed.
	 *         Uses desired background color as padding color.
	 */
	Pad = 'PAD',
	/** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
	Scale = 'SCALE',
	/** Creates a thumbnail from the image. */
	Thumb = 'THUMB'
}

export type ImageTransformOptions = {
	/**
	 * Desired background color, used with corner radius or `PAD` resize strategy.
	 *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
	 */
	backgroundColor?: InputMaybe<Scalars['HexColor']['input']>
	/**
	 * Desired corner radius in pixels.
	 *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
	 *         Defaults to `0`. Uses desired background color as padding color,
	 *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
	 */
	cornerRadius?: InputMaybe<Scalars['Int']['input']>
	/** Desired image format. Defaults to the original image format. */
	format?: InputMaybe<ImageFormat>
	/** Desired height in pixels. Defaults to the original image height. */
	height?: InputMaybe<Scalars['Dimension']['input']>
	/**
	 * Desired quality of the image in percents.
	 *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
	 */
	quality?: InputMaybe<Scalars['Quality']['input']>
	/** Desired resize focus area. Defaults to `CENTER`. */
	resizeFocus?: InputMaybe<ImageResizeFocus>
	/** Desired resize strategy. Defaults to `FIT`. */
	resizeStrategy?: InputMaybe<ImageResizeStrategy>
	/** Desired width in pixels. Defaults to the original image width. */
	width?: InputMaybe<Scalars['Dimension']['input']>
}

/** Used for microcopy. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/keyValue) */
export type KeyValue = Entry &
	_Node & {
		__typename?: 'KeyValue'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		key?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<KeyValueLinkingCollections>
		sys: Sys
		value?: Maybe<Scalars['String']['output']>
	}

/** Used for microcopy. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/keyValue) */
export type KeyValueKeyArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for microcopy. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/keyValue) */
export type KeyValueLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Used for microcopy. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/keyValue) */
export type KeyValueValueArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type KeyValueCollection = {
	__typename?: 'KeyValueCollection'
	items: Array<Maybe<KeyValue>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type KeyValueFilter = {
	AND?: InputMaybe<Array<InputMaybe<KeyValueFilter>>>
	OR?: InputMaybe<Array<InputMaybe<KeyValueFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	key?: InputMaybe<Scalars['String']['input']>
	key_contains?: InputMaybe<Scalars['String']['input']>
	key_exists?: InputMaybe<Scalars['Boolean']['input']>
	key_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	key_not?: InputMaybe<Scalars['String']['input']>
	key_not_contains?: InputMaybe<Scalars['String']['input']>
	key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	value?: InputMaybe<Scalars['String']['input']>
	value_contains?: InputMaybe<Scalars['String']['input']>
	value_exists?: InputMaybe<Scalars['Boolean']['input']>
	value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	value_not?: InputMaybe<Scalars['String']['input']>
	value_not_contains?: InputMaybe<Scalars['String']['input']>
	value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type KeyValueLinkingCollections = {
	__typename?: 'KeyValueLinkingCollections'
	caseStudyCollection?: Maybe<CaseStudyCollection>
	entryCollection?: Maybe<EntryCollection>
	keyValueSetCollection?: Maybe<KeyValueSetCollection>
	resourceCollection?: Maybe<ResourceCollection>
}

export type KeyValueLinkingCollectionsCaseStudyCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<KeyValueLinkingCollectionsCaseStudyCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type KeyValueLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type KeyValueLinkingCollectionsKeyValueSetCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<KeyValueLinkingCollectionsKeyValueSetCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type KeyValueLinkingCollectionsResourceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<KeyValueLinkingCollectionsResourceCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum KeyValueLinkingCollectionsCaseStudyCollectionOrder {
	FeaturedAuthorNameAsc = 'featuredAuthorName_ASC',
	FeaturedAuthorNameDesc = 'featuredAuthorName_DESC',
	FeaturedAuthorTitleAsc = 'featuredAuthorTitle_ASC',
	FeaturedAuthorTitleDesc = 'featuredAuthorTitle_DESC',
	FeaturedLinkTextAsc = 'featuredLinkText_ASC',
	FeaturedLinkTextDesc = 'featuredLinkText_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	WhitepaperStandinAsc = 'whitepaperStandin_ASC',
	WhitepaperStandinDesc = 'whitepaperStandin_DESC'
}

export enum KeyValueLinkingCollectionsKeyValueSetCollectionOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	MakeswiftPageIdAsc = 'makeswiftPageId_ASC',
	MakeswiftPageIdDesc = 'makeswiftPageId_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum KeyValueLinkingCollectionsResourceCollectionOrder {
	FormButtonTextAsc = 'formButtonText_ASC',
	FormButtonTextDesc = 'formButtonText_DESC',
	FormIdAsc = 'formId_ASC',
	FormIdDesc = 'formId_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum KeyValueOrder {
	KeyAsc = 'key_ASC',
	KeyDesc = 'key_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	ValueAsc = 'value_ASC',
	ValueDesc = 'value_DESC'
}

/** Used for microcopy. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/keyValueSet) */
export type KeyValueSet = Entry &
	_Node & {
		__typename?: 'KeyValueSet'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		internalName?: Maybe<Scalars['String']['output']>
		keyValueCollection?: Maybe<KeyValueSetKeyValueCollection>
		linkedFrom?: Maybe<KeyValueSetLinkingCollections>
		makeswiftPageId?: Maybe<Scalars['String']['output']>
		sys: Sys
	}

/** Used for microcopy. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/keyValueSet) */
export type KeyValueSetInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for microcopy. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/keyValueSet) */
export type KeyValueSetKeyValueCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<KeyValueSetKeyValueCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<KeyValueFilter>
}

/** Used for microcopy. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/keyValueSet) */
export type KeyValueSetLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Used for microcopy. [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/keyValueSet) */
export type KeyValueSetMakeswiftPageIdArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type KeyValueSetCollection = {
	__typename?: 'KeyValueSetCollection'
	items: Array<Maybe<KeyValueSet>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type KeyValueSetFilter = {
	AND?: InputMaybe<Array<InputMaybe<KeyValueSetFilter>>>
	OR?: InputMaybe<Array<InputMaybe<KeyValueSetFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	keyValue?: InputMaybe<CfKeyValueNestedFilter>
	keyValueCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	makeswiftPageId?: InputMaybe<Scalars['String']['input']>
	makeswiftPageId_contains?: InputMaybe<Scalars['String']['input']>
	makeswiftPageId_exists?: InputMaybe<Scalars['Boolean']['input']>
	makeswiftPageId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	makeswiftPageId_not?: InputMaybe<Scalars['String']['input']>
	makeswiftPageId_not_contains?: InputMaybe<Scalars['String']['input']>
	makeswiftPageId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type KeyValueSetKeyValueCollection = {
	__typename?: 'KeyValueSetKeyValueCollection'
	items: Array<Maybe<KeyValue>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum KeyValueSetKeyValueCollectionOrder {
	KeyAsc = 'key_ASC',
	KeyDesc = 'key_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	ValueAsc = 'value_ASC',
	ValueDesc = 'value_DESC'
}

export type KeyValueSetLinkingCollections = {
	__typename?: 'KeyValueSetLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
}

export type KeyValueSetLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum KeyValueSetOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	MakeswiftPageIdAsc = 'makeswiftPageId_ASC',
	MakeswiftPageIdDesc = 'makeswiftPageId_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Used to contain values related to the marketo form [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketoForm) */
export type MarketoForm = Entry &
	_Node & {
		__typename?: 'MarketoForm'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<MarketoFormLinkingCollections>
		marketoFormSuccessMessage?: Maybe<Scalars['String']['output']>
		sys: Sys
	}

/** Used to contain values related to the marketo form [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketoForm) */
export type MarketoFormInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used to contain values related to the marketo form [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketoForm) */
export type MarketoFormLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Used to contain values related to the marketo form [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketoForm) */
export type MarketoFormMarketoFormSuccessMessageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type MarketoFormCollection = {
	__typename?: 'MarketoFormCollection'
	items: Array<Maybe<MarketoForm>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type MarketoFormFilter = {
	AND?: InputMaybe<Array<InputMaybe<MarketoFormFilter>>>
	OR?: InputMaybe<Array<InputMaybe<MarketoFormFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	marketoFormSuccessMessage?: InputMaybe<Scalars['String']['input']>
	marketoFormSuccessMessage_contains?: InputMaybe<Scalars['String']['input']>
	marketoFormSuccessMessage_exists?: InputMaybe<Scalars['Boolean']['input']>
	marketoFormSuccessMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	marketoFormSuccessMessage_not?: InputMaybe<Scalars['String']['input']>
	marketoFormSuccessMessage_not_contains?: InputMaybe<Scalars['String']['input']>
	marketoFormSuccessMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type MarketoFormLinkingCollections = {
	__typename?: 'MarketoFormLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
}

export type MarketoFormLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum MarketoFormOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceApp = Entry &
	_Node & {
		__typename?: 'MarketplaceApp'
		_id: Scalars['ID']['output']
		abstract?: Maybe<MarketplaceAppAbstract>
		algoliaKeywords?: Maybe<Array<Maybe<Scalars['String']['output']>>>
		alternativeLogo?: Maybe<Scalars['String']['output']>
		appFeaturesCollection?: Maybe<MarketplaceAppAppFeaturesCollection>
		appRegistryId?: Maybe<Scalars['String']['output']>
		appRegistryUpdatedAt?: Maybe<Scalars['DateTime']['output']>
		appTier?: Maybe<AppTier>
		approvedAt?: Maybe<Scalars['DateTime']['output']>
		caseStudiesCollection?: Maybe<MarketplaceAppCaseStudiesCollection>
		catalystCompatible?: Maybe<Scalars['Boolean']['output']>
		categoriesCollection?: Maybe<MarketplaceAppCategoriesCollection>
		collectionsCollection?: Maybe<MarketplaceAppCollectionsCollection>
		contentfulMetadata: ContentfulMetadata
		createdOn?: Maybe<Scalars['DateTime']['output']>
		featured?: Maybe<Scalars['Boolean']['output']>
		featuredOrder?: Maybe<Scalars['Int']['output']>
		freeTrial?: Maybe<Scalars['Boolean']['output']>
		freeTrialDays?: Maybe<Scalars['Int']['output']>
		installationGuideUrl?: Maybe<Scalars['String']['output']>
		internalName?: Maybe<Scalars['String']['output']>
		lastHash?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<MarketplaceAppLinkingCollections>
		logo?: Maybe<Scalars['String']['output']>
		logoIcon?: Maybe<Scalars['String']['output']>
		monthlyPrice?: Maybe<Scalars['String']['output']>
		multiLocation?: Maybe<Scalars['Boolean']['output']>
		multiStorefront?: Maybe<Scalars['Boolean']['output']>
		name?: Maybe<Scalars['String']['output']>
		pricing?: Maybe<Scalars['String']['output']>
		pricingType?: Maybe<Scalars['String']['output']>
		privacyPolicyUrl?: Maybe<Scalars['String']['output']>
		rank?: Maybe<Scalars['Int']['output']>
		rating?: Maybe<Scalars['Float']['output']>
		recommended?: Maybe<Scalars['Boolean']['output']>
		recommendedOrder?: Maybe<Scalars['Int']['output']>
		reviewCount?: Maybe<Scalars['Int']['output']>
		screenshots?: Maybe<Array<Maybe<Scalars['String']['output']>>>
		seoMetadata?: Maybe<SeoMetadata>
		similarApps?: Maybe<Array<Maybe<Scalars['String']['output']>>>
		singleClick?: Maybe<Scalars['Boolean']['output']>
		slug?: Maybe<Scalars['String']['output']>
		summary?: Maybe<MarketplaceAppSummary>
		supportEmail?: Maybe<Scalars['String']['output']>
		supportUrl?: Maybe<Scalars['String']['output']>
		sys: Sys
		termsOfServiceUrl?: Maybe<Scalars['String']['output']>
		type?: Maybe<Scalars['String']['output']>
		unifiedBilling?: Maybe<Scalars['Boolean']['output']>
		upfrontPrice?: Maybe<Scalars['String']['output']>
		userGuideUrl?: Maybe<Scalars['String']['output']>
		version?: Maybe<Scalars['String']['output']>
		video?: Maybe<Array<Maybe<Scalars['String']['output']>>>
		website?: Maybe<Scalars['String']['output']>
	}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppAbstractArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppAlgoliaKeywordsArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppAlternativeLogoArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppAppFeaturesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceAppAppFeaturesCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<AppFeatureFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppAppRegistryIdArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppAppRegistryUpdatedAtArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppAppTierArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<AppTierFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppApprovedAtArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppCaseStudiesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceAppCaseStudiesCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<AppCaseStudyFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppCatalystCompatibleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppCategoriesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceAppCategoriesCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CategoryFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppCollectionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceAppCollectionsCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<AppCollectionFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppCreatedOnArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppFeaturedArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppFeaturedOrderArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppFreeTrialArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppFreeTrialDaysArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppInstallationGuideUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppLastHashArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppLogoArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppLogoIconArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppMonthlyPriceArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppMultiLocationArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppMultiStorefrontArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppPricingArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppPricingTypeArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppPrivacyPolicyUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppRankArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppRatingArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppRecommendedArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppRecommendedOrderArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppReviewCountArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppScreenshotsArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppSeoMetadataArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<SeoMetadataFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppSimilarAppsArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppSingleClickArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppSlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppSummaryArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppSupportEmailArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppSupportUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppTermsOfServiceUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppTypeArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppUnifiedBillingArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppUpfrontPriceArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppUserGuideUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppVersionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppVideoArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceApp) */
export type MarketplaceAppWebsiteArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type MarketplaceAppAbstract = {
	__typename?: 'MarketplaceAppAbstract'
	json: Scalars['JSON']['output']
	links: MarketplaceAppAbstractLinks
}

export type MarketplaceAppAbstractAssets = {
	__typename?: 'MarketplaceAppAbstractAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type MarketplaceAppAbstractEntries = {
	__typename?: 'MarketplaceAppAbstractEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type MarketplaceAppAbstractLinks = {
	__typename?: 'MarketplaceAppAbstractLinks'
	assets: MarketplaceAppAbstractAssets
	entries: MarketplaceAppAbstractEntries
	resources: MarketplaceAppAbstractResources
}

export type MarketplaceAppAbstractResources = {
	__typename?: 'MarketplaceAppAbstractResources'
	block: Array<MarketplaceAppAbstractResourcesBlock>
	hyperlink: Array<MarketplaceAppAbstractResourcesHyperlink>
	inline: Array<MarketplaceAppAbstractResourcesInline>
}

export type MarketplaceAppAbstractResourcesBlock = ResourceLink & {
	__typename?: 'MarketplaceAppAbstractResourcesBlock'
	sys: ResourceSys
}

export type MarketplaceAppAbstractResourcesHyperlink = ResourceLink & {
	__typename?: 'MarketplaceAppAbstractResourcesHyperlink'
	sys: ResourceSys
}

export type MarketplaceAppAbstractResourcesInline = ResourceLink & {
	__typename?: 'MarketplaceAppAbstractResourcesInline'
	sys: ResourceSys
}

export type MarketplaceAppAppFeaturesCollection = {
	__typename?: 'MarketplaceAppAppFeaturesCollection'
	items: Array<Maybe<AppFeature>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum MarketplaceAppAppFeaturesCollectionOrder {
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export type MarketplaceAppCaseStudiesCollection = {
	__typename?: 'MarketplaceAppCaseStudiesCollection'
	items: Array<Maybe<AppCaseStudy>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum MarketplaceAppCaseStudiesCollectionOrder {
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	UrlAsc = 'url_ASC',
	UrlDesc = 'url_DESC'
}

export type MarketplaceAppCategoriesCollection = {
	__typename?: 'MarketplaceAppCategoriesCollection'
	items: Array<Maybe<Category>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum MarketplaceAppCategoriesCollectionOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type MarketplaceAppCollection = {
	__typename?: 'MarketplaceAppCollection'
	items: Array<Maybe<MarketplaceApp>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type MarketplaceAppCollectionsCollection = {
	__typename?: 'MarketplaceAppCollectionsCollection'
	items: Array<Maybe<AppCollection>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum MarketplaceAppCollectionsCollectionOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	PrivateAsc = 'private_ASC',
	PrivateDesc = 'private_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SortAsc = 'sort_ASC',
	SortDesc = 'sort_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	ThumbnailAsc = 'thumbnail_ASC',
	ThumbnailDesc = 'thumbnail_DESC'
}

export type MarketplaceAppFilter = {
	AND?: InputMaybe<Array<InputMaybe<MarketplaceAppFilter>>>
	OR?: InputMaybe<Array<InputMaybe<MarketplaceAppFilter>>>
	abstract_contains?: InputMaybe<Scalars['String']['input']>
	abstract_exists?: InputMaybe<Scalars['Boolean']['input']>
	abstract_not_contains?: InputMaybe<Scalars['String']['input']>
	algoliaKeywords_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	algoliaKeywords_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	algoliaKeywords_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	algoliaKeywords_exists?: InputMaybe<Scalars['Boolean']['input']>
	alternativeLogo?: InputMaybe<Scalars['String']['input']>
	alternativeLogo_contains?: InputMaybe<Scalars['String']['input']>
	alternativeLogo_exists?: InputMaybe<Scalars['Boolean']['input']>
	alternativeLogo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	alternativeLogo_not?: InputMaybe<Scalars['String']['input']>
	alternativeLogo_not_contains?: InputMaybe<Scalars['String']['input']>
	alternativeLogo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	appFeatures?: InputMaybe<CfAppFeatureNestedFilter>
	appFeaturesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	appRegistryId?: InputMaybe<Scalars['String']['input']>
	appRegistryId_contains?: InputMaybe<Scalars['String']['input']>
	appRegistryId_exists?: InputMaybe<Scalars['Boolean']['input']>
	appRegistryId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	appRegistryId_not?: InputMaybe<Scalars['String']['input']>
	appRegistryId_not_contains?: InputMaybe<Scalars['String']['input']>
	appRegistryId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	appRegistryUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>
	appRegistryUpdatedAt_exists?: InputMaybe<Scalars['Boolean']['input']>
	appRegistryUpdatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
	appRegistryUpdatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
	appRegistryUpdatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	appRegistryUpdatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
	appRegistryUpdatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
	appRegistryUpdatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
	appRegistryUpdatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	appTier?: InputMaybe<CfAppTierNestedFilter>
	appTier_exists?: InputMaybe<Scalars['Boolean']['input']>
	approvedAt?: InputMaybe<Scalars['DateTime']['input']>
	approvedAt_exists?: InputMaybe<Scalars['Boolean']['input']>
	approvedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
	approvedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
	approvedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	approvedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
	approvedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
	approvedAt_not?: InputMaybe<Scalars['DateTime']['input']>
	approvedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	caseStudies?: InputMaybe<CfAppCaseStudyNestedFilter>
	caseStudiesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	catalystCompatible?: InputMaybe<Scalars['Boolean']['input']>
	catalystCompatible_exists?: InputMaybe<Scalars['Boolean']['input']>
	catalystCompatible_not?: InputMaybe<Scalars['Boolean']['input']>
	categories?: InputMaybe<CfCategoryNestedFilter>
	categoriesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	collections?: InputMaybe<CfAppCollectionNestedFilter>
	collectionsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	createdOn?: InputMaybe<Scalars['DateTime']['input']>
	createdOn_exists?: InputMaybe<Scalars['Boolean']['input']>
	createdOn_gt?: InputMaybe<Scalars['DateTime']['input']>
	createdOn_gte?: InputMaybe<Scalars['DateTime']['input']>
	createdOn_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	createdOn_lt?: InputMaybe<Scalars['DateTime']['input']>
	createdOn_lte?: InputMaybe<Scalars['DateTime']['input']>
	createdOn_not?: InputMaybe<Scalars['DateTime']['input']>
	createdOn_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	featured?: InputMaybe<Scalars['Boolean']['input']>
	featuredOrder?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredOrder_gt?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_gte?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	featuredOrder_lt?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_lte?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_not?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	featured_exists?: InputMaybe<Scalars['Boolean']['input']>
	featured_not?: InputMaybe<Scalars['Boolean']['input']>
	freeTrial?: InputMaybe<Scalars['Boolean']['input']>
	freeTrialDays?: InputMaybe<Scalars['Int']['input']>
	freeTrialDays_exists?: InputMaybe<Scalars['Boolean']['input']>
	freeTrialDays_gt?: InputMaybe<Scalars['Int']['input']>
	freeTrialDays_gte?: InputMaybe<Scalars['Int']['input']>
	freeTrialDays_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	freeTrialDays_lt?: InputMaybe<Scalars['Int']['input']>
	freeTrialDays_lte?: InputMaybe<Scalars['Int']['input']>
	freeTrialDays_not?: InputMaybe<Scalars['Int']['input']>
	freeTrialDays_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	freeTrial_exists?: InputMaybe<Scalars['Boolean']['input']>
	freeTrial_not?: InputMaybe<Scalars['Boolean']['input']>
	installationGuideUrl?: InputMaybe<Scalars['String']['input']>
	installationGuideUrl_contains?: InputMaybe<Scalars['String']['input']>
	installationGuideUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	installationGuideUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	installationGuideUrl_not?: InputMaybe<Scalars['String']['input']>
	installationGuideUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	installationGuideUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	lastHash?: InputMaybe<Scalars['String']['input']>
	lastHash_contains?: InputMaybe<Scalars['String']['input']>
	lastHash_exists?: InputMaybe<Scalars['Boolean']['input']>
	lastHash_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	lastHash_not?: InputMaybe<Scalars['String']['input']>
	lastHash_not_contains?: InputMaybe<Scalars['String']['input']>
	lastHash_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	logo?: InputMaybe<Scalars['String']['input']>
	logoIcon?: InputMaybe<Scalars['String']['input']>
	logoIcon_contains?: InputMaybe<Scalars['String']['input']>
	logoIcon_exists?: InputMaybe<Scalars['Boolean']['input']>
	logoIcon_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	logoIcon_not?: InputMaybe<Scalars['String']['input']>
	logoIcon_not_contains?: InputMaybe<Scalars['String']['input']>
	logoIcon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	logo_contains?: InputMaybe<Scalars['String']['input']>
	logo_exists?: InputMaybe<Scalars['Boolean']['input']>
	logo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	logo_not?: InputMaybe<Scalars['String']['input']>
	logo_not_contains?: InputMaybe<Scalars['String']['input']>
	logo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	monthlyPrice?: InputMaybe<Scalars['String']['input']>
	monthlyPrice_contains?: InputMaybe<Scalars['String']['input']>
	monthlyPrice_exists?: InputMaybe<Scalars['Boolean']['input']>
	monthlyPrice_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	monthlyPrice_not?: InputMaybe<Scalars['String']['input']>
	monthlyPrice_not_contains?: InputMaybe<Scalars['String']['input']>
	monthlyPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	multiLocation?: InputMaybe<Scalars['Boolean']['input']>
	multiLocation_exists?: InputMaybe<Scalars['Boolean']['input']>
	multiLocation_not?: InputMaybe<Scalars['Boolean']['input']>
	multiStorefront?: InputMaybe<Scalars['Boolean']['input']>
	multiStorefront_exists?: InputMaybe<Scalars['Boolean']['input']>
	multiStorefront_not?: InputMaybe<Scalars['Boolean']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	name_contains?: InputMaybe<Scalars['String']['input']>
	name_exists?: InputMaybe<Scalars['Boolean']['input']>
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name_not?: InputMaybe<Scalars['String']['input']>
	name_not_contains?: InputMaybe<Scalars['String']['input']>
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	pricing?: InputMaybe<Scalars['String']['input']>
	pricingType?: InputMaybe<Scalars['String']['input']>
	pricingType_contains?: InputMaybe<Scalars['String']['input']>
	pricingType_exists?: InputMaybe<Scalars['Boolean']['input']>
	pricingType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	pricingType_not?: InputMaybe<Scalars['String']['input']>
	pricingType_not_contains?: InputMaybe<Scalars['String']['input']>
	pricingType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	pricing_contains?: InputMaybe<Scalars['String']['input']>
	pricing_exists?: InputMaybe<Scalars['Boolean']['input']>
	pricing_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	pricing_not?: InputMaybe<Scalars['String']['input']>
	pricing_not_contains?: InputMaybe<Scalars['String']['input']>
	pricing_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	privacyPolicyUrl?: InputMaybe<Scalars['String']['input']>
	privacyPolicyUrl_contains?: InputMaybe<Scalars['String']['input']>
	privacyPolicyUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	privacyPolicyUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	privacyPolicyUrl_not?: InputMaybe<Scalars['String']['input']>
	privacyPolicyUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	privacyPolicyUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	rank?: InputMaybe<Scalars['Int']['input']>
	rank_exists?: InputMaybe<Scalars['Boolean']['input']>
	rank_gt?: InputMaybe<Scalars['Int']['input']>
	rank_gte?: InputMaybe<Scalars['Int']['input']>
	rank_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	rank_lt?: InputMaybe<Scalars['Int']['input']>
	rank_lte?: InputMaybe<Scalars['Int']['input']>
	rank_not?: InputMaybe<Scalars['Int']['input']>
	rank_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	rating?: InputMaybe<Scalars['Float']['input']>
	rating_exists?: InputMaybe<Scalars['Boolean']['input']>
	rating_gt?: InputMaybe<Scalars['Float']['input']>
	rating_gte?: InputMaybe<Scalars['Float']['input']>
	rating_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
	rating_lt?: InputMaybe<Scalars['Float']['input']>
	rating_lte?: InputMaybe<Scalars['Float']['input']>
	rating_not?: InputMaybe<Scalars['Float']['input']>
	rating_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
	recommended?: InputMaybe<Scalars['Boolean']['input']>
	recommendedOrder?: InputMaybe<Scalars['Int']['input']>
	recommendedOrder_exists?: InputMaybe<Scalars['Boolean']['input']>
	recommendedOrder_gt?: InputMaybe<Scalars['Int']['input']>
	recommendedOrder_gte?: InputMaybe<Scalars['Int']['input']>
	recommendedOrder_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	recommendedOrder_lt?: InputMaybe<Scalars['Int']['input']>
	recommendedOrder_lte?: InputMaybe<Scalars['Int']['input']>
	recommendedOrder_not?: InputMaybe<Scalars['Int']['input']>
	recommendedOrder_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	recommended_exists?: InputMaybe<Scalars['Boolean']['input']>
	recommended_not?: InputMaybe<Scalars['Boolean']['input']>
	reviewCount?: InputMaybe<Scalars['Int']['input']>
	reviewCount_exists?: InputMaybe<Scalars['Boolean']['input']>
	reviewCount_gt?: InputMaybe<Scalars['Int']['input']>
	reviewCount_gte?: InputMaybe<Scalars['Int']['input']>
	reviewCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	reviewCount_lt?: InputMaybe<Scalars['Int']['input']>
	reviewCount_lte?: InputMaybe<Scalars['Int']['input']>
	reviewCount_not?: InputMaybe<Scalars['Int']['input']>
	reviewCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	screenshots_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	screenshots_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	screenshots_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	screenshots_exists?: InputMaybe<Scalars['Boolean']['input']>
	seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	similarApps_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	similarApps_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	similarApps_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	similarApps_exists?: InputMaybe<Scalars['Boolean']['input']>
	singleClick?: InputMaybe<Scalars['Boolean']['input']>
	singleClick_exists?: InputMaybe<Scalars['Boolean']['input']>
	singleClick_not?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	summary_contains?: InputMaybe<Scalars['String']['input']>
	summary_exists?: InputMaybe<Scalars['Boolean']['input']>
	summary_not_contains?: InputMaybe<Scalars['String']['input']>
	supportEmail?: InputMaybe<Scalars['String']['input']>
	supportEmail_contains?: InputMaybe<Scalars['String']['input']>
	supportEmail_exists?: InputMaybe<Scalars['Boolean']['input']>
	supportEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	supportEmail_not?: InputMaybe<Scalars['String']['input']>
	supportEmail_not_contains?: InputMaybe<Scalars['String']['input']>
	supportEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	supportUrl?: InputMaybe<Scalars['String']['input']>
	supportUrl_contains?: InputMaybe<Scalars['String']['input']>
	supportUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	supportUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	supportUrl_not?: InputMaybe<Scalars['String']['input']>
	supportUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	supportUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	termsOfServiceUrl?: InputMaybe<Scalars['String']['input']>
	termsOfServiceUrl_contains?: InputMaybe<Scalars['String']['input']>
	termsOfServiceUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	termsOfServiceUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	termsOfServiceUrl_not?: InputMaybe<Scalars['String']['input']>
	termsOfServiceUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	termsOfServiceUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	type?: InputMaybe<Scalars['String']['input']>
	type_contains?: InputMaybe<Scalars['String']['input']>
	type_exists?: InputMaybe<Scalars['Boolean']['input']>
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	type_not?: InputMaybe<Scalars['String']['input']>
	type_not_contains?: InputMaybe<Scalars['String']['input']>
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	unifiedBilling?: InputMaybe<Scalars['Boolean']['input']>
	unifiedBilling_exists?: InputMaybe<Scalars['Boolean']['input']>
	unifiedBilling_not?: InputMaybe<Scalars['Boolean']['input']>
	upfrontPrice?: InputMaybe<Scalars['String']['input']>
	upfrontPrice_contains?: InputMaybe<Scalars['String']['input']>
	upfrontPrice_exists?: InputMaybe<Scalars['Boolean']['input']>
	upfrontPrice_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	upfrontPrice_not?: InputMaybe<Scalars['String']['input']>
	upfrontPrice_not_contains?: InputMaybe<Scalars['String']['input']>
	upfrontPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	userGuideUrl?: InputMaybe<Scalars['String']['input']>
	userGuideUrl_contains?: InputMaybe<Scalars['String']['input']>
	userGuideUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	userGuideUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	userGuideUrl_not?: InputMaybe<Scalars['String']['input']>
	userGuideUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	userGuideUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	version?: InputMaybe<Scalars['String']['input']>
	version_contains?: InputMaybe<Scalars['String']['input']>
	version_exists?: InputMaybe<Scalars['Boolean']['input']>
	version_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	version_not?: InputMaybe<Scalars['String']['input']>
	version_not_contains?: InputMaybe<Scalars['String']['input']>
	version_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	video_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	video_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	video_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	video_exists?: InputMaybe<Scalars['Boolean']['input']>
	website?: InputMaybe<Scalars['String']['input']>
	website_contains?: InputMaybe<Scalars['String']['input']>
	website_exists?: InputMaybe<Scalars['Boolean']['input']>
	website_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	website_not?: InputMaybe<Scalars['String']['input']>
	website_not_contains?: InputMaybe<Scalars['String']['input']>
	website_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type MarketplaceAppLinkingCollections = {
	__typename?: 'MarketplaceAppLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
}

export type MarketplaceAppLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum MarketplaceAppOrder {
	AlternativeLogoAsc = 'alternativeLogo_ASC',
	AlternativeLogoDesc = 'alternativeLogo_DESC',
	AppRegistryIdAsc = 'appRegistryId_ASC',
	AppRegistryIdDesc = 'appRegistryId_DESC',
	AppRegistryUpdatedAtAsc = 'appRegistryUpdatedAt_ASC',
	AppRegistryUpdatedAtDesc = 'appRegistryUpdatedAt_DESC',
	ApprovedAtAsc = 'approvedAt_ASC',
	ApprovedAtDesc = 'approvedAt_DESC',
	CatalystCompatibleAsc = 'catalystCompatible_ASC',
	CatalystCompatibleDesc = 'catalystCompatible_DESC',
	CreatedOnAsc = 'createdOn_ASC',
	CreatedOnDesc = 'createdOn_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	FreeTrialDaysAsc = 'freeTrialDays_ASC',
	FreeTrialDaysDesc = 'freeTrialDays_DESC',
	FreeTrialAsc = 'freeTrial_ASC',
	FreeTrialDesc = 'freeTrial_DESC',
	InstallationGuideUrlAsc = 'installationGuideUrl_ASC',
	InstallationGuideUrlDesc = 'installationGuideUrl_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	LastHashAsc = 'lastHash_ASC',
	LastHashDesc = 'lastHash_DESC',
	LogoIconAsc = 'logoIcon_ASC',
	LogoIconDesc = 'logoIcon_DESC',
	LogoAsc = 'logo_ASC',
	LogoDesc = 'logo_DESC',
	MonthlyPriceAsc = 'monthlyPrice_ASC',
	MonthlyPriceDesc = 'monthlyPrice_DESC',
	MultiLocationAsc = 'multiLocation_ASC',
	MultiLocationDesc = 'multiLocation_DESC',
	MultiStorefrontAsc = 'multiStorefront_ASC',
	MultiStorefrontDesc = 'multiStorefront_DESC',
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	PricingTypeAsc = 'pricingType_ASC',
	PricingTypeDesc = 'pricingType_DESC',
	PricingAsc = 'pricing_ASC',
	PricingDesc = 'pricing_DESC',
	PrivacyPolicyUrlAsc = 'privacyPolicyUrl_ASC',
	PrivacyPolicyUrlDesc = 'privacyPolicyUrl_DESC',
	RankAsc = 'rank_ASC',
	RankDesc = 'rank_DESC',
	RatingAsc = 'rating_ASC',
	RatingDesc = 'rating_DESC',
	RecommendedOrderAsc = 'recommendedOrder_ASC',
	RecommendedOrderDesc = 'recommendedOrder_DESC',
	RecommendedAsc = 'recommended_ASC',
	RecommendedDesc = 'recommended_DESC',
	ReviewCountAsc = 'reviewCount_ASC',
	ReviewCountDesc = 'reviewCount_DESC',
	SingleClickAsc = 'singleClick_ASC',
	SingleClickDesc = 'singleClick_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SupportEmailAsc = 'supportEmail_ASC',
	SupportEmailDesc = 'supportEmail_DESC',
	SupportUrlAsc = 'supportUrl_ASC',
	SupportUrlDesc = 'supportUrl_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TermsOfServiceUrlAsc = 'termsOfServiceUrl_ASC',
	TermsOfServiceUrlDesc = 'termsOfServiceUrl_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC',
	UnifiedBillingAsc = 'unifiedBilling_ASC',
	UnifiedBillingDesc = 'unifiedBilling_DESC',
	UpfrontPriceAsc = 'upfrontPrice_ASC',
	UpfrontPriceDesc = 'upfrontPrice_DESC',
	UserGuideUrlAsc = 'userGuideUrl_ASC',
	UserGuideUrlDesc = 'userGuideUrl_DESC',
	VersionAsc = 'version_ASC',
	VersionDesc = 'version_DESC',
	WebsiteAsc = 'website_ASC',
	WebsiteDesc = 'website_DESC'
}

export type MarketplaceAppSummary = {
	__typename?: 'MarketplaceAppSummary'
	json: Scalars['JSON']['output']
	links: MarketplaceAppSummaryLinks
}

export type MarketplaceAppSummaryAssets = {
	__typename?: 'MarketplaceAppSummaryAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type MarketplaceAppSummaryEntries = {
	__typename?: 'MarketplaceAppSummaryEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type MarketplaceAppSummaryLinks = {
	__typename?: 'MarketplaceAppSummaryLinks'
	assets: MarketplaceAppSummaryAssets
	entries: MarketplaceAppSummaryEntries
	resources: MarketplaceAppSummaryResources
}

export type MarketplaceAppSummaryResources = {
	__typename?: 'MarketplaceAppSummaryResources'
	block: Array<MarketplaceAppSummaryResourcesBlock>
	hyperlink: Array<MarketplaceAppSummaryResourcesHyperlink>
	inline: Array<MarketplaceAppSummaryResourcesInline>
}

export type MarketplaceAppSummaryResourcesBlock = ResourceLink & {
	__typename?: 'MarketplaceAppSummaryResourcesBlock'
	sys: ResourceSys
}

export type MarketplaceAppSummaryResourcesHyperlink = ResourceLink & {
	__typename?: 'MarketplaceAppSummaryResourcesHyperlink'
	sys: ResourceSys
}

export type MarketplaceAppSummaryResourcesInline = ResourceLink & {
	__typename?: 'MarketplaceAppSummaryResourcesInline'
	sys: ResourceSys
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceTheme = Entry &
	_Node & {
		__typename?: 'MarketplaceTheme'
		_id: Scalars['ID']['output']
		contactUrl?: Maybe<Scalars['String']['output']>
		contentfulMetadata: ContentfulMetadata
		demoUrl?: Maybe<Scalars['String']['output']>
		description?: Maybe<MarketplaceThemeDescription>
		documentationUrl?: Maybe<Scalars['String']['output']>
		externalId?: Maybe<Scalars['String']['output']>
		featuredOrder?: Maybe<Scalars['Int']['output']>
		internalName?: Maybe<Scalars['String']['output']>
		largePreview?: Maybe<Scalars['String']['output']>
		largeThumbnail?: Maybe<Scalars['String']['output']>
		lastHash?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<MarketplaceThemeLinkingCollections>
		mobilePreview?: Maybe<Scalars['String']['output']>
		partnerEmail?: Maybe<Scalars['String']['output']>
		partnerId?: Maybe<Scalars['String']['output']>
		partnerName?: Maybe<Scalars['String']['output']>
		price?: Maybe<Scalars['String']['output']>
		publishedDate?: Maybe<Scalars['DateTime']['output']>
		registryId?: Maybe<Scalars['String']['output']>
		related?: Maybe<Array<Maybe<Scalars['String']['output']>>>
		seoMetadata?: Maybe<SeoMetadata>
		slug?: Maybe<Scalars['String']['output']>
		smallThumbnail?: Maybe<Scalars['String']['output']>
		sys: Sys
		themeCollections?: Maybe<Array<Maybe<Scalars['String']['output']>>>
		themeDefaultConfigurationId?: Maybe<Scalars['String']['output']>
		themeFeatures?: Maybe<Array<Maybe<Scalars['String']['output']>>>
		themeId?: Maybe<Scalars['String']['output']>
		themeIndustriesCollection?: Maybe<MarketplaceThemeThemeIndustriesCollection>
		themeOptimizations?: Maybe<Array<Maybe<Scalars['String']['output']>>>
		title?: Maybe<Scalars['String']['output']>
		variationName?: Maybe<Scalars['String']['output']>
		versionId?: Maybe<Scalars['String']['output']>
	}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeContactUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeDemoUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeDocumentationUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeExternalIdArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeFeaturedOrderArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeLargePreviewArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeLargeThumbnailArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeLastHashArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeMobilePreviewArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemePartnerEmailArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemePartnerIdArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemePartnerNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemePriceArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemePublishedDateArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeRegistryIdArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeRelatedArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeSeoMetadataArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<SeoMetadataFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeSlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeSmallThumbnailArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeThemeCollectionsArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeThemeDefaultConfigurationIdArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeThemeFeaturesArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeThemeIdArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeThemeIndustriesCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceThemeThemeIndustriesCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<ThemePropertyFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeThemeOptimizationsArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeVariationNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/marketplaceTheme) */
export type MarketplaceThemeVersionIdArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type MarketplaceThemeCollection = {
	__typename?: 'MarketplaceThemeCollection'
	items: Array<Maybe<MarketplaceTheme>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type MarketplaceThemeDescription = {
	__typename?: 'MarketplaceThemeDescription'
	json: Scalars['JSON']['output']
	links: MarketplaceThemeDescriptionLinks
}

export type MarketplaceThemeDescriptionAssets = {
	__typename?: 'MarketplaceThemeDescriptionAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type MarketplaceThemeDescriptionEntries = {
	__typename?: 'MarketplaceThemeDescriptionEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type MarketplaceThemeDescriptionLinks = {
	__typename?: 'MarketplaceThemeDescriptionLinks'
	assets: MarketplaceThemeDescriptionAssets
	entries: MarketplaceThemeDescriptionEntries
	resources: MarketplaceThemeDescriptionResources
}

export type MarketplaceThemeDescriptionResources = {
	__typename?: 'MarketplaceThemeDescriptionResources'
	block: Array<MarketplaceThemeDescriptionResourcesBlock>
	hyperlink: Array<MarketplaceThemeDescriptionResourcesHyperlink>
	inline: Array<MarketplaceThemeDescriptionResourcesInline>
}

export type MarketplaceThemeDescriptionResourcesBlock = ResourceLink & {
	__typename?: 'MarketplaceThemeDescriptionResourcesBlock'
	sys: ResourceSys
}

export type MarketplaceThemeDescriptionResourcesHyperlink = ResourceLink & {
	__typename?: 'MarketplaceThemeDescriptionResourcesHyperlink'
	sys: ResourceSys
}

export type MarketplaceThemeDescriptionResourcesInline = ResourceLink & {
	__typename?: 'MarketplaceThemeDescriptionResourcesInline'
	sys: ResourceSys
}

export type MarketplaceThemeFilter = {
	AND?: InputMaybe<Array<InputMaybe<MarketplaceThemeFilter>>>
	OR?: InputMaybe<Array<InputMaybe<MarketplaceThemeFilter>>>
	contactUrl?: InputMaybe<Scalars['String']['input']>
	contactUrl_contains?: InputMaybe<Scalars['String']['input']>
	contactUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	contactUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contactUrl_not?: InputMaybe<Scalars['String']['input']>
	contactUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	contactUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	demoUrl?: InputMaybe<Scalars['String']['input']>
	demoUrl_contains?: InputMaybe<Scalars['String']['input']>
	demoUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	demoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	demoUrl_not?: InputMaybe<Scalars['String']['input']>
	demoUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	demoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	documentationUrl?: InputMaybe<Scalars['String']['input']>
	documentationUrl_contains?: InputMaybe<Scalars['String']['input']>
	documentationUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	documentationUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	documentationUrl_not?: InputMaybe<Scalars['String']['input']>
	documentationUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	documentationUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	externalId?: InputMaybe<Scalars['String']['input']>
	externalId_contains?: InputMaybe<Scalars['String']['input']>
	externalId_exists?: InputMaybe<Scalars['Boolean']['input']>
	externalId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	externalId_not?: InputMaybe<Scalars['String']['input']>
	externalId_not_contains?: InputMaybe<Scalars['String']['input']>
	externalId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredOrder?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredOrder_gt?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_gte?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	featuredOrder_lt?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_lte?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_not?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	largePreview?: InputMaybe<Scalars['String']['input']>
	largePreview_contains?: InputMaybe<Scalars['String']['input']>
	largePreview_exists?: InputMaybe<Scalars['Boolean']['input']>
	largePreview_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	largePreview_not?: InputMaybe<Scalars['String']['input']>
	largePreview_not_contains?: InputMaybe<Scalars['String']['input']>
	largePreview_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	largeThumbnail?: InputMaybe<Scalars['String']['input']>
	largeThumbnail_contains?: InputMaybe<Scalars['String']['input']>
	largeThumbnail_exists?: InputMaybe<Scalars['Boolean']['input']>
	largeThumbnail_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	largeThumbnail_not?: InputMaybe<Scalars['String']['input']>
	largeThumbnail_not_contains?: InputMaybe<Scalars['String']['input']>
	largeThumbnail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	lastHash?: InputMaybe<Scalars['String']['input']>
	lastHash_contains?: InputMaybe<Scalars['String']['input']>
	lastHash_exists?: InputMaybe<Scalars['Boolean']['input']>
	lastHash_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	lastHash_not?: InputMaybe<Scalars['String']['input']>
	lastHash_not_contains?: InputMaybe<Scalars['String']['input']>
	lastHash_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	mobilePreview?: InputMaybe<Scalars['String']['input']>
	mobilePreview_contains?: InputMaybe<Scalars['String']['input']>
	mobilePreview_exists?: InputMaybe<Scalars['Boolean']['input']>
	mobilePreview_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	mobilePreview_not?: InputMaybe<Scalars['String']['input']>
	mobilePreview_not_contains?: InputMaybe<Scalars['String']['input']>
	mobilePreview_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	partnerEmail?: InputMaybe<Scalars['String']['input']>
	partnerEmail_contains?: InputMaybe<Scalars['String']['input']>
	partnerEmail_exists?: InputMaybe<Scalars['Boolean']['input']>
	partnerEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	partnerEmail_not?: InputMaybe<Scalars['String']['input']>
	partnerEmail_not_contains?: InputMaybe<Scalars['String']['input']>
	partnerEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	partnerId?: InputMaybe<Scalars['String']['input']>
	partnerId_contains?: InputMaybe<Scalars['String']['input']>
	partnerId_exists?: InputMaybe<Scalars['Boolean']['input']>
	partnerId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	partnerId_not?: InputMaybe<Scalars['String']['input']>
	partnerId_not_contains?: InputMaybe<Scalars['String']['input']>
	partnerId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	partnerName?: InputMaybe<Scalars['String']['input']>
	partnerName_contains?: InputMaybe<Scalars['String']['input']>
	partnerName_exists?: InputMaybe<Scalars['Boolean']['input']>
	partnerName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	partnerName_not?: InputMaybe<Scalars['String']['input']>
	partnerName_not_contains?: InputMaybe<Scalars['String']['input']>
	partnerName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	price?: InputMaybe<Scalars['String']['input']>
	price_contains?: InputMaybe<Scalars['String']['input']>
	price_exists?: InputMaybe<Scalars['Boolean']['input']>
	price_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	price_not?: InputMaybe<Scalars['String']['input']>
	price_not_contains?: InputMaybe<Scalars['String']['input']>
	price_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	publishedDate?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_exists?: InputMaybe<Scalars['Boolean']['input']>
	publishedDate_gt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_gte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	publishedDate_lt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_lte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	registryId?: InputMaybe<Scalars['String']['input']>
	registryId_contains?: InputMaybe<Scalars['String']['input']>
	registryId_exists?: InputMaybe<Scalars['Boolean']['input']>
	registryId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	registryId_not?: InputMaybe<Scalars['String']['input']>
	registryId_not_contains?: InputMaybe<Scalars['String']['input']>
	registryId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	related_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	related_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	related_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	related_exists?: InputMaybe<Scalars['Boolean']['input']>
	seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	smallThumbnail?: InputMaybe<Scalars['String']['input']>
	smallThumbnail_contains?: InputMaybe<Scalars['String']['input']>
	smallThumbnail_exists?: InputMaybe<Scalars['Boolean']['input']>
	smallThumbnail_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	smallThumbnail_not?: InputMaybe<Scalars['String']['input']>
	smallThumbnail_not_contains?: InputMaybe<Scalars['String']['input']>
	smallThumbnail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	themeCollections_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	themeCollections_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	themeCollections_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	themeCollections_exists?: InputMaybe<Scalars['Boolean']['input']>
	themeDefaultConfigurationId?: InputMaybe<Scalars['String']['input']>
	themeDefaultConfigurationId_contains?: InputMaybe<Scalars['String']['input']>
	themeDefaultConfigurationId_exists?: InputMaybe<Scalars['Boolean']['input']>
	themeDefaultConfigurationId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	themeDefaultConfigurationId_not?: InputMaybe<Scalars['String']['input']>
	themeDefaultConfigurationId_not_contains?: InputMaybe<Scalars['String']['input']>
	themeDefaultConfigurationId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	themeFeatures_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	themeFeatures_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	themeFeatures_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	themeFeatures_exists?: InputMaybe<Scalars['Boolean']['input']>
	themeId?: InputMaybe<Scalars['String']['input']>
	themeId_contains?: InputMaybe<Scalars['String']['input']>
	themeId_exists?: InputMaybe<Scalars['Boolean']['input']>
	themeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	themeId_not?: InputMaybe<Scalars['String']['input']>
	themeId_not_contains?: InputMaybe<Scalars['String']['input']>
	themeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	themeIndustries?: InputMaybe<CfThemePropertyNestedFilter>
	themeIndustriesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	themeOptimizations_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	themeOptimizations_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	themeOptimizations_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	themeOptimizations_exists?: InputMaybe<Scalars['Boolean']['input']>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	variationName?: InputMaybe<Scalars['String']['input']>
	variationName_contains?: InputMaybe<Scalars['String']['input']>
	variationName_exists?: InputMaybe<Scalars['Boolean']['input']>
	variationName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	variationName_not?: InputMaybe<Scalars['String']['input']>
	variationName_not_contains?: InputMaybe<Scalars['String']['input']>
	variationName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	versionId?: InputMaybe<Scalars['String']['input']>
	versionId_contains?: InputMaybe<Scalars['String']['input']>
	versionId_exists?: InputMaybe<Scalars['Boolean']['input']>
	versionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	versionId_not?: InputMaybe<Scalars['String']['input']>
	versionId_not_contains?: InputMaybe<Scalars['String']['input']>
	versionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type MarketplaceThemeLinkingCollections = {
	__typename?: 'MarketplaceThemeLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
}

export type MarketplaceThemeLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum MarketplaceThemeOrder {
	ContactUrlAsc = 'contactUrl_ASC',
	ContactUrlDesc = 'contactUrl_DESC',
	DemoUrlAsc = 'demoUrl_ASC',
	DemoUrlDesc = 'demoUrl_DESC',
	DocumentationUrlAsc = 'documentationUrl_ASC',
	DocumentationUrlDesc = 'documentationUrl_DESC',
	ExternalIdAsc = 'externalId_ASC',
	ExternalIdDesc = 'externalId_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	LargePreviewAsc = 'largePreview_ASC',
	LargePreviewDesc = 'largePreview_DESC',
	LargeThumbnailAsc = 'largeThumbnail_ASC',
	LargeThumbnailDesc = 'largeThumbnail_DESC',
	LastHashAsc = 'lastHash_ASC',
	LastHashDesc = 'lastHash_DESC',
	MobilePreviewAsc = 'mobilePreview_ASC',
	MobilePreviewDesc = 'mobilePreview_DESC',
	PartnerEmailAsc = 'partnerEmail_ASC',
	PartnerEmailDesc = 'partnerEmail_DESC',
	PartnerIdAsc = 'partnerId_ASC',
	PartnerIdDesc = 'partnerId_DESC',
	PartnerNameAsc = 'partnerName_ASC',
	PartnerNameDesc = 'partnerName_DESC',
	PriceAsc = 'price_ASC',
	PriceDesc = 'price_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	RegistryIdAsc = 'registryId_ASC',
	RegistryIdDesc = 'registryId_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SmallThumbnailAsc = 'smallThumbnail_ASC',
	SmallThumbnailDesc = 'smallThumbnail_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	ThemeDefaultConfigurationIdAsc = 'themeDefaultConfigurationId_ASC',
	ThemeDefaultConfigurationIdDesc = 'themeDefaultConfigurationId_DESC',
	ThemeIdAsc = 'themeId_ASC',
	ThemeIdDesc = 'themeId_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	VariationNameAsc = 'variationName_ASC',
	VariationNameDesc = 'variationName_DESC',
	VersionIdAsc = 'versionId_ASC',
	VersionIdDesc = 'versionId_DESC'
}

export type MarketplaceThemeThemeIndustriesCollection = {
	__typename?: 'MarketplaceThemeThemeIndustriesCollection'
	items: Array<Maybe<ThemeProperty>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum MarketplaceThemeThemeIndustriesCollectionOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

/** Used for blog authors and /leaders pages [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/person) */
export type Person = Entry &
	_Node & {
		__typename?: 'Person'
		_id: Scalars['ID']['output']
		additionalOptions?: Maybe<AdditionalPersonOptions>
		avatar?: Maybe<Asset>
		bio?: Maybe<PersonBio>
		contentfulMetadata: ContentfulMetadata
		email?: Maybe<Scalars['String']['output']>
		enabled?: Maybe<Scalars['Boolean']['output']>
		fullName?: Maybe<Scalars['String']['output']>
		fullSizePhoto?: Maybe<Asset>
		jobTitle?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<PersonLinkingCollections>
		linkedUrl?: Maybe<Scalars['String']['output']>
		seoMetadata?: Maybe<SeoMetadata>
		slug?: Maybe<Scalars['String']['output']>
		sys: Sys
		type?: Maybe<Scalars['String']['output']>
	}

/** Used for blog authors and /leaders pages [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/person) */
export type PersonAdditionalOptionsArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<AdditionalPersonOptionsFilter>
}

/** Used for blog authors and /leaders pages [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/person) */
export type PersonAvatarArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Used for blog authors and /leaders pages [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/person) */
export type PersonBioArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for blog authors and /leaders pages [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/person) */
export type PersonEmailArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for blog authors and /leaders pages [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/person) */
export type PersonEnabledArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for blog authors and /leaders pages [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/person) */
export type PersonFullNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for blog authors and /leaders pages [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/person) */
export type PersonFullSizePhotoArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Used for blog authors and /leaders pages [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/person) */
export type PersonJobTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for blog authors and /leaders pages [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/person) */
export type PersonLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Used for blog authors and /leaders pages [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/person) */
export type PersonLinkedUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for blog authors and /leaders pages [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/person) */
export type PersonSeoMetadataArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<SeoMetadataFilter>
}

/** Used for blog authors and /leaders pages [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/person) */
export type PersonSlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for blog authors and /leaders pages [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/person) */
export type PersonTypeArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type PersonBio = {
	__typename?: 'PersonBio'
	json: Scalars['JSON']['output']
	links: PersonBioLinks
}

export type PersonBioAssets = {
	__typename?: 'PersonBioAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type PersonBioEntries = {
	__typename?: 'PersonBioEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type PersonBioLinks = {
	__typename?: 'PersonBioLinks'
	assets: PersonBioAssets
	entries: PersonBioEntries
	resources: PersonBioResources
}

export type PersonBioResources = {
	__typename?: 'PersonBioResources'
	block: Array<PersonBioResourcesBlock>
	hyperlink: Array<PersonBioResourcesHyperlink>
	inline: Array<PersonBioResourcesInline>
}

export type PersonBioResourcesBlock = ResourceLink & {
	__typename?: 'PersonBioResourcesBlock'
	sys: ResourceSys
}

export type PersonBioResourcesHyperlink = ResourceLink & {
	__typename?: 'PersonBioResourcesHyperlink'
	sys: ResourceSys
}

export type PersonBioResourcesInline = ResourceLink & {
	__typename?: 'PersonBioResourcesInline'
	sys: ResourceSys
}

export type PersonCollection = {
	__typename?: 'PersonCollection'
	items: Array<Maybe<Person>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type PersonFilter = {
	AND?: InputMaybe<Array<InputMaybe<PersonFilter>>>
	OR?: InputMaybe<Array<InputMaybe<PersonFilter>>>
	additionalOptions?: InputMaybe<CfAdditionalPersonOptionsNestedFilter>
	additionalOptions_exists?: InputMaybe<Scalars['Boolean']['input']>
	avatar_exists?: InputMaybe<Scalars['Boolean']['input']>
	bio_contains?: InputMaybe<Scalars['String']['input']>
	bio_exists?: InputMaybe<Scalars['Boolean']['input']>
	bio_not_contains?: InputMaybe<Scalars['String']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	email?: InputMaybe<Scalars['String']['input']>
	email_contains?: InputMaybe<Scalars['String']['input']>
	email_exists?: InputMaybe<Scalars['Boolean']['input']>
	email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	email_not?: InputMaybe<Scalars['String']['input']>
	email_not_contains?: InputMaybe<Scalars['String']['input']>
	email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	enabled?: InputMaybe<Scalars['Boolean']['input']>
	enabled_exists?: InputMaybe<Scalars['Boolean']['input']>
	enabled_not?: InputMaybe<Scalars['Boolean']['input']>
	fullName?: InputMaybe<Scalars['String']['input']>
	fullName_contains?: InputMaybe<Scalars['String']['input']>
	fullName_exists?: InputMaybe<Scalars['Boolean']['input']>
	fullName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	fullName_not?: InputMaybe<Scalars['String']['input']>
	fullName_not_contains?: InputMaybe<Scalars['String']['input']>
	fullName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	fullSizePhoto_exists?: InputMaybe<Scalars['Boolean']['input']>
	jobTitle?: InputMaybe<Scalars['String']['input']>
	jobTitle_contains?: InputMaybe<Scalars['String']['input']>
	jobTitle_exists?: InputMaybe<Scalars['Boolean']['input']>
	jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	jobTitle_not?: InputMaybe<Scalars['String']['input']>
	jobTitle_not_contains?: InputMaybe<Scalars['String']['input']>
	jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	linkedUrl?: InputMaybe<Scalars['String']['input']>
	linkedUrl_contains?: InputMaybe<Scalars['String']['input']>
	linkedUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	linkedUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	linkedUrl_not?: InputMaybe<Scalars['String']['input']>
	linkedUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	linkedUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	type?: InputMaybe<Scalars['String']['input']>
	type_contains?: InputMaybe<Scalars['String']['input']>
	type_exists?: InputMaybe<Scalars['Boolean']['input']>
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	type_not?: InputMaybe<Scalars['String']['input']>
	type_not_contains?: InputMaybe<Scalars['String']['input']>
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type PersonLinkingCollections = {
	__typename?: 'PersonLinkingCollections'
	articleCollection?: Maybe<ArticleCollection>
	blogPostCollection?: Maybe<BlogPostCollection>
	entryCollection?: Maybe<EntryCollection>
}

export type PersonLinkingCollectionsArticleCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<PersonLinkingCollectionsArticleCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type PersonLinkingCollectionsBlogPostCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<PersonLinkingCollectionsBlogPostCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type PersonLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum PersonLinkingCollectionsArticleCollectionOrder {
	EditStatusAsc = 'editStatus_ASC',
	EditStatusDesc = 'editStatus_DESC',
	FaqsHeadingAsc = 'faqsHeading_ASC',
	FaqsHeadingDesc = 'faqsHeading_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	InterstitialHeadlineAsc = 'interstitialHeadline_ASC',
	InterstitialHeadlineDesc = 'interstitialHeadline_DESC',
	PopularAsc = 'popular_ASC',
	PopularDesc = 'popular_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum PersonLinkingCollectionsBlogPostCollectionOrder {
	HideOnHomepageAsc = 'hideOnHomepage_ASC',
	HideOnHomepageDesc = 'hideOnHomepage_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	ShowPostHeroAsc = 'showPostHero_ASC',
	ShowPostHeroDesc = 'showPostHero_DESC',
	ShowTableOfContentsAsc = 'showTableOfContents_ASC',
	ShowTableOfContentsDesc = 'showTableOfContents_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum PersonOrder {
	EmailAsc = 'email_ASC',
	EmailDesc = 'email_DESC',
	EnabledAsc = 'enabled_ASC',
	EnabledDesc = 'enabled_DESC',
	FullNameAsc = 'fullName_ASC',
	FullNameDesc = 'fullName_DESC',
	JobTitleAsc = 'jobTitle_ASC',
	JobTitleDesc = 'jobTitle_DESC',
	LinkedUrlAsc = 'linkedUrl_ASC',
	LinkedUrlDesc = 'linkedUrl_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

/** used for /press/news and /press/releases [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/press) */
export type Press = Entry &
	_Node & {
		__typename?: 'Press'
		_id: Scalars['ID']['output']
		body?: Maybe<PressBody>
		contentfulMetadata: ContentfulMetadata
		enabled?: Maybe<Scalars['Boolean']['output']>
		externalLink?: Maybe<ExternalLink>
		featured?: Maybe<Scalars['Boolean']['output']>
		image?: Maybe<PressImage>
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<PressLinkingCollections>
		publishedDate?: Maybe<Scalars['DateTime']['output']>
		seoMetadata?: Maybe<SeoMetadata>
		slug?: Maybe<Scalars['String']['output']>
		summary?: Maybe<Scalars['String']['output']>
		sys: Sys
		title?: Maybe<Scalars['String']['output']>
		type?: Maybe<Scalars['String']['output']>
	}

/** used for /press/news and /press/releases [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/press) */
export type PressBodyArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** used for /press/news and /press/releases [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/press) */
export type PressEnabledArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** used for /press/news and /press/releases [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/press) */
export type PressExternalLinkArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<ExternalLinkFilter>
}

/** used for /press/news and /press/releases [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/press) */
export type PressFeaturedArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** used for /press/news and /press/releases [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/press) */
export type PressImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** used for /press/news and /press/releases [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/press) */
export type PressInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** used for /press/news and /press/releases [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/press) */
export type PressLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** used for /press/news and /press/releases [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/press) */
export type PressPublishedDateArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** used for /press/news and /press/releases [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/press) */
export type PressSeoMetadataArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<SeoMetadataFilter>
}

/** used for /press/news and /press/releases [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/press) */
export type PressSlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** used for /press/news and /press/releases [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/press) */
export type PressSummaryArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** used for /press/news and /press/releases [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/press) */
export type PressTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** used for /press/news and /press/releases [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/press) */
export type PressTypeArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type PressBody = {
	__typename?: 'PressBody'
	json: Scalars['JSON']['output']
	links: PressBodyLinks
}

export type PressBodyAssets = {
	__typename?: 'PressBodyAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type PressBodyEntries = {
	__typename?: 'PressBodyEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type PressBodyLinks = {
	__typename?: 'PressBodyLinks'
	assets: PressBodyAssets
	entries: PressBodyEntries
	resources: PressBodyResources
}

export type PressBodyResources = {
	__typename?: 'PressBodyResources'
	block: Array<PressBodyResourcesBlock>
	hyperlink: Array<PressBodyResourcesHyperlink>
	inline: Array<PressBodyResourcesInline>
}

export type PressBodyResourcesBlock = ResourceLink & {
	__typename?: 'PressBodyResourcesBlock'
	sys: ResourceSys
}

export type PressBodyResourcesHyperlink = ResourceLink & {
	__typename?: 'PressBodyResourcesHyperlink'
	sys: ResourceSys
}

export type PressBodyResourcesInline = ResourceLink & {
	__typename?: 'PressBodyResourcesInline'
	sys: ResourceSys
}

export type PressCollection = {
	__typename?: 'PressCollection'
	items: Array<Maybe<Press>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type PressFilter = {
	AND?: InputMaybe<Array<InputMaybe<PressFilter>>>
	OR?: InputMaybe<Array<InputMaybe<PressFilter>>>
	body_contains?: InputMaybe<Scalars['String']['input']>
	body_exists?: InputMaybe<Scalars['Boolean']['input']>
	body_not_contains?: InputMaybe<Scalars['String']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	enabled?: InputMaybe<Scalars['Boolean']['input']>
	enabled_exists?: InputMaybe<Scalars['Boolean']['input']>
	enabled_not?: InputMaybe<Scalars['Boolean']['input']>
	externalLink?: InputMaybe<CfExternalLinkNestedFilter>
	externalLink_exists?: InputMaybe<Scalars['Boolean']['input']>
	featured?: InputMaybe<Scalars['Boolean']['input']>
	featured_exists?: InputMaybe<Scalars['Boolean']['input']>
	featured_not?: InputMaybe<Scalars['Boolean']['input']>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	publishedDate?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_exists?: InputMaybe<Scalars['Boolean']['input']>
	publishedDate_gt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_gte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	publishedDate_lt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_lte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	summary?: InputMaybe<Scalars['String']['input']>
	summary_contains?: InputMaybe<Scalars['String']['input']>
	summary_exists?: InputMaybe<Scalars['Boolean']['input']>
	summary_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	summary_not?: InputMaybe<Scalars['String']['input']>
	summary_not_contains?: InputMaybe<Scalars['String']['input']>
	summary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	type?: InputMaybe<Scalars['String']['input']>
	type_contains?: InputMaybe<Scalars['String']['input']>
	type_exists?: InputMaybe<Scalars['Boolean']['input']>
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	type_not?: InputMaybe<Scalars['String']['input']>
	type_not_contains?: InputMaybe<Scalars['String']['input']>
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type PressImage = AssetExternal | AssetInternal

export type PressLinkingCollections = {
	__typename?: 'PressLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
}

export type PressLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum PressOrder {
	EnabledAsc = 'enabled_ASC',
	EnabledDesc = 'enabled_DESC',
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export type Query = {
	__typename?: 'Query'
	_node?: Maybe<_Node>
	additionalPersonOptions?: Maybe<AdditionalPersonOptions>
	additionalPersonOptionsCollection?: Maybe<AdditionalPersonOptionsCollection>
	amplitudeExperiments?: Maybe<AmplitudeExperiments>
	amplitudeExperimentsCollection?: Maybe<AmplitudeExperimentsCollection>
	appCaseStudy?: Maybe<AppCaseStudy>
	appCaseStudyCollection?: Maybe<AppCaseStudyCollection>
	appCollection?: Maybe<AppCollection>
	appCollectionCollection?: Maybe<AppCollectionCollection>
	appFeature?: Maybe<AppFeature>
	appFeatureCollection?: Maybe<AppFeatureCollection>
	appTier?: Maybe<AppTier>
	appTierCollection?: Maybe<AppTierCollection>
	article?: Maybe<Article>
	articleCollection?: Maybe<ArticleCollection>
	asset?: Maybe<Asset>
	assetCollection?: Maybe<AssetCollection>
	assetExternal?: Maybe<AssetExternal>
	assetExternalCollection?: Maybe<AssetExternalCollection>
	assetInternal?: Maybe<AssetInternal>
	assetInternalCollection?: Maybe<AssetInternalCollection>
	background?: Maybe<Background>
	backgroundCollection?: Maybe<BackgroundCollection>
	basicContentCard?: Maybe<BasicContentCard>
	basicContentCardCollection?: Maybe<BasicContentCardCollection>
	blogPost?: Maybe<BlogPost>
	blogPostCollection?: Maybe<BlogPostCollection>
	caseStudy?: Maybe<CaseStudy>
	caseStudyCollection?: Maybe<CaseStudyCollection>
	caseStudyProperty?: Maybe<CaseStudyProperty>
	caseStudyPropertyCollection?: Maybe<CaseStudyPropertyCollection>
	category?: Maybe<Category>
	categoryCollection?: Maybe<CategoryCollection>
	ctaButton?: Maybe<CtaButton>
	ctaButtonCollection?: Maybe<CtaButtonCollection>
	ctaMatPattern?: Maybe<CtaMatPattern>
	ctaMatPatternCollection?: Maybe<CtaMatPatternCollection>
	entryCollection?: Maybe<EntryCollection>
	event?: Maybe<Event>
	eventCollection?: Maybe<EventCollection>
	externalLink?: Maybe<ExternalLink>
	externalLinkCollection?: Maybe<ExternalLinkCollection>
	faqItem?: Maybe<FaqItem>
	faqItemCollection?: Maybe<FaqItemCollection>
	gatedContent?: Maybe<GatedContent>
	gatedContentCollection?: Maybe<GatedContentCollection>
	glossary?: Maybe<Glossary>
	glossaryCollection?: Maybe<GlossaryCollection>
	hero?: Maybe<Hero>
	heroCollection?: Maybe<HeroCollection>
	keyValue?: Maybe<KeyValue>
	keyValueCollection?: Maybe<KeyValueCollection>
	keyValueSet?: Maybe<KeyValueSet>
	keyValueSetCollection?: Maybe<KeyValueSetCollection>
	marketoForm?: Maybe<MarketoForm>
	marketoFormCollection?: Maybe<MarketoFormCollection>
	marketplaceApp?: Maybe<MarketplaceApp>
	marketplaceAppCollection?: Maybe<MarketplaceAppCollection>
	marketplaceTheme?: Maybe<MarketplaceTheme>
	marketplaceThemeCollection?: Maybe<MarketplaceThemeCollection>
	person?: Maybe<Person>
	personCollection?: Maybe<PersonCollection>
	press?: Maybe<Press>
	pressCollection?: Maybe<PressCollection>
	redirect?: Maybe<Redirect>
	redirectCollection?: Maybe<RedirectCollection>
	resource?: Maybe<Resource>
	resourceCollection?: Maybe<ResourceCollection>
	richTextWrapper?: Maybe<RichTextWrapper>
	richTextWrapperCollection?: Maybe<RichTextWrapperCollection>
	robots?: Maybe<Robots>
	robotsCollection?: Maybe<RobotsCollection>
	seoMetadata?: Maybe<SeoMetadata>
	seoMetadataCollection?: Maybe<SeoMetadataCollection>
	showcase?: Maybe<Showcase>
	showcaseCollection?: Maybe<ShowcaseCollection>
	speakerCard?: Maybe<SpeakerCard>
	speakerCardCollection?: Maybe<SpeakerCardCollection>
	tag?: Maybe<Tag>
	tagCollection?: Maybe<TagCollection>
	themeProperty?: Maybe<ThemeProperty>
	themePropertyCollection?: Maybe<ThemePropertyCollection>
}

export type Query_NodeArgs = {
	id: Scalars['ID']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryAdditionalPersonOptionsArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryAdditionalPersonOptionsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AdditionalPersonOptionsOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<AdditionalPersonOptionsFilter>
}

export type QueryAmplitudeExperimentsArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryAmplitudeExperimentsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AmplitudeExperimentsOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<AmplitudeExperimentsFilter>
}

export type QueryAppCaseStudyArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryAppCaseStudyCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AppCaseStudyOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<AppCaseStudyFilter>
}

export type QueryAppCollectionArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryAppCollectionCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AppCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<AppCollectionFilter>
}

export type QueryAppFeatureArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryAppFeatureCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AppFeatureOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<AppFeatureFilter>
}

export type QueryAppTierArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryAppTierCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AppTierOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<AppTierFilter>
}

export type QueryArticleArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryArticleCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ArticleOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<ArticleFilter>
}

export type QueryAssetArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryAssetCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AssetOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<AssetFilter>
}

export type QueryAssetExternalArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryAssetExternalCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AssetExternalOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<AssetExternalFilter>
}

export type QueryAssetInternalArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryAssetInternalCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<AssetInternalOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<AssetInternalFilter>
}

export type QueryBackgroundArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryBackgroundCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<BackgroundOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<BackgroundFilter>
}

export type QueryBasicContentCardArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryBasicContentCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<BasicContentCardOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<BasicContentCardFilter>
}

export type QueryBlogPostArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryBlogPostCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<BlogPostOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<BlogPostFilter>
}

export type QueryCaseStudyArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryCaseStudyCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CaseStudyOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CaseStudyFilter>
}

export type QueryCaseStudyPropertyArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryCaseStudyPropertyCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CaseStudyPropertyOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CaseStudyPropertyFilter>
}

export type QueryCategoryArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryCategoryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CategoryOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CategoryFilter>
}

export type QueryCtaButtonArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryCtaButtonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CtaButtonOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CtaButtonFilter>
}

export type QueryCtaMatPatternArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryCtaMatPatternCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<CtaMatPatternOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CtaMatPatternFilter>
}

export type QueryEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<EntryOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<EntryFilter>
}

export type QueryEventArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryEventCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<EventOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<EventFilter>
}

export type QueryExternalLinkArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryExternalLinkCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ExternalLinkOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<ExternalLinkFilter>
}

export type QueryFaqItemArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryFaqItemCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<FaqItemOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<FaqItemFilter>
}

export type QueryGatedContentArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryGatedContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<GatedContentOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<GatedContentFilter>
}

export type QueryGlossaryArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryGlossaryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<GlossaryOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<GlossaryFilter>
}

export type QueryHeroArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryHeroCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<HeroOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<HeroFilter>
}

export type QueryKeyValueArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryKeyValueCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<KeyValueOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<KeyValueFilter>
}

export type QueryKeyValueSetArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryKeyValueSetCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<KeyValueSetOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<KeyValueSetFilter>
}

export type QueryMarketoFormArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryMarketoFormCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketoFormOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<MarketoFormFilter>
}

export type QueryMarketplaceAppArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryMarketplaceAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceAppOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<MarketplaceAppFilter>
}

export type QueryMarketplaceThemeArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryMarketplaceThemeCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceThemeOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<MarketplaceThemeFilter>
}

export type QueryPersonArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryPersonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<PersonOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<PersonFilter>
}

export type QueryPressArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryPressCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<PressOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<PressFilter>
}

export type QueryRedirectArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryRedirectCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<RedirectOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<RedirectFilter>
}

export type QueryResourceArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryResourceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ResourceOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<ResourceFilter>
}

export type QueryRichTextWrapperArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryRichTextWrapperCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<RichTextWrapperOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<RichTextWrapperFilter>
}

export type QueryRobotsArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryRobotsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<RobotsOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<RobotsFilter>
}

export type QuerySeoMetadataArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QuerySeoMetadataCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SeoMetadataOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<SeoMetadataFilter>
}

export type QueryShowcaseArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryShowcaseCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ShowcaseOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<ShowcaseFilter>
}

export type QuerySpeakerCardArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QuerySpeakerCardCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SpeakerCardOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<SpeakerCardFilter>
}

export type QueryTagArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryTagCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<TagOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<TagFilter>
}

export type QueryThemePropertyArgs = {
	id: Scalars['String']['input']
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryThemePropertyCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ThemePropertyOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<ThemePropertyFilter>
}

/** Used to store redirects from a source path to another [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/redirect) */
export type Redirect = Entry &
	_Node & {
		__typename?: 'Redirect'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		destinationUrl?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<RedirectLinkingCollections>
		locale?: Maybe<Array<Maybe<Scalars['String']['output']>>>
		sourceUrl?: Maybe<Scalars['String']['output']>
		sys: Sys
	}

/** Used to store redirects from a source path to another [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/redirect) */
export type RedirectDestinationUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used to store redirects from a source path to another [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/redirect) */
export type RedirectLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Used to store redirects from a source path to another [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/redirect) */
export type RedirectLocaleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used to store redirects from a source path to another [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/redirect) */
export type RedirectSourceUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type RedirectCollection = {
	__typename?: 'RedirectCollection'
	items: Array<Maybe<Redirect>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type RedirectFilter = {
	AND?: InputMaybe<Array<InputMaybe<RedirectFilter>>>
	OR?: InputMaybe<Array<InputMaybe<RedirectFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	destinationUrl?: InputMaybe<Scalars['String']['input']>
	destinationUrl_contains?: InputMaybe<Scalars['String']['input']>
	destinationUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	destinationUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	destinationUrl_not?: InputMaybe<Scalars['String']['input']>
	destinationUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	destinationUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	locale_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	locale_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	locale_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	locale_exists?: InputMaybe<Scalars['Boolean']['input']>
	sourceUrl?: InputMaybe<Scalars['String']['input']>
	sourceUrl_contains?: InputMaybe<Scalars['String']['input']>
	sourceUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	sourceUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sourceUrl_not?: InputMaybe<Scalars['String']['input']>
	sourceUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	sourceUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type RedirectLinkingCollections = {
	__typename?: 'RedirectLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
}

export type RedirectLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum RedirectOrder {
	DestinationUrlAsc = 'destinationUrl_ASC',
	DestinationUrlDesc = 'destinationUrl_DESC',
	SourceUrlAsc = 'sourceUrl_ASC',
	SourceUrlDesc = 'sourceUrl_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type Resource = Entry &
	_Node & {
		__typename?: 'Resource'
		_id: Scalars['ID']['output']
		aboveFormContent?: Maybe<ResourceAboveFormContent>
		additionalContent?: Maybe<ResourceAdditionalContent>
		additionalContentButton?: Maybe<CtaButton>
		cardImage?: Maybe<Asset>
		content?: Maybe<ResourceContent>
		contentImage?: Maybe<Asset>
		contentfulMetadata: ContentfulMetadata
		disclaimer?: Maybe<ResourceDisclaimer>
		featuredImage?: Maybe<Asset>
		formButtonText?: Maybe<Scalars['String']['output']>
		formDescription?: Maybe<ResourceFormDescription>
		formHeadline?: Maybe<ResourceFormHeadline>
		formId?: Maybe<Scalars['String']['output']>
		hero?: Maybe<Hero>
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<ResourceLinkingCollections>
		publishedDate?: Maybe<Scalars['DateTime']['output']>
		recommendedContentCollection?: Maybe<ResourceRecommendedContentCollection>
		seoMetadata?: Maybe<SeoMetadata>
		sideFormContent?: Maybe<ResourceSideFormContent>
		slug?: Maybe<Scalars['String']['output']>
		speakersCollection?: Maybe<ResourceSpeakersCollection>
		statsCollection?: Maybe<ResourceStatsCollection>
		status?: Maybe<Scalars['Boolean']['output']>
		sys: Sys
		title?: Maybe<Scalars['String']['output']>
		type?: Maybe<Scalars['String']['output']>
	}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceAboveFormContentArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceAdditionalContentArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceAdditionalContentButtonArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<CtaButtonFilter>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceCardImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceContentArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceContentImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceDisclaimerArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceFeaturedImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceFormButtonTextArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceFormDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceFormHeadlineArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceFormIdArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceHeroArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<HeroFilter>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourcePublishedDateArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceRecommendedContentCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<ResourceRecommendedContentFilter>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceSeoMetadataArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<SeoMetadataFilter>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceSideFormContentArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceSlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceSpeakersCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ResourceSpeakersCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<SpeakerCardFilter>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceStatsCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ResourceStatsCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<KeyValueFilter>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceStatusArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For webinars, guides, and reports [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/resource) */
export type ResourceTypeArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type ResourceAboveFormContent = {
	__typename?: 'ResourceAboveFormContent'
	json: Scalars['JSON']['output']
	links: ResourceAboveFormContentLinks
}

export type ResourceAboveFormContentAssets = {
	__typename?: 'ResourceAboveFormContentAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type ResourceAboveFormContentEntries = {
	__typename?: 'ResourceAboveFormContentEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type ResourceAboveFormContentLinks = {
	__typename?: 'ResourceAboveFormContentLinks'
	assets: ResourceAboveFormContentAssets
	entries: ResourceAboveFormContentEntries
	resources: ResourceAboveFormContentResources
}

export type ResourceAboveFormContentResources = {
	__typename?: 'ResourceAboveFormContentResources'
	block: Array<ResourceAboveFormContentResourcesBlock>
	hyperlink: Array<ResourceAboveFormContentResourcesHyperlink>
	inline: Array<ResourceAboveFormContentResourcesInline>
}

export type ResourceAboveFormContentResourcesBlock = ResourceLink & {
	__typename?: 'ResourceAboveFormContentResourcesBlock'
	sys: ResourceSys
}

export type ResourceAboveFormContentResourcesHyperlink = ResourceLink & {
	__typename?: 'ResourceAboveFormContentResourcesHyperlink'
	sys: ResourceSys
}

export type ResourceAboveFormContentResourcesInline = ResourceLink & {
	__typename?: 'ResourceAboveFormContentResourcesInline'
	sys: ResourceSys
}

export type ResourceAdditionalContent = {
	__typename?: 'ResourceAdditionalContent'
	json: Scalars['JSON']['output']
	links: ResourceAdditionalContentLinks
}

export type ResourceAdditionalContentAssets = {
	__typename?: 'ResourceAdditionalContentAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type ResourceAdditionalContentEntries = {
	__typename?: 'ResourceAdditionalContentEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type ResourceAdditionalContentLinks = {
	__typename?: 'ResourceAdditionalContentLinks'
	assets: ResourceAdditionalContentAssets
	entries: ResourceAdditionalContentEntries
	resources: ResourceAdditionalContentResources
}

export type ResourceAdditionalContentResources = {
	__typename?: 'ResourceAdditionalContentResources'
	block: Array<ResourceAdditionalContentResourcesBlock>
	hyperlink: Array<ResourceAdditionalContentResourcesHyperlink>
	inline: Array<ResourceAdditionalContentResourcesInline>
}

export type ResourceAdditionalContentResourcesBlock = ResourceLink & {
	__typename?: 'ResourceAdditionalContentResourcesBlock'
	sys: ResourceSys
}

export type ResourceAdditionalContentResourcesHyperlink = ResourceLink & {
	__typename?: 'ResourceAdditionalContentResourcesHyperlink'
	sys: ResourceSys
}

export type ResourceAdditionalContentResourcesInline = ResourceLink & {
	__typename?: 'ResourceAdditionalContentResourcesInline'
	sys: ResourceSys
}

export type ResourceCollection = {
	__typename?: 'ResourceCollection'
	items: Array<Maybe<Resource>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type ResourceContent = {
	__typename?: 'ResourceContent'
	json: Scalars['JSON']['output']
	links: ResourceContentLinks
}

export type ResourceContentAssets = {
	__typename?: 'ResourceContentAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type ResourceContentEntries = {
	__typename?: 'ResourceContentEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type ResourceContentLinks = {
	__typename?: 'ResourceContentLinks'
	assets: ResourceContentAssets
	entries: ResourceContentEntries
	resources: ResourceContentResources
}

export type ResourceContentResources = {
	__typename?: 'ResourceContentResources'
	block: Array<ResourceContentResourcesBlock>
	hyperlink: Array<ResourceContentResourcesHyperlink>
	inline: Array<ResourceContentResourcesInline>
}

export type ResourceContentResourcesBlock = ResourceLink & {
	__typename?: 'ResourceContentResourcesBlock'
	sys: ResourceSys
}

export type ResourceContentResourcesHyperlink = ResourceLink & {
	__typename?: 'ResourceContentResourcesHyperlink'
	sys: ResourceSys
}

export type ResourceContentResourcesInline = ResourceLink & {
	__typename?: 'ResourceContentResourcesInline'
	sys: ResourceSys
}

export type ResourceDisclaimer = {
	__typename?: 'ResourceDisclaimer'
	json: Scalars['JSON']['output']
	links: ResourceDisclaimerLinks
}

export type ResourceDisclaimerAssets = {
	__typename?: 'ResourceDisclaimerAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type ResourceDisclaimerEntries = {
	__typename?: 'ResourceDisclaimerEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type ResourceDisclaimerLinks = {
	__typename?: 'ResourceDisclaimerLinks'
	assets: ResourceDisclaimerAssets
	entries: ResourceDisclaimerEntries
	resources: ResourceDisclaimerResources
}

export type ResourceDisclaimerResources = {
	__typename?: 'ResourceDisclaimerResources'
	block: Array<ResourceDisclaimerResourcesBlock>
	hyperlink: Array<ResourceDisclaimerResourcesHyperlink>
	inline: Array<ResourceDisclaimerResourcesInline>
}

export type ResourceDisclaimerResourcesBlock = ResourceLink & {
	__typename?: 'ResourceDisclaimerResourcesBlock'
	sys: ResourceSys
}

export type ResourceDisclaimerResourcesHyperlink = ResourceLink & {
	__typename?: 'ResourceDisclaimerResourcesHyperlink'
	sys: ResourceSys
}

export type ResourceDisclaimerResourcesInline = ResourceLink & {
	__typename?: 'ResourceDisclaimerResourcesInline'
	sys: ResourceSys
}

export type ResourceFilter = {
	AND?: InputMaybe<Array<InputMaybe<ResourceFilter>>>
	OR?: InputMaybe<Array<InputMaybe<ResourceFilter>>>
	aboveFormContent_contains?: InputMaybe<Scalars['String']['input']>
	aboveFormContent_exists?: InputMaybe<Scalars['Boolean']['input']>
	aboveFormContent_not_contains?: InputMaybe<Scalars['String']['input']>
	additionalContentButton?: InputMaybe<CfCtaButtonNestedFilter>
	additionalContentButton_exists?: InputMaybe<Scalars['Boolean']['input']>
	additionalContent_contains?: InputMaybe<Scalars['String']['input']>
	additionalContent_exists?: InputMaybe<Scalars['Boolean']['input']>
	additionalContent_not_contains?: InputMaybe<Scalars['String']['input']>
	cardImage_exists?: InputMaybe<Scalars['Boolean']['input']>
	contentImage_exists?: InputMaybe<Scalars['Boolean']['input']>
	content_contains?: InputMaybe<Scalars['String']['input']>
	content_exists?: InputMaybe<Scalars['Boolean']['input']>
	content_not_contains?: InputMaybe<Scalars['String']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	disclaimer_contains?: InputMaybe<Scalars['String']['input']>
	disclaimer_exists?: InputMaybe<Scalars['Boolean']['input']>
	disclaimer_not_contains?: InputMaybe<Scalars['String']['input']>
	featuredImage_exists?: InputMaybe<Scalars['Boolean']['input']>
	formButtonText?: InputMaybe<Scalars['String']['input']>
	formButtonText_contains?: InputMaybe<Scalars['String']['input']>
	formButtonText_exists?: InputMaybe<Scalars['Boolean']['input']>
	formButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	formButtonText_not?: InputMaybe<Scalars['String']['input']>
	formButtonText_not_contains?: InputMaybe<Scalars['String']['input']>
	formButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	formDescription_contains?: InputMaybe<Scalars['String']['input']>
	formDescription_exists?: InputMaybe<Scalars['Boolean']['input']>
	formDescription_not_contains?: InputMaybe<Scalars['String']['input']>
	formHeadline_contains?: InputMaybe<Scalars['String']['input']>
	formHeadline_exists?: InputMaybe<Scalars['Boolean']['input']>
	formHeadline_not_contains?: InputMaybe<Scalars['String']['input']>
	formId?: InputMaybe<Scalars['String']['input']>
	formId_contains?: InputMaybe<Scalars['String']['input']>
	formId_exists?: InputMaybe<Scalars['Boolean']['input']>
	formId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	formId_not?: InputMaybe<Scalars['String']['input']>
	formId_not_contains?: InputMaybe<Scalars['String']['input']>
	formId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	hero?: InputMaybe<CfHeroNestedFilter>
	hero_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	publishedDate?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_exists?: InputMaybe<Scalars['Boolean']['input']>
	publishedDate_gt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_gte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	publishedDate_lt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_lte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	recommendedContent?: InputMaybe<CfrecommendedContentMultiTypeNestedFilter>
	recommendedContentCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	sideFormContent_contains?: InputMaybe<Scalars['String']['input']>
	sideFormContent_exists?: InputMaybe<Scalars['Boolean']['input']>
	sideFormContent_not_contains?: InputMaybe<Scalars['String']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	speakers?: InputMaybe<CfSpeakerCardNestedFilter>
	speakersCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	stats?: InputMaybe<CfKeyValueNestedFilter>
	statsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	status?: InputMaybe<Scalars['Boolean']['input']>
	status_exists?: InputMaybe<Scalars['Boolean']['input']>
	status_not?: InputMaybe<Scalars['Boolean']['input']>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	type?: InputMaybe<Scalars['String']['input']>
	type_contains?: InputMaybe<Scalars['String']['input']>
	type_exists?: InputMaybe<Scalars['Boolean']['input']>
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	type_not?: InputMaybe<Scalars['String']['input']>
	type_not_contains?: InputMaybe<Scalars['String']['input']>
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ResourceFormDescription = {
	__typename?: 'ResourceFormDescription'
	json: Scalars['JSON']['output']
	links: ResourceFormDescriptionLinks
}

export type ResourceFormDescriptionAssets = {
	__typename?: 'ResourceFormDescriptionAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type ResourceFormDescriptionEntries = {
	__typename?: 'ResourceFormDescriptionEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type ResourceFormDescriptionLinks = {
	__typename?: 'ResourceFormDescriptionLinks'
	assets: ResourceFormDescriptionAssets
	entries: ResourceFormDescriptionEntries
	resources: ResourceFormDescriptionResources
}

export type ResourceFormDescriptionResources = {
	__typename?: 'ResourceFormDescriptionResources'
	block: Array<ResourceFormDescriptionResourcesBlock>
	hyperlink: Array<ResourceFormDescriptionResourcesHyperlink>
	inline: Array<ResourceFormDescriptionResourcesInline>
}

export type ResourceFormDescriptionResourcesBlock = ResourceLink & {
	__typename?: 'ResourceFormDescriptionResourcesBlock'
	sys: ResourceSys
}

export type ResourceFormDescriptionResourcesHyperlink = ResourceLink & {
	__typename?: 'ResourceFormDescriptionResourcesHyperlink'
	sys: ResourceSys
}

export type ResourceFormDescriptionResourcesInline = ResourceLink & {
	__typename?: 'ResourceFormDescriptionResourcesInline'
	sys: ResourceSys
}

export type ResourceFormHeadline = {
	__typename?: 'ResourceFormHeadline'
	json: Scalars['JSON']['output']
	links: ResourceFormHeadlineLinks
}

export type ResourceFormHeadlineAssets = {
	__typename?: 'ResourceFormHeadlineAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type ResourceFormHeadlineEntries = {
	__typename?: 'ResourceFormHeadlineEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type ResourceFormHeadlineLinks = {
	__typename?: 'ResourceFormHeadlineLinks'
	assets: ResourceFormHeadlineAssets
	entries: ResourceFormHeadlineEntries
	resources: ResourceFormHeadlineResources
}

export type ResourceFormHeadlineResources = {
	__typename?: 'ResourceFormHeadlineResources'
	block: Array<ResourceFormHeadlineResourcesBlock>
	hyperlink: Array<ResourceFormHeadlineResourcesHyperlink>
	inline: Array<ResourceFormHeadlineResourcesInline>
}

export type ResourceFormHeadlineResourcesBlock = ResourceLink & {
	__typename?: 'ResourceFormHeadlineResourcesBlock'
	sys: ResourceSys
}

export type ResourceFormHeadlineResourcesHyperlink = ResourceLink & {
	__typename?: 'ResourceFormHeadlineResourcesHyperlink'
	sys: ResourceSys
}

export type ResourceFormHeadlineResourcesInline = ResourceLink & {
	__typename?: 'ResourceFormHeadlineResourcesInline'
	sys: ResourceSys
}

export type ResourceLink = {
	sys: ResourceSys
}

export type ResourceLinkingCollections = {
	__typename?: 'ResourceLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
	resourceCollection?: Maybe<ResourceCollection>
}

export type ResourceLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type ResourceLinkingCollectionsResourceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ResourceLinkingCollectionsResourceCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum ResourceLinkingCollectionsResourceCollectionOrder {
	FormButtonTextAsc = 'formButtonText_ASC',
	FormButtonTextDesc = 'formButtonText_DESC',
	FormIdAsc = 'formId_ASC',
	FormIdDesc = 'formId_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum ResourceOrder {
	FormButtonTextAsc = 'formButtonText_ASC',
	FormButtonTextDesc = 'formButtonText_DESC',
	FormIdAsc = 'formId_ASC',
	FormIdDesc = 'formId_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export type ResourceRecommendedContentCollection = {
	__typename?: 'ResourceRecommendedContentCollection'
	items: Array<Maybe<ResourceRecommendedContentItem>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type ResourceRecommendedContentFilter = {
	AND?: InputMaybe<Array<InputMaybe<ResourceRecommendedContentFilter>>>
	OR?: InputMaybe<Array<InputMaybe<ResourceRecommendedContentFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ResourceRecommendedContentItem =
	| Article
	| BasicContentCard
	| BlogPost
	| CaseStudy
	| Resource

export type ResourceSideFormContent = {
	__typename?: 'ResourceSideFormContent'
	json: Scalars['JSON']['output']
	links: ResourceSideFormContentLinks
}

export type ResourceSideFormContentAssets = {
	__typename?: 'ResourceSideFormContentAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type ResourceSideFormContentEntries = {
	__typename?: 'ResourceSideFormContentEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type ResourceSideFormContentLinks = {
	__typename?: 'ResourceSideFormContentLinks'
	assets: ResourceSideFormContentAssets
	entries: ResourceSideFormContentEntries
	resources: ResourceSideFormContentResources
}

export type ResourceSideFormContentResources = {
	__typename?: 'ResourceSideFormContentResources'
	block: Array<ResourceSideFormContentResourcesBlock>
	hyperlink: Array<ResourceSideFormContentResourcesHyperlink>
	inline: Array<ResourceSideFormContentResourcesInline>
}

export type ResourceSideFormContentResourcesBlock = ResourceLink & {
	__typename?: 'ResourceSideFormContentResourcesBlock'
	sys: ResourceSys
}

export type ResourceSideFormContentResourcesHyperlink = ResourceLink & {
	__typename?: 'ResourceSideFormContentResourcesHyperlink'
	sys: ResourceSys
}

export type ResourceSideFormContentResourcesInline = ResourceLink & {
	__typename?: 'ResourceSideFormContentResourcesInline'
	sys: ResourceSys
}

export type ResourceSpeakersCollection = {
	__typename?: 'ResourceSpeakersCollection'
	items: Array<Maybe<SpeakerCard>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum ResourceSpeakersCollectionOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	JobTitleAsc = 'jobTitle_ASC',
	JobTitleDesc = 'jobTitle_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ResourceStatsCollection = {
	__typename?: 'ResourceStatsCollection'
	items: Array<Maybe<KeyValue>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum ResourceStatsCollectionOrder {
	KeyAsc = 'key_ASC',
	KeyDesc = 'key_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	ValueAsc = 'value_ASC',
	ValueDesc = 'value_DESC'
}

export type ResourceSys = {
	__typename?: 'ResourceSys'
	linkType: Scalars['String']['output']
	urn: Scalars['String']['output']
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/richTextWrapper) */
export type RichTextWrapper = Entry &
	_Node & {
		__typename?: 'RichTextWrapper'
		_id: Scalars['ID']['output']
		content?: Maybe<RichTextWrapperContent>
		contentfulMetadata: ContentfulMetadata
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<RichTextWrapperLinkingCollections>
		sys: Sys
		type?: Maybe<Scalars['String']['output']>
	}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/richTextWrapper) */
export type RichTextWrapperContentArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/richTextWrapper) */
export type RichTextWrapperInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/richTextWrapper) */
export type RichTextWrapperLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/richTextWrapper) */
export type RichTextWrapperTypeArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type RichTextWrapperCollection = {
	__typename?: 'RichTextWrapperCollection'
	items: Array<Maybe<RichTextWrapper>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type RichTextWrapperContent = {
	__typename?: 'RichTextWrapperContent'
	json: Scalars['JSON']['output']
	links: RichTextWrapperContentLinks
}

export type RichTextWrapperContentAssets = {
	__typename?: 'RichTextWrapperContentAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type RichTextWrapperContentEntries = {
	__typename?: 'RichTextWrapperContentEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type RichTextWrapperContentLinks = {
	__typename?: 'RichTextWrapperContentLinks'
	assets: RichTextWrapperContentAssets
	entries: RichTextWrapperContentEntries
	resources: RichTextWrapperContentResources
}

export type RichTextWrapperContentResources = {
	__typename?: 'RichTextWrapperContentResources'
	block: Array<RichTextWrapperContentResourcesBlock>
	hyperlink: Array<RichTextWrapperContentResourcesHyperlink>
	inline: Array<RichTextWrapperContentResourcesInline>
}

export type RichTextWrapperContentResourcesBlock = ResourceLink & {
	__typename?: 'RichTextWrapperContentResourcesBlock'
	sys: ResourceSys
}

export type RichTextWrapperContentResourcesHyperlink = ResourceLink & {
	__typename?: 'RichTextWrapperContentResourcesHyperlink'
	sys: ResourceSys
}

export type RichTextWrapperContentResourcesInline = ResourceLink & {
	__typename?: 'RichTextWrapperContentResourcesInline'
	sys: ResourceSys
}

export type RichTextWrapperFilter = {
	AND?: InputMaybe<Array<InputMaybe<RichTextWrapperFilter>>>
	OR?: InputMaybe<Array<InputMaybe<RichTextWrapperFilter>>>
	content_contains?: InputMaybe<Scalars['String']['input']>
	content_exists?: InputMaybe<Scalars['Boolean']['input']>
	content_not_contains?: InputMaybe<Scalars['String']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	type?: InputMaybe<Scalars['String']['input']>
	type_contains?: InputMaybe<Scalars['String']['input']>
	type_exists?: InputMaybe<Scalars['Boolean']['input']>
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	type_not?: InputMaybe<Scalars['String']['input']>
	type_not_contains?: InputMaybe<Scalars['String']['input']>
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type RichTextWrapperLinkingCollections = {
	__typename?: 'RichTextWrapperLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
}

export type RichTextWrapperLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum RichTextWrapperOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

/** For robots.txt files [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/robots) */
export type Robots = Entry &
	_Node & {
		__typename?: 'Robots'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<RobotsLinkingCollections>
		robots?: Maybe<Scalars['String']['output']>
		sys: Sys
	}

/** For robots.txt files [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/robots) */
export type RobotsInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** For robots.txt files [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/robots) */
export type RobotsLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** For robots.txt files [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/robots) */
export type RobotsRobotsArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type RobotsCollection = {
	__typename?: 'RobotsCollection'
	items: Array<Maybe<Robots>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type RobotsFilter = {
	AND?: InputMaybe<Array<InputMaybe<RobotsFilter>>>
	OR?: InputMaybe<Array<InputMaybe<RobotsFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	robots?: InputMaybe<Scalars['String']['input']>
	robots_contains?: InputMaybe<Scalars['String']['input']>
	robots_exists?: InputMaybe<Scalars['Boolean']['input']>
	robots_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	robots_not?: InputMaybe<Scalars['String']['input']>
	robots_not_contains?: InputMaybe<Scalars['String']['input']>
	robots_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type RobotsLinkingCollections = {
	__typename?: 'RobotsLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
}

export type RobotsLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum RobotsOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Used by many content types to provide SEO data [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/seoMetadata) */
export type SeoMetadata = Entry &
	_Node & {
		__typename?: 'SeoMetadata'
		_id: Scalars['ID']['output']
		canonicalUrl?: Maybe<Scalars['String']['output']>
		contentfulMetadata: ContentfulMetadata
		description?: Maybe<Scalars['String']['output']>
		image?: Maybe<Asset>
		includeTitleTemplate?: Maybe<Scalars['Boolean']['output']>
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<SeoMetadataLinkingCollections>
		nofollow?: Maybe<Scalars['Boolean']['output']>
		noindex?: Maybe<Scalars['Boolean']['output']>
		readingTime?: Maybe<Scalars['String']['output']>
		sys: Sys
		title?: Maybe<Scalars['String']['output']>
	}

/** Used by many content types to provide SEO data [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/seoMetadata) */
export type SeoMetadataCanonicalUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used by many content types to provide SEO data [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/seoMetadata) */
export type SeoMetadataDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used by many content types to provide SEO data [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/seoMetadata) */
export type SeoMetadataImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Used by many content types to provide SEO data [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/seoMetadata) */
export type SeoMetadataIncludeTitleTemplateArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used by many content types to provide SEO data [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/seoMetadata) */
export type SeoMetadataInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used by many content types to provide SEO data [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/seoMetadata) */
export type SeoMetadataLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Used by many content types to provide SEO data [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/seoMetadata) */
export type SeoMetadataNofollowArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used by many content types to provide SEO data [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/seoMetadata) */
export type SeoMetadataNoindexArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used by many content types to provide SEO data [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/seoMetadata) */
export type SeoMetadataReadingTimeArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used by many content types to provide SEO data [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/seoMetadata) */
export type SeoMetadataTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type SeoMetadataCollection = {
	__typename?: 'SeoMetadataCollection'
	items: Array<Maybe<SeoMetadata>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type SeoMetadataFilter = {
	AND?: InputMaybe<Array<InputMaybe<SeoMetadataFilter>>>
	OR?: InputMaybe<Array<InputMaybe<SeoMetadataFilter>>>
	canonicalUrl?: InputMaybe<Scalars['String']['input']>
	canonicalUrl_contains?: InputMaybe<Scalars['String']['input']>
	canonicalUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	canonicalUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	canonicalUrl_not?: InputMaybe<Scalars['String']['input']>
	canonicalUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	canonicalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description?: InputMaybe<Scalars['String']['input']>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	description_not?: InputMaybe<Scalars['String']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	includeTitleTemplate?: InputMaybe<Scalars['Boolean']['input']>
	includeTitleTemplate_exists?: InputMaybe<Scalars['Boolean']['input']>
	includeTitleTemplate_not?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	nofollow?: InputMaybe<Scalars['Boolean']['input']>
	nofollow_exists?: InputMaybe<Scalars['Boolean']['input']>
	nofollow_not?: InputMaybe<Scalars['Boolean']['input']>
	noindex?: InputMaybe<Scalars['Boolean']['input']>
	noindex_exists?: InputMaybe<Scalars['Boolean']['input']>
	noindex_not?: InputMaybe<Scalars['Boolean']['input']>
	readingTime?: InputMaybe<Scalars['String']['input']>
	readingTime_contains?: InputMaybe<Scalars['String']['input']>
	readingTime_exists?: InputMaybe<Scalars['Boolean']['input']>
	readingTime_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	readingTime_not?: InputMaybe<Scalars['String']['input']>
	readingTime_not_contains?: InputMaybe<Scalars['String']['input']>
	readingTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type SeoMetadataLinkingCollections = {
	__typename?: 'SeoMetadataLinkingCollections'
	appCollectionCollection?: Maybe<AppCollectionCollection>
	appTierCollection?: Maybe<AppTierCollection>
	articleCollection?: Maybe<ArticleCollection>
	blogPostCollection?: Maybe<BlogPostCollection>
	caseStudyCollection?: Maybe<CaseStudyCollection>
	categoryCollection?: Maybe<CategoryCollection>
	entryCollection?: Maybe<EntryCollection>
	glossaryCollection?: Maybe<GlossaryCollection>
	marketplaceAppCollection?: Maybe<MarketplaceAppCollection>
	marketplaceThemeCollection?: Maybe<MarketplaceThemeCollection>
	personCollection?: Maybe<PersonCollection>
	pressCollection?: Maybe<PressCollection>
	resourceCollection?: Maybe<ResourceCollection>
	themePropertyCollection?: Maybe<ThemePropertyCollection>
}

export type SeoMetadataLinkingCollectionsAppCollectionCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsAppCollectionCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type SeoMetadataLinkingCollectionsAppTierCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsAppTierCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type SeoMetadataLinkingCollectionsArticleCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsArticleCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type SeoMetadataLinkingCollectionsBlogPostCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsBlogPostCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type SeoMetadataLinkingCollectionsCaseStudyCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsCaseStudyCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type SeoMetadataLinkingCollectionsCategoryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsCategoryCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type SeoMetadataLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type SeoMetadataLinkingCollectionsGlossaryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsGlossaryCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type SeoMetadataLinkingCollectionsMarketplaceAppCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsMarketplaceAppCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type SeoMetadataLinkingCollectionsMarketplaceThemeCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<
		Array<InputMaybe<SeoMetadataLinkingCollectionsMarketplaceThemeCollectionOrder>>
	>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type SeoMetadataLinkingCollectionsPersonCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsPersonCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type SeoMetadataLinkingCollectionsPressCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsPressCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type SeoMetadataLinkingCollectionsResourceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsResourceCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type SeoMetadataLinkingCollectionsThemePropertyCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsThemePropertyCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum SeoMetadataLinkingCollectionsAppCollectionCollectionOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	PrivateAsc = 'private_ASC',
	PrivateDesc = 'private_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SortAsc = 'sort_ASC',
	SortDesc = 'sort_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	ThumbnailAsc = 'thumbnail_ASC',
	ThumbnailDesc = 'thumbnail_DESC'
}

export enum SeoMetadataLinkingCollectionsAppTierCollectionOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SortAsc = 'sort_ASC',
	SortDesc = 'sort_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SeoMetadataLinkingCollectionsArticleCollectionOrder {
	EditStatusAsc = 'editStatus_ASC',
	EditStatusDesc = 'editStatus_DESC',
	FaqsHeadingAsc = 'faqsHeading_ASC',
	FaqsHeadingDesc = 'faqsHeading_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	InterstitialHeadlineAsc = 'interstitialHeadline_ASC',
	InterstitialHeadlineDesc = 'interstitialHeadline_DESC',
	PopularAsc = 'popular_ASC',
	PopularDesc = 'popular_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsBlogPostCollectionOrder {
	HideOnHomepageAsc = 'hideOnHomepage_ASC',
	HideOnHomepageDesc = 'hideOnHomepage_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	ShowPostHeroAsc = 'showPostHero_ASC',
	ShowPostHeroDesc = 'showPostHero_DESC',
	ShowTableOfContentsAsc = 'showTableOfContents_ASC',
	ShowTableOfContentsDesc = 'showTableOfContents_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsCaseStudyCollectionOrder {
	FeaturedAuthorNameAsc = 'featuredAuthorName_ASC',
	FeaturedAuthorNameDesc = 'featuredAuthorName_DESC',
	FeaturedAuthorTitleAsc = 'featuredAuthorTitle_ASC',
	FeaturedAuthorTitleDesc = 'featuredAuthorTitle_DESC',
	FeaturedLinkTextAsc = 'featuredLinkText_ASC',
	FeaturedLinkTextDesc = 'featuredLinkText_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	WhitepaperStandinAsc = 'whitepaperStandin_ASC',
	WhitepaperStandinDesc = 'whitepaperStandin_DESC'
}

export enum SeoMetadataLinkingCollectionsCategoryCollectionOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SeoMetadataLinkingCollectionsGlossaryCollectionOrder {
	EnabledAsc = 'enabled_ASC',
	EnabledDesc = 'enabled_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsMarketplaceAppCollectionOrder {
	AlternativeLogoAsc = 'alternativeLogo_ASC',
	AlternativeLogoDesc = 'alternativeLogo_DESC',
	AppRegistryIdAsc = 'appRegistryId_ASC',
	AppRegistryIdDesc = 'appRegistryId_DESC',
	AppRegistryUpdatedAtAsc = 'appRegistryUpdatedAt_ASC',
	AppRegistryUpdatedAtDesc = 'appRegistryUpdatedAt_DESC',
	ApprovedAtAsc = 'approvedAt_ASC',
	ApprovedAtDesc = 'approvedAt_DESC',
	CatalystCompatibleAsc = 'catalystCompatible_ASC',
	CatalystCompatibleDesc = 'catalystCompatible_DESC',
	CreatedOnAsc = 'createdOn_ASC',
	CreatedOnDesc = 'createdOn_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	FreeTrialDaysAsc = 'freeTrialDays_ASC',
	FreeTrialDaysDesc = 'freeTrialDays_DESC',
	FreeTrialAsc = 'freeTrial_ASC',
	FreeTrialDesc = 'freeTrial_DESC',
	InstallationGuideUrlAsc = 'installationGuideUrl_ASC',
	InstallationGuideUrlDesc = 'installationGuideUrl_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	LastHashAsc = 'lastHash_ASC',
	LastHashDesc = 'lastHash_DESC',
	LogoIconAsc = 'logoIcon_ASC',
	LogoIconDesc = 'logoIcon_DESC',
	LogoAsc = 'logo_ASC',
	LogoDesc = 'logo_DESC',
	MonthlyPriceAsc = 'monthlyPrice_ASC',
	MonthlyPriceDesc = 'monthlyPrice_DESC',
	MultiLocationAsc = 'multiLocation_ASC',
	MultiLocationDesc = 'multiLocation_DESC',
	MultiStorefrontAsc = 'multiStorefront_ASC',
	MultiStorefrontDesc = 'multiStorefront_DESC',
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	PricingTypeAsc = 'pricingType_ASC',
	PricingTypeDesc = 'pricingType_DESC',
	PricingAsc = 'pricing_ASC',
	PricingDesc = 'pricing_DESC',
	PrivacyPolicyUrlAsc = 'privacyPolicyUrl_ASC',
	PrivacyPolicyUrlDesc = 'privacyPolicyUrl_DESC',
	RankAsc = 'rank_ASC',
	RankDesc = 'rank_DESC',
	RatingAsc = 'rating_ASC',
	RatingDesc = 'rating_DESC',
	RecommendedOrderAsc = 'recommendedOrder_ASC',
	RecommendedOrderDesc = 'recommendedOrder_DESC',
	RecommendedAsc = 'recommended_ASC',
	RecommendedDesc = 'recommended_DESC',
	ReviewCountAsc = 'reviewCount_ASC',
	ReviewCountDesc = 'reviewCount_DESC',
	SingleClickAsc = 'singleClick_ASC',
	SingleClickDesc = 'singleClick_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SupportEmailAsc = 'supportEmail_ASC',
	SupportEmailDesc = 'supportEmail_DESC',
	SupportUrlAsc = 'supportUrl_ASC',
	SupportUrlDesc = 'supportUrl_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TermsOfServiceUrlAsc = 'termsOfServiceUrl_ASC',
	TermsOfServiceUrlDesc = 'termsOfServiceUrl_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC',
	UnifiedBillingAsc = 'unifiedBilling_ASC',
	UnifiedBillingDesc = 'unifiedBilling_DESC',
	UpfrontPriceAsc = 'upfrontPrice_ASC',
	UpfrontPriceDesc = 'upfrontPrice_DESC',
	UserGuideUrlAsc = 'userGuideUrl_ASC',
	UserGuideUrlDesc = 'userGuideUrl_DESC',
	VersionAsc = 'version_ASC',
	VersionDesc = 'version_DESC',
	WebsiteAsc = 'website_ASC',
	WebsiteDesc = 'website_DESC'
}

export enum SeoMetadataLinkingCollectionsMarketplaceThemeCollectionOrder {
	ContactUrlAsc = 'contactUrl_ASC',
	ContactUrlDesc = 'contactUrl_DESC',
	DemoUrlAsc = 'demoUrl_ASC',
	DemoUrlDesc = 'demoUrl_DESC',
	DocumentationUrlAsc = 'documentationUrl_ASC',
	DocumentationUrlDesc = 'documentationUrl_DESC',
	ExternalIdAsc = 'externalId_ASC',
	ExternalIdDesc = 'externalId_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	LargePreviewAsc = 'largePreview_ASC',
	LargePreviewDesc = 'largePreview_DESC',
	LargeThumbnailAsc = 'largeThumbnail_ASC',
	LargeThumbnailDesc = 'largeThumbnail_DESC',
	LastHashAsc = 'lastHash_ASC',
	LastHashDesc = 'lastHash_DESC',
	MobilePreviewAsc = 'mobilePreview_ASC',
	MobilePreviewDesc = 'mobilePreview_DESC',
	PartnerEmailAsc = 'partnerEmail_ASC',
	PartnerEmailDesc = 'partnerEmail_DESC',
	PartnerIdAsc = 'partnerId_ASC',
	PartnerIdDesc = 'partnerId_DESC',
	PartnerNameAsc = 'partnerName_ASC',
	PartnerNameDesc = 'partnerName_DESC',
	PriceAsc = 'price_ASC',
	PriceDesc = 'price_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	RegistryIdAsc = 'registryId_ASC',
	RegistryIdDesc = 'registryId_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SmallThumbnailAsc = 'smallThumbnail_ASC',
	SmallThumbnailDesc = 'smallThumbnail_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	ThemeDefaultConfigurationIdAsc = 'themeDefaultConfigurationId_ASC',
	ThemeDefaultConfigurationIdDesc = 'themeDefaultConfigurationId_DESC',
	ThemeIdAsc = 'themeId_ASC',
	ThemeIdDesc = 'themeId_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	VariationNameAsc = 'variationName_ASC',
	VariationNameDesc = 'variationName_DESC',
	VersionIdAsc = 'versionId_ASC',
	VersionIdDesc = 'versionId_DESC'
}

export enum SeoMetadataLinkingCollectionsPersonCollectionOrder {
	EmailAsc = 'email_ASC',
	EmailDesc = 'email_DESC',
	EnabledAsc = 'enabled_ASC',
	EnabledDesc = 'enabled_DESC',
	FullNameAsc = 'fullName_ASC',
	FullNameDesc = 'fullName_DESC',
	JobTitleAsc = 'jobTitle_ASC',
	JobTitleDesc = 'jobTitle_DESC',
	LinkedUrlAsc = 'linkedUrl_ASC',
	LinkedUrlDesc = 'linkedUrl_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum SeoMetadataLinkingCollectionsPressCollectionOrder {
	EnabledAsc = 'enabled_ASC',
	EnabledDesc = 'enabled_DESC',
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum SeoMetadataLinkingCollectionsResourceCollectionOrder {
	FormButtonTextAsc = 'formButtonText_ASC',
	FormButtonTextDesc = 'formButtonText_DESC',
	FormIdAsc = 'formId_ASC',
	FormIdDesc = 'formId_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum SeoMetadataLinkingCollectionsThemePropertyCollectionOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum SeoMetadataOrder {
	CanonicalUrlAsc = 'canonicalUrl_ASC',
	CanonicalUrlDesc = 'canonicalUrl_DESC',
	IncludeTitleTemplateAsc = 'includeTitleTemplate_ASC',
	IncludeTitleTemplateDesc = 'includeTitleTemplate_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	NofollowAsc = 'nofollow_ASC',
	NofollowDesc = 'nofollow_DESC',
	NoindexAsc = 'noindex_ASC',
	NoindexDesc = 'noindex_DESC',
	ReadingTimeAsc = 'readingTime_ASC',
	ReadingTimeDesc = 'readingTime_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

/** Used for /showcase items [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/showcase) */
export type Showcase = Entry &
	_Node & {
		__typename?: 'Showcase'
		_id: Scalars['ID']['output']
		caseStudy?: Maybe<ExternalLink>
		categoryCollection?: Maybe<ShowcaseCategoryCollection>
		contentfulMetadata: ContentfulMetadata
		description?: Maybe<ShowcaseDescription>
		externalUrl?: Maybe<ExternalLink>
		featured?: Maybe<Scalars['Boolean']['output']>
		image?: Maybe<ShowcaseImage>
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<ShowcaseLinkingCollections>
		slug?: Maybe<Scalars['String']['output']>
		status?: Maybe<Scalars['Boolean']['output']>
		sys: Sys
		title?: Maybe<Scalars['String']['output']>
	}

/** Used for /showcase items [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/showcase) */
export type ShowcaseCaseStudyArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<ExternalLinkFilter>
}

/** Used for /showcase items [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/showcase) */
export type ShowcaseCategoryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<ShowcaseCategoryCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	where?: InputMaybe<CategoryFilter>
}

/** Used for /showcase items [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/showcase) */
export type ShowcaseDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for /showcase items [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/showcase) */
export type ShowcaseExternalUrlArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<ExternalLinkFilter>
}

/** Used for /showcase items [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/showcase) */
export type ShowcaseFeaturedArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for /showcase items [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/showcase) */
export type ShowcaseImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Used for /showcase items [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/showcase) */
export type ShowcaseInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for /showcase items [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/showcase) */
export type ShowcaseLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Used for /showcase items [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/showcase) */
export type ShowcaseSlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for /showcase items [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/showcase) */
export type ShowcaseStatusArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Used for /showcase items [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/showcase) */
export type ShowcaseTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type ShowcaseCategoryCollection = {
	__typename?: 'ShowcaseCategoryCollection'
	items: Array<Maybe<Category>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export enum ShowcaseCategoryCollectionOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ShowcaseCollection = {
	__typename?: 'ShowcaseCollection'
	items: Array<Maybe<Showcase>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type ShowcaseDescription = {
	__typename?: 'ShowcaseDescription'
	json: Scalars['JSON']['output']
	links: ShowcaseDescriptionLinks
}

export type ShowcaseDescriptionAssets = {
	__typename?: 'ShowcaseDescriptionAssets'
	block: Array<Maybe<Asset>>
	hyperlink: Array<Maybe<Asset>>
}

export type ShowcaseDescriptionEntries = {
	__typename?: 'ShowcaseDescriptionEntries'
	block: Array<Maybe<Entry>>
	hyperlink: Array<Maybe<Entry>>
	inline: Array<Maybe<Entry>>
}

export type ShowcaseDescriptionLinks = {
	__typename?: 'ShowcaseDescriptionLinks'
	assets: ShowcaseDescriptionAssets
	entries: ShowcaseDescriptionEntries
	resources: ShowcaseDescriptionResources
}

export type ShowcaseDescriptionResources = {
	__typename?: 'ShowcaseDescriptionResources'
	block: Array<ShowcaseDescriptionResourcesBlock>
	hyperlink: Array<ShowcaseDescriptionResourcesHyperlink>
	inline: Array<ShowcaseDescriptionResourcesInline>
}

export type ShowcaseDescriptionResourcesBlock = ResourceLink & {
	__typename?: 'ShowcaseDescriptionResourcesBlock'
	sys: ResourceSys
}

export type ShowcaseDescriptionResourcesHyperlink = ResourceLink & {
	__typename?: 'ShowcaseDescriptionResourcesHyperlink'
	sys: ResourceSys
}

export type ShowcaseDescriptionResourcesInline = ResourceLink & {
	__typename?: 'ShowcaseDescriptionResourcesInline'
	sys: ResourceSys
}

export type ShowcaseFilter = {
	AND?: InputMaybe<Array<InputMaybe<ShowcaseFilter>>>
	OR?: InputMaybe<Array<InputMaybe<ShowcaseFilter>>>
	caseStudy?: InputMaybe<CfExternalLinkNestedFilter>
	caseStudy_exists?: InputMaybe<Scalars['Boolean']['input']>
	category?: InputMaybe<CfCategoryNestedFilter>
	categoryCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	externalUrl?: InputMaybe<CfExternalLinkNestedFilter>
	externalUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	featured?: InputMaybe<Scalars['Boolean']['input']>
	featured_exists?: InputMaybe<Scalars['Boolean']['input']>
	featured_not?: InputMaybe<Scalars['Boolean']['input']>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	status?: InputMaybe<Scalars['Boolean']['input']>
	status_exists?: InputMaybe<Scalars['Boolean']['input']>
	status_not?: InputMaybe<Scalars['Boolean']['input']>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ShowcaseImage = AssetExternal | AssetInternal

export type ShowcaseLinkingCollections = {
	__typename?: 'ShowcaseLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
}

export type ShowcaseLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum ShowcaseOrder {
	FeaturedAsc = 'featured_ASC',
	FeaturedDesc = 'featured_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

/** Cards used for speakers on webinars [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/speakerCard) */
export type SpeakerCard = Entry &
	_Node & {
		__typename?: 'SpeakerCard'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		description?: Maybe<Scalars['String']['output']>
		image?: Maybe<Asset>
		internalName?: Maybe<Scalars['String']['output']>
		jobTitle?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<SpeakerCardLinkingCollections>
		sys: Sys
		title?: Maybe<Scalars['String']['output']>
	}

/** Cards used for speakers on webinars [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/speakerCard) */
export type SpeakerCardDescriptionArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Cards used for speakers on webinars [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/speakerCard) */
export type SpeakerCardImageArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}

/** Cards used for speakers on webinars [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/speakerCard) */
export type SpeakerCardInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Cards used for speakers on webinars [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/speakerCard) */
export type SpeakerCardJobTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Cards used for speakers on webinars [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/speakerCard) */
export type SpeakerCardLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Cards used for speakers on webinars [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/speakerCard) */
export type SpeakerCardTitleArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type SpeakerCardCollection = {
	__typename?: 'SpeakerCardCollection'
	items: Array<Maybe<SpeakerCard>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type SpeakerCardFilter = {
	AND?: InputMaybe<Array<InputMaybe<SpeakerCardFilter>>>
	OR?: InputMaybe<Array<InputMaybe<SpeakerCardFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description?: InputMaybe<Scalars['String']['input']>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	description_not?: InputMaybe<Scalars['String']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	jobTitle?: InputMaybe<Scalars['String']['input']>
	jobTitle_contains?: InputMaybe<Scalars['String']['input']>
	jobTitle_exists?: InputMaybe<Scalars['Boolean']['input']>
	jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	jobTitle_not?: InputMaybe<Scalars['String']['input']>
	jobTitle_not_contains?: InputMaybe<Scalars['String']['input']>
	jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type SpeakerCardLinkingCollections = {
	__typename?: 'SpeakerCardLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
	resourceCollection?: Maybe<ResourceCollection>
}

export type SpeakerCardLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type SpeakerCardLinkingCollectionsResourceCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<SpeakerCardLinkingCollectionsResourceCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum SpeakerCardLinkingCollectionsResourceCollectionOrder {
	FormButtonTextAsc = 'formButtonText_ASC',
	FormButtonTextDesc = 'formButtonText_DESC',
	FormIdAsc = 'formId_ASC',
	FormIdDesc = 'formId_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export enum SpeakerCardOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	JobTitleAsc = 'jobTitle_ASC',
	JobTitleDesc = 'jobTitle_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Sys = {
	__typename?: 'Sys'
	environmentId: Scalars['String']['output']
	firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
	id: Scalars['String']['output']
	/** The locale that was requested. */
	locale?: Maybe<Scalars['String']['output']>
	publishedAt?: Maybe<Scalars['DateTime']['output']>
	publishedVersion?: Maybe<Scalars['Int']['output']>
	spaceId: Scalars['String']['output']
}

export type SysFilter = {
	firstPublishedAt?: InputMaybe<Scalars['DateTime']['input']>
	firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']['input']>
	firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
	firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
	firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
	firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
	firstPublishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
	firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	id?: InputMaybe<Scalars['String']['input']>
	id_contains?: InputMaybe<Scalars['String']['input']>
	id_exists?: InputMaybe<Scalars['Boolean']['input']>
	id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	id_not?: InputMaybe<Scalars['String']['input']>
	id_not_contains?: InputMaybe<Scalars['String']['input']>
	id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	publishedAt?: InputMaybe<Scalars['DateTime']['input']>
	publishedAt_exists?: InputMaybe<Scalars['Boolean']['input']>
	publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
	publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
	publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
	publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
	publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
	publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	publishedVersion?: InputMaybe<Scalars['Float']['input']>
	publishedVersion_exists?: InputMaybe<Scalars['Boolean']['input']>
	publishedVersion_gt?: InputMaybe<Scalars['Float']['input']>
	publishedVersion_gte?: InputMaybe<Scalars['Float']['input']>
	publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
	publishedVersion_lt?: InputMaybe<Scalars['Float']['input']>
	publishedVersion_lte?: InputMaybe<Scalars['Float']['input']>
	publishedVersion_not?: InputMaybe<Scalars['Float']['input']>
	publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
}

/** Non hierarchical descriptors for blog posts  [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/tag) */
export type Tag = Entry &
	_Node & {
		__typename?: 'Tag'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		linkedFrom?: Maybe<TagLinkingCollections>
		name?: Maybe<Scalars['String']['output']>
		slug?: Maybe<Scalars['String']['output']>
		sys: Sys
	}

/** Non hierarchical descriptors for blog posts  [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/tag) */
export type TagLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Non hierarchical descriptors for blog posts  [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/tag) */
export type TagNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** Non hierarchical descriptors for blog posts  [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/tag) */
export type TagSlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type TagCollection = {
	__typename?: 'TagCollection'
	items: Array<Maybe<Tag>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type TagFilter = {
	AND?: InputMaybe<Array<InputMaybe<TagFilter>>>
	OR?: InputMaybe<Array<InputMaybe<TagFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	name?: InputMaybe<Scalars['String']['input']>
	name_contains?: InputMaybe<Scalars['String']['input']>
	name_exists?: InputMaybe<Scalars['Boolean']['input']>
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name_not?: InputMaybe<Scalars['String']['input']>
	name_not_contains?: InputMaybe<Scalars['String']['input']>
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type TagLinkingCollections = {
	__typename?: 'TagLinkingCollections'
	blogPostCollection?: Maybe<BlogPostCollection>
	entryCollection?: Maybe<EntryCollection>
}

export type TagLinkingCollectionsBlogPostCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<TagLinkingCollectionsBlogPostCollectionOrder>>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type TagLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum TagLinkingCollectionsBlogPostCollectionOrder {
	HideOnHomepageAsc = 'hideOnHomepage_ASC',
	HideOnHomepageDesc = 'hideOnHomepage_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	ShowPostHeroAsc = 'showPostHero_ASC',
	ShowPostHeroDesc = 'showPostHero_DESC',
	ShowTableOfContentsAsc = 'showTableOfContents_ASC',
	ShowTableOfContentsDesc = 'showTableOfContents_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	StatusAsc = 'status_ASC',
	StatusDesc = 'status_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC'
}

export enum TagOrder {
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * Represents a taxonomy concept entity for finding and organizing content easily.
 *         Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-concepts
 */
export type TaxonomyConcept = {
	__typename?: 'TaxonomyConcept'
	id?: Maybe<Scalars['String']['output']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/themeProperty) */
export type ThemeProperty = Entry &
	_Node & {
		__typename?: 'ThemeProperty'
		_id: Scalars['ID']['output']
		contentfulMetadata: ContentfulMetadata
		internalName?: Maybe<Scalars['String']['output']>
		linkedFrom?: Maybe<ThemePropertyLinkingCollections>
		name?: Maybe<Scalars['String']['output']>
		seoMetadata?: Maybe<SeoMetadata>
		slug?: Maybe<Scalars['String']['output']>
		sys: Sys
		type?: Maybe<Scalars['String']['output']>
	}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/themeProperty) */
export type ThemePropertyInternalNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/themeProperty) */
export type ThemePropertyLinkedFromArgs = {
	allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/themeProperty) */
export type ThemePropertyNameArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/themeProperty) */
export type ThemePropertySeoMetadataArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	where?: InputMaybe<SeoMetadataFilter>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/themeProperty) */
export type ThemePropertySlugArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

/** [See type definition](https://app.contentful.com/spaces/wowgx05xsdrr/content_types/themeProperty) */
export type ThemePropertyTypeArgs = {
	locale?: InputMaybe<Scalars['String']['input']>
}

export type ThemePropertyCollection = {
	__typename?: 'ThemePropertyCollection'
	items: Array<Maybe<ThemeProperty>>
	limit: Scalars['Int']['output']
	skip: Scalars['Int']['output']
	total: Scalars['Int']['output']
}

export type ThemePropertyFilter = {
	AND?: InputMaybe<Array<InputMaybe<ThemePropertyFilter>>>
	OR?: InputMaybe<Array<InputMaybe<ThemePropertyFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name?: InputMaybe<Scalars['String']['input']>
	name_contains?: InputMaybe<Scalars['String']['input']>
	name_exists?: InputMaybe<Scalars['Boolean']['input']>
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name_not?: InputMaybe<Scalars['String']['input']>
	name_not_contains?: InputMaybe<Scalars['String']['input']>
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	type?: InputMaybe<Scalars['String']['input']>
	type_contains?: InputMaybe<Scalars['String']['input']>
	type_exists?: InputMaybe<Scalars['Boolean']['input']>
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	type_not?: InputMaybe<Scalars['String']['input']>
	type_not_contains?: InputMaybe<Scalars['String']['input']>
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ThemePropertyLinkingCollections = {
	__typename?: 'ThemePropertyLinkingCollections'
	entryCollection?: Maybe<EntryCollection>
	marketplaceThemeCollection?: Maybe<MarketplaceThemeCollection>
}

export type ThemePropertyLinkingCollectionsEntryCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export type ThemePropertyLinkingCollectionsMarketplaceThemeCollectionArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<
		Array<InputMaybe<ThemePropertyLinkingCollectionsMarketplaceThemeCollectionOrder>>
	>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
}

export enum ThemePropertyLinkingCollectionsMarketplaceThemeCollectionOrder {
	ContactUrlAsc = 'contactUrl_ASC',
	ContactUrlDesc = 'contactUrl_DESC',
	DemoUrlAsc = 'demoUrl_ASC',
	DemoUrlDesc = 'demoUrl_DESC',
	DocumentationUrlAsc = 'documentationUrl_ASC',
	DocumentationUrlDesc = 'documentationUrl_DESC',
	ExternalIdAsc = 'externalId_ASC',
	ExternalIdDesc = 'externalId_DESC',
	FeaturedOrderAsc = 'featuredOrder_ASC',
	FeaturedOrderDesc = 'featuredOrder_DESC',
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	LargePreviewAsc = 'largePreview_ASC',
	LargePreviewDesc = 'largePreview_DESC',
	LargeThumbnailAsc = 'largeThumbnail_ASC',
	LargeThumbnailDesc = 'largeThumbnail_DESC',
	LastHashAsc = 'lastHash_ASC',
	LastHashDesc = 'lastHash_DESC',
	MobilePreviewAsc = 'mobilePreview_ASC',
	MobilePreviewDesc = 'mobilePreview_DESC',
	PartnerEmailAsc = 'partnerEmail_ASC',
	PartnerEmailDesc = 'partnerEmail_DESC',
	PartnerIdAsc = 'partnerId_ASC',
	PartnerIdDesc = 'partnerId_DESC',
	PartnerNameAsc = 'partnerName_ASC',
	PartnerNameDesc = 'partnerName_DESC',
	PriceAsc = 'price_ASC',
	PriceDesc = 'price_DESC',
	PublishedDateAsc = 'publishedDate_ASC',
	PublishedDateDesc = 'publishedDate_DESC',
	RegistryIdAsc = 'registryId_ASC',
	RegistryIdDesc = 'registryId_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SmallThumbnailAsc = 'smallThumbnail_ASC',
	SmallThumbnailDesc = 'smallThumbnail_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	ThemeDefaultConfigurationIdAsc = 'themeDefaultConfigurationId_ASC',
	ThemeDefaultConfigurationIdDesc = 'themeDefaultConfigurationId_DESC',
	ThemeIdAsc = 'themeId_ASC',
	ThemeIdDesc = 'themeId_DESC',
	TitleAsc = 'title_ASC',
	TitleDesc = 'title_DESC',
	VariationNameAsc = 'variationName_ASC',
	VariationNameDesc = 'variationName_DESC',
	VersionIdAsc = 'versionId_ASC',
	VersionIdDesc = 'versionId_DESC'
}

export enum ThemePropertyOrder {
	InternalNameAsc = 'internalName_ASC',
	InternalNameDesc = 'internalName_DESC',
	NameAsc = 'name_ASC',
	NameDesc = 'name_DESC',
	SlugAsc = 'slug_ASC',
	SlugDesc = 'slug_DESC',
	SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
	SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
	SysIdAsc = 'sys_id_ASC',
	SysIdDesc = 'sys_id_DESC',
	SysPublishedAtAsc = 'sys_publishedAt_ASC',
	SysPublishedAtDesc = 'sys_publishedAt_DESC',
	SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
	SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
	TypeAsc = 'type_ASC',
	TypeDesc = 'type_DESC'
}

export type _Node = {
	_id: Scalars['ID']['output']
}

export type CfAdditionalPersonOptionsNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfAdditionalPersonOptionsNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfAdditionalPersonOptionsNestedFilter>>>
	avatarOverride_exists?: InputMaybe<Scalars['Boolean']['input']>
	bioSummary_contains?: InputMaybe<Scalars['String']['input']>
	bioSummary_exists?: InputMaybe<Scalars['Boolean']['input']>
	bioSummary_not_contains?: InputMaybe<Scalars['String']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	fullBio_contains?: InputMaybe<Scalars['String']['input']>
	fullBio_exists?: InputMaybe<Scalars['Boolean']['input']>
	fullBio_not_contains?: InputMaybe<Scalars['String']['input']>
	heroBackground_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	intro_contains?: InputMaybe<Scalars['String']['input']>
	intro_exists?: InputMaybe<Scalars['Boolean']['input']>
	intro_not_contains?: InputMaybe<Scalars['String']['input']>
	relatedNewsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	sys?: InputMaybe<SysFilter>
}

export type CfAppCaseStudyNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfAppCaseStudyNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfAppCaseStudyNestedFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	url?: InputMaybe<Scalars['String']['input']>
	url_contains?: InputMaybe<Scalars['String']['input']>
	url_exists?: InputMaybe<Scalars['Boolean']['input']>
	url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	url_not?: InputMaybe<Scalars['String']['input']>
	url_not_contains?: InputMaybe<Scalars['String']['input']>
	url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfAppCollectionNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfAppCollectionNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfAppCollectionNestedFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description?: InputMaybe<Scalars['String']['input']>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	description_not?: InputMaybe<Scalars['String']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name?: InputMaybe<Scalars['String']['input']>
	name_contains?: InputMaybe<Scalars['String']['input']>
	name_exists?: InputMaybe<Scalars['Boolean']['input']>
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name_not?: InputMaybe<Scalars['String']['input']>
	name_not_contains?: InputMaybe<Scalars['String']['input']>
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	private?: InputMaybe<Scalars['Boolean']['input']>
	private_exists?: InputMaybe<Scalars['Boolean']['input']>
	private_not?: InputMaybe<Scalars['Boolean']['input']>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sort?: InputMaybe<Scalars['Int']['input']>
	sort_exists?: InputMaybe<Scalars['Boolean']['input']>
	sort_gt?: InputMaybe<Scalars['Int']['input']>
	sort_gte?: InputMaybe<Scalars['Int']['input']>
	sort_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	sort_lt?: InputMaybe<Scalars['Int']['input']>
	sort_lte?: InputMaybe<Scalars['Int']['input']>
	sort_not?: InputMaybe<Scalars['Int']['input']>
	sort_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	summary?: InputMaybe<Scalars['String']['input']>
	summary_contains?: InputMaybe<Scalars['String']['input']>
	summary_exists?: InputMaybe<Scalars['Boolean']['input']>
	summary_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	summary_not?: InputMaybe<Scalars['String']['input']>
	summary_not_contains?: InputMaybe<Scalars['String']['input']>
	summary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	thumbnail?: InputMaybe<Scalars['String']['input']>
	thumbnail_contains?: InputMaybe<Scalars['String']['input']>
	thumbnail_exists?: InputMaybe<Scalars['Boolean']['input']>
	thumbnail_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	thumbnail_not?: InputMaybe<Scalars['String']['input']>
	thumbnail_not_contains?: InputMaybe<Scalars['String']['input']>
	thumbnail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfAppFeatureNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfAppFeatureNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfAppFeatureNestedFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfAppTierNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfAppTierNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfAppTierNestedFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description?: InputMaybe<Scalars['String']['input']>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	description_not?: InputMaybe<Scalars['String']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	logo_exists?: InputMaybe<Scalars['Boolean']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	name_contains?: InputMaybe<Scalars['String']['input']>
	name_exists?: InputMaybe<Scalars['Boolean']['input']>
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name_not?: InputMaybe<Scalars['String']['input']>
	name_not_contains?: InputMaybe<Scalars['String']['input']>
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sort?: InputMaybe<Scalars['Int']['input']>
	sort_exists?: InputMaybe<Scalars['Boolean']['input']>
	sort_gt?: InputMaybe<Scalars['Int']['input']>
	sort_gte?: InputMaybe<Scalars['Int']['input']>
	sort_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	sort_lt?: InputMaybe<Scalars['Int']['input']>
	sort_lte?: InputMaybe<Scalars['Int']['input']>
	sort_not?: InputMaybe<Scalars['Int']['input']>
	sort_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type CfArticleNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfArticleNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfArticleNestedFilter>>>
	additionalResourcesOverridesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	author_exists?: InputMaybe<Scalars['Boolean']['input']>
	body_contains?: InputMaybe<Scalars['String']['input']>
	body_exists?: InputMaybe<Scalars['Boolean']['input']>
	body_not_contains?: InputMaybe<Scalars['String']['input']>
	cardSummary?: InputMaybe<Scalars['String']['input']>
	cardSummary_contains?: InputMaybe<Scalars['String']['input']>
	cardSummary_exists?: InputMaybe<Scalars['Boolean']['input']>
	cardSummary_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	cardSummary_not?: InputMaybe<Scalars['String']['input']>
	cardSummary_not_contains?: InputMaybe<Scalars['String']['input']>
	cardSummary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	editStatus?: InputMaybe<Scalars['String']['input']>
	editStatus_contains?: InputMaybe<Scalars['String']['input']>
	editStatus_exists?: InputMaybe<Scalars['Boolean']['input']>
	editStatus_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	editStatus_not?: InputMaybe<Scalars['String']['input']>
	editStatus_not_contains?: InputMaybe<Scalars['String']['input']>
	editStatus_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	faqsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	faqsHeading?: InputMaybe<Scalars['String']['input']>
	faqsHeading_contains?: InputMaybe<Scalars['String']['input']>
	faqsHeading_exists?: InputMaybe<Scalars['Boolean']['input']>
	faqsHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	faqsHeading_not?: InputMaybe<Scalars['String']['input']>
	faqsHeading_not_contains?: InputMaybe<Scalars['String']['input']>
	faqsHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredImage_exists?: InputMaybe<Scalars['Boolean']['input']>
	gatedContentSettings_exists?: InputMaybe<Scalars['Boolean']['input']>
	hero_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	interstitialCta_exists?: InputMaybe<Scalars['Boolean']['input']>
	interstitialHeadline?: InputMaybe<Scalars['String']['input']>
	interstitialHeadline_contains?: InputMaybe<Scalars['String']['input']>
	interstitialHeadline_exists?: InputMaybe<Scalars['Boolean']['input']>
	interstitialHeadline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	interstitialHeadline_not?: InputMaybe<Scalars['String']['input']>
	interstitialHeadline_not_contains?: InputMaybe<Scalars['String']['input']>
	interstitialHeadline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	parentArticle_exists?: InputMaybe<Scalars['Boolean']['input']>
	popular?: InputMaybe<Scalars['Boolean']['input']>
	popular_exists?: InputMaybe<Scalars['Boolean']['input']>
	popular_not?: InputMaybe<Scalars['Boolean']['input']>
	publishedDate?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_exists?: InputMaybe<Scalars['Boolean']['input']>
	publishedDate_gt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_gte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	publishedDate_lt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_lte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	secondaryCtasCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	secondaryHero_exists?: InputMaybe<Scalars['Boolean']['input']>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	summary_contains?: InputMaybe<Scalars['String']['input']>
	summary_exists?: InputMaybe<Scalars['Boolean']['input']>
	summary_not_contains?: InputMaybe<Scalars['String']['input']>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfAssetExternalNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfAssetExternalNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfAssetExternalNestedFilter>>>
	altText?: InputMaybe<Scalars['String']['input']>
	altText_contains?: InputMaybe<Scalars['String']['input']>
	altText_exists?: InputMaybe<Scalars['Boolean']['input']>
	altText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	altText_not?: InputMaybe<Scalars['String']['input']>
	altText_not_contains?: InputMaybe<Scalars['String']['input']>
	altText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	assetType?: InputMaybe<Scalars['String']['input']>
	assetType_contains?: InputMaybe<Scalars['String']['input']>
	assetType_exists?: InputMaybe<Scalars['Boolean']['input']>
	assetType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	assetType_not?: InputMaybe<Scalars['String']['input']>
	assetType_not_contains?: InputMaybe<Scalars['String']['input']>
	assetType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	url?: InputMaybe<Scalars['String']['input']>
	url_contains?: InputMaybe<Scalars['String']['input']>
	url_exists?: InputMaybe<Scalars['Boolean']['input']>
	url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	url_not?: InputMaybe<Scalars['String']['input']>
	url_not_contains?: InputMaybe<Scalars['String']['input']>
	url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfBackgroundNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfBackgroundNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfBackgroundNestedFilter>>>
	backgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	gradient?: InputMaybe<Scalars['String']['input']>
	gradient_contains?: InputMaybe<Scalars['String']['input']>
	gradient_exists?: InputMaybe<Scalars['Boolean']['input']>
	gradient_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	gradient_not?: InputMaybe<Scalars['String']['input']>
	gradient_not_contains?: InputMaybe<Scalars['String']['input']>
	gradient_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	solidColor?: InputMaybe<Scalars['String']['input']>
	solidColor_contains?: InputMaybe<Scalars['String']['input']>
	solidColor_exists?: InputMaybe<Scalars['Boolean']['input']>
	solidColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	solidColor_not?: InputMaybe<Scalars['String']['input']>
	solidColor_not_contains?: InputMaybe<Scalars['String']['input']>
	solidColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	textColor?: InputMaybe<Scalars['String']['input']>
	textColor_contains?: InputMaybe<Scalars['String']['input']>
	textColor_exists?: InputMaybe<Scalars['Boolean']['input']>
	textColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	textColor_not?: InputMaybe<Scalars['String']['input']>
	textColor_not_contains?: InputMaybe<Scalars['String']['input']>
	textColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfBasicContentCardNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfBasicContentCardNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfBasicContentCardNestedFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	ctaButtonCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	description?: InputMaybe<Scalars['String']['input']>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	description_not?: InputMaybe<Scalars['String']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	overline?: InputMaybe<Scalars['String']['input']>
	overline_contains?: InputMaybe<Scalars['String']['input']>
	overline_exists?: InputMaybe<Scalars['Boolean']['input']>
	overline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	overline_not?: InputMaybe<Scalars['String']['input']>
	overline_not_contains?: InputMaybe<Scalars['String']['input']>
	overline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	theme?: InputMaybe<Scalars['String']['input']>
	theme_contains?: InputMaybe<Scalars['String']['input']>
	theme_exists?: InputMaybe<Scalars['Boolean']['input']>
	theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	theme_not?: InputMaybe<Scalars['String']['input']>
	theme_not_contains?: InputMaybe<Scalars['String']['input']>
	theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfCaseStudyNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfCaseStudyNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfCaseStudyNestedFilter>>>
	caseStudyStatsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	competitorsListCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	extraSection_contains?: InputMaybe<Scalars['String']['input']>
	extraSection_exists?: InputMaybe<Scalars['Boolean']['input']>
	extraSection_not_contains?: InputMaybe<Scalars['String']['input']>
	featuredAuthorName?: InputMaybe<Scalars['String']['input']>
	featuredAuthorName_contains?: InputMaybe<Scalars['String']['input']>
	featuredAuthorName_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredAuthorName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredAuthorName_not?: InputMaybe<Scalars['String']['input']>
	featuredAuthorName_not_contains?: InputMaybe<Scalars['String']['input']>
	featuredAuthorName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredAuthorTitle?: InputMaybe<Scalars['String']['input']>
	featuredAuthorTitle_contains?: InputMaybe<Scalars['String']['input']>
	featuredAuthorTitle_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredAuthorTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredAuthorTitle_not?: InputMaybe<Scalars['String']['input']>
	featuredAuthorTitle_not_contains?: InputMaybe<Scalars['String']['input']>
	featuredAuthorTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredContent_contains?: InputMaybe<Scalars['String']['input']>
	featuredContent_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredContent_not_contains?: InputMaybe<Scalars['String']['input']>
	featuredImage_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredLinkText?: InputMaybe<Scalars['String']['input']>
	featuredLinkText_contains?: InputMaybe<Scalars['String']['input']>
	featuredLinkText_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredLinkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredLinkText_not?: InputMaybe<Scalars['String']['input']>
	featuredLinkText_not_contains?: InputMaybe<Scalars['String']['input']>
	featuredLinkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	featuredLogo_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredOrder?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredOrder_gt?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_gte?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	featuredOrder_lt?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_lte?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_not?: InputMaybe<Scalars['Int']['input']>
	featuredOrder_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	featuredVideo_exists?: InputMaybe<Scalars['Boolean']['input']>
	hero_exists?: InputMaybe<Scalars['Boolean']['input']>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	industriesListCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	publishedDate?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_exists?: InputMaybe<Scalars['Boolean']['input']>
	publishedDate_gt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_gte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	publishedDate_lt?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_lte?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not?: InputMaybe<Scalars['DateTime']['input']>
	publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
	quoteImage_exists?: InputMaybe<Scalars['Boolean']['input']>
	quote_contains?: InputMaybe<Scalars['String']['input']>
	quote_exists?: InputMaybe<Scalars['Boolean']['input']>
	quote_not_contains?: InputMaybe<Scalars['String']['input']>
	relatedCaseStudiesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	section1_contains?: InputMaybe<Scalars['String']['input']>
	section1_exists?: InputMaybe<Scalars['Boolean']['input']>
	section1_not_contains?: InputMaybe<Scalars['String']['input']>
	section2_contains?: InputMaybe<Scalars['String']['input']>
	section2_exists?: InputMaybe<Scalars['Boolean']['input']>
	section2_not_contains?: InputMaybe<Scalars['String']['input']>
	section3_contains?: InputMaybe<Scalars['String']['input']>
	section3_exists?: InputMaybe<Scalars['Boolean']['input']>
	section3_not_contains?: InputMaybe<Scalars['String']['input']>
	section4_contains?: InputMaybe<Scalars['String']['input']>
	section4_exists?: InputMaybe<Scalars['Boolean']['input']>
	section4_not_contains?: InputMaybe<Scalars['String']['input']>
	section5_contains?: InputMaybe<Scalars['String']['input']>
	section5_exists?: InputMaybe<Scalars['Boolean']['input']>
	section5_not_contains?: InputMaybe<Scalars['String']['input']>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	statsDescription_contains?: InputMaybe<Scalars['String']['input']>
	statsDescription_exists?: InputMaybe<Scalars['Boolean']['input']>
	statsDescription_not_contains?: InputMaybe<Scalars['String']['input']>
	statsSubtitle_contains?: InputMaybe<Scalars['String']['input']>
	statsSubtitle_exists?: InputMaybe<Scalars['Boolean']['input']>
	statsSubtitle_not_contains?: InputMaybe<Scalars['String']['input']>
	statsTitle_contains?: InputMaybe<Scalars['String']['input']>
	statsTitle_exists?: InputMaybe<Scalars['Boolean']['input']>
	statsTitle_not_contains?: InputMaybe<Scalars['String']['input']>
	status?: InputMaybe<Scalars['Boolean']['input']>
	status_exists?: InputMaybe<Scalars['Boolean']['input']>
	status_not?: InputMaybe<Scalars['Boolean']['input']>
	summary_contains?: InputMaybe<Scalars['String']['input']>
	summary_exists?: InputMaybe<Scalars['Boolean']['input']>
	summary_not_contains?: InputMaybe<Scalars['String']['input']>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	topicsListCollection_exists?: InputMaybe<Scalars['Boolean']['input']>
	whitepaperStandin?: InputMaybe<Scalars['Boolean']['input']>
	whitepaperStandin_exists?: InputMaybe<Scalars['Boolean']['input']>
	whitepaperStandin_not?: InputMaybe<Scalars['Boolean']['input']>
}

export type CfCaseStudyPropertyNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfCaseStudyPropertyNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfCaseStudyPropertyNestedFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	name?: InputMaybe<Scalars['String']['input']>
	name_contains?: InputMaybe<Scalars['String']['input']>
	name_exists?: InputMaybe<Scalars['Boolean']['input']>
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name_not?: InputMaybe<Scalars['String']['input']>
	name_not_contains?: InputMaybe<Scalars['String']['input']>
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	type?: InputMaybe<Scalars['String']['input']>
	type_contains?: InputMaybe<Scalars['String']['input']>
	type_exists?: InputMaybe<Scalars['Boolean']['input']>
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	type_not?: InputMaybe<Scalars['String']['input']>
	type_not_contains?: InputMaybe<Scalars['String']['input']>
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfCategoryNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfCategoryNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfCategoryNestedFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	name?: InputMaybe<Scalars['String']['input']>
	name_contains?: InputMaybe<Scalars['String']['input']>
	name_exists?: InputMaybe<Scalars['Boolean']['input']>
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name_not?: InputMaybe<Scalars['String']['input']>
	name_not_contains?: InputMaybe<Scalars['String']['input']>
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	parentCategory_exists?: InputMaybe<Scalars['Boolean']['input']>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type CfCtaButtonNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfCtaButtonNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfCtaButtonNestedFilter>>>
	color?: InputMaybe<Scalars['String']['input']>
	color_contains?: InputMaybe<Scalars['String']['input']>
	color_exists?: InputMaybe<Scalars['Boolean']['input']>
	color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	color_not?: InputMaybe<Scalars['String']['input']>
	color_not_contains?: InputMaybe<Scalars['String']['input']>
	color_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	displayText?: InputMaybe<Scalars['String']['input']>
	displayText_contains?: InputMaybe<Scalars['String']['input']>
	displayText_exists?: InputMaybe<Scalars['Boolean']['input']>
	displayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	displayText_not?: InputMaybe<Scalars['String']['input']>
	displayText_not_contains?: InputMaybe<Scalars['String']['input']>
	displayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	formId?: InputMaybe<Scalars['String']['input']>
	formId_contains?: InputMaybe<Scalars['String']['input']>
	formId_exists?: InputMaybe<Scalars['Boolean']['input']>
	formId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	formId_not?: InputMaybe<Scalars['String']['input']>
	formId_not_contains?: InputMaybe<Scalars['String']['input']>
	formId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	link_exists?: InputMaybe<Scalars['Boolean']['input']>
	openInNewTab?: InputMaybe<Scalars['Boolean']['input']>
	openInNewTab_exists?: InputMaybe<Scalars['Boolean']['input']>
	openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>
	sys?: InputMaybe<SysFilter>
	variant?: InputMaybe<Scalars['String']['input']>
	variant_contains?: InputMaybe<Scalars['String']['input']>
	variant_exists?: InputMaybe<Scalars['Boolean']['input']>
	variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	variant_not?: InputMaybe<Scalars['String']['input']>
	variant_not_contains?: InputMaybe<Scalars['String']['input']>
	variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfCtaMatPatternNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfCtaMatPatternNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfCtaMatPatternNestedFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	ctaText?: InputMaybe<Scalars['String']['input']>
	ctaText_contains?: InputMaybe<Scalars['String']['input']>
	ctaText_exists?: InputMaybe<Scalars['Boolean']['input']>
	ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	ctaText_not?: InputMaybe<Scalars['String']['input']>
	ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
	ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	ctaUrl?: InputMaybe<Scalars['String']['input']>
	ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
	ctaUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	ctaUrl_not?: InputMaybe<Scalars['String']['input']>
	ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	enabled?: InputMaybe<Scalars['Boolean']['input']>
	enabled_exists?: InputMaybe<Scalars['Boolean']['input']>
	enabled_not?: InputMaybe<Scalars['Boolean']['input']>
	heading?: InputMaybe<Scalars['String']['input']>
	heading_contains?: InputMaybe<Scalars['String']['input']>
	heading_exists?: InputMaybe<Scalars['Boolean']['input']>
	heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	heading_not?: InputMaybe<Scalars['String']['input']>
	heading_not_contains?: InputMaybe<Scalars['String']['input']>
	heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	subheading?: InputMaybe<Scalars['String']['input']>
	subheading_contains?: InputMaybe<Scalars['String']['input']>
	subheading_exists?: InputMaybe<Scalars['Boolean']['input']>
	subheading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	subheading_not?: InputMaybe<Scalars['String']['input']>
	subheading_not_contains?: InputMaybe<Scalars['String']['input']>
	subheading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type CfExternalLinkNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfExternalLinkNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfExternalLinkNestedFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	url?: InputMaybe<Scalars['String']['input']>
	url_contains?: InputMaybe<Scalars['String']['input']>
	url_exists?: InputMaybe<Scalars['Boolean']['input']>
	url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	url_not?: InputMaybe<Scalars['String']['input']>
	url_not_contains?: InputMaybe<Scalars['String']['input']>
	url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfFaqItemNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfFaqItemNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfFaqItemNestedFilter>>>
	answer_contains?: InputMaybe<Scalars['String']['input']>
	answer_exists?: InputMaybe<Scalars['Boolean']['input']>
	answer_not_contains?: InputMaybe<Scalars['String']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	question?: InputMaybe<Scalars['String']['input']>
	question_contains?: InputMaybe<Scalars['String']['input']>
	question_exists?: InputMaybe<Scalars['Boolean']['input']>
	question_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	question_not?: InputMaybe<Scalars['String']['input']>
	question_not_contains?: InputMaybe<Scalars['String']['input']>
	question_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type CfGatedContentNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfGatedContentNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfGatedContentNestedFilter>>>
	calloutDescription?: InputMaybe<Scalars['String']['input']>
	calloutDescription_contains?: InputMaybe<Scalars['String']['input']>
	calloutDescription_exists?: InputMaybe<Scalars['Boolean']['input']>
	calloutDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	calloutDescription_not?: InputMaybe<Scalars['String']['input']>
	calloutDescription_not_contains?: InputMaybe<Scalars['String']['input']>
	calloutDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	calloutTitle?: InputMaybe<Scalars['String']['input']>
	calloutTitle_contains?: InputMaybe<Scalars['String']['input']>
	calloutTitle_exists?: InputMaybe<Scalars['Boolean']['input']>
	calloutTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	calloutTitle_not?: InputMaybe<Scalars['String']['input']>
	calloutTitle_not_contains?: InputMaybe<Scalars['String']['input']>
	calloutTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	gatedAsset_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	providePrintVersion?: InputMaybe<Scalars['Boolean']['input']>
	providePrintVersion_exists?: InputMaybe<Scalars['Boolean']['input']>
	providePrintVersion_not?: InputMaybe<Scalars['Boolean']['input']>
	sys?: InputMaybe<SysFilter>
}

export type CfHeroNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfHeroNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfHeroNestedFilter>>>
	backgroundOptions_exists?: InputMaybe<Scalars['Boolean']['input']>
	bcLogo?: InputMaybe<Scalars['Boolean']['input']>
	bcLogo_exists?: InputMaybe<Scalars['Boolean']['input']>
	bcLogo_not?: InputMaybe<Scalars['Boolean']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	cta_exists?: InputMaybe<Scalars['Boolean']['input']>
	featuredAsset_exists?: InputMaybe<Scalars['Boolean']['input']>
	headline?: InputMaybe<Scalars['String']['input']>
	headline_contains?: InputMaybe<Scalars['String']['input']>
	headline_exists?: InputMaybe<Scalars['Boolean']['input']>
	headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	headline_not?: InputMaybe<Scalars['String']['input']>
	headline_not_contains?: InputMaybe<Scalars['String']['input']>
	headline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	subheading_contains?: InputMaybe<Scalars['String']['input']>
	subheading_exists?: InputMaybe<Scalars['Boolean']['input']>
	subheading_not_contains?: InputMaybe<Scalars['String']['input']>
	sys?: InputMaybe<SysFilter>
}

export type CfKeyValueNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfKeyValueNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfKeyValueNestedFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	key?: InputMaybe<Scalars['String']['input']>
	key_contains?: InputMaybe<Scalars['String']['input']>
	key_exists?: InputMaybe<Scalars['Boolean']['input']>
	key_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	key_not?: InputMaybe<Scalars['String']['input']>
	key_not_contains?: InputMaybe<Scalars['String']['input']>
	key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	value?: InputMaybe<Scalars['String']['input']>
	value_contains?: InputMaybe<Scalars['String']['input']>
	value_exists?: InputMaybe<Scalars['Boolean']['input']>
	value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	value_not?: InputMaybe<Scalars['String']['input']>
	value_not_contains?: InputMaybe<Scalars['String']['input']>
	value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfPersonNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfPersonNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfPersonNestedFilter>>>
	additionalOptions_exists?: InputMaybe<Scalars['Boolean']['input']>
	avatar_exists?: InputMaybe<Scalars['Boolean']['input']>
	bio_contains?: InputMaybe<Scalars['String']['input']>
	bio_exists?: InputMaybe<Scalars['Boolean']['input']>
	bio_not_contains?: InputMaybe<Scalars['String']['input']>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	email?: InputMaybe<Scalars['String']['input']>
	email_contains?: InputMaybe<Scalars['String']['input']>
	email_exists?: InputMaybe<Scalars['Boolean']['input']>
	email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	email_not?: InputMaybe<Scalars['String']['input']>
	email_not_contains?: InputMaybe<Scalars['String']['input']>
	email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	enabled?: InputMaybe<Scalars['Boolean']['input']>
	enabled_exists?: InputMaybe<Scalars['Boolean']['input']>
	enabled_not?: InputMaybe<Scalars['Boolean']['input']>
	fullName?: InputMaybe<Scalars['String']['input']>
	fullName_contains?: InputMaybe<Scalars['String']['input']>
	fullName_exists?: InputMaybe<Scalars['Boolean']['input']>
	fullName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	fullName_not?: InputMaybe<Scalars['String']['input']>
	fullName_not_contains?: InputMaybe<Scalars['String']['input']>
	fullName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	fullSizePhoto_exists?: InputMaybe<Scalars['Boolean']['input']>
	jobTitle?: InputMaybe<Scalars['String']['input']>
	jobTitle_contains?: InputMaybe<Scalars['String']['input']>
	jobTitle_exists?: InputMaybe<Scalars['Boolean']['input']>
	jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	jobTitle_not?: InputMaybe<Scalars['String']['input']>
	jobTitle_not_contains?: InputMaybe<Scalars['String']['input']>
	jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	linkedUrl?: InputMaybe<Scalars['String']['input']>
	linkedUrl_contains?: InputMaybe<Scalars['String']['input']>
	linkedUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	linkedUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	linkedUrl_not?: InputMaybe<Scalars['String']['input']>
	linkedUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	linkedUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	type?: InputMaybe<Scalars['String']['input']>
	type_contains?: InputMaybe<Scalars['String']['input']>
	type_exists?: InputMaybe<Scalars['Boolean']['input']>
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	type_not?: InputMaybe<Scalars['String']['input']>
	type_not_contains?: InputMaybe<Scalars['String']['input']>
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfSeoMetadataNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfSeoMetadataNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfSeoMetadataNestedFilter>>>
	canonicalUrl?: InputMaybe<Scalars['String']['input']>
	canonicalUrl_contains?: InputMaybe<Scalars['String']['input']>
	canonicalUrl_exists?: InputMaybe<Scalars['Boolean']['input']>
	canonicalUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	canonicalUrl_not?: InputMaybe<Scalars['String']['input']>
	canonicalUrl_not_contains?: InputMaybe<Scalars['String']['input']>
	canonicalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description?: InputMaybe<Scalars['String']['input']>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	description_not?: InputMaybe<Scalars['String']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	includeTitleTemplate?: InputMaybe<Scalars['Boolean']['input']>
	includeTitleTemplate_exists?: InputMaybe<Scalars['Boolean']['input']>
	includeTitleTemplate_not?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	nofollow?: InputMaybe<Scalars['Boolean']['input']>
	nofollow_exists?: InputMaybe<Scalars['Boolean']['input']>
	nofollow_not?: InputMaybe<Scalars['Boolean']['input']>
	noindex?: InputMaybe<Scalars['Boolean']['input']>
	noindex_exists?: InputMaybe<Scalars['Boolean']['input']>
	noindex_not?: InputMaybe<Scalars['Boolean']['input']>
	readingTime?: InputMaybe<Scalars['String']['input']>
	readingTime_contains?: InputMaybe<Scalars['String']['input']>
	readingTime_exists?: InputMaybe<Scalars['Boolean']['input']>
	readingTime_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	readingTime_not?: InputMaybe<Scalars['String']['input']>
	readingTime_not_contains?: InputMaybe<Scalars['String']['input']>
	readingTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfSpeakerCardNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfSpeakerCardNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfSpeakerCardNestedFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	description?: InputMaybe<Scalars['String']['input']>
	description_contains?: InputMaybe<Scalars['String']['input']>
	description_exists?: InputMaybe<Scalars['Boolean']['input']>
	description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	description_not?: InputMaybe<Scalars['String']['input']>
	description_not_contains?: InputMaybe<Scalars['String']['input']>
	description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	image_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	jobTitle?: InputMaybe<Scalars['String']['input']>
	jobTitle_contains?: InputMaybe<Scalars['String']['input']>
	jobTitle_exists?: InputMaybe<Scalars['Boolean']['input']>
	jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	jobTitle_not?: InputMaybe<Scalars['String']['input']>
	jobTitle_not_contains?: InputMaybe<Scalars['String']['input']>
	jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfTagNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfTagNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfTagNestedFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	name?: InputMaybe<Scalars['String']['input']>
	name_contains?: InputMaybe<Scalars['String']['input']>
	name_exists?: InputMaybe<Scalars['Boolean']['input']>
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name_not?: InputMaybe<Scalars['String']['input']>
	name_not_contains?: InputMaybe<Scalars['String']['input']>
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
}

export type CfThemePropertyNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfThemePropertyNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfThemePropertyNestedFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name?: InputMaybe<Scalars['String']['input']>
	name_contains?: InputMaybe<Scalars['String']['input']>
	name_exists?: InputMaybe<Scalars['Boolean']['input']>
	name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	name_not?: InputMaybe<Scalars['String']['input']>
	name_not_contains?: InputMaybe<Scalars['String']['input']>
	name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug?: InputMaybe<Scalars['String']['input']>
	slug_contains?: InputMaybe<Scalars['String']['input']>
	slug_exists?: InputMaybe<Scalars['Boolean']['input']>
	slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	slug_not?: InputMaybe<Scalars['String']['input']>
	slug_not_contains?: InputMaybe<Scalars['String']['input']>
	slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	type?: InputMaybe<Scalars['String']['input']>
	type_contains?: InputMaybe<Scalars['String']['input']>
	type_exists?: InputMaybe<Scalars['Boolean']['input']>
	type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	type_not?: InputMaybe<Scalars['String']['input']>
	type_not_contains?: InputMaybe<Scalars['String']['input']>
	type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CfrecommendedContentMultiTypeNestedFilter = {
	AND?: InputMaybe<Array<InputMaybe<CfrecommendedContentMultiTypeNestedFilter>>>
	OR?: InputMaybe<Array<InputMaybe<CfrecommendedContentMultiTypeNestedFilter>>>
	contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
	internalName?: InputMaybe<Scalars['String']['input']>
	internalName_contains?: InputMaybe<Scalars['String']['input']>
	internalName_exists?: InputMaybe<Scalars['Boolean']['input']>
	internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	internalName_not?: InputMaybe<Scalars['String']['input']>
	internalName_not_contains?: InputMaybe<Scalars['String']['input']>
	internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	sys?: InputMaybe<SysFilter>
	title?: InputMaybe<Scalars['String']['input']>
	title_contains?: InputMaybe<Scalars['String']['input']>
	title_exists?: InputMaybe<Scalars['Boolean']['input']>
	title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
	title_not?: InputMaybe<Scalars['String']['input']>
	title_not_contains?: InputMaybe<Scalars['String']['input']>
	title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
	__typename?: '__EnumValue'
	name: Scalars['String']['output']
	description?: Maybe<Scalars['String']['output']>
	isDeprecated: Scalars['Boolean']['output']
	deprecationReason?: Maybe<Scalars['String']['output']>
}

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
	__typename?: '__Field'
	name: Scalars['String']['output']
	description?: Maybe<Scalars['String']['output']>
	args: Array<__InputValue>
	type: __Type
	isDeprecated: Scalars['Boolean']['output']
	deprecationReason?: Maybe<Scalars['String']['output']>
}

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
	includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>
}

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
	__typename?: '__InputValue'
	name: Scalars['String']['output']
	description?: Maybe<Scalars['String']['output']>
	type: __Type
	/** A GraphQL-formatted string representing the default value for this input value. */
	defaultValue?: Maybe<Scalars['String']['output']>
	isDeprecated: Scalars['Boolean']['output']
	deprecationReason?: Maybe<Scalars['String']['output']>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
	__typename?: '__Type'
	kind: __TypeKind
	name?: Maybe<Scalars['String']['output']>
	description?: Maybe<Scalars['String']['output']>
	specifiedByURL?: Maybe<Scalars['String']['output']>
	fields?: Maybe<Array<__Field>>
	interfaces?: Maybe<Array<__Type>>
	possibleTypes?: Maybe<Array<__Type>>
	enumValues?: Maybe<Array<__EnumValue>>
	inputFields?: Maybe<Array<__InputValue>>
	ofType?: Maybe<__Type>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
	includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
	includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
	includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>
}

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
	/** Indicates this type is a scalar. */
	Scalar = 'SCALAR',
	/** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
	Object = 'OBJECT',
	/** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
	Interface = 'INTERFACE',
	/** Indicates this type is a union. `possibleTypes` is a valid field. */
	Union = 'UNION',
	/** Indicates this type is an enum. `enumValues` is a valid field. */
	Enum = 'ENUM',
	/** Indicates this type is an input object. `inputFields` is a valid field. */
	InputObject = 'INPUT_OBJECT',
	/** Indicates this type is a list. `ofType` is a valid field. */
	List = 'LIST',
	/** Indicates this type is a non-null. `ofType` is a valid field. */
	NonNull = 'NON_NULL'
}

export const TypeFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Type' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: '__Type' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'fields' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'type' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ofType' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<TypeFragment, unknown>
export const ArticleBreadcrumbsFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleBreadcrumbs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentArticle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'parentArticle' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'parentArticle' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'parentArticle' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'parentArticle' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
																				{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleBreadcrumbsFragment, unknown>
export const ArticleAssetFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleAssetFragment, unknown>
export const RichTextCalloutFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'RichTextCallout' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextWrapper' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		}
	]
} as unknown as DocumentNode<RichTextCalloutFragment, unknown>
export const ArticleAssetExternalFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleAssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleAssetExternalFragment, unknown>
export const ArticleExternalLinkFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleExternalLink' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExternalLink' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleExternalLinkFragment, unknown>
export const BasicContentCardFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BasicContentCard' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicContentCard' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaButtonCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'link' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleExternalLink' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleExternalLink' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExternalLink' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }
				]
			}
		}
	]
} as unknown as DocumentNode<BasicContentCardFragment, unknown>
export const ArticleBodyLinksFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleBodyLinks' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleBody' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'links' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleAsset' }
														}
													]
												}
											}
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'entries' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'RichTextCallout' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleAssetExternal' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BasicContentCard' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleExternalLink' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExternalLink' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'RichTextCallout' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextWrapper' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleAssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BasicContentCard' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicContentCard' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaButtonCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'link' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleExternalLink' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleBodyLinksFragment, unknown>
export const ArticleRichTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleBodyLinks' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subheading' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'bio' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'RichTextCallout' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextWrapper' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleAssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleExternalLink' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExternalLink' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BasicContentCard' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicContentCard' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaButtonCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'link' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleExternalLink' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleBodyLinks' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleBody' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'links' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleAsset' }
														}
													]
												}
											}
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'entries' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'RichTextCallout' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleAssetExternal' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BasicContentCard' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleRichTextFragment, unknown>
export const ImageAssetFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ImageAssetFragment, unknown>
export const ArticleImageFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'headline' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleImageFragment, unknown>
export const ArticlePdfDownloadFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticlePDFDownload' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'gatedContentSettings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'calloutTitle' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'calloutDescription' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gatedAsset' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'providePrintVersion' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ArticlePdfDownloadFragment, unknown>
export const ArticleFeedCarouselFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleFeedCarousel' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'cardSummary' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentArticle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'editStatus' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleFeedCarouselFragment, unknown>
export const ArticleFaqAccordionsFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleFAQAccordions' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'faqsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'question' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'answer' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleFaqAccordionsFragment, unknown>
export const ArticleInterstitialFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleInterstitial' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'interstitialHeadline' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'interstitialCta' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'link' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'ArticleExternalLink' }
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleExternalLink' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExternalLink' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleInterstitialFragment, unknown>
export const ArticleStatusFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleStatus' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_US' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_GB' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_AU' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_it_IT' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_nl_NL' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_fr_FR' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_es_ES' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_de_DE' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_es_MX' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleStatusFragment, unknown>
export const LocalizedArticleSlugFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'LocalizedArticleSlug' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<LocalizedArticleSlugFragment, unknown>
export const SeoMetadataFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<SeoMetadataFragmentFragment, unknown>
export const ArticleMetaFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'avatar' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleStatus' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LocalizedArticleSlug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentArticle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LocalizedArticleSlug' } }
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleStatus' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_US' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_GB' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_AU' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_it_IT' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_nl_NL' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_fr_FR' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_es_ES' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_de_DE' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_es_MX' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'LocalizedArticleSlug' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleMetaFragment, unknown>
export const ArticleSecondaryCtAsFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleSecondaryCTAs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'secondaryCtasCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ctaButtonCollection' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'items' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'openInNewTab' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'formId' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'link' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'InlineFragment',
																					typeCondition: {
																						kind: 'NamedType',
																						name: { kind: 'Name', value: 'ExternalLink' }
																					},
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections: [
																							{
																								kind: 'Field',
																								name: { kind: 'Name', value: '__typename' }
																							},
																							{
																								kind: 'Field',
																								name: { kind: 'Name', value: 'url' }
																							}
																						]
																					}
																				}
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleSecondaryCtAsFragment, unknown>
export const ArticleFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Article' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleBreadcrumbs' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleRichText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleImage' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticlePDFDownload' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleFeedCarousel' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleFAQAccordions' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleInterstitial' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleMeta' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleSecondaryCTAs' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'RichTextCallout' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextWrapper' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleAssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleExternalLink' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExternalLink' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BasicContentCard' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicContentCard' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaButtonCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'link' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleExternalLink' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleBodyLinks' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleBody' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'links' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleAsset' }
														}
													]
												}
											}
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'entries' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'RichTextCallout' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleAssetExternal' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BasicContentCard' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleStatus' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_US' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_GB' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_AU' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_it_IT' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_nl_NL' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_fr_FR' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_es_ES' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_de_DE' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_es_MX' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'LocalizedArticleSlug' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleBreadcrumbs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentArticle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'parentArticle' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'parentArticle' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'parentArticle' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'parentArticle' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
																				{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleBodyLinks' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subheading' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'bio' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'headline' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticlePDFDownload' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'gatedContentSettings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'calloutTitle' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'calloutDescription' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gatedAsset' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'providePrintVersion' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleFeedCarousel' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'cardSummary' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentArticle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'editStatus' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleFAQAccordions' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'faqsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'question' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'answer' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleInterstitial' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'interstitialHeadline' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'interstitialCta' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'link' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'ArticleExternalLink' }
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'avatar' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleStatus' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LocalizedArticleSlug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentArticle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LocalizedArticleSlug' } }
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleSecondaryCTAs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'secondaryCtasCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ctaButtonCollection' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'items' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'openInNewTab' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'formId' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'link' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'InlineFragment',
																					typeCondition: {
																						kind: 'NamedType',
																						name: { kind: 'Name', value: 'ExternalLink' }
																					},
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections: [
																							{
																								kind: 'Field',
																								name: { kind: 'Name', value: '__typename' }
																							},
																							{
																								kind: 'Field',
																								name: { kind: 'Name', value: 'url' }
																							}
																						]
																					}
																				}
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleFragment, unknown>
export const ArticleChildrenCarouselFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleChildrenCarousel' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'cardSummary' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentArticle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleChildrenCarouselFragment, unknown>
export const ArticleTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'cardSummary' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'headline' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleTextFragment, unknown>
export const CategoryBreadcrumbsFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CategoryBreadcrumbs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentCategory' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'parentCategory' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'parentCategory' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CategoryBreadcrumbsFragment, unknown>
export const BlogPostBreadcrumbsFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostBreadcrumbs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'CategoryBreadcrumbs' }
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CategoryBreadcrumbs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentCategory' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'parentCategory' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'parentCategory' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostBreadcrumbsFragment, unknown>
export const BlogPostCategoriesFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostCategories' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostCategoriesFragment, unknown>
export const BlogPostMetaFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'avatar' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'tagsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostMetaFragment, unknown>
export const BlogPostPdfDownloadFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostPDFDownload' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'gatedContentSettings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'calloutTitle' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'calloutDescription' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gatedAsset' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'providePrintVersion' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostPdfDownloadFragment, unknown>
export const BlogPostAssetFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostAssetFragment, unknown>
export const BlogPostAssetExternalFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostAssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostAssetExternalFragment, unknown>
export const BlogPostBodyLinksFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostBodyLinks' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPostBody' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'links' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BlogPostAsset' }
														}
													]
												}
											}
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'entries' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'RichTextCallout' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BlogPostAssetExternal' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'RichTextCallout' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextWrapper' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostAssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostBodyLinksFragment, unknown>
export const BlogPostRichTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostBodyLinks' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'bio' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'RichTextCallout' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextWrapper' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostAssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostBodyLinks' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPostBody' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'links' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BlogPostAsset' }
														}
													]
												}
											}
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'entries' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'RichTextCallout' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BlogPostAssetExternal' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostRichTextFragment, unknown>
export const BlogPostImageFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaMatPattern' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostImageFragment, unknown>
export const BlogPostButtonFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostButton' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaMatPattern' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'ctaUrl' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostButtonFragment, unknown>
export const BlogPostTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaMatPattern' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'heading' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'subheading' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostTextFragment, unknown>
export const BlogPostFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPost' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostBreadcrumbs' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostCategories' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostMeta' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostPDFDownload' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostRichText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostImage' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostButton' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostText' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CategoryBreadcrumbs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentCategory' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'parentCategory' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'parentCategory' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'RichTextCallout' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextWrapper' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostAssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostBodyLinks' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPostBody' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'links' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BlogPostAsset' }
														}
													]
												}
											}
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'entries' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'RichTextCallout' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BlogPostAssetExternal' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostBreadcrumbs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'CategoryBreadcrumbs' }
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostCategories' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'avatar' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'tagsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostPDFDownload' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'gatedContentSettings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'calloutTitle' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'calloutDescription' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gatedAsset' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'providePrintVersion' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostBodyLinks' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'bio' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaMatPattern' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostButton' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaMatPattern' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'ctaUrl' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaMatPattern' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'heading' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'subheading' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostFragment, unknown>
export const CaseStudyButtonFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyButton' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'cta' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'link' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudyButtonFragment, unknown>
export const CaseStudyTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudyTextFragment, unknown>
export const AssetFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Asset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AssetFragment, unknown>
export const CaseStudyRichTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'quote' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'statsTitle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'statsSubtitle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'statsDescription' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'extraSection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section1' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section2' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section3' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section4' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section5' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Asset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'RichTextCallout' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextWrapper' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudyRichTextFragment, unknown>
export const CaseStudyStatsFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyStats' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'caseStudyStatsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'key' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'value' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudyStatsFragment, unknown>
export const CaseStudyImageFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'quoteImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudyImageFragment, unknown>
export const CaseStudyVideoFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyVideo' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredVideo' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudyVideoFragment, unknown>
export const CaseStudyMetaFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_US' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_GB' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_AU' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_it_IT' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_nl_NL' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_fr_FR' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_es_ES' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_de_DE' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_es_MX' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudyMetaFragment, unknown>
export const CaseStudyHeroFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyHero' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'headline' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subheading' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'cta' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'link' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudyHeroFragment, unknown>
export const CaseStudyCategoriesFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyCategories' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'competitorsListCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'industriesListCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'topicsListCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudyCategoriesFragment, unknown>
export const CaseStudyRelatedCaseStudiesFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyRelatedCaseStudies' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'relatedCaseStudiesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudyRelatedCaseStudiesFragment, unknown>
export const CaseStudyFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudy' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyButton' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyRichText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyStats' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyImage' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyVideo' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyMeta' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyHero' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyCategories' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyRelatedCaseStudies' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Asset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'RichTextCallout' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextWrapper' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyButton' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'cta' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'link' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'quote' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'statsTitle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'statsSubtitle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'statsDescription' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'extraSection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section1' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section2' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section3' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section4' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section5' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyStats' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'caseStudyStatsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'key' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'value' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'quoteImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyVideo' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredVideo' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_US' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_GB' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_AU' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_it_IT' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_nl_NL' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_fr_FR' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_es_ES' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_de_DE' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_es_MX' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyHero' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'headline' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subheading' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'cta' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'link' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyCategories' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'competitorsListCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'industriesListCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'topicsListCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyRelatedCaseStudies' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'relatedCaseStudiesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudyFragment, unknown>
export const CategoryMetaFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CategoryMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<CategoryMetaFragment, unknown>
export const CategoryRichTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CategoryRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'description' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CategoryRichTextFragment, unknown>
export const CategoryFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Category' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryBreadcrumbs' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryMeta' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryRichText' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CategoryBreadcrumbs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentCategory' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'parentCategory' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'parentCategory' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CategoryMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CategoryRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'description' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CategoryFragment, unknown>
export const GlossaryTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
				]
			}
		}
	]
} as unknown as DocumentNode<GlossaryTextFragment, unknown>
export const GlossaryAssetFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<GlossaryAssetFragment, unknown>
export const GlossaryRichTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'GlossaryAsset' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<GlossaryRichTextFragment, unknown>
export const GlossaryImageFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<GlossaryImageFragment, unknown>
export const GlossaryMetaFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<GlossaryMetaFragment, unknown>
export const GlossaryFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Glossary' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlossaryText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlossaryRichText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlossaryImage' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlossaryMeta' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'GlossaryAsset' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<GlossaryFragment, unknown>
export const GlossaryCategorySlugsFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryCategorySlugs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<GlossaryCategorySlugsFragment, unknown>
export const GlossaryCategoryFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryCategory' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }
							]
						}
					},
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlossaryCategorySlugs' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'linkedFrom' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'allowedLocales' },
								value: {
									kind: 'ListValue',
									values: [
										{ kind: 'StringValue', value: 'en-US', block: false },
										{ kind: 'StringValue', value: 'en-AU', block: false },
										{ kind: 'StringValue', value: 'en-GB', block: false }
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'glossaryCollection' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'locale' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
										}
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryCategorySlugs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<GlossaryCategoryFragment, unknown>
export const AppRichTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceApp' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'abstract' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppRichTextFragment, unknown>
export const AppFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'App' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceApp' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppRichText' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'logo' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'logoIcon' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'alternativeLogo' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'appRegistryId' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'video' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'screenshots' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'reviewCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'unifiedBilling' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'catalystCompatible' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'termsOfServiceUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'freeTrial' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'freeTrialDays' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'upfrontPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pricing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appTier' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'logo' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'supportUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'installationGuideUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'userGuideUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'appRegistryUpdatedAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'multiStorefront' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'multiLocation' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'similarApps' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appFeaturesCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '5' }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'description' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'caseStudiesCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '2' }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'description' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceApp' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'abstract' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AppFragment, unknown>
export const AppInformationFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppInformation' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'MarketplaceAppCollection' }
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'items' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'App' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceApp' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'abstract' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'App' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceApp' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppRichText' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'logo' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'logoIcon' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'alternativeLogo' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'appRegistryId' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'video' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'screenshots' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'reviewCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'unifiedBilling' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'catalystCompatible' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'termsOfServiceUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'freeTrial' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'freeTrialDays' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'upfrontPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pricing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appTier' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'logo' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'supportUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'installationGuideUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'userGuideUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'appRegistryUpdatedAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'multiStorefront' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'multiLocation' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'similarApps' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appFeaturesCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '5' }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'description' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'caseStudiesCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '2' }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'description' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppInformationFragment, unknown>
export const AppCollectionImageFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppCollectionImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollection' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [{ kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } }]
			}
		}
	]
} as unknown as DocumentNode<AppCollectionImageFragment, unknown>
export const AppCollectionTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppCollectionText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollection' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'summary' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AppCollectionTextFragment, unknown>
export const AppCollectionFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppCollection' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollection' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppCollectionImage' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppCollectionText' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'linkedFrom' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'marketplaceAppCollection' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppCollectionImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollection' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [{ kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } }]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppCollectionText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollection' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'summary' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AppCollectionFragment, unknown>
export const AppMetaFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppMetaFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceApp' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'upfrontPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'reviewCount' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AppMetaFragmentFragment, unknown>
export const AppTierTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppTierText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppTier' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AppTierTextFragment, unknown>
export const AppTierImageFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppTierImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppTier' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'logo' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AppTierImageFragment, unknown>
export const AppTierFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppTier' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppTier' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppTierText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppTierImage' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppTierText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppTier' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppTierImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppTier' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'logo' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppTierFragment, unknown>
export const ThemeImageFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ThemeImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceTheme' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'smallThumbnail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'largeThumbnail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'mobilePreview' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'largePreview' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ThemeImageFragment, unknown>
export const ThemeRichTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ThemeRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceTheme' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'description' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ThemeRichTextFragment, unknown>
export const ThemeInformationFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ThemeInformation' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'MarketplaceThemeCollection' }
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'items' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemeImage' } },
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemeRichText' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'sys' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'registryId' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'partnerName' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'seoMetadata' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											}
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'partnerEmail' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'contactUrl' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'demoUrl' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'price' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'themeFeatures' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'themeOptimizations' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'documentationUrl' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'related' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ThemeImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceTheme' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'smallThumbnail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'largeThumbnail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'mobilePreview' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'largePreview' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ThemeRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceTheme' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'description' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ThemeInformationFragment, unknown>
export const ThemesPropertyFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ThemesProperty' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'ThemePropertyCollection' }
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'items' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'sys' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'seoMetadata' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											}
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'linkedFrom' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'marketplaceThemeCollection' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ThemesPropertyFragment, unknown>
export const PersonTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'linkedUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		}
	]
} as unknown as DocumentNode<PersonTextFragment, unknown>
export const PersonRichTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'bio' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'additionalOptions' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'bioSummary' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'fullBio' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PersonRichTextFragment, unknown>
export const PersonMetaFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PersonMetaFragment, unknown>
export const PersonRelatedNewsFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonRelatedNews' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'additionalOptions' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'relatedNewsCollection' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'items' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'sys' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
															}
														},
														{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PersonRelatedNewsFragment, unknown>
export const PersonImageFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'fullSizePhoto' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'avatar' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<PersonImageFragment, unknown>
export const PersonFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Person' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PersonText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PersonRichText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PersonMeta' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PersonRelatedNews' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PersonImage' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'linkedUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'bio' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'additionalOptions' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'bioSummary' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'fullBio' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonRelatedNews' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'additionalOptions' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'relatedNewsCollection' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'items' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'sys' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
															}
														},
														{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'fullSizePhoto' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'avatar' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PersonFragment, unknown>
export const PressAssetFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<PressAssetFragment, unknown>
export const PressRichTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'PressAsset' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<PressRichTextFragment, unknown>
export const PressTextFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'summary' } }
				]
			}
		}
	]
} as unknown as DocumentNode<PressTextFragment, unknown>
export const PressDateFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressDate' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } }]
			}
		}
	]
} as unknown as DocumentNode<PressDateFragment, unknown>
export const PressSlugFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressSlug' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_SG' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-SG', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_AT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-AT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_da_DK' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'da-DK', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_sv_SE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'sv-SE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nb_NO' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nb-NO', block: false }
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PressSlugFragment, unknown>
export const PressEnabledFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressEnabled' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_US' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_AU' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_GB' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_SG' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-SG', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_es_MX' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_es_ES' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_it_IT' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_fr_FR' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_nl_NL' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_de_DE' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_de_AT' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-AT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_da_DK' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'da-DK', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_sv_SE' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'sv-SE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_nb_NO' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nb-NO', block: false }
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PressEnabledFragment, unknown>
export const PressPostMetaFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressPostMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressSlug' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressEnabled' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressSlug' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_SG' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-SG', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_AT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-AT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_da_DK' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'da-DK', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_sv_SE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'sv-SE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nb_NO' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nb-NO', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressEnabled' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_US' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_AU' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_GB' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_SG' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-SG', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_es_MX' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_es_ES' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_it_IT' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_fr_FR' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_nl_NL' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_de_DE' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_de_AT' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-AT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_da_DK' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'da-DK', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_sv_SE' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'sv-SE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_nb_NO' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nb-NO', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PressPostMetaFragment, unknown>
export const PressFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Press' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressRichText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressDate' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressPostMeta' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressSlug' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_SG' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-SG', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_AT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-AT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_da_DK' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'da-DK', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_sv_SE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'sv-SE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nb_NO' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nb-NO', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressEnabled' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_US' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_AU' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_GB' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_SG' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-SG', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_es_MX' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_es_ES' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_it_IT' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_fr_FR' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_nl_NL' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_de_DE' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_de_AT' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-AT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_da_DK' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'da-DK', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_sv_SE' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'sv-SE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_nb_NO' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nb-NO', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'PressAsset' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'summary' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressDate' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } }]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressPostMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressSlug' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressEnabled' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PressFragment, unknown>
export const ResourceSlugFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ResourceSlug' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ResourceSlugFragment, unknown>
export const AssetExternalFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AssetExternalFragment, unknown>
export const HeroSubheadingLinkFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'HeroSubheadingLink' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroSubheadingLinks' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'assets' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'block' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'entries' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'block' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AssetExternal' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Asset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		}
	]
} as unknown as DocumentNode<HeroSubheadingLinkFragment, unknown>
export const ResourceHeroFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ResourceHero' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Hero' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'headline' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'subheading' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'HeroSubheadingLink' }
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'backgroundOptions' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'solidColor' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'gradient' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'backgroundImage' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cta' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'color' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'variant' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'formId' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'link' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Asset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'HeroSubheadingLink' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroSubheadingLinks' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'assets' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'block' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'entries' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'block' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AssetExternal' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ResourceHeroFragment, unknown>
export const ResourceRecommendedFeedFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ResourceRecommendedFeed' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'recommendedContentCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'Article' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'featuredImage' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'parentArticle' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'Resource' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'cardImage' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'BlogPost' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'featuredImage' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'CaseStudy' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'BasicContentCard' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'overline' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														},
														{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'ctaButtonCollection' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'items' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'Field',
																					name: { kind: 'Name', value: 'displayText' }
																				},
																				{
																					kind: 'Field',
																					name: { kind: 'Name', value: 'link' },
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections: [
																							{
																								kind: 'Field',
																								name: { kind: 'Name', value: 'url' }
																							}
																						]
																					}
																				}
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ResourceRecommendedFeedFragment, unknown>
export const ResourceSpeakersFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ResourceSpeakers' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'speakersCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ResourceSpeakersFragment, unknown>
export const ResourceFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Resource' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'formButtonText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceSlug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'formId' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cardImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceHero' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'statsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'key' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'value' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'aboveFormContent' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sideFormContent' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'contentImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'disclaimer' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceRecommendedFeed' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceSpeakers' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Asset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'HeroSubheadingLink' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroSubheadingLinks' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'assets' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'block' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'entries' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'block' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AssetExternal' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ResourceSlug' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ResourceHero' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Hero' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'headline' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'subheading' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'HeroSubheadingLink' }
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'backgroundOptions' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'solidColor' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'gradient' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'backgroundImage' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cta' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'color' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'variant' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'formId' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'link' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ResourceRecommendedFeed' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'recommendedContentCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'Article' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'featuredImage' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'parentArticle' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'Resource' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'cardImage' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'BlogPost' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'featuredImage' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'CaseStudy' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'BasicContentCard' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'overline' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														},
														{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'ctaButtonCollection' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'items' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'Field',
																					name: { kind: 'Name', value: 'displayText' }
																				},
																				{
																					kind: 'Field',
																					name: { kind: 'Name', value: 'link' },
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections: [
																							{
																								kind: 'Field',
																								name: { kind: 'Name', value: 'url' }
																							}
																						]
																					}
																				}
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ResourceSpeakers' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'speakersCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ResourceFragment, unknown>
export const ShowcaseItemFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ShowcaseItem' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Showcase' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'description' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'caseStudy' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'InlineFragment',
									typeCondition: {
										kind: 'NamedType',
										name: { kind: 'Name', value: 'AssetInternal' }
									},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'media' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'InlineFragment',
															typeCondition: {
																kind: 'NamedType',
																name: { kind: 'Name', value: 'Asset' }
															},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'height' } }
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'externalUrl' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'InlineFragment',
									typeCondition: {
										kind: 'NamedType',
										name: { kind: 'Name', value: 'ExternalLink' }
									},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ShowcaseItemFragment, unknown>
export const ShowcaseCategorySlugsFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ShowcaseCategorySlugs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_SG' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-SG', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_AT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-AT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_da_DK' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'da-DK', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_sv_SE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'sv-SE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nb_NO' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nb-NO', block: false }
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ShowcaseCategorySlugsFragment, unknown>
export const ShowcaseCategoryFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ShowcaseCategory' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }
							]
						}
					},
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShowcaseCategorySlugs' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentCategory' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShowcaseCategorySlugs' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'linkedFrom' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'allowedLocales' },
								value: {
									kind: 'ListValue',
									values: [
										{ kind: 'StringValue', value: 'en-US', block: false },
										{ kind: 'StringValue', value: 'en-AU', block: false },
										{ kind: 'StringValue', value: 'en-GB', block: false },
										{ kind: 'StringValue', value: 'en-SG', block: false },
										{ kind: 'StringValue', value: 'sv-SE', block: false },
										{ kind: 'StringValue', value: 'de-DE', block: false },
										{ kind: 'StringValue', value: 'fr-FR', block: false },
										{ kind: 'StringValue', value: 'es-ES', block: false },
										{ kind: 'StringValue', value: 'es-MX', block: false },
										{ kind: 'StringValue', value: 'it-IT', block: false },
										{ kind: 'StringValue', value: 'nl-NL', block: false },
										{ kind: 'StringValue', value: 'nb-NO', block: false },
										{ kind: 'StringValue', value: 'da-DK', block: false },
										{ kind: 'StringValue', value: 'de-DE', block: false },
										{ kind: 'StringValue', value: 'de-AT', block: false }
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'showcaseCollection' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'locale' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
										}
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ShowcaseCategorySlugs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_SG' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-SG', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_AT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-AT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_da_DK' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'da-DK', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_sv_SE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'sv-SE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nb_NO' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nb-NO', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ShowcaseCategoryFragment, unknown>
export const AlgoliaBlogPostFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AlgoliaBlogPost' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'avatar' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'tagsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AlgoliaBlogPostFragment, unknown>
export const AlgoliaMarketplaceAppFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AlgoliaMarketplaceApp' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceApp' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'algoliaKeywords' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'logo' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'logoIcon' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'reviewCount' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'abstract' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'unifiedBilling' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'catalystCompatible' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'freeTrial' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'freeTrialDays' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'upfrontPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pricing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appTier' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'sort' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'logo' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'appRegistryUpdatedAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'multiStorefront' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'multiLocation' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'termsOfServiceUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appFeaturesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'description' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											}
										]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'similarApps' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'featured' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'featuredOrder' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'appRegistryId' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appFeaturesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'parentCategory' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'contentfulMetadata' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'tags' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'id' } }
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'rank' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collectionsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AlgoliaMarketplaceAppFragment, unknown>
export const AlgoliaMarketplaceThemeFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AlgoliaMarketplaceTheme' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceTheme' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'partnerName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'featuredOrder' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'partnerEmail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contactUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'demoUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'price' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'registryId' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'versionId' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'description' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'largePreview' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'largeThumbnail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'smallThumbnail' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'themeIndustriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'mobilePreview' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'themeFeatures' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'themeOptimizations' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'themeCollections' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'documentationUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'related' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AlgoliaMarketplaceThemeFragment, unknown>
export const AppCollectionSitemapFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppCollectionSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollection' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppCollectionSitemapFragment, unknown>
export const AppSitemapFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceApp' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppSitemapFragment, unknown>
export const ArticleSitemapFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentArticle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleSitemapFragment, unknown>
export const BlogPostAuthorsFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostAuthors' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'linkedFrom' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'blogPostCollection' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostAuthorsFragment, unknown>
export const BlogPostCategoriesSitemapFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostCategoriesSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'linkedFrom' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'blogPostCollection' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostCategoriesSitemapFragment, unknown>
export const BlogPostSitemapFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostSitemapFragment, unknown>
export const CaseStudySitemapFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudySitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudySitemapFragment, unknown>
export const CategoriesSitemapFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CategoriesSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentCategory' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CategoriesSitemapFragment, unknown>
export const GlossarySitemapFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossarySitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<GlossarySitemapFragment, unknown>
export const PressSitemapFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PressSitemapFragment, unknown>
export const ResourcesSitemapFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ResourcesSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ResourcesSitemapFragment, unknown>
export const ThemeIndustryFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ThemeIndustry' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemeProperty' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ThemeIndustryFragment, unknown>
export const ThemeSitemapFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ThemeSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceTheme' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ThemeSitemapFragment, unknown>
export const ArticleSlugFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleSlug' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'de_AT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-AT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'nb_NO' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nb-NO', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'da_DK' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'da-DK', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'sv_SE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'sv-SE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleSlugFragment, unknown>
export const TypesDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'Types' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: 'Resource', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: '__type' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'name' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Type' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Type' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: '__Type' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'fields' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'type' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ofType' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<TypesQuery, TypesQueryVariables>
export const ArticlesDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'Articles' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '10' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'articleCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Article' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleBreadcrumbs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentArticle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'parentArticle' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'parentArticle' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'parentArticle' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'parentArticle' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
																				{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'RichTextCallout' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextWrapper' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleAssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleExternalLink' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExternalLink' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BasicContentCard' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicContentCard' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaButtonCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'link' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleExternalLink' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleBodyLinks' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleBody' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'links' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleAsset' }
														}
													]
												}
											}
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'entries' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'RichTextCallout' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleAssetExternal' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BasicContentCard' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleBodyLinks' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subheading' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'bio' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'headline' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticlePDFDownload' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'gatedContentSettings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'calloutTitle' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'calloutDescription' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gatedAsset' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'providePrintVersion' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleFeedCarousel' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'cardSummary' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentArticle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'editStatus' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleFAQAccordions' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'faqsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'question' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'answer' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleInterstitial' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'interstitialHeadline' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'interstitialCta' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'link' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'ArticleExternalLink' }
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleStatus' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_US' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_GB' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_AU' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_it_IT' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_nl_NL' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_fr_FR' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_es_ES' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_de_DE' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_es_MX' },
						name: { kind: 'Name', value: 'editStatus' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'LocalizedArticleSlug' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'avatar' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleStatus' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LocalizedArticleSlug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentArticle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LocalizedArticleSlug' } }
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleSecondaryCTAs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'secondaryCtasCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ctaButtonCollection' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'items' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'openInNewTab' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'formId' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'link' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'InlineFragment',
																					typeCondition: {
																						kind: 'NamedType',
																						name: { kind: 'Name', value: 'ExternalLink' }
																					},
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections: [
																							{
																								kind: 'Field',
																								name: { kind: 'Name', value: '__typename' }
																							},
																							{
																								kind: 'Field',
																								name: { kind: 'Name', value: 'url' }
																							}
																						]
																					}
																				}
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Article' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleBreadcrumbs' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleRichText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleImage' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticlePDFDownload' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleFeedCarousel' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleFAQAccordions' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleInterstitial' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleMeta' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleSecondaryCTAs' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ArticlesQuery, ArticlesQueryVariables>
export const ArticleChildFeedGridDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ArticleChildFeedGrid' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: 'en-US', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleOrder' } }
					},
					defaultValue: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'slug_ASC' }] }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'articleCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'hero' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'headline' } }]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'parentArticle' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'sys' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
															}
														},
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleChildFeedGridQuery, ArticleChildFeedGridQueryVariables>
export const ArticleChildrenCarouselDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ArticleChildrenCarousel' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '12' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'articleCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'ArticleChildrenCarousel' }
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleChildrenCarousel' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'cardSummary' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentArticle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleChildrenCarouselQuery, ArticleChildrenCarouselQueryVariables>
export const ArticleFeedCarouselDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ArticleFeedCarousel' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '12' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'articleCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'ArticleFeedCarousel' }
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleFeedCarousel' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'cardSummary' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentArticle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'editStatus' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleFeedCarouselQuery, ArticleFeedCarouselQueryVariables>
export const LocalizedParentArticlesDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'LocalizedParentArticles' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: 'en-US', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleOrder' } }
					},
					defaultValue: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'slug_ASC' }] }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleFilter' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'parentArticles' },
						name: { kind: 'Name', value: 'articleCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<LocalizedParentArticlesQuery, LocalizedParentArticlesQueryVariables>
export const BlogPostsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'BlogPosts' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPostFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '10' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'blogPostCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPost' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CategoryBreadcrumbs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentCategory' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'parentCategory' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'parentCategory' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostBreadcrumbs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'CategoryBreadcrumbs' }
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostCategories' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'avatar' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'tagsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostPDFDownload' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'gatedContentSettings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'calloutTitle' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'calloutDescription' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gatedAsset' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'providePrintVersion' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'RichTextCallout' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextWrapper' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostAssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostBodyLinks' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPostBody' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'links' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BlogPostAsset' }
														}
													]
												}
											}
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'entries' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'RichTextCallout' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BlogPostAssetExternal' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostBodyLinks' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'bio' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaMatPattern' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostButton' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaMatPattern' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'ctaUrl' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaMatPattern' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'heading' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'subheading' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPost' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostBreadcrumbs' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostCategories' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostMeta' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostPDFDownload' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostRichText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostImage' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostButton' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostText' } }
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostsQuery, BlogPostsQueryVariables>
export const BlogPostFeedDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'BlogPostFeed' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPostFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '10' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'blogPostCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: {
									kind: 'ListValue',
									values: [{ kind: 'EnumValue', value: 'publishedDate_DESC' }]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'author' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } }]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'featuredImage' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'seoMetadata' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'categoriesCollection' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'items' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
																]
															}
														}
													]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'hideOnHomepage' } }
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostFeedQuery, BlogPostFeedQueryVariables>
export const BlogPostFeedPersonsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'BlogPostFeedPersons' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'personCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'fullName_contains' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } }
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'linkedFrom' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'blogPostCollection' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'total' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostFeedPersonsQuery, BlogPostFeedPersonsQueryVariables>
export const BlogPostFeedCategoriesDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'BlogPostFeedCategories' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoryCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'name_contains' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } }
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'linkedFrom' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'blogPostCollection' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'total' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostFeedCategoriesQuery, BlogPostFeedCategoriesQueryVariables>
export const CaseStudiesDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'CaseStudies' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudyFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '12' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'caseStudyCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudy' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyButton' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'cta' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'link' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Asset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'RichTextCallout' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextWrapper' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'quote' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'statsTitle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'statsSubtitle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'statsDescription' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'extraSection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section1' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section2' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section3' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section4' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'section5' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'entries' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'RichTextCallout' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyStats' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'caseStudyStatsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'key' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'value' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'quoteImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyVideo' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredVideo' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_US' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_GB' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_en_AU' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_it_IT' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_nl_NL' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_fr_FR' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_es_ES' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_de_DE' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'status_es_MX' },
						name: { kind: 'Name', value: 'status' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyHero' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'headline' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subheading' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'cta' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'link' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyCategories' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'competitorsListCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'industriesListCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'topicsListCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudyRelatedCaseStudies' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'relatedCaseStudiesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudy' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyButton' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyRichText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyStats' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyImage' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyVideo' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyMeta' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyHero' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyCategories' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudyRelatedCaseStudies' } }
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudiesQuery, CaseStudiesQueryVariables>
export const CaseStudyFeedDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'CaseStudyFeed' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudyFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '12' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'caseStudyCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: {
									kind: 'ListValue',
									values: [{ kind: 'EnumValue', value: 'publishedDate_DESC' }]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'summary' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											}
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudyFeedQuery, CaseStudyFeedQueryVariables>
export const CaseStudyPropertyDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'CaseStudyProperty' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudyPropertyOrder' } }
					},
					defaultValue: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'name_ASC' }] }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'industries' },
						name: { kind: 'Name', value: 'caseStudyPropertyCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'type' },
											value: { kind: 'StringValue', value: 'Industries', block: false }
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'competitors' },
						name: { kind: 'Name', value: 'caseStudyPropertyCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'type' },
											value: { kind: 'StringValue', value: 'Competitors', block: false }
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'topics' },
						name: { kind: 'Name', value: 'caseStudyPropertyCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'type' },
											value: { kind: 'StringValue', value: 'Topics', block: false }
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudyPropertyQuery, CaseStudyPropertyQueryVariables>
export const CaseStudySlugsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'CaseStudySlugs' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudyFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'status' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '5' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: 'en-US', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'caseStudyCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudySlugsQuery, CaseStudySlugsQueryVariables>
export const CategoriesDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'Categories' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '100' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoryCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Category' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CategoryBreadcrumbs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentCategory' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'parentCategory' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'parentCategory' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CategoryMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CategoryRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'description' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Category' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryBreadcrumbs' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryMeta' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryRichText' } }
				]
			}
		}
	]
} as unknown as DocumentNode<CategoriesQuery, CategoriesQueryVariables>
export const EventFeedDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'EventFeed' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'EventFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'EventOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '20' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'eventCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'summary' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'featured' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'url' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'InlineFragment',
															typeCondition: {
																kind: 'NamedType',
																name: { kind: 'Name', value: 'Asset' }
															},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'height' } }
																]
															}
														}
													]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'recurringEvent' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'endDate' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<EventFeedQuery, EventFeedQueryVariables>
export const FeaturedContentBlockDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'FeaturedContentBlock' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntryFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'EntryOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '20' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'entryCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }
													]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'BlogPost' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'featuredImage' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'summary' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'json' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'Article' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'featuredImage' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'interstitialHeadline' }
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'parentArticle' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'Resource' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hero' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'headline' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'subheading' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'json' } }
																			]
																		}
																	}
																]
															}
														},
														{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'featuredImage' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'CaseStudy' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'hero' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'headline' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'subheading' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'json' } }
																			]
																		}
																	}
																]
															}
														},
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<FeaturedContentBlockQuery, FeaturedContentBlockQueryVariables>
export const FeaturedLogoCarouselDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'FeaturedLogoCarousel' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudyFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '4' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'caseStudyCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: {
									kind: 'ListValue',
									values: [{ kind: 'EnumValue', value: 'publishedDate_DESC' }]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'featuredLogo' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'featuredContent' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'featuredAuthorName' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'featuredAuthorTitle' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'featuredImage' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'featuredLinkText' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'featuredOrder' } }
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<FeaturedLogoCarouselQuery, FeaturedLogoCarouselQueryVariables>
export const GlossaryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'Glossary' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'GlossaryFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'glossaryCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Glossary' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'GlossaryAsset' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Glossary' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlossaryText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlossaryRichText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlossaryImage' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlossaryMeta' } }
				]
			}
		}
	]
} as unknown as DocumentNode<GlossaryQuery, GlossaryQueryVariables>
export const GlossaryFeedDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GlossaryFeed' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'GlossaryFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '10' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'glossaryCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: {
									kind: 'ListValue',
									values: [{ kind: 'EnumValue', value: 'publishedDate_DESC' }]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'categoryCollection' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'items' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'description' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'json' } }
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<GlossaryFeedQuery, GlossaryFeedQueryVariables>
export const GlossaryCategoriesDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GlossaryCategories' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '100' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryOrder' } }
					},
					defaultValue: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'name_ASC' }] }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoryCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlossaryCategory' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryCategorySlugs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossaryCategory' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }
							]
						}
					},
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlossaryCategorySlugs' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'linkedFrom' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'allowedLocales' },
								value: {
									kind: 'ListValue',
									values: [
										{ kind: 'StringValue', value: 'en-US', block: false },
										{ kind: 'StringValue', value: 'en-AU', block: false },
										{ kind: 'StringValue', value: 'en-GB', block: false }
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'glossaryCollection' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'locale' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
										}
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<GlossaryCategoriesQuery, GlossaryCategoriesQueryVariables>
export const AppInformationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppInformation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceAppCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppInformation' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceApp' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'abstract' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'App' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceApp' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppRichText' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'logo' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'logoIcon' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'alternativeLogo' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'appRegistryId' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'video' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'screenshots' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'reviewCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'unifiedBilling' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'catalystCompatible' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'termsOfServiceUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'freeTrial' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'freeTrialDays' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'upfrontPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pricing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appTier' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'logo' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'supportUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'installationGuideUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'userGuideUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'appRegistryUpdatedAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'multiStorefront' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'multiLocation' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'similarApps' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appFeaturesCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '5' }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'description' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'caseStudiesCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '2' }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'description' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppInformation' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'MarketplaceAppCollection' }
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'items' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'App' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppInformationQuery, AppInformationQueryVariables>
export const AppCarouselDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppCarousel' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '12' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceAppCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'summary' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'alternativeLogo' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'appTier' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'logo' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'categoriesCollection' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'items' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'sys' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'id' } }
																			]
																		}
																	},
																	{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'parentCategory' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
																				{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
																				{
																					kind: 'Field',
																					name: { kind: 'Name', value: 'sys' },
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections: [
																							{ kind: 'Field', name: { kind: 'Name', value: 'id' } }
																						]
																					}
																				}
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AppCarouselQuery, AppCarouselQueryVariables>
export const AppCategoriesDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppCategories' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryOrder' } }
					}
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoryCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppCategoriesQuery, AppCategoriesQueryVariables>
export const AppCollectionDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppCollection' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollectionFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'private_not' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollectionOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appCollectionCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppCollection' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppCollectionImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollection' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [{ kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } }]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppCollectionText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollection' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'summary' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppCollection' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollection' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppCollectionImage' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppCollectionText' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'linkedFrom' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'marketplaceAppCollection' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppCollectionQuery, AppCollectionQueryVariables>
export const AppCollectionCarouselDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppCollectionCarousel' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollectionFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'private_not' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '100' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appCollectionCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppCollection' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppCollectionImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollection' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [{ kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } }]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppCollectionText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollection' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'summary' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppCollection' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollection' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppCollectionImage' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppCollectionText' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'linkedFrom' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'marketplaceAppCollection' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppCollectionCarouselQuery, AppCollectionCarouselQueryVariables>
export const AppCollectionCarouselPriorityDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppCollectionCarouselPriority' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appCollectionCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'name' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } }
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '12' }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	AppCollectionCarouselPriorityQuery,
	AppCollectionCarouselPriorityQueryVariables
>
export const AppCollectionListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppCollectionList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '12' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceAppCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'summary' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'reviewCount' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'upfrontPrice' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'logo' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'logoIcon' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'categoriesCollection' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'items' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'sys' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'id' } }
																			]
																		}
																	},
																	{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'parentCategory' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
																				{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
																				{
																					kind: 'Field',
																					name: { kind: 'Name', value: 'sys' },
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections: [
																							{ kind: 'Field', name: { kind: 'Name', value: 'id' } }
																						]
																					}
																				}
																			]
																		}
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'collectionsCollection' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'items' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'sys' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'id' } }
																			]
																		}
																	},
																	{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'appTier' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'logo' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'url' } }
																]
															}
														}
													]
												}
											}
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppCollectionListQuery, AppCollectionListQueryVariables>
export const AppMetaDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppMeta' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceAppCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppMetaFragment' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppMetaFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceApp' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'upfrontPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'reviewCount' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppMetaQuery, AppMetaQueryVariables>
export const AppRowDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppRow' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '4' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceAppCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'summary' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'logoIcon' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppRowQuery, AppRowQueryVariables>
export const AppRowCardsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppRowCards' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '3' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceAppCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'summary' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'logo' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'reviewCount' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'logoIcon' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'appTier' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'logo' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'categoriesCollection' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'items' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'sys' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'id' } }
																			]
																		}
																	},
																	{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'parentCategory' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
																				{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
																				{
																					kind: 'Field',
																					name: { kind: 'Name', value: 'sys' },
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections: [
																							{ kind: 'Field', name: { kind: 'Name', value: 'id' } }
																						]
																					}
																				}
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AppRowCardsQuery, AppRowCardsQueryVariables>
export const AppTierDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppTier' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'AppTierFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'AppTierOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appTierCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppTier' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppTierText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppTier' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppTierImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppTier' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'logo' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppTier' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppTier' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppTierText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppTierImage' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppTierQuery, AppTierQueryVariables>
export const AppTierAppGridDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppTierAppGrid' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '100' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceAppCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'logo' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'summary' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'reviewCount' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'categoriesCollection' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'items' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'sys' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'id' } }
																			]
																		}
																	},
																	{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'parentCategory' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
																				{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
																				{
																					kind: 'Field',
																					name: { kind: 'Name', value: 'sys' },
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections: [
																							{ kind: 'Field', name: { kind: 'Name', value: 'id' } }
																						]
																					}
																				}
																			]
																		}
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'appTier' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'logo' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'url' } }
																]
															}
														},
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppTierAppGridQuery, AppTierAppGridQueryVariables>
export const AppsRecommendedDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppsRecommended' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '100' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceAppCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'summary' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'upfrontPrice' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'reviewCount' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'logoIcon' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'appTier' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'logo' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AppsRecommendedQuery, AppsRecommendedQueryVariables>
export const ThemesInformationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ThemesInformation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceThemeFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceThemeCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemeInformation' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ThemeImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceTheme' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'smallThumbnail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'largeThumbnail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'mobilePreview' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'largePreview' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ThemeRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceTheme' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'description' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ThemeInformation' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'MarketplaceThemeCollection' }
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'items' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemeImage' } },
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemeRichText' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'sys' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'registryId' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'partnerName' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'seoMetadata' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											}
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'partnerEmail' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'contactUrl' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'demoUrl' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'price' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'themeFeatures' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'themeOptimizations' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'documentationUrl' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'related' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ThemesInformationQuery, ThemesInformationQueryVariables>
export const ThemeRelatedStyleDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ThemeRelatedStyle' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'relatedId' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceThemeCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'registryId' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'relatedId' } }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'largeThumbnail' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'smallThumbnail' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'registryId' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ThemeRelatedStyleQuery, ThemeRelatedStyleQueryVariables>
export const ThemesCollectionsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ThemesCollections' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemePropertyFilter' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'themePropertyCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemesProperty' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ThemesProperty' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'ThemePropertyCollection' }
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'items' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'sys' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'seoMetadata' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											}
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'linkedFrom' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'marketplaceThemeCollection' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ThemesCollectionsQuery, ThemesCollectionsQueryVariables>
export const ThemesListDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ThemesList' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceThemeFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '12' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceThemeCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'price' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'largePreview' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'largeThumbnail' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'smallThumbnail' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'mobilePreview' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'related' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'seoMetadata' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'includeTitleTemplate' }
														},
														{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ThemesListQuery, ThemesListQueryVariables>
export const PersonsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'Persons' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'PersonFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '10' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'personCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Person' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'linkedUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'bio' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'additionalOptions' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'bioSummary' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'fullBio' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonRelatedNews' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'additionalOptions' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'relatedNewsCollection' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'items' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'sys' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
															}
														},
														{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PersonImage' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'fullSizePhoto' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'avatar' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Person' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PersonText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PersonRichText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PersonMeta' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PersonRelatedNews' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PersonImage' } }
				]
			}
		}
	]
} as unknown as DocumentNode<PersonsQuery, PersonsQueryVariables>
export const PersonFeedDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'PersonFeed' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'PersonFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '10' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'personCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'jobTitleInEnglish' },
												name: { kind: 'Name', value: 'jobTitle' },
												arguments: [
													{
														kind: 'Argument',
														name: { kind: 'Name', value: 'locale' },
														value: { kind: 'StringValue', value: 'en-US', block: false }
													}
												]
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'avatar' },
												arguments: [
													{
														kind: 'Argument',
														name: { kind: 'Name', value: 'locale' },
														value: { kind: 'StringValue', value: 'en-US', block: false }
													}
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'additionalOptions' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'avatarOverride' },
															arguments: [
																{
																	kind: 'Argument',
																	name: { kind: 'Name', value: 'locale' },
																	value: { kind: 'StringValue', value: 'en-US', block: false }
																}
															],
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'bioSummary' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'json' } }
																]
															}
														}
													]
												}
											}
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<PersonFeedQuery, PersonFeedQueryVariables>
export const PersonFeedPriorityDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'PersonFeedPriority' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'personCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'fullName_contains' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } }
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '10' }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PersonFeedPriorityQuery, PersonFeedPriorityQueryVariables>
export const PressDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'Press' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'PressFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '10' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'pressCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Press' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressRichText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'assets' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'block' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'PressAsset' }
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressText' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'summary' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressDate' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } }]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressSlug' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_SG' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-SG', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_AT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-AT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_da_DK' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'da-DK', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_sv_SE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'sv-SE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nb_NO' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nb-NO', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressEnabled' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_US' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_AU' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_GB' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_en_SG' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-SG', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_es_MX' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_es_ES' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_it_IT' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_fr_FR' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_nl_NL' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_de_DE' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_de_AT' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-AT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_da_DK' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'da-DK', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_sv_SE' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'sv-SE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'enabled_nb_NO' },
						name: { kind: 'Name', value: 'enabled' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nb-NO', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressPostMeta' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressSlug' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressEnabled' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Press' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressRichText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressDate' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressPostMeta' } }
				]
			}
		}
	]
} as unknown as DocumentNode<PressQuery, PressQueryVariables>
export const PressFeedDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'PressFeed' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'PressFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '10' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'pressCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'EnumValue', value: 'publishedDate_DESC' }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'summary' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'externalLink' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
												}
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'InlineFragment',
															typeCondition: {
																kind: 'NamedType',
																name: { kind: 'Name', value: 'AssetInternal' }
															},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'media' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'FragmentSpread',
																					name: { kind: 'Name', value: 'ImageAsset' }
																				}
																			]
																		}
																	}
																]
															}
														},
														{
															kind: 'InlineFragment',
															typeCondition: {
																kind: 'NamedType',
																name: { kind: 'Name', value: 'AssetExternal' }
															},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'url' } }
																]
															}
														}
													]
												}
											}
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<PressFeedQuery, PressFeedQueryVariables>
export const ResourceDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'Resource' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '20' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'resourceCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Resource' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ResourceSlug' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Asset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'HeroSubheadingLink' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroSubheadingLinks' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'assets' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'block' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'entries' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'block' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AssetExternal' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ResourceHero' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Hero' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'headline' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'subheading' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'links' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'HeroSubheadingLink' }
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'backgroundOptions' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'solidColor' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'gradient' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'backgroundImage' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cta' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'color' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'variant' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'formId' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'link' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ResourceRecommendedFeed' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'recommendedContentCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'Article' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'featuredImage' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'parentArticle' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'Resource' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'cardImage' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'BlogPost' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'featuredImage' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'CaseStudy' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: 'InlineFragment',
												typeCondition: {
													kind: 'NamedType',
													name: { kind: 'Name', value: 'BasicContentCard' }
												},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'overline' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: { kind: 'Name', value: 'ImageAsset' }
																	}
																]
															}
														},
														{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'ctaButtonCollection' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'items' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'Field',
																					name: { kind: 'Name', value: 'displayText' }
																				},
																				{
																					kind: 'Field',
																					name: { kind: 'Name', value: 'link' },
																					selectionSet: {
																						kind: 'SelectionSet',
																						selections: [
																							{
																								kind: 'Field',
																								name: { kind: 'Name', value: 'url' }
																							}
																						]
																					}
																				}
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ResourceSpeakers' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'speakersCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'Resource' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'formButtonText' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceSlug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'formId' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'featuredImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'cardImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'hero' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceHero' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'statsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'key' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'value' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'aboveFormContent' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sideFormContent' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'contentImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'disclaimer' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceRecommendedFeed' } },
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceSpeakers' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ResourceQuery, ResourceQueryVariables>
export const ResourceFeedDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ResourceFeed' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '20' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'resourceCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardImage' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		}
	]
} as unknown as DocumentNode<ResourceFeedQuery, ResourceFeedQueryVariables>
export const ShowcaseFeedDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ShowcaseFeed' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShowcaseFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
					type: {
						kind: 'ListType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShowcaseOrder' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '10' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'showcaseCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'order' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShowcaseItem' } }
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ShowcaseItem' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Showcase' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'description' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'caseStudy' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'InlineFragment',
									typeCondition: {
										kind: 'NamedType',
										name: { kind: 'Name', value: 'AssetInternal' }
									},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'media' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'InlineFragment',
															typeCondition: {
																kind: 'NamedType',
																name: { kind: 'Name', value: 'Asset' }
															},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
																	{ kind: 'Field', name: { kind: 'Name', value: 'height' } }
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'externalUrl' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'InlineFragment',
									typeCondition: {
										kind: 'NamedType',
										name: { kind: 'Name', value: 'ExternalLink' }
									},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ShowcaseFeedQuery, ShowcaseFeedQueryVariables>
export const ShowcaseCategoriesDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ShowcaseCategories' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '100' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoryCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShowcaseCategory' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ShowcaseCategorySlugs' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_en_SG' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-SG', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_de_AT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-AT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_da_DK' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'da-DK', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_sv_SE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'sv-SE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'slug_nb_NO' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nb-NO', block: false }
							}
						]
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ImageAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SeoMetadataFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoMetadata' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'includeTitleTemplate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'readingTime' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'image' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageAsset' } }]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ShowcaseCategory' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }
							]
						}
					},
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShowcaseCategorySlugs' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentCategory' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShowcaseCategorySlugs' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SeoMetadataFragment' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'linkedFrom' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'allowedLocales' },
								value: {
									kind: 'ListValue',
									values: [
										{ kind: 'StringValue', value: 'en-US', block: false },
										{ kind: 'StringValue', value: 'en-AU', block: false },
										{ kind: 'StringValue', value: 'en-GB', block: false },
										{ kind: 'StringValue', value: 'en-SG', block: false },
										{ kind: 'StringValue', value: 'sv-SE', block: false },
										{ kind: 'StringValue', value: 'de-DE', block: false },
										{ kind: 'StringValue', value: 'fr-FR', block: false },
										{ kind: 'StringValue', value: 'es-ES', block: false },
										{ kind: 'StringValue', value: 'es-MX', block: false },
										{ kind: 'StringValue', value: 'it-IT', block: false },
										{ kind: 'StringValue', value: 'nl-NL', block: false },
										{ kind: 'StringValue', value: 'nb-NO', block: false },
										{ kind: 'StringValue', value: 'da-DK', block: false },
										{ kind: 'StringValue', value: 'de-DE', block: false },
										{ kind: 'StringValue', value: 'de-AT', block: false }
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'showcaseCollection' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'locale' },
											value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
										}
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ShowcaseCategoriesQuery, ShowcaseCategoriesQueryVariables>
export const AlgoliaBlogPostsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AlgoliaBlogPosts' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '100' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
					}
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'blogPostCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AlgoliaBlogPost' } }
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AlgoliaBlogPost' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'avatar' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'tagsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AlgoliaBlogPostsQuery, AlgoliaBlogPostsQueryVariables>
export const AlgoliaBlogPostByIdDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AlgoliaBlogPostById' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'postID' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
					},
					defaultValue: { kind: 'StringValue', value: 'en-US', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'blogPost' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'id' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'postID' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AlgoliaBlogPost' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AlgoliaBlogPost' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'canonicalUrl' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'author' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'avatar' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'body' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'tagsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AlgoliaBlogPostByIdQuery, AlgoliaBlogPostByIdQueryVariables>
export const AlgoliaMarketplaceAppsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AlgoliaMarketplaceApps' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '15' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: 'en-US', block: false }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppFilter' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceAppCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'AlgoliaMarketplaceApp' }
											}
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AlgoliaMarketplaceApp' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceApp' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'algoliaKeywords' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'logo' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'logoIcon' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'reviewCount' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'abstract' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'unifiedBilling' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'catalystCompatible' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'freeTrial' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'freeTrialDays' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'upfrontPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pricing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appTier' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'sort' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'logo' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'appRegistryUpdatedAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'multiStorefront' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'multiLocation' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'termsOfServiceUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appFeaturesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'description' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											}
										]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'similarApps' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'featured' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'featuredOrder' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'appRegistryId' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appFeaturesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'parentCategory' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'contentfulMetadata' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'tags' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'id' } }
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'rank' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collectionsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AlgoliaMarketplaceAppsQuery, AlgoliaMarketplaceAppsQueryVariables>
export const AlgoliaMarketplaceAppByIdDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AlgoliaMarketplaceAppById' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'appID' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
					},
					defaultValue: { kind: 'StringValue', value: 'en-US', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceApp' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'id' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'appID' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AlgoliaMarketplaceApp' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AlgoliaMarketplaceApp' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceApp' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'algoliaKeywords' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'logo' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'logoIcon' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rating' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'reviewCount' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'summary' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'abstract' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'unifiedBilling' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'catalystCompatible' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'freeTrial' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'freeTrialDays' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'upfrontPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'monthlyPrice' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pricing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appTier' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'sort' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'logo' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'appRegistryUpdatedAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'multiStorefront' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'multiLocation' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'termsOfServiceUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appFeaturesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'description' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
												}
											}
										]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'similarApps' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'featured' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'featuredOrder' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'appRegistryId' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appFeaturesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'parentCategory' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'contentfulMetadata' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: { kind: 'Name', value: 'tags' },
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{ kind: 'Field', name: { kind: 'Name', value: 'id' } }
																			]
																		}
																	}
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'rank' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collectionsCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }
										]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		}
	]
} as unknown as DocumentNode<
	AlgoliaMarketplaceAppByIdQuery,
	AlgoliaMarketplaceAppByIdQueryVariables
>
export const AlgoliaMarketplaceThemesDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AlgoliaMarketplaceThemes' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '100' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					defaultValue: { kind: 'StringValue', value: 'en-US', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceThemeCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'AlgoliaMarketplaceTheme' }
											}
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AlgoliaMarketplaceTheme' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceTheme' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'partnerName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'featuredOrder' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'partnerEmail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contactUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'demoUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'price' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'registryId' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'versionId' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'description' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'largePreview' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'largeThumbnail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'smallThumbnail' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'themeIndustriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'mobilePreview' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'themeFeatures' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'themeOptimizations' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'themeCollections' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'documentationUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'related' } }
				]
			}
		}
	]
} as unknown as DocumentNode<AlgoliaMarketplaceThemesQuery, AlgoliaMarketplaceThemesQueryVariables>
export const AlgoliaMarketplaceThemeByIdDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AlgoliaMarketplaceThemeById' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'themeID' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
					},
					defaultValue: { kind: 'StringValue', value: 'en-US', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceTheme' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'id' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'themeID' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AlgoliaMarketplaceTheme' } }
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AlgoliaMarketplaceTheme' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceTheme' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'partnerName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'featuredOrder' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publishedDate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'partnerEmail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contactUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'demoUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'price' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'registryId' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'versionId' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'description' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'largePreview' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'largeThumbnail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'smallThumbnail' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'themeIndustriesCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
									}
								}
							]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'mobilePreview' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'themeFeatures' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'themeOptimizations' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'themeCollections' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'documentationUrl' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'related' } }
				]
			}
		}
	]
} as unknown as DocumentNode<
	AlgoliaMarketplaceThemeByIdQuery,
	AlgoliaMarketplaceThemeByIdQueryVariables
>
export const BlogPostPathsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'BlogPostPaths' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPostFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '10' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'blogPostCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostPathsQuery, BlogPostPathsQueryVariables>
export const BlogContentForPdfDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'BlogContentForPDF' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'postID' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
					},
					defaultValue: { kind: 'StringValue', value: 'en-US', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'blogPost' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'id' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'postID' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'featuredImage' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'body' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostBodyLinks' } }
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gatedContentSettings' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'where' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'providePrintVersion' },
														value: { kind: 'BooleanValue', value: true }
													}
												]
											}
										}
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'RichTextCallout' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextWrapper' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostAssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostBodyLinks' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPostBody' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'links' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BlogPostAsset' }
														}
													]
												}
											}
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'entries' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'RichTextCallout' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BlogPostAssetExternal' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogContentForPdfQuery, BlogContentForPdfQueryVariables>
export const ArticleContentForPdfDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ArticleContentForPDF' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'postID' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
					},
					defaultValue: { kind: 'StringValue', value: 'en-US', block: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'article' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'id' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'postID' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'featuredImage' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'body' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'json' } },
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleBodyLinks' } }
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gatedContentSettings' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'where' },
											value: {
												kind: 'ObjectValue',
												fields: [
													{
														kind: 'ObjectField',
														name: { kind: 'Name', value: 'providePrintVersion' },
														value: { kind: 'BooleanValue', value: true }
													}
												]
											}
										}
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											}
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'faqsCollection' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'items' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'question' } },
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'answer' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{ kind: 'Field', name: { kind: 'Name', value: 'json' } }
																]
															}
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleAsset' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'contentType' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'RichTextCallout' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RichTextWrapper' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'content' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleAssetExternal' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetExternal' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'altText' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleExternalLink' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExternalLink' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BasicContentCard' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicContentCard' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: '__typename' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ctaButtonCollection' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'displayText' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'link' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleExternalLink' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleBodyLinks' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleBody' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'links' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleAsset' }
														}
													]
												}
											}
										]
									}
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'entries' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'block' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'RichTextCallout' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'ArticleAssetExternal' }
														},
														{
															kind: 'FragmentSpread',
															name: { kind: 'Name', value: 'BasicContentCard' }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticleContentForPdfQuery, ArticleContentForPdfQueryVariables>
export const RobotsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'Robots' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'RobotsFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'robots_exists' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'robotsCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'robots' } }]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<RobotsQuery, RobotsQueryVariables>
export const AppCollectionSitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppCollectionSitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollectionFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'slug_exists' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appCollectionCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'AppCollectionSitemap' }
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppCollectionSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppCollection' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppCollectionSitemapQuery, AppCollectionSitemapQueryVariables>
export const AppsSitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AppsSitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceAppFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'slug_exists' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceAppCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppSitemap' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AppSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceApp' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AppsSitemapQuery, AppsSitemapQueryVariables>
export const ArticlesSitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ArticlesSitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'slug_exists' },
								value: { kind: 'BooleanValue', value: true }
							},
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'editStatus' },
								value: { kind: 'StringValue', value: '🟢 live', block: false }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'articleCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleSitemap' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentArticle' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticlesSitemapQuery, ArticlesSitemapQueryVariables>
export const BlogPostAuthorsSitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'BlogPostAuthorsSitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'PersonFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'slug_exists' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '100' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'personCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostAuthors' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostAuthors' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'linkedFrom' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'blogPostCollection' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostAuthorsSitemapQuery, BlogPostAuthorsSitemapQueryVariables>
export const BlogPostCategoriesSitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'BlogPostCategoriesSitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'slug_exists' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '100' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoryCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'BlogPostCategoriesSitemap' }
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostCategoriesSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'linkedFrom' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'blogPostCollection' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
									}
								}
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	BlogPostCategoriesSitemapQuery,
	BlogPostCategoriesSitemapQueryVariables
>
export const BlogPostsSitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'BlogPostsSitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPostFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'slug_exists' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'blogPostCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlogPostSitemap' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BlogPostSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPost' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostsSitemapQuery, BlogPostsSitemapQueryVariables>
export const CaseStudySitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'CaseStudySitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudyFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'slug_exists' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'caseStudyCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaseStudySitemap' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CaseStudySitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseStudy' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CaseStudySitemapQuery, CaseStudySitemapQueryVariables>
export const CategoriesSitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'CategoriesSitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'categoryCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoriesSitemap' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CategoriesSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parentCategory' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<CategoriesSitemapQuery, CategoriesSitemapQueryVariables>
export const GlossarySitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'GlossarySitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'GlossaryFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'slug_exists' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'glossaryCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlossarySitemap' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'GlossarySitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Glossary' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<GlossarySitemapQuery, GlossarySitemapQueryVariables>
export const LeadersSitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'LeadersSitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'PersonFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'type' },
								value: { kind: 'StringValue', value: 'Leader', block: false }
							},
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'slug_exists' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'personCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sys' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }
													]
												}
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'seoMetadata' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
														{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<LeadersSitemapQuery, LeadersSitemapQueryVariables>
export const PressSitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'PressSitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'PressFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'slug_exists' },
								value: { kind: 'BooleanValue', value: true }
							},
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'type_in' },
								value: {
									kind: 'ListValue',
									values: [
										{ kind: 'StringValue', value: 'Press Release', block: false },
										{ kind: 'StringValue', value: 'News / Press Release', block: false }
									]
								}
							},
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'enabled' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'pressCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PressSitemap' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'PressSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Press' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PressSitemapQuery, PressSitemapQueryVariables>
export const ResourcesSitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ResourcesSitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'slug_exists' },
								value: { kind: 'BooleanValue', value: true }
							},
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'status' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'resourceCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourcesSitemap' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ResourcesSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ResourcesSitemapQuery, ResourcesSitemapQueryVariables>
export const RootSitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'RootSitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'pressCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'slug_exists' },
											value: { kind: 'BooleanValue', value: true }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'articleCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'slug_exists' },
											value: { kind: 'BooleanValue', value: true }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
						}
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'leaders' },
						name: { kind: 'Name', value: 'personCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'type' },
											value: { kind: 'StringValue', value: 'Leader', block: false }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'showcaseCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'slug_exists' },
											value: { kind: 'BooleanValue', value: true }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceAppCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'slug_exists' },
											value: { kind: 'BooleanValue', value: true }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceThemeCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'slug_exists' },
											value: { kind: 'BooleanValue', value: true }
										}
									]
								}
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'themePropertyCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: {
									kind: 'ObjectValue',
									fields: [
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'type' },
											value: { kind: 'StringValue', value: 'industry', block: false }
										},
										{
											kind: 'ObjectField',
											name: { kind: 'Name', value: 'slug_exists' },
											value: { kind: 'BooleanValue', value: true }
										}
									]
								}
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '1000' }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
						}
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'appCollectionCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<RootSitemapQuery, RootSitemapQueryVariables>
export const ThemeIndustriesSitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ThemeIndustriesSitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemePropertyFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'type' },
								value: { kind: 'StringValue', value: 'industry', block: false }
							},
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'slug_exists' },
								value: { kind: 'BooleanValue', value: true }
							},
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'slug_not' },
								value: { kind: 'StringValue', value: 'theme-store', block: false }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'themePropertyCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemeIndustry' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ThemeIndustry' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemeProperty' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ThemeIndustriesSitemapQuery, ThemeIndustriesSitemapQueryVariables>
export const ThemesSitemapDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ThemesSitemap' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceThemeFilter' } },
					defaultValue: {
						kind: 'ObjectValue',
						fields: [
							{
								kind: 'ObjectField',
								name: { kind: 'Name', value: 'slug_exists' },
								value: { kind: 'BooleanValue', value: true }
							}
						]
					}
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1000' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'marketplaceThemeCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'skip' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'limit' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemeSitemap' } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ThemeSitemap' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceTheme' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sys' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } }]
						}
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'seoMetadata' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'noindex' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'nofollow' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ThemesSitemapQuery, ThemesSitemapQueryVariables>
export const BlogPostPreviewDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'BlogPostPreview' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPostFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '10' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: false }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'blogPostCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'us' },
												name: { kind: 'Name', value: 'slug' },
												arguments: [
													{
														kind: 'Argument',
														name: { kind: 'Name', value: 'locale' },
														value: { kind: 'StringValue', value: 'en-US', block: false }
													}
												]
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'gb' },
												name: { kind: 'Name', value: 'slug' },
												arguments: [
													{
														kind: 'Argument',
														name: { kind: 'Name', value: 'locale' },
														value: { kind: 'StringValue', value: 'en-GB', block: false }
													}
												]
											},
											{
												kind: 'Field',
												alias: { kind: 'Name', value: 'au' },
												name: { kind: 'Name', value: 'slug' },
												arguments: [
													{
														kind: 'Argument',
														name: { kind: 'Name', value: 'locale' },
														value: { kind: 'StringValue', value: 'en-AU', block: false }
													}
												]
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<BlogPostPreviewQuery, BlogPostPreviewQueryVariables>
export const ArticlePreviewDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ArticlePreview' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '1' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					defaultValue: { kind: 'BooleanValue', value: true }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'articleCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'preview' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleSlug' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'parentArticle' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleSlug' } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ArticleSlug' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'en_US' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-US', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'en_GB' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-GB', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'en_AU' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'en-AU', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'de_AT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-AT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'nb_NO' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nb-NO', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'da_DK' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'da-DK', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'sv_SE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'sv-SE', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'fr_FR' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'fr-FR', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'it_IT' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'it-IT', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'es_ES' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-ES', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'es_MX' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'es-MX', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'nl_NL' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'nl-NL', block: false }
							}
						]
					},
					{
						kind: 'Field',
						alias: { kind: 'Name', value: 'de_DE' },
						name: { kind: 'Name', value: 'slug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'StringValue', value: 'de-DE', block: false }
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ArticlePreviewQuery, ArticlePreviewQueryVariables>
export const RedirectsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'Redirects' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'RedirectFilter' } }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '100' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
					defaultValue: { kind: 'IntValue', value: '0' }
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
				}
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'redirectCollection' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'where' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'skip' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'locale' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } }
							}
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'sourceUrl' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'destinationUrl' } }
										]
									}
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<RedirectsQuery, RedirectsQueryVariables>
export type AssetFragment = {
	__typename?: 'Asset'
	url?: string | null
	title?: string | null
	width?: number | null
	height?: number | null
	description?: string | null
	contentType?: string | null
	sys: { __typename?: 'Sys'; id: string }
}

export type AssetExternalFragment = {
	__typename: 'AssetExternal'
	assetType?: string | null
	url?: string | null
	internalName?: string | null
	altText?: string | null
	sys: { __typename?: 'Sys'; id: string }
}

export type ImageAssetFragment = {
	__typename: 'Asset'
	url?: string | null
	width?: number | null
	height?: number | null
	title?: string | null
	contentType?: string | null
}

export type SeoMetadataFragmentFragment = {
	__typename?: 'SeoMetadata'
	title?: string | null
	description?: string | null
	canonicalUrl?: string | null
	includeTitleTemplate?: boolean | null
	noindex?: boolean | null
	nofollow?: boolean | null
	readingTime?: string | null
	image?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
}

export type TypeFragment = {
	__typename?: '__Type'
	name?: string | null
	fields?: Array<{
		__typename?: '__Field'
		name: string
		type: {
			__typename?: '__Type'
			name?: string | null
			ofType?: { __typename?: '__Type'; name?: string | null } | null
		}
	}> | null
}

export type TypesQueryVariables = Exact<{
	name?: InputMaybe<Scalars['String']['input']>
}>

export type TypesQuery = {
	__typename?: 'Query'
	__type?: {
		__typename?: '__Type'
		name?: string | null
		fields?: Array<{
			__typename?: '__Field'
			name: string
			type: {
				__typename?: '__Type'
				name?: string | null
				ofType?: { __typename?: '__Type'; name?: string | null } | null
			}
		}> | null
	} | null
}

export type ArticleFragment = {
	__typename?: 'Article'
	title?: string | null
	slug?: string | null
	publishedDate?: string | null
	cardSummary?: string | null
	editStatus?: string | null
	interstitialHeadline?: string | null
	status_en_US?: string | null
	status_en_GB?: string | null
	status_en_AU?: string | null
	status_it_IT?: string | null
	status_nl_NL?: string | null
	status_fr_FR?: string | null
	status_es_ES?: string | null
	status_de_DE?: string | null
	status_es_MX?: string | null
	slug_en_US?: string | null
	slug_en_AU?: string | null
	slug_en_GB?: string | null
	slug_es_MX?: string | null
	slug_es_ES?: string | null
	slug_it_IT?: string | null
	slug_fr_FR?: string | null
	slug_nl_NL?: string | null
	slug_de_DE?: string | null
	parentArticle?: {
		__typename?: 'Article'
		title?: string | null
		slug?: string | null
		slug_en_US?: string | null
		slug_en_AU?: string | null
		slug_en_GB?: string | null
		slug_es_MX?: string | null
		slug_es_ES?: string | null
		slug_it_IT?: string | null
		slug_fr_FR?: string | null
		slug_nl_NL?: string | null
		slug_de_DE?: string | null
		parentArticle?: {
			__typename?: 'Article'
			title?: string | null
			slug?: string | null
			parentArticle?: {
				__typename?: 'Article'
				title?: string | null
				slug?: string | null
				parentArticle?: {
					__typename?: 'Article'
					title?: string | null
					slug?: string | null
					parentArticle?: {
						__typename?: 'Article'
						title?: string | null
						slug?: string | null
					} | null
				} | null
			} | null
		} | null
	} | null
	body?: {
		__typename?: 'ArticleBody'
		json: { [key: string]: any }
		links: {
			__typename?: 'ArticleBodyLinks'
			assets: {
				__typename?: 'ArticleBodyAssets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'ArticleBodyEntries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| {
							__typename: 'AssetExternal'
							assetType?: string | null
							url?: string | null
							internalName?: string | null
							altText?: string | null
							sys: { __typename?: 'Sys'; id: string }
					  }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| {
							__typename: 'BasicContentCard'
							title?: string | null
							description?: string | null
							sys: { __typename?: 'Sys'; id: string }
							ctaButtonCollection?: {
								__typename?: 'BasicContentCardCtaButtonCollection'
								items: Array<{
									__typename?: 'CtaButton'
									displayText?: string | null
									sys: { __typename?: 'Sys'; id: string }
									link?: { __typename: 'ExternalLink'; url?: string | null } | null
								} | null>
							} | null
					  }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	summary?: { __typename?: 'ArticleSummary'; json: { [key: string]: any } } | null
	hero?: {
		__typename?: 'Hero'
		headline?: string | null
		subheading?: { __typename?: 'HeroSubheading'; json: { [key: string]: any } } | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	author?: {
		__typename?: 'Person'
		fullName?: string | null
		slug?: string | null
		bio?: { __typename?: 'PersonBio'; json: { [key: string]: any } } | null
		avatar?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	featuredImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		description?: string | null
		title?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	gatedContentSettings?: {
		__typename?: 'GatedContent'
		calloutTitle?: string | null
		calloutDescription?: string | null
		providePrintVersion?: boolean | null
		gatedAsset?: { __typename?: 'Asset'; url?: string | null } | null
	} | null
	faqsCollection?: {
		__typename?: 'ArticleFaqsCollection'
		items: Array<{
			__typename?: 'FaqItem'
			question?: string | null
			answer?: { __typename?: 'FaqItemAnswer'; json: { [key: string]: any } } | null
		} | null>
	} | null
	interstitialCta?: {
		__typename?: 'CtaButton'
		displayText?: string | null
		link?: { __typename: 'ExternalLink'; url?: string | null } | null
	} | null
	sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
	secondaryCtasCollection?: {
		__typename?: 'ArticleSecondaryCtasCollection'
		items: Array<{
			__typename?: 'BasicContentCard'
			title?: string | null
			ctaButtonCollection?: {
				__typename?: 'BasicContentCardCtaButtonCollection'
				items: Array<{
					__typename?: 'CtaButton'
					displayText?: string | null
					openInNewTab?: boolean | null
					formId?: string | null
					link?: { __typename: 'ExternalLink'; url?: string | null } | null
				} | null>
			} | null
		} | null>
	} | null
}

export type ArticlePdfDownloadFragment = {
	__typename?: 'Article'
	title?: string | null
	slug?: string | null
	featuredImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	gatedContentSettings?: {
		__typename?: 'GatedContent'
		calloutTitle?: string | null
		calloutDescription?: string | null
		providePrintVersion?: boolean | null
		gatedAsset?: { __typename?: 'Asset'; url?: string | null } | null
	} | null
}

export type ArticlesQueryVariables = Exact<{
	where?: InputMaybe<ArticleFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ArticlesQuery = {
	__typename?: 'Query'
	articleCollection?: {
		__typename?: 'ArticleCollection'
		items: Array<{
			__typename?: 'Article'
			title?: string | null
			slug?: string | null
			publishedDate?: string | null
			cardSummary?: string | null
			editStatus?: string | null
			interstitialHeadline?: string | null
			status_en_US?: string | null
			status_en_GB?: string | null
			status_en_AU?: string | null
			status_it_IT?: string | null
			status_nl_NL?: string | null
			status_fr_FR?: string | null
			status_es_ES?: string | null
			status_de_DE?: string | null
			status_es_MX?: string | null
			slug_en_US?: string | null
			slug_en_AU?: string | null
			slug_en_GB?: string | null
			slug_es_MX?: string | null
			slug_es_ES?: string | null
			slug_it_IT?: string | null
			slug_fr_FR?: string | null
			slug_nl_NL?: string | null
			slug_de_DE?: string | null
			parentArticle?: {
				__typename?: 'Article'
				title?: string | null
				slug?: string | null
				slug_en_US?: string | null
				slug_en_AU?: string | null
				slug_en_GB?: string | null
				slug_es_MX?: string | null
				slug_es_ES?: string | null
				slug_it_IT?: string | null
				slug_fr_FR?: string | null
				slug_nl_NL?: string | null
				slug_de_DE?: string | null
				parentArticle?: {
					__typename?: 'Article'
					title?: string | null
					slug?: string | null
					parentArticle?: {
						__typename?: 'Article'
						title?: string | null
						slug?: string | null
						parentArticle?: {
							__typename?: 'Article'
							title?: string | null
							slug?: string | null
							parentArticle?: {
								__typename?: 'Article'
								title?: string | null
								slug?: string | null
							} | null
						} | null
					} | null
				} | null
			} | null
			body?: {
				__typename?: 'ArticleBody'
				json: { [key: string]: any }
				links: {
					__typename?: 'ArticleBodyLinks'
					assets: {
						__typename?: 'ArticleBodyAssets'
						block: Array<{
							__typename?: 'Asset'
							url?: string | null
							title?: string | null
							width?: number | null
							height?: number | null
							description?: string | null
							contentType?: string | null
							sys: { __typename?: 'Sys'; id: string }
						} | null>
					}
					entries: {
						__typename?: 'ArticleBodyEntries'
						block: Array<
							| { __typename?: 'AdditionalPersonOptions' }
							| { __typename?: 'AmplitudeExperiments' }
							| { __typename?: 'AppCaseStudy' }
							| { __typename?: 'AppCollection' }
							| { __typename?: 'AppFeature' }
							| { __typename?: 'AppTier' }
							| { __typename?: 'Article' }
							| {
									__typename: 'AssetExternal'
									assetType?: string | null
									url?: string | null
									internalName?: string | null
									altText?: string | null
									sys: { __typename?: 'Sys'; id: string }
							  }
							| { __typename?: 'AssetInternal' }
							| { __typename?: 'Background' }
							| {
									__typename: 'BasicContentCard'
									title?: string | null
									description?: string | null
									sys: { __typename?: 'Sys'; id: string }
									ctaButtonCollection?: {
										__typename?: 'BasicContentCardCtaButtonCollection'
										items: Array<{
											__typename?: 'CtaButton'
											displayText?: string | null
											sys: { __typename?: 'Sys'; id: string }
											link?: { __typename: 'ExternalLink'; url?: string | null } | null
										} | null>
									} | null
							  }
							| { __typename?: 'BlogPost' }
							| { __typename?: 'CaseStudy' }
							| { __typename?: 'CaseStudyProperty' }
							| { __typename?: 'Category' }
							| { __typename?: 'CtaButton' }
							| { __typename?: 'CtaMatPattern' }
							| { __typename?: 'Event' }
							| { __typename?: 'ExternalLink' }
							| { __typename?: 'FaqItem' }
							| { __typename?: 'GatedContent' }
							| { __typename?: 'Glossary' }
							| { __typename?: 'Hero' }
							| { __typename?: 'KeyValue' }
							| { __typename?: 'KeyValueSet' }
							| { __typename?: 'MarketoForm' }
							| { __typename?: 'MarketplaceApp' }
							| { __typename?: 'MarketplaceTheme' }
							| { __typename?: 'Person' }
							| { __typename?: 'Press' }
							| { __typename?: 'Redirect' }
							| { __typename?: 'Resource' }
							| {
									__typename: 'RichTextWrapper'
									type?: string | null
									sys: { __typename?: 'Sys'; id: string }
									content?: {
										__typename?: 'RichTextWrapperContent'
										json: { [key: string]: any }
									} | null
							  }
							| { __typename?: 'Robots' }
							| { __typename?: 'SeoMetadata' }
							| { __typename?: 'Showcase' }
							| { __typename?: 'SpeakerCard' }
							| { __typename?: 'Tag' }
							| { __typename?: 'ThemeProperty' }
							| null
						>
					}
				}
			} | null
			summary?: { __typename?: 'ArticleSummary'; json: { [key: string]: any } } | null
			hero?: {
				__typename?: 'Hero'
				headline?: string | null
				subheading?: { __typename?: 'HeroSubheading'; json: { [key: string]: any } } | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			author?: {
				__typename?: 'Person'
				fullName?: string | null
				slug?: string | null
				bio?: { __typename?: 'PersonBio'; json: { [key: string]: any } } | null
				avatar?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			featuredImage?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				description?: string | null
				title?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			gatedContentSettings?: {
				__typename?: 'GatedContent'
				calloutTitle?: string | null
				calloutDescription?: string | null
				providePrintVersion?: boolean | null
				gatedAsset?: { __typename?: 'Asset'; url?: string | null } | null
			} | null
			faqsCollection?: {
				__typename?: 'ArticleFaqsCollection'
				items: Array<{
					__typename?: 'FaqItem'
					question?: string | null
					answer?: { __typename?: 'FaqItemAnswer'; json: { [key: string]: any } } | null
				} | null>
			} | null
			interstitialCta?: {
				__typename?: 'CtaButton'
				displayText?: string | null
				link?: { __typename: 'ExternalLink'; url?: string | null } | null
			} | null
			sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
			secondaryCtasCollection?: {
				__typename?: 'ArticleSecondaryCtasCollection'
				items: Array<{
					__typename?: 'BasicContentCard'
					title?: string | null
					ctaButtonCollection?: {
						__typename?: 'BasicContentCardCtaButtonCollection'
						items: Array<{
							__typename?: 'CtaButton'
							displayText?: string | null
							openInNewTab?: boolean | null
							formId?: string | null
							link?: { __typename: 'ExternalLink'; url?: string | null } | null
						} | null>
					} | null
				} | null>
			} | null
		} | null>
	} | null
}

export type ArticleBreadcrumbsFragment = {
	__typename?: 'Article'
	title?: string | null
	slug?: string | null
	parentArticle?: {
		__typename?: 'Article'
		title?: string | null
		slug?: string | null
		parentArticle?: {
			__typename?: 'Article'
			title?: string | null
			slug?: string | null
			parentArticle?: {
				__typename?: 'Article'
				title?: string | null
				slug?: string | null
				parentArticle?: {
					__typename?: 'Article'
					title?: string | null
					slug?: string | null
					parentArticle?: {
						__typename?: 'Article'
						title?: string | null
						slug?: string | null
					} | null
				} | null
			} | null
		} | null
	} | null
}

export type ArticleChildFeedGridQueryVariables = Exact<{
	where?: InputMaybe<ArticleFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	order?: InputMaybe<Array<InputMaybe<ArticleOrder>> | InputMaybe<ArticleOrder>>
}>

export type ArticleChildFeedGridQuery = {
	__typename?: 'Query'
	articleCollection?: {
		__typename?: 'ArticleCollection'
		items: Array<{
			__typename?: 'Article'
			slug?: string | null
			title?: string | null
			sys: { __typename?: 'Sys'; id: string }
			hero?: { __typename?: 'Hero'; headline?: string | null } | null
			parentArticle?: {
				__typename?: 'Article'
				title?: string | null
				sys: { __typename?: 'Sys'; id: string }
			} | null
		} | null>
	} | null
}

export type ArticleChildrenCarouselFragment = {
	__typename?: 'Article'
	title?: string | null
	slug?: string | null
	cardSummary?: string | null
	parentArticle?: { __typename?: 'Article'; slug?: string | null } | null
	featuredImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	seoMetadata?: { __typename?: 'SeoMetadata'; description?: string | null } | null
}

export type ArticleChildrenCarouselQueryVariables = Exact<{
	where?: InputMaybe<ArticleFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ArticleChildrenCarouselQuery = {
	__typename?: 'Query'
	articleCollection?: {
		__typename?: 'ArticleCollection'
		items: Array<{
			__typename?: 'Article'
			title?: string | null
			slug?: string | null
			cardSummary?: string | null
			parentArticle?: { __typename?: 'Article'; slug?: string | null } | null
			featuredImage?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			seoMetadata?: { __typename?: 'SeoMetadata'; description?: string | null } | null
		} | null>
	} | null
}

export type ArticleFaqAccordionsFragment = {
	__typename?: 'Article'
	faqsCollection?: {
		__typename?: 'ArticleFaqsCollection'
		items: Array<{
			__typename?: 'FaqItem'
			question?: string | null
			answer?: { __typename?: 'FaqItemAnswer'; json: { [key: string]: any } } | null
		} | null>
	} | null
}

export type ArticleFeedCarouselFragment = {
	__typename?: 'Article'
	title?: string | null
	slug?: string | null
	publishedDate?: string | null
	cardSummary?: string | null
	editStatus?: string | null
	parentArticle?: { __typename?: 'Article'; slug?: string | null } | null
	featuredImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	seoMetadata?: { __typename?: 'SeoMetadata'; description?: string | null } | null
}

export type ArticleFeedCarouselQueryVariables = Exact<{
	where?: InputMaybe<ArticleFilter>
	order?: InputMaybe<Array<InputMaybe<ArticleOrder>> | InputMaybe<ArticleOrder>>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ArticleFeedCarouselQuery = {
	__typename?: 'Query'
	articleCollection?: {
		__typename?: 'ArticleCollection'
		items: Array<{
			__typename?: 'Article'
			title?: string | null
			slug?: string | null
			publishedDate?: string | null
			cardSummary?: string | null
			editStatus?: string | null
			parentArticle?: { __typename?: 'Article'; slug?: string | null } | null
			featuredImage?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			seoMetadata?: { __typename?: 'SeoMetadata'; description?: string | null } | null
		} | null>
	} | null
}

export type ArticleImageFragment = {
	__typename?: 'Article'
	featuredImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	hero?: {
		__typename?: 'Hero'
		headline?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
}

export type ArticleExternalLinkFragment = { __typename: 'ExternalLink'; url?: string | null }

export type ArticleInterstitialFragment = {
	__typename?: 'Article'
	interstitialHeadline?: string | null
	interstitialCta?: {
		__typename?: 'CtaButton'
		displayText?: string | null
		link?: { __typename: 'ExternalLink'; url?: string | null } | null
	} | null
}

export type LocalizedArticleSlugFragment = {
	__typename?: 'Article'
	slug_en_US?: string | null
	slug_en_AU?: string | null
	slug_en_GB?: string | null
	slug_es_MX?: string | null
	slug_es_ES?: string | null
	slug_it_IT?: string | null
	slug_fr_FR?: string | null
	slug_nl_NL?: string | null
	slug_de_DE?: string | null
}

export type ArticleStatusFragment = {
	__typename?: 'Article'
	status_en_US?: string | null
	status_en_GB?: string | null
	status_en_AU?: string | null
	status_it_IT?: string | null
	status_nl_NL?: string | null
	status_fr_FR?: string | null
	status_es_ES?: string | null
	status_de_DE?: string | null
	status_es_MX?: string | null
}

export type ArticleMetaFragment = {
	__typename?: 'Article'
	publishedDate?: string | null
	status_en_US?: string | null
	status_en_GB?: string | null
	status_en_AU?: string | null
	status_it_IT?: string | null
	status_nl_NL?: string | null
	status_fr_FR?: string | null
	status_es_ES?: string | null
	status_de_DE?: string | null
	status_es_MX?: string | null
	slug_en_US?: string | null
	slug_en_AU?: string | null
	slug_en_GB?: string | null
	slug_es_MX?: string | null
	slug_es_ES?: string | null
	slug_it_IT?: string | null
	slug_fr_FR?: string | null
	slug_nl_NL?: string | null
	slug_de_DE?: string | null
	sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
	author?: {
		__typename?: 'Person'
		fullName?: string | null
		slug?: string | null
		avatar?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	parentArticle?: {
		__typename?: 'Article'
		slug_en_US?: string | null
		slug_en_AU?: string | null
		slug_en_GB?: string | null
		slug_es_MX?: string | null
		slug_es_ES?: string | null
		slug_it_IT?: string | null
		slug_fr_FR?: string | null
		slug_nl_NL?: string | null
		slug_de_DE?: string | null
	} | null
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
}

export type LocalizedParentArticlesQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	order?: InputMaybe<Array<InputMaybe<ArticleOrder>> | InputMaybe<ArticleOrder>>
	where?: InputMaybe<ArticleFilter>
}>

export type LocalizedParentArticlesQuery = {
	__typename?: 'Query'
	parentArticles?: {
		__typename?: 'ArticleCollection'
		items: Array<{
			__typename?: 'Article'
			title?: string | null
			slug?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
}

export type ArticleRichTextFragment = {
	__typename?: 'Article'
	body?: {
		__typename?: 'ArticleBody'
		json: { [key: string]: any }
		links: {
			__typename?: 'ArticleBodyLinks'
			assets: {
				__typename?: 'ArticleBodyAssets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'ArticleBodyEntries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| {
							__typename: 'AssetExternal'
							assetType?: string | null
							url?: string | null
							internalName?: string | null
							altText?: string | null
							sys: { __typename?: 'Sys'; id: string }
					  }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| {
							__typename: 'BasicContentCard'
							title?: string | null
							description?: string | null
							sys: { __typename?: 'Sys'; id: string }
							ctaButtonCollection?: {
								__typename?: 'BasicContentCardCtaButtonCollection'
								items: Array<{
									__typename?: 'CtaButton'
									displayText?: string | null
									sys: { __typename?: 'Sys'; id: string }
									link?: { __typename: 'ExternalLink'; url?: string | null } | null
								} | null>
							} | null
					  }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	summary?: { __typename?: 'ArticleSummary'; json: { [key: string]: any } } | null
	hero?: {
		__typename?: 'Hero'
		subheading?: { __typename?: 'HeroSubheading'; json: { [key: string]: any } } | null
	} | null
	author?: {
		__typename?: 'Person'
		bio?: { __typename?: 'PersonBio'; json: { [key: string]: any } } | null
	} | null
}

export type ArticleBodyLinksFragment = {
	__typename?: 'ArticleBody'
	links: {
		__typename?: 'ArticleBodyLinks'
		assets: {
			__typename?: 'ArticleBodyAssets'
			block: Array<{
				__typename?: 'Asset'
				url?: string | null
				title?: string | null
				width?: number | null
				height?: number | null
				description?: string | null
				contentType?: string | null
				sys: { __typename?: 'Sys'; id: string }
			} | null>
		}
		entries: {
			__typename?: 'ArticleBodyEntries'
			block: Array<
				| { __typename?: 'AdditionalPersonOptions' }
				| { __typename?: 'AmplitudeExperiments' }
				| { __typename?: 'AppCaseStudy' }
				| { __typename?: 'AppCollection' }
				| { __typename?: 'AppFeature' }
				| { __typename?: 'AppTier' }
				| { __typename?: 'Article' }
				| {
						__typename: 'AssetExternal'
						assetType?: string | null
						url?: string | null
						internalName?: string | null
						altText?: string | null
						sys: { __typename?: 'Sys'; id: string }
				  }
				| { __typename?: 'AssetInternal' }
				| { __typename?: 'Background' }
				| {
						__typename: 'BasicContentCard'
						title?: string | null
						description?: string | null
						sys: { __typename?: 'Sys'; id: string }
						ctaButtonCollection?: {
							__typename?: 'BasicContentCardCtaButtonCollection'
							items: Array<{
								__typename?: 'CtaButton'
								displayText?: string | null
								sys: { __typename?: 'Sys'; id: string }
								link?: { __typename: 'ExternalLink'; url?: string | null } | null
							} | null>
						} | null
				  }
				| { __typename?: 'BlogPost' }
				| { __typename?: 'CaseStudy' }
				| { __typename?: 'CaseStudyProperty' }
				| { __typename?: 'Category' }
				| { __typename?: 'CtaButton' }
				| { __typename?: 'CtaMatPattern' }
				| { __typename?: 'Event' }
				| { __typename?: 'ExternalLink' }
				| { __typename?: 'FaqItem' }
				| { __typename?: 'GatedContent' }
				| { __typename?: 'Glossary' }
				| { __typename?: 'Hero' }
				| { __typename?: 'KeyValue' }
				| { __typename?: 'KeyValueSet' }
				| { __typename?: 'MarketoForm' }
				| { __typename?: 'MarketplaceApp' }
				| { __typename?: 'MarketplaceTheme' }
				| { __typename?: 'Person' }
				| { __typename?: 'Press' }
				| { __typename?: 'Redirect' }
				| { __typename?: 'Resource' }
				| {
						__typename: 'RichTextWrapper'
						type?: string | null
						sys: { __typename?: 'Sys'; id: string }
						content?: { __typename?: 'RichTextWrapperContent'; json: { [key: string]: any } } | null
				  }
				| { __typename?: 'Robots' }
				| { __typename?: 'SeoMetadata' }
				| { __typename?: 'Showcase' }
				| { __typename?: 'SpeakerCard' }
				| { __typename?: 'Tag' }
				| { __typename?: 'ThemeProperty' }
				| null
			>
		}
	}
}

export type ArticleAssetFragment = {
	__typename?: 'Asset'
	url?: string | null
	title?: string | null
	width?: number | null
	height?: number | null
	description?: string | null
	contentType?: string | null
	sys: { __typename?: 'Sys'; id: string }
}

export type ArticleAssetExternalFragment = {
	__typename: 'AssetExternal'
	assetType?: string | null
	url?: string | null
	internalName?: string | null
	altText?: string | null
	sys: { __typename?: 'Sys'; id: string }
}

export type BasicContentCardFragment = {
	__typename: 'BasicContentCard'
	title?: string | null
	description?: string | null
	sys: { __typename?: 'Sys'; id: string }
	ctaButtonCollection?: {
		__typename?: 'BasicContentCardCtaButtonCollection'
		items: Array<{
			__typename?: 'CtaButton'
			displayText?: string | null
			sys: { __typename?: 'Sys'; id: string }
			link?: { __typename: 'ExternalLink'; url?: string | null } | null
		} | null>
	} | null
}

export type RichTextCalloutFragment = {
	__typename: 'RichTextWrapper'
	type?: string | null
	sys: { __typename?: 'Sys'; id: string }
	content?: { __typename?: 'RichTextWrapperContent'; json: { [key: string]: any } } | null
}

export type ArticleSecondaryCtAsFragment = {
	__typename?: 'Article'
	secondaryCtasCollection?: {
		__typename?: 'ArticleSecondaryCtasCollection'
		items: Array<{
			__typename?: 'BasicContentCard'
			title?: string | null
			ctaButtonCollection?: {
				__typename?: 'BasicContentCardCtaButtonCollection'
				items: Array<{
					__typename?: 'CtaButton'
					displayText?: string | null
					openInNewTab?: boolean | null
					formId?: string | null
					link?: { __typename: 'ExternalLink'; url?: string | null } | null
				} | null>
			} | null
		} | null>
	} | null
}

export type ArticleTextFragment = {
	__typename?: 'Article'
	title?: string | null
	slug?: string | null
	publishedDate?: string | null
	cardSummary?: string | null
	hero?: { __typename?: 'Hero'; headline?: string | null } | null
}

export type BlogPostFragment = {
	__typename?: 'BlogPost'
	title?: string | null
	publishedDate?: string | null
	slug?: string | null
	categoriesCollection?: {
		__typename?: 'BlogPostCategoriesCollection'
		items: Array<{
			__typename: 'Category'
			name?: string | null
			slug?: string | null
			sys: { __typename?: 'Sys'; id: string }
			parentCategory?: {
				__typename?: 'Category'
				name?: string | null
				slug?: string | null
				parentCategory?: {
					__typename?: 'Category'
					name?: string | null
					slug?: string | null
					parentCategory?: {
						__typename?: 'Category'
						name?: string | null
						slug?: string | null
					} | null
				} | null
			} | null
		} | null>
	} | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	author?: {
		__typename?: 'Person'
		fullName?: string | null
		slug?: string | null
		avatar?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
		bio?: { __typename?: 'PersonBio'; json: { [key: string]: any } } | null
	} | null
	tagsCollection?: {
		__typename?: 'BlogPostTagsCollection'
		items: Array<{ __typename?: 'Tag'; name?: string | null; slug?: string | null } | null>
	} | null
	featuredImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	gatedContentSettings?: {
		__typename?: 'GatedContent'
		calloutTitle?: string | null
		calloutDescription?: string | null
		providePrintVersion?: boolean | null
		gatedAsset?: { __typename?: 'Asset'; url?: string | null } | null
	} | null
	body?: {
		__typename?: 'BlogPostBody'
		json: { [key: string]: any }
		links: {
			__typename?: 'BlogPostBodyLinks'
			assets: {
				__typename?: 'BlogPostBodyAssets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'BlogPostBodyEntries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| {
							__typename: 'AssetExternal'
							assetType?: string | null
							url?: string | null
							internalName?: string | null
							altText?: string | null
							sys: { __typename?: 'Sys'; id: string }
					  }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	summary?: { __typename?: 'BlogPostSummary'; json: { [key: string]: any } } | null
	ctaMatPattern?: {
		__typename?: 'CtaMatPattern'
		ctaText?: string | null
		ctaUrl?: string | null
		heading?: string | null
		subheading?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
}

export type BlogPostsQueryVariables = Exact<{
	where?: InputMaybe<BlogPostFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type BlogPostsQuery = {
	__typename?: 'Query'
	blogPostCollection?: {
		__typename?: 'BlogPostCollection'
		items: Array<{
			__typename?: 'BlogPost'
			title?: string | null
			publishedDate?: string | null
			slug?: string | null
			sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
			categoriesCollection?: {
				__typename?: 'BlogPostCategoriesCollection'
				items: Array<{
					__typename: 'Category'
					name?: string | null
					slug?: string | null
					sys: { __typename?: 'Sys'; id: string }
					parentCategory?: {
						__typename?: 'Category'
						name?: string | null
						slug?: string | null
						parentCategory?: {
							__typename?: 'Category'
							name?: string | null
							slug?: string | null
							parentCategory?: {
								__typename?: 'Category'
								name?: string | null
								slug?: string | null
							} | null
						} | null
					} | null
				} | null>
			} | null
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			author?: {
				__typename?: 'Person'
				fullName?: string | null
				slug?: string | null
				avatar?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
				bio?: { __typename?: 'PersonBio'; json: { [key: string]: any } } | null
			} | null
			tagsCollection?: {
				__typename?: 'BlogPostTagsCollection'
				items: Array<{ __typename?: 'Tag'; name?: string | null; slug?: string | null } | null>
			} | null
			featuredImage?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			gatedContentSettings?: {
				__typename?: 'GatedContent'
				calloutTitle?: string | null
				calloutDescription?: string | null
				providePrintVersion?: boolean | null
				gatedAsset?: { __typename?: 'Asset'; url?: string | null } | null
			} | null
			body?: {
				__typename?: 'BlogPostBody'
				json: { [key: string]: any }
				links: {
					__typename?: 'BlogPostBodyLinks'
					assets: {
						__typename?: 'BlogPostBodyAssets'
						block: Array<{
							__typename?: 'Asset'
							url?: string | null
							title?: string | null
							width?: number | null
							height?: number | null
							description?: string | null
							contentType?: string | null
							sys: { __typename?: 'Sys'; id: string }
						} | null>
					}
					entries: {
						__typename?: 'BlogPostBodyEntries'
						block: Array<
							| { __typename?: 'AdditionalPersonOptions' }
							| { __typename?: 'AmplitudeExperiments' }
							| { __typename?: 'AppCaseStudy' }
							| { __typename?: 'AppCollection' }
							| { __typename?: 'AppFeature' }
							| { __typename?: 'AppTier' }
							| { __typename?: 'Article' }
							| {
									__typename: 'AssetExternal'
									assetType?: string | null
									url?: string | null
									internalName?: string | null
									altText?: string | null
									sys: { __typename?: 'Sys'; id: string }
							  }
							| { __typename?: 'AssetInternal' }
							| { __typename?: 'Background' }
							| { __typename?: 'BasicContentCard' }
							| { __typename?: 'BlogPost' }
							| { __typename?: 'CaseStudy' }
							| { __typename?: 'CaseStudyProperty' }
							| { __typename?: 'Category' }
							| { __typename?: 'CtaButton' }
							| { __typename?: 'CtaMatPattern' }
							| { __typename?: 'Event' }
							| { __typename?: 'ExternalLink' }
							| { __typename?: 'FaqItem' }
							| { __typename?: 'GatedContent' }
							| { __typename?: 'Glossary' }
							| { __typename?: 'Hero' }
							| { __typename?: 'KeyValue' }
							| { __typename?: 'KeyValueSet' }
							| { __typename?: 'MarketoForm' }
							| { __typename?: 'MarketplaceApp' }
							| { __typename?: 'MarketplaceTheme' }
							| { __typename?: 'Person' }
							| { __typename?: 'Press' }
							| { __typename?: 'Redirect' }
							| { __typename?: 'Resource' }
							| {
									__typename: 'RichTextWrapper'
									type?: string | null
									sys: { __typename?: 'Sys'; id: string }
									content?: {
										__typename?: 'RichTextWrapperContent'
										json: { [key: string]: any }
									} | null
							  }
							| { __typename?: 'Robots' }
							| { __typename?: 'SeoMetadata' }
							| { __typename?: 'Showcase' }
							| { __typename?: 'SpeakerCard' }
							| { __typename?: 'Tag' }
							| { __typename?: 'ThemeProperty' }
							| null
						>
					}
				}
			} | null
			summary?: { __typename?: 'BlogPostSummary'; json: { [key: string]: any } } | null
			ctaMatPattern?: {
				__typename?: 'CtaMatPattern'
				ctaText?: string | null
				ctaUrl?: string | null
				heading?: string | null
				subheading?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
		} | null>
	} | null
}

export type BlogPostBreadcrumbsFragment = {
	__typename?: 'BlogPost'
	title?: string | null
	categoriesCollection?: {
		__typename?: 'BlogPostCategoriesCollection'
		items: Array<{
			__typename?: 'Category'
			name?: string | null
			slug?: string | null
			parentCategory?: {
				__typename?: 'Category'
				name?: string | null
				slug?: string | null
				parentCategory?: {
					__typename?: 'Category'
					name?: string | null
					slug?: string | null
					parentCategory?: {
						__typename?: 'Category'
						name?: string | null
						slug?: string | null
					} | null
				} | null
			} | null
		} | null>
	} | null
}

export type BlogPostButtonFragment = {
	__typename?: 'BlogPost'
	ctaMatPattern?: {
		__typename?: 'CtaMatPattern'
		ctaText?: string | null
		ctaUrl?: string | null
	} | null
}

export type BlogPostCategoriesFragment = {
	__typename?: 'BlogPost'
	title?: string | null
	categoriesCollection?: {
		__typename?: 'BlogPostCategoriesCollection'
		items: Array<{
			__typename: 'Category'
			name?: string | null
			slug?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
}

export type BlogPostFeedQueryVariables = Exact<{
	where?: InputMaybe<BlogPostFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type BlogPostFeedQuery = {
	__typename?: 'Query'
	blogPostCollection?: {
		__typename?: 'BlogPostCollection'
		limit: number
		skip: number
		total: number
		items: Array<{
			__typename?: 'BlogPost'
			title?: string | null
			slug?: string | null
			hideOnHomepage?: boolean | null
			sys: { __typename?: 'Sys'; id: string }
			author?: { __typename?: 'Person'; fullName?: string | null } | null
			featuredImage?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			categoriesCollection?: {
				__typename?: 'BlogPostCategoriesCollection'
				items: Array<{ __typename?: 'Category'; name?: string | null; slug?: string | null } | null>
			} | null
		} | null>
	} | null
}

export type BlogPostFeedPersonsQueryVariables = Exact<{
	query?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type BlogPostFeedPersonsQuery = {
	__typename?: 'Query'
	personCollection?: {
		__typename?: 'PersonCollection'
		items: Array<{
			__typename?: 'Person'
			fullName?: string | null
			slug?: string | null
			linkedFrom?: {
				__typename?: 'PersonLinkingCollections'
				blogPostCollection?: { __typename?: 'BlogPostCollection'; total: number } | null
			} | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
}

export type BlogPostFeedCategoriesQueryVariables = Exact<{
	query?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type BlogPostFeedCategoriesQuery = {
	__typename?: 'Query'
	categoryCollection?: {
		__typename?: 'CategoryCollection'
		items: Array<{
			__typename?: 'Category'
			name?: string | null
			slug?: string | null
			linkedFrom?: {
				__typename?: 'CategoryLinkingCollections'
				blogPostCollection?: { __typename?: 'BlogPostCollection'; total: number } | null
			} | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
}

export type BlogPostImageFragment = {
	__typename?: 'BlogPost'
	featuredImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	ctaMatPattern?: {
		__typename?: 'CtaMatPattern'
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
}

export type BlogPostMetaFragment = {
	__typename?: 'BlogPost'
	publishedDate?: string | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	author?: {
		__typename?: 'Person'
		fullName?: string | null
		slug?: string | null
		avatar?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	tagsCollection?: {
		__typename?: 'BlogPostTagsCollection'
		items: Array<{ __typename?: 'Tag'; name?: string | null; slug?: string | null } | null>
	} | null
}

export type BlogPostPdfDownloadFragment = {
	__typename?: 'BlogPost'
	title?: string | null
	slug?: string | null
	featuredImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	gatedContentSettings?: {
		__typename?: 'GatedContent'
		calloutTitle?: string | null
		calloutDescription?: string | null
		providePrintVersion?: boolean | null
		gatedAsset?: { __typename?: 'Asset'; url?: string | null } | null
	} | null
}

export type BlogPostRichTextFragment = {
	__typename?: 'BlogPost'
	body?: {
		__typename?: 'BlogPostBody'
		json: { [key: string]: any }
		links: {
			__typename?: 'BlogPostBodyLinks'
			assets: {
				__typename?: 'BlogPostBodyAssets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'BlogPostBodyEntries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| {
							__typename: 'AssetExternal'
							assetType?: string | null
							url?: string | null
							internalName?: string | null
							altText?: string | null
							sys: { __typename?: 'Sys'; id: string }
					  }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	summary?: { __typename?: 'BlogPostSummary'; json: { [key: string]: any } } | null
	author?: {
		__typename?: 'Person'
		bio?: { __typename?: 'PersonBio'; json: { [key: string]: any } } | null
	} | null
}

export type BlogPostBodyLinksFragment = {
	__typename?: 'BlogPostBody'
	links: {
		__typename?: 'BlogPostBodyLinks'
		assets: {
			__typename?: 'BlogPostBodyAssets'
			block: Array<{
				__typename?: 'Asset'
				url?: string | null
				title?: string | null
				width?: number | null
				height?: number | null
				description?: string | null
				contentType?: string | null
				sys: { __typename?: 'Sys'; id: string }
			} | null>
		}
		entries: {
			__typename?: 'BlogPostBodyEntries'
			block: Array<
				| { __typename?: 'AdditionalPersonOptions' }
				| { __typename?: 'AmplitudeExperiments' }
				| { __typename?: 'AppCaseStudy' }
				| { __typename?: 'AppCollection' }
				| { __typename?: 'AppFeature' }
				| { __typename?: 'AppTier' }
				| { __typename?: 'Article' }
				| {
						__typename: 'AssetExternal'
						assetType?: string | null
						url?: string | null
						internalName?: string | null
						altText?: string | null
						sys: { __typename?: 'Sys'; id: string }
				  }
				| { __typename?: 'AssetInternal' }
				| { __typename?: 'Background' }
				| { __typename?: 'BasicContentCard' }
				| { __typename?: 'BlogPost' }
				| { __typename?: 'CaseStudy' }
				| { __typename?: 'CaseStudyProperty' }
				| { __typename?: 'Category' }
				| { __typename?: 'CtaButton' }
				| { __typename?: 'CtaMatPattern' }
				| { __typename?: 'Event' }
				| { __typename?: 'ExternalLink' }
				| { __typename?: 'FaqItem' }
				| { __typename?: 'GatedContent' }
				| { __typename?: 'Glossary' }
				| { __typename?: 'Hero' }
				| { __typename?: 'KeyValue' }
				| { __typename?: 'KeyValueSet' }
				| { __typename?: 'MarketoForm' }
				| { __typename?: 'MarketplaceApp' }
				| { __typename?: 'MarketplaceTheme' }
				| { __typename?: 'Person' }
				| { __typename?: 'Press' }
				| { __typename?: 'Redirect' }
				| { __typename?: 'Resource' }
				| {
						__typename: 'RichTextWrapper'
						type?: string | null
						sys: { __typename?: 'Sys'; id: string }
						content?: { __typename?: 'RichTextWrapperContent'; json: { [key: string]: any } } | null
				  }
				| { __typename?: 'Robots' }
				| { __typename?: 'SeoMetadata' }
				| { __typename?: 'Showcase' }
				| { __typename?: 'SpeakerCard' }
				| { __typename?: 'Tag' }
				| { __typename?: 'ThemeProperty' }
				| null
			>
		}
	}
}

export type BlogPostAssetFragment = {
	__typename?: 'Asset'
	url?: string | null
	title?: string | null
	width?: number | null
	height?: number | null
	description?: string | null
	contentType?: string | null
	sys: { __typename?: 'Sys'; id: string }
}

export type BlogPostAssetExternalFragment = {
	__typename: 'AssetExternal'
	assetType?: string | null
	url?: string | null
	internalName?: string | null
	altText?: string | null
	sys: { __typename?: 'Sys'; id: string }
}

export type BlogPostTextFragment = {
	__typename?: 'BlogPost'
	title?: string | null
	ctaMatPattern?: {
		__typename?: 'CtaMatPattern'
		heading?: string | null
		subheading?: string | null
	} | null
	author?: { __typename?: 'Person'; fullName?: string | null } | null
}

export type CaseStudyFragment = {
	__typename?: 'CaseStudy'
	title?: string | null
	slug?: string | null
	publishedDate?: string | null
	status_en_US?: boolean | null
	status_en_GB?: boolean | null
	status_en_AU?: boolean | null
	status_it_IT?: boolean | null
	status_nl_NL?: boolean | null
	status_fr_FR?: boolean | null
	status_es_ES?: boolean | null
	status_de_DE?: boolean | null
	status_es_MX?: boolean | null
	hero?: {
		__typename?: 'Hero'
		headline?: string | null
		cta?: {
			__typename?: 'CtaButton'
			displayText?: string | null
			link?: { __typename?: 'ExternalLink'; url?: string | null } | null
		} | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
		subheading?: { __typename?: 'HeroSubheading'; json: { [key: string]: any } } | null
	} | null
	summary?: { __typename?: 'CaseStudySummary'; json: { [key: string]: any } } | null
	quote?: { __typename?: 'CaseStudyQuote'; json: { [key: string]: any } } | null
	statsTitle?: { __typename?: 'CaseStudyStatsTitle'; json: { [key: string]: any } } | null
	statsSubtitle?: { __typename?: 'CaseStudyStatsSubtitle'; json: { [key: string]: any } } | null
	statsDescription?: {
		__typename?: 'CaseStudyStatsDescription'
		json: { [key: string]: any }
	} | null
	extraSection?: {
		__typename?: 'CaseStudyExtraSection'
		json: { [key: string]: any }
		links: {
			__typename?: 'CaseStudyExtraSectionLinks'
			assets: {
				__typename?: 'CaseStudyExtraSectionAssets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'CaseStudyExtraSectionEntries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| { __typename?: 'AssetExternal' }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	section1?: {
		__typename?: 'CaseStudySection1'
		json: { [key: string]: any }
		links: {
			__typename?: 'CaseStudySection1Links'
			assets: {
				__typename?: 'CaseStudySection1Assets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'CaseStudySection1Entries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| { __typename?: 'AssetExternal' }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	section2?: {
		__typename?: 'CaseStudySection2'
		json: { [key: string]: any }
		links: {
			__typename?: 'CaseStudySection2Links'
			assets: {
				__typename?: 'CaseStudySection2Assets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'CaseStudySection2Entries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| { __typename?: 'AssetExternal' }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	section3?: {
		__typename?: 'CaseStudySection3'
		json: { [key: string]: any }
		links: {
			__typename?: 'CaseStudySection3Links'
			assets: {
				__typename?: 'CaseStudySection3Assets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'CaseStudySection3Entries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| { __typename?: 'AssetExternal' }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	section4?: {
		__typename?: 'CaseStudySection4'
		json: { [key: string]: any }
		links: {
			__typename?: 'CaseStudySection4Links'
			assets: {
				__typename?: 'CaseStudySection4Assets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'CaseStudySection4Entries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| { __typename?: 'AssetExternal' }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	section5?: {
		__typename?: 'CaseStudySection5'
		json: { [key: string]: any }
		links: {
			__typename?: 'CaseStudySection5Links'
			assets: {
				__typename?: 'CaseStudySection5Assets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'CaseStudySection5Entries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| { __typename?: 'AssetExternal' }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	caseStudyStatsCollection?: {
		__typename?: 'CaseStudyCaseStudyStatsCollection'
		items: Array<{ __typename?: 'KeyValue'; key?: string | null; value?: string | null } | null>
	} | null
	image?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	quoteImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	featuredVideo?: {
		__typename?: 'AssetExternal'
		url?: string | null
		altText?: string | null
	} | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	competitorsListCollection?: {
		__typename?: 'CaseStudyCompetitorsListCollection'
		items: Array<{
			__typename?: 'CaseStudyProperty'
			name?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
	industriesListCollection?: {
		__typename?: 'CaseStudyIndustriesListCollection'
		items: Array<{
			__typename?: 'CaseStudyProperty'
			name?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
	topicsListCollection?: {
		__typename?: 'CaseStudyTopicsListCollection'
		items: Array<{
			__typename?: 'CaseStudyProperty'
			name?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
	relatedCaseStudiesCollection?: {
		__typename?: 'CaseStudyRelatedCaseStudiesCollection'
		items: Array<{
			__typename?: 'CaseStudy'
			status?: boolean | null
			title?: string | null
			slug?: string | null
			sys: { __typename?: 'Sys'; id: string }
			image?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
		} | null>
	} | null
}

export type CaseStudyHeroFragment = {
	__typename?: 'CaseStudy'
	hero?: {
		__typename?: 'Hero'
		headline?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
		subheading?: { __typename?: 'HeroSubheading'; json: { [key: string]: any } } | null
		cta?: {
			__typename?: 'CtaButton'
			displayText?: string | null
			link?: { __typename?: 'ExternalLink'; url?: string | null } | null
		} | null
	} | null
}

export type CaseStudiesQueryVariables = Exact<{
	where?: InputMaybe<CaseStudyFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type CaseStudiesQuery = {
	__typename?: 'Query'
	caseStudyCollection?: {
		__typename?: 'CaseStudyCollection'
		items: Array<{
			__typename?: 'CaseStudy'
			title?: string | null
			slug?: string | null
			publishedDate?: string | null
			status_en_US?: boolean | null
			status_en_GB?: boolean | null
			status_en_AU?: boolean | null
			status_it_IT?: boolean | null
			status_nl_NL?: boolean | null
			status_fr_FR?: boolean | null
			status_es_ES?: boolean | null
			status_de_DE?: boolean | null
			status_es_MX?: boolean | null
			sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
			hero?: {
				__typename?: 'Hero'
				headline?: string | null
				cta?: {
					__typename?: 'CtaButton'
					displayText?: string | null
					link?: { __typename?: 'ExternalLink'; url?: string | null } | null
				} | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
				subheading?: { __typename?: 'HeroSubheading'; json: { [key: string]: any } } | null
			} | null
			summary?: { __typename?: 'CaseStudySummary'; json: { [key: string]: any } } | null
			quote?: { __typename?: 'CaseStudyQuote'; json: { [key: string]: any } } | null
			statsTitle?: { __typename?: 'CaseStudyStatsTitle'; json: { [key: string]: any } } | null
			statsSubtitle?: { __typename?: 'CaseStudyStatsSubtitle'; json: { [key: string]: any } } | null
			statsDescription?: {
				__typename?: 'CaseStudyStatsDescription'
				json: { [key: string]: any }
			} | null
			extraSection?: {
				__typename?: 'CaseStudyExtraSection'
				json: { [key: string]: any }
				links: {
					__typename?: 'CaseStudyExtraSectionLinks'
					assets: {
						__typename?: 'CaseStudyExtraSectionAssets'
						block: Array<{
							__typename?: 'Asset'
							url?: string | null
							title?: string | null
							width?: number | null
							height?: number | null
							description?: string | null
							contentType?: string | null
							sys: { __typename?: 'Sys'; id: string }
						} | null>
					}
					entries: {
						__typename?: 'CaseStudyExtraSectionEntries'
						block: Array<
							| { __typename?: 'AdditionalPersonOptions' }
							| { __typename?: 'AmplitudeExperiments' }
							| { __typename?: 'AppCaseStudy' }
							| { __typename?: 'AppCollection' }
							| { __typename?: 'AppFeature' }
							| { __typename?: 'AppTier' }
							| { __typename?: 'Article' }
							| { __typename?: 'AssetExternal' }
							| { __typename?: 'AssetInternal' }
							| { __typename?: 'Background' }
							| { __typename?: 'BasicContentCard' }
							| { __typename?: 'BlogPost' }
							| { __typename?: 'CaseStudy' }
							| { __typename?: 'CaseStudyProperty' }
							| { __typename?: 'Category' }
							| { __typename?: 'CtaButton' }
							| { __typename?: 'CtaMatPattern' }
							| { __typename?: 'Event' }
							| { __typename?: 'ExternalLink' }
							| { __typename?: 'FaqItem' }
							| { __typename?: 'GatedContent' }
							| { __typename?: 'Glossary' }
							| { __typename?: 'Hero' }
							| { __typename?: 'KeyValue' }
							| { __typename?: 'KeyValueSet' }
							| { __typename?: 'MarketoForm' }
							| { __typename?: 'MarketplaceApp' }
							| { __typename?: 'MarketplaceTheme' }
							| { __typename?: 'Person' }
							| { __typename?: 'Press' }
							| { __typename?: 'Redirect' }
							| { __typename?: 'Resource' }
							| {
									__typename: 'RichTextWrapper'
									type?: string | null
									sys: { __typename?: 'Sys'; id: string }
									content?: {
										__typename?: 'RichTextWrapperContent'
										json: { [key: string]: any }
									} | null
							  }
							| { __typename?: 'Robots' }
							| { __typename?: 'SeoMetadata' }
							| { __typename?: 'Showcase' }
							| { __typename?: 'SpeakerCard' }
							| { __typename?: 'Tag' }
							| { __typename?: 'ThemeProperty' }
							| null
						>
					}
				}
			} | null
			section1?: {
				__typename?: 'CaseStudySection1'
				json: { [key: string]: any }
				links: {
					__typename?: 'CaseStudySection1Links'
					assets: {
						__typename?: 'CaseStudySection1Assets'
						block: Array<{
							__typename?: 'Asset'
							url?: string | null
							title?: string | null
							width?: number | null
							height?: number | null
							description?: string | null
							contentType?: string | null
							sys: { __typename?: 'Sys'; id: string }
						} | null>
					}
					entries: {
						__typename?: 'CaseStudySection1Entries'
						block: Array<
							| { __typename?: 'AdditionalPersonOptions' }
							| { __typename?: 'AmplitudeExperiments' }
							| { __typename?: 'AppCaseStudy' }
							| { __typename?: 'AppCollection' }
							| { __typename?: 'AppFeature' }
							| { __typename?: 'AppTier' }
							| { __typename?: 'Article' }
							| { __typename?: 'AssetExternal' }
							| { __typename?: 'AssetInternal' }
							| { __typename?: 'Background' }
							| { __typename?: 'BasicContentCard' }
							| { __typename?: 'BlogPost' }
							| { __typename?: 'CaseStudy' }
							| { __typename?: 'CaseStudyProperty' }
							| { __typename?: 'Category' }
							| { __typename?: 'CtaButton' }
							| { __typename?: 'CtaMatPattern' }
							| { __typename?: 'Event' }
							| { __typename?: 'ExternalLink' }
							| { __typename?: 'FaqItem' }
							| { __typename?: 'GatedContent' }
							| { __typename?: 'Glossary' }
							| { __typename?: 'Hero' }
							| { __typename?: 'KeyValue' }
							| { __typename?: 'KeyValueSet' }
							| { __typename?: 'MarketoForm' }
							| { __typename?: 'MarketplaceApp' }
							| { __typename?: 'MarketplaceTheme' }
							| { __typename?: 'Person' }
							| { __typename?: 'Press' }
							| { __typename?: 'Redirect' }
							| { __typename?: 'Resource' }
							| {
									__typename: 'RichTextWrapper'
									type?: string | null
									sys: { __typename?: 'Sys'; id: string }
									content?: {
										__typename?: 'RichTextWrapperContent'
										json: { [key: string]: any }
									} | null
							  }
							| { __typename?: 'Robots' }
							| { __typename?: 'SeoMetadata' }
							| { __typename?: 'Showcase' }
							| { __typename?: 'SpeakerCard' }
							| { __typename?: 'Tag' }
							| { __typename?: 'ThemeProperty' }
							| null
						>
					}
				}
			} | null
			section2?: {
				__typename?: 'CaseStudySection2'
				json: { [key: string]: any }
				links: {
					__typename?: 'CaseStudySection2Links'
					assets: {
						__typename?: 'CaseStudySection2Assets'
						block: Array<{
							__typename?: 'Asset'
							url?: string | null
							title?: string | null
							width?: number | null
							height?: number | null
							description?: string | null
							contentType?: string | null
							sys: { __typename?: 'Sys'; id: string }
						} | null>
					}
					entries: {
						__typename?: 'CaseStudySection2Entries'
						block: Array<
							| { __typename?: 'AdditionalPersonOptions' }
							| { __typename?: 'AmplitudeExperiments' }
							| { __typename?: 'AppCaseStudy' }
							| { __typename?: 'AppCollection' }
							| { __typename?: 'AppFeature' }
							| { __typename?: 'AppTier' }
							| { __typename?: 'Article' }
							| { __typename?: 'AssetExternal' }
							| { __typename?: 'AssetInternal' }
							| { __typename?: 'Background' }
							| { __typename?: 'BasicContentCard' }
							| { __typename?: 'BlogPost' }
							| { __typename?: 'CaseStudy' }
							| { __typename?: 'CaseStudyProperty' }
							| { __typename?: 'Category' }
							| { __typename?: 'CtaButton' }
							| { __typename?: 'CtaMatPattern' }
							| { __typename?: 'Event' }
							| { __typename?: 'ExternalLink' }
							| { __typename?: 'FaqItem' }
							| { __typename?: 'GatedContent' }
							| { __typename?: 'Glossary' }
							| { __typename?: 'Hero' }
							| { __typename?: 'KeyValue' }
							| { __typename?: 'KeyValueSet' }
							| { __typename?: 'MarketoForm' }
							| { __typename?: 'MarketplaceApp' }
							| { __typename?: 'MarketplaceTheme' }
							| { __typename?: 'Person' }
							| { __typename?: 'Press' }
							| { __typename?: 'Redirect' }
							| { __typename?: 'Resource' }
							| {
									__typename: 'RichTextWrapper'
									type?: string | null
									sys: { __typename?: 'Sys'; id: string }
									content?: {
										__typename?: 'RichTextWrapperContent'
										json: { [key: string]: any }
									} | null
							  }
							| { __typename?: 'Robots' }
							| { __typename?: 'SeoMetadata' }
							| { __typename?: 'Showcase' }
							| { __typename?: 'SpeakerCard' }
							| { __typename?: 'Tag' }
							| { __typename?: 'ThemeProperty' }
							| null
						>
					}
				}
			} | null
			section3?: {
				__typename?: 'CaseStudySection3'
				json: { [key: string]: any }
				links: {
					__typename?: 'CaseStudySection3Links'
					assets: {
						__typename?: 'CaseStudySection3Assets'
						block: Array<{
							__typename?: 'Asset'
							url?: string | null
							title?: string | null
							width?: number | null
							height?: number | null
							description?: string | null
							contentType?: string | null
							sys: { __typename?: 'Sys'; id: string }
						} | null>
					}
					entries: {
						__typename?: 'CaseStudySection3Entries'
						block: Array<
							| { __typename?: 'AdditionalPersonOptions' }
							| { __typename?: 'AmplitudeExperiments' }
							| { __typename?: 'AppCaseStudy' }
							| { __typename?: 'AppCollection' }
							| { __typename?: 'AppFeature' }
							| { __typename?: 'AppTier' }
							| { __typename?: 'Article' }
							| { __typename?: 'AssetExternal' }
							| { __typename?: 'AssetInternal' }
							| { __typename?: 'Background' }
							| { __typename?: 'BasicContentCard' }
							| { __typename?: 'BlogPost' }
							| { __typename?: 'CaseStudy' }
							| { __typename?: 'CaseStudyProperty' }
							| { __typename?: 'Category' }
							| { __typename?: 'CtaButton' }
							| { __typename?: 'CtaMatPattern' }
							| { __typename?: 'Event' }
							| { __typename?: 'ExternalLink' }
							| { __typename?: 'FaqItem' }
							| { __typename?: 'GatedContent' }
							| { __typename?: 'Glossary' }
							| { __typename?: 'Hero' }
							| { __typename?: 'KeyValue' }
							| { __typename?: 'KeyValueSet' }
							| { __typename?: 'MarketoForm' }
							| { __typename?: 'MarketplaceApp' }
							| { __typename?: 'MarketplaceTheme' }
							| { __typename?: 'Person' }
							| { __typename?: 'Press' }
							| { __typename?: 'Redirect' }
							| { __typename?: 'Resource' }
							| {
									__typename: 'RichTextWrapper'
									type?: string | null
									sys: { __typename?: 'Sys'; id: string }
									content?: {
										__typename?: 'RichTextWrapperContent'
										json: { [key: string]: any }
									} | null
							  }
							| { __typename?: 'Robots' }
							| { __typename?: 'SeoMetadata' }
							| { __typename?: 'Showcase' }
							| { __typename?: 'SpeakerCard' }
							| { __typename?: 'Tag' }
							| { __typename?: 'ThemeProperty' }
							| null
						>
					}
				}
			} | null
			section4?: {
				__typename?: 'CaseStudySection4'
				json: { [key: string]: any }
				links: {
					__typename?: 'CaseStudySection4Links'
					assets: {
						__typename?: 'CaseStudySection4Assets'
						block: Array<{
							__typename?: 'Asset'
							url?: string | null
							title?: string | null
							width?: number | null
							height?: number | null
							description?: string | null
							contentType?: string | null
							sys: { __typename?: 'Sys'; id: string }
						} | null>
					}
					entries: {
						__typename?: 'CaseStudySection4Entries'
						block: Array<
							| { __typename?: 'AdditionalPersonOptions' }
							| { __typename?: 'AmplitudeExperiments' }
							| { __typename?: 'AppCaseStudy' }
							| { __typename?: 'AppCollection' }
							| { __typename?: 'AppFeature' }
							| { __typename?: 'AppTier' }
							| { __typename?: 'Article' }
							| { __typename?: 'AssetExternal' }
							| { __typename?: 'AssetInternal' }
							| { __typename?: 'Background' }
							| { __typename?: 'BasicContentCard' }
							| { __typename?: 'BlogPost' }
							| { __typename?: 'CaseStudy' }
							| { __typename?: 'CaseStudyProperty' }
							| { __typename?: 'Category' }
							| { __typename?: 'CtaButton' }
							| { __typename?: 'CtaMatPattern' }
							| { __typename?: 'Event' }
							| { __typename?: 'ExternalLink' }
							| { __typename?: 'FaqItem' }
							| { __typename?: 'GatedContent' }
							| { __typename?: 'Glossary' }
							| { __typename?: 'Hero' }
							| { __typename?: 'KeyValue' }
							| { __typename?: 'KeyValueSet' }
							| { __typename?: 'MarketoForm' }
							| { __typename?: 'MarketplaceApp' }
							| { __typename?: 'MarketplaceTheme' }
							| { __typename?: 'Person' }
							| { __typename?: 'Press' }
							| { __typename?: 'Redirect' }
							| { __typename?: 'Resource' }
							| {
									__typename: 'RichTextWrapper'
									type?: string | null
									sys: { __typename?: 'Sys'; id: string }
									content?: {
										__typename?: 'RichTextWrapperContent'
										json: { [key: string]: any }
									} | null
							  }
							| { __typename?: 'Robots' }
							| { __typename?: 'SeoMetadata' }
							| { __typename?: 'Showcase' }
							| { __typename?: 'SpeakerCard' }
							| { __typename?: 'Tag' }
							| { __typename?: 'ThemeProperty' }
							| null
						>
					}
				}
			} | null
			section5?: {
				__typename?: 'CaseStudySection5'
				json: { [key: string]: any }
				links: {
					__typename?: 'CaseStudySection5Links'
					assets: {
						__typename?: 'CaseStudySection5Assets'
						block: Array<{
							__typename?: 'Asset'
							url?: string | null
							title?: string | null
							width?: number | null
							height?: number | null
							description?: string | null
							contentType?: string | null
							sys: { __typename?: 'Sys'; id: string }
						} | null>
					}
					entries: {
						__typename?: 'CaseStudySection5Entries'
						block: Array<
							| { __typename?: 'AdditionalPersonOptions' }
							| { __typename?: 'AmplitudeExperiments' }
							| { __typename?: 'AppCaseStudy' }
							| { __typename?: 'AppCollection' }
							| { __typename?: 'AppFeature' }
							| { __typename?: 'AppTier' }
							| { __typename?: 'Article' }
							| { __typename?: 'AssetExternal' }
							| { __typename?: 'AssetInternal' }
							| { __typename?: 'Background' }
							| { __typename?: 'BasicContentCard' }
							| { __typename?: 'BlogPost' }
							| { __typename?: 'CaseStudy' }
							| { __typename?: 'CaseStudyProperty' }
							| { __typename?: 'Category' }
							| { __typename?: 'CtaButton' }
							| { __typename?: 'CtaMatPattern' }
							| { __typename?: 'Event' }
							| { __typename?: 'ExternalLink' }
							| { __typename?: 'FaqItem' }
							| { __typename?: 'GatedContent' }
							| { __typename?: 'Glossary' }
							| { __typename?: 'Hero' }
							| { __typename?: 'KeyValue' }
							| { __typename?: 'KeyValueSet' }
							| { __typename?: 'MarketoForm' }
							| { __typename?: 'MarketplaceApp' }
							| { __typename?: 'MarketplaceTheme' }
							| { __typename?: 'Person' }
							| { __typename?: 'Press' }
							| { __typename?: 'Redirect' }
							| { __typename?: 'Resource' }
							| {
									__typename: 'RichTextWrapper'
									type?: string | null
									sys: { __typename?: 'Sys'; id: string }
									content?: {
										__typename?: 'RichTextWrapperContent'
										json: { [key: string]: any }
									} | null
							  }
							| { __typename?: 'Robots' }
							| { __typename?: 'SeoMetadata' }
							| { __typename?: 'Showcase' }
							| { __typename?: 'SpeakerCard' }
							| { __typename?: 'Tag' }
							| { __typename?: 'ThemeProperty' }
							| null
						>
					}
				}
			} | null
			caseStudyStatsCollection?: {
				__typename?: 'CaseStudyCaseStudyStatsCollection'
				items: Array<{ __typename?: 'KeyValue'; key?: string | null; value?: string | null } | null>
			} | null
			image?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			quoteImage?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			featuredVideo?: {
				__typename?: 'AssetExternal'
				url?: string | null
				altText?: string | null
			} | null
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			competitorsListCollection?: {
				__typename?: 'CaseStudyCompetitorsListCollection'
				items: Array<{
					__typename?: 'CaseStudyProperty'
					name?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			} | null
			industriesListCollection?: {
				__typename?: 'CaseStudyIndustriesListCollection'
				items: Array<{
					__typename?: 'CaseStudyProperty'
					name?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			} | null
			topicsListCollection?: {
				__typename?: 'CaseStudyTopicsListCollection'
				items: Array<{
					__typename?: 'CaseStudyProperty'
					name?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			} | null
			relatedCaseStudiesCollection?: {
				__typename?: 'CaseStudyRelatedCaseStudiesCollection'
				items: Array<{
					__typename?: 'CaseStudy'
					status?: boolean | null
					title?: string | null
					slug?: string | null
					sys: { __typename?: 'Sys'; id: string }
					image?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
				} | null>
			} | null
		} | null>
	} | null
}

export type CaseStudyButtonFragment = {
	__typename?: 'CaseStudy'
	hero?: {
		__typename?: 'Hero'
		cta?: {
			__typename?: 'CtaButton'
			displayText?: string | null
			link?: { __typename?: 'ExternalLink'; url?: string | null } | null
		} | null
	} | null
}

export type CaseStudyCategoriesFragment = {
	__typename?: 'CaseStudy'
	competitorsListCollection?: {
		__typename?: 'CaseStudyCompetitorsListCollection'
		items: Array<{
			__typename?: 'CaseStudyProperty'
			name?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
	industriesListCollection?: {
		__typename?: 'CaseStudyIndustriesListCollection'
		items: Array<{
			__typename?: 'CaseStudyProperty'
			name?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
	topicsListCollection?: {
		__typename?: 'CaseStudyTopicsListCollection'
		items: Array<{
			__typename?: 'CaseStudyProperty'
			name?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
}

export type CaseStudyFeedQueryVariables = Exact<{
	where?: InputMaybe<CaseStudyFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type CaseStudyFeedQuery = {
	__typename?: 'Query'
	caseStudyCollection?: {
		__typename?: 'CaseStudyCollection'
		limit: number
		skip: number
		total: number
		items: Array<{
			__typename?: 'CaseStudy'
			slug?: string | null
			title?: string | null
			sys: { __typename?: 'Sys'; id: string }
			image?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			summary?: { __typename?: 'CaseStudySummary'; json: { [key: string]: any } } | null
		} | null>
	} | null
}

export type CaseStudyPropertyQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	order?: InputMaybe<Array<InputMaybe<CaseStudyPropertyOrder>> | InputMaybe<CaseStudyPropertyOrder>>
}>

export type CaseStudyPropertyQuery = {
	__typename?: 'Query'
	industries?: {
		__typename?: 'CaseStudyPropertyCollection'
		total: number
		items: Array<{
			__typename?: 'CaseStudyProperty'
			type?: string | null
			name?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
	competitors?: {
		__typename?: 'CaseStudyPropertyCollection'
		total: number
		items: Array<{
			__typename?: 'CaseStudyProperty'
			type?: string | null
			name?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
	topics?: {
		__typename?: 'CaseStudyPropertyCollection'
		total: number
		items: Array<{
			__typename?: 'CaseStudyProperty'
			type?: string | null
			name?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
}

export type CaseStudyImageFragment = {
	__typename?: 'CaseStudy'
	image?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	quoteImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
}

export type CaseStudyMetaFragment = {
	__typename?: 'CaseStudy'
	publishedDate?: string | null
	status_en_US?: boolean | null
	status_en_GB?: boolean | null
	status_en_AU?: boolean | null
	status_it_IT?: boolean | null
	status_nl_NL?: boolean | null
	status_fr_FR?: boolean | null
	status_es_ES?: boolean | null
	status_de_DE?: boolean | null
	status_es_MX?: boolean | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
}

export type CaseStudyRelatedCaseStudiesFragment = {
	__typename?: 'CaseStudy'
	relatedCaseStudiesCollection?: {
		__typename?: 'CaseStudyRelatedCaseStudiesCollection'
		items: Array<{
			__typename?: 'CaseStudy'
			status?: boolean | null
			title?: string | null
			slug?: string | null
			sys: { __typename?: 'Sys'; id: string }
			image?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
		} | null>
	} | null
}

export type CaseStudyRichTextFragment = {
	__typename?: 'CaseStudy'
	summary?: { __typename?: 'CaseStudySummary'; json: { [key: string]: any } } | null
	quote?: { __typename?: 'CaseStudyQuote'; json: { [key: string]: any } } | null
	statsTitle?: { __typename?: 'CaseStudyStatsTitle'; json: { [key: string]: any } } | null
	statsSubtitle?: { __typename?: 'CaseStudyStatsSubtitle'; json: { [key: string]: any } } | null
	statsDescription?: {
		__typename?: 'CaseStudyStatsDescription'
		json: { [key: string]: any }
	} | null
	extraSection?: {
		__typename?: 'CaseStudyExtraSection'
		json: { [key: string]: any }
		links: {
			__typename?: 'CaseStudyExtraSectionLinks'
			assets: {
				__typename?: 'CaseStudyExtraSectionAssets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'CaseStudyExtraSectionEntries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| { __typename?: 'AssetExternal' }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	section1?: {
		__typename?: 'CaseStudySection1'
		json: { [key: string]: any }
		links: {
			__typename?: 'CaseStudySection1Links'
			assets: {
				__typename?: 'CaseStudySection1Assets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'CaseStudySection1Entries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| { __typename?: 'AssetExternal' }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	section2?: {
		__typename?: 'CaseStudySection2'
		json: { [key: string]: any }
		links: {
			__typename?: 'CaseStudySection2Links'
			assets: {
				__typename?: 'CaseStudySection2Assets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'CaseStudySection2Entries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| { __typename?: 'AssetExternal' }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	section3?: {
		__typename?: 'CaseStudySection3'
		json: { [key: string]: any }
		links: {
			__typename?: 'CaseStudySection3Links'
			assets: {
				__typename?: 'CaseStudySection3Assets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'CaseStudySection3Entries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| { __typename?: 'AssetExternal' }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	section4?: {
		__typename?: 'CaseStudySection4'
		json: { [key: string]: any }
		links: {
			__typename?: 'CaseStudySection4Links'
			assets: {
				__typename?: 'CaseStudySection4Assets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'CaseStudySection4Entries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| { __typename?: 'AssetExternal' }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	section5?: {
		__typename?: 'CaseStudySection5'
		json: { [key: string]: any }
		links: {
			__typename?: 'CaseStudySection5Links'
			assets: {
				__typename?: 'CaseStudySection5Assets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'CaseStudySection5Entries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| { __typename?: 'AssetExternal' }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| {
							__typename: 'RichTextWrapper'
							type?: string | null
							sys: { __typename?: 'Sys'; id: string }
							content?: {
								__typename?: 'RichTextWrapperContent'
								json: { [key: string]: any }
							} | null
					  }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
}

export type CaseStudySlugsQueryVariables = Exact<{
	where?: InputMaybe<CaseStudyFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type CaseStudySlugsQuery = {
	__typename?: 'Query'
	caseStudyCollection?: {
		__typename?: 'CaseStudyCollection'
		items: Array<{ __typename?: 'CaseStudy'; slug?: string | null } | null>
	} | null
}

export type CaseStudyStatsFragment = {
	__typename?: 'CaseStudy'
	caseStudyStatsCollection?: {
		__typename?: 'CaseStudyCaseStudyStatsCollection'
		items: Array<{ __typename?: 'KeyValue'; key?: string | null; value?: string | null } | null>
	} | null
}

export type CaseStudyTextFragment = {
	__typename?: 'CaseStudy'
	title?: string | null
	slug?: string | null
}

export type CaseStudyVideoFragment = {
	__typename?: 'CaseStudy'
	featuredVideo?: {
		__typename?: 'AssetExternal'
		url?: string | null
		altText?: string | null
	} | null
}

export type CategoryFragment = {
	__typename?: 'Category'
	name?: string | null
	slug?: string | null
	parentCategory?: {
		__typename?: 'Category'
		name?: string | null
		slug?: string | null
		parentCategory?: {
			__typename?: 'Category'
			name?: string | null
			slug?: string | null
			parentCategory?: {
				__typename?: 'Category'
				name?: string | null
				slug?: string | null
			} | null
		} | null
	} | null
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		includeTitleTemplate?: boolean | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	description?: { __typename?: 'CategoryDescription'; json: { [key: string]: any } } | null
}

export type CategoriesQueryVariables = Exact<{
	where?: InputMaybe<CategoryFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type CategoriesQuery = {
	__typename?: 'Query'
	categoryCollection?: {
		__typename?: 'CategoryCollection'
		items: Array<{
			__typename?: 'Category'
			name?: string | null
			slug?: string | null
			sys: { __typename?: 'Sys'; id: string }
			parentCategory?: {
				__typename?: 'Category'
				name?: string | null
				slug?: string | null
				parentCategory?: {
					__typename?: 'Category'
					name?: string | null
					slug?: string | null
					parentCategory?: {
						__typename?: 'Category'
						name?: string | null
						slug?: string | null
					} | null
				} | null
			} | null
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				includeTitleTemplate?: boolean | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			description?: { __typename?: 'CategoryDescription'; json: { [key: string]: any } } | null
		} | null>
	} | null
}

export type CategoryBreadcrumbsFragment = {
	__typename?: 'Category'
	name?: string | null
	slug?: string | null
	parentCategory?: {
		__typename?: 'Category'
		name?: string | null
		slug?: string | null
		parentCategory?: {
			__typename?: 'Category'
			name?: string | null
			slug?: string | null
			parentCategory?: {
				__typename?: 'Category'
				name?: string | null
				slug?: string | null
			} | null
		} | null
	} | null
}

export type CategoryMetaFragment = {
	__typename?: 'Category'
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		includeTitleTemplate?: boolean | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
}

export type CategoryRichTextFragment = {
	__typename?: 'Category'
	description?: { __typename?: 'CategoryDescription'; json: { [key: string]: any } } | null
}

export type EventFeedQueryVariables = Exact<{
	where?: InputMaybe<EventFilter>
	order?: InputMaybe<Array<InputMaybe<EventOrder>> | InputMaybe<EventOrder>>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type EventFeedQuery = {
	__typename?: 'Query'
	eventCollection?: {
		__typename?: 'EventCollection'
		total: number
		items: Array<{
			__typename: 'Event'
			title?: string | null
			summary?: string | null
			featured?: boolean | null
			recurringEvent?: boolean | null
			startDate?: string | null
			endDate?: string | null
			url?: { __typename?: 'ExternalLink'; url?: string | null } | null
			image?: {
				__typename: 'Asset'
				url?: string | null
				description?: string | null
				width?: number | null
				height?: number | null
			} | null
		} | null>
	} | null
}

export type FeaturedContentBlockQueryVariables = Exact<{
	where?: InputMaybe<EntryFilter>
	order?: InputMaybe<Array<InputMaybe<EntryOrder>> | InputMaybe<EntryOrder>>
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type FeaturedContentBlockQuery = {
	__typename?: 'Query'
	entryCollection?: {
		__typename?: 'EntryCollection'
		items: Array<
			| { __typename: 'AdditionalPersonOptions'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'AmplitudeExperiments'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'AppCaseStudy'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'AppCollection'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'AppFeature'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'AppTier'; sys: { __typename: 'Sys'; id: string } }
			| {
					__typename: 'Article'
					title?: string | null
					slug?: string | null
					interstitialHeadline?: string | null
					featuredImage?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
					parentArticle?: { __typename?: 'Article'; slug?: string | null } | null
					sys: { __typename: 'Sys'; id: string }
			  }
			| { __typename: 'AssetExternal'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'AssetInternal'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'Background'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'BasicContentCard'; sys: { __typename: 'Sys'; id: string } }
			| {
					__typename: 'BlogPost'
					title?: string | null
					slug?: string | null
					featuredImage?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
					summary?: { __typename?: 'BlogPostSummary'; json: { [key: string]: any } } | null
					sys: { __typename: 'Sys'; id: string }
			  }
			| {
					__typename: 'CaseStudy'
					slug?: string | null
					hero?: {
						__typename?: 'Hero'
						headline?: string | null
						subheading?: { __typename?: 'HeroSubheading'; json: { [key: string]: any } } | null
					} | null
					image?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
					sys: { __typename: 'Sys'; id: string }
			  }
			| { __typename: 'CaseStudyProperty'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'Category'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'CtaButton'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'CtaMatPattern'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'Event'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'ExternalLink'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'FaqItem'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'GatedContent'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'Glossary'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'Hero'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'KeyValue'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'KeyValueSet'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'MarketoForm'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'MarketplaceApp'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'MarketplaceTheme'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'Person'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'Press'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'Redirect'; sys: { __typename: 'Sys'; id: string } }
			| {
					__typename: 'Resource'
					type?: string | null
					slug?: string | null
					hero?: {
						__typename?: 'Hero'
						headline?: string | null
						subheading?: { __typename?: 'HeroSubheading'; json: { [key: string]: any } } | null
					} | null
					featuredImage?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
					sys: { __typename: 'Sys'; id: string }
			  }
			| { __typename: 'RichTextWrapper'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'Robots'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'SeoMetadata'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'Showcase'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'SpeakerCard'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'Tag'; sys: { __typename: 'Sys'; id: string } }
			| { __typename: 'ThemeProperty'; sys: { __typename: 'Sys'; id: string } }
			| null
		>
	} | null
}

export type FeaturedLogoCarouselQueryVariables = Exact<{
	where?: InputMaybe<CaseStudyFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type FeaturedLogoCarouselQuery = {
	__typename?: 'Query'
	caseStudyCollection?: {
		__typename?: 'CaseStudyCollection'
		limit: number
		skip: number
		total: number
		items: Array<{
			__typename?: 'CaseStudy'
			title?: string | null
			slug?: string | null
			featuredAuthorName?: string | null
			featuredAuthorTitle?: string | null
			featuredLinkText?: string | null
			featuredOrder?: number | null
			sys: { __typename?: 'Sys'; id: string }
			featuredLogo?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			featuredContent?: {
				__typename?: 'CaseStudyFeaturedContent'
				json: { [key: string]: any }
			} | null
			featuredImage?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
		} | null>
	} | null
}

export type GlossaryFragment = {
	__typename?: 'Glossary'
	title?: string | null
	slug?: string | null
	body?: {
		__typename?: 'GlossaryBody'
		json: { [key: string]: any }
		links: {
			__typename?: 'GlossaryBodyLinks'
			assets: {
				__typename?: 'GlossaryBodyAssets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
		}
	} | null
	featuredImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
}

export type GlossaryQueryVariables = Exact<{
	where?: InputMaybe<GlossaryFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GlossaryQuery = {
	__typename?: 'Query'
	glossaryCollection?: {
		__typename?: 'GlossaryCollection'
		items: Array<{
			__typename?: 'Glossary'
			title?: string | null
			slug?: string | null
			sys: { __typename?: 'Sys'; id: string }
			body?: {
				__typename?: 'GlossaryBody'
				json: { [key: string]: any }
				links: {
					__typename?: 'GlossaryBodyLinks'
					assets: {
						__typename?: 'GlossaryBodyAssets'
						block: Array<{
							__typename?: 'Asset'
							url?: string | null
							title?: string | null
							width?: number | null
							height?: number | null
							description?: string | null
							contentType?: string | null
							sys: { __typename?: 'Sys'; id: string }
						} | null>
					}
				}
			} | null
			featuredImage?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
		} | null>
	} | null
}

export type GlossaryFeedQueryVariables = Exact<{
	where?: InputMaybe<GlossaryFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GlossaryFeedQuery = {
	__typename?: 'Query'
	glossaryCollection?: {
		__typename?: 'GlossaryCollection'
		limit: number
		skip: number
		total: number
		items: Array<{
			__typename?: 'Glossary'
			title?: string | null
			slug?: string | null
			sys: { __typename?: 'Sys'; id: string }
			categoryCollection?: {
				__typename?: 'GlossaryCategoryCollection'
				items: Array<{
					__typename?: 'Category'
					name?: string | null
					slug?: string | null
					description?: { __typename?: 'CategoryDescription'; json: { [key: string]: any } } | null
				} | null>
			} | null
		} | null>
	} | null
}

export type GlossaryImageFragment = {
	__typename?: 'Glossary'
	featuredImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
}

export type GlossaryMetaFragment = {
	__typename?: 'Glossary'
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
}

export type GlossaryRichTextFragment = {
	__typename?: 'Glossary'
	body?: {
		__typename?: 'GlossaryBody'
		json: { [key: string]: any }
		links: {
			__typename?: 'GlossaryBodyLinks'
			assets: {
				__typename?: 'GlossaryBodyAssets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
		}
	} | null
}

export type GlossaryAssetFragment = {
	__typename?: 'Asset'
	url?: string | null
	title?: string | null
	width?: number | null
	height?: number | null
	description?: string | null
	contentType?: string | null
	sys: { __typename?: 'Sys'; id: string }
}

export type GlossaryCategorySlugsFragment = {
	__typename?: 'Category'
	slug?: string | null
	slug_en_US?: string | null
	slug_en_AU?: string | null
	slug_en_GB?: string | null
}

export type GlossaryCategoryFragment = {
	__typename: 'Category'
	name?: string | null
	slug?: string | null
	slug_en_US?: string | null
	slug_en_AU?: string | null
	slug_en_GB?: string | null
	sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	linkedFrom?: {
		__typename?: 'CategoryLinkingCollections'
		glossaryCollection?: { __typename?: 'GlossaryCollection'; total: number } | null
	} | null
}

export type GlossaryCategoriesQueryVariables = Exact<{
	where?: InputMaybe<CategoryFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	order?: InputMaybe<Array<InputMaybe<CategoryOrder>> | InputMaybe<CategoryOrder>>
}>

export type GlossaryCategoriesQuery = {
	__typename?: 'Query'
	categoryCollection?: {
		__typename?: 'CategoryCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename: 'Category'
			name?: string | null
			slug?: string | null
			slug_en_US?: string | null
			slug_en_AU?: string | null
			slug_en_GB?: string | null
			sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			linkedFrom?: {
				__typename?: 'CategoryLinkingCollections'
				glossaryCollection?: { __typename?: 'GlossaryCollection'; total: number } | null
			} | null
		} | null>
	} | null
}

export type GlossaryTextFragment = {
	__typename?: 'Glossary'
	title?: string | null
	slug?: string | null
}

export type AppFragment = {
	__typename?: 'MarketplaceApp'
	logo?: string | null
	logoIcon?: string | null
	alternativeLogo?: string | null
	slug?: string | null
	appRegistryId?: string | null
	name?: string | null
	video?: Array<string | null> | null
	screenshots?: Array<string | null> | null
	rating?: number | null
	reviewCount?: number | null
	unifiedBilling?: boolean | null
	catalystCompatible?: boolean | null
	termsOfServiceUrl?: string | null
	privacyPolicyUrl?: string | null
	freeTrial?: boolean | null
	freeTrialDays?: number | null
	upfrontPrice?: string | null
	monthlyPrice?: string | null
	pricing?: string | null
	pricingType?: string | null
	supportUrl?: string | null
	installationGuideUrl?: string | null
	userGuideUrl?: string | null
	createdOn?: string | null
	appRegistryUpdatedAt?: string | null
	multiStorefront?: boolean | null
	multiLocation?: boolean | null
	similarApps?: Array<string | null> | null
	sys: { __typename?: 'Sys'; id: string }
	appTier?: {
		__typename?: 'AppTier'
		name?: string | null
		description?: string | null
		logo?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	appFeaturesCollection?: {
		__typename?: 'MarketplaceAppAppFeaturesCollection'
		items: Array<{
			__typename?: 'AppFeature'
			title?: string | null
			description?: { __typename?: 'AppFeatureDescription'; json: { [key: string]: any } } | null
		} | null>
	} | null
	caseStudiesCollection?: {
		__typename?: 'MarketplaceAppCaseStudiesCollection'
		items: Array<{
			__typename?: 'AppCaseStudy'
			title?: string | null
			url?: string | null
			description?: { __typename?: 'AppCaseStudyDescription'; json: { [key: string]: any } } | null
		} | null>
	} | null
	abstract?: { __typename?: 'MarketplaceAppAbstract'; json: { [key: string]: any } } | null
	summary?: { __typename?: 'MarketplaceAppSummary'; json: { [key: string]: any } } | null
}

export type AppInformationFragment = {
	__typename?: 'MarketplaceAppCollection'
	items: Array<{
		__typename?: 'MarketplaceApp'
		logo?: string | null
		logoIcon?: string | null
		alternativeLogo?: string | null
		slug?: string | null
		appRegistryId?: string | null
		name?: string | null
		video?: Array<string | null> | null
		screenshots?: Array<string | null> | null
		rating?: number | null
		reviewCount?: number | null
		unifiedBilling?: boolean | null
		catalystCompatible?: boolean | null
		termsOfServiceUrl?: string | null
		privacyPolicyUrl?: string | null
		freeTrial?: boolean | null
		freeTrialDays?: number | null
		upfrontPrice?: string | null
		monthlyPrice?: string | null
		pricing?: string | null
		pricingType?: string | null
		supportUrl?: string | null
		installationGuideUrl?: string | null
		userGuideUrl?: string | null
		createdOn?: string | null
		appRegistryUpdatedAt?: string | null
		multiStorefront?: boolean | null
		multiLocation?: boolean | null
		similarApps?: Array<string | null> | null
		sys: { __typename?: 'Sys'; id: string }
		appTier?: {
			__typename?: 'AppTier'
			name?: string | null
			description?: string | null
			logo?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
		} | null
		appFeaturesCollection?: {
			__typename?: 'MarketplaceAppAppFeaturesCollection'
			items: Array<{
				__typename?: 'AppFeature'
				title?: string | null
				description?: { __typename?: 'AppFeatureDescription'; json: { [key: string]: any } } | null
			} | null>
		} | null
		caseStudiesCollection?: {
			__typename?: 'MarketplaceAppCaseStudiesCollection'
			items: Array<{
				__typename?: 'AppCaseStudy'
				title?: string | null
				url?: string | null
				description?: {
					__typename?: 'AppCaseStudyDescription'
					json: { [key: string]: any }
				} | null
			} | null>
		} | null
		abstract?: { __typename?: 'MarketplaceAppAbstract'; json: { [key: string]: any } } | null
		summary?: { __typename?: 'MarketplaceAppSummary'; json: { [key: string]: any } } | null
	} | null>
}

export type AppInformationQueryVariables = Exact<{
	where?: InputMaybe<MarketplaceAppFilter>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceAppOrder>> | InputMaybe<MarketplaceAppOrder>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	limit?: InputMaybe<Scalars['Int']['input']>
}>

export type AppInformationQuery = {
	__typename?: 'Query'
	marketplaceAppCollection?: {
		__typename?: 'MarketplaceAppCollection'
		items: Array<{
			__typename?: 'MarketplaceApp'
			logo?: string | null
			logoIcon?: string | null
			alternativeLogo?: string | null
			slug?: string | null
			appRegistryId?: string | null
			name?: string | null
			video?: Array<string | null> | null
			screenshots?: Array<string | null> | null
			rating?: number | null
			reviewCount?: number | null
			unifiedBilling?: boolean | null
			catalystCompatible?: boolean | null
			termsOfServiceUrl?: string | null
			privacyPolicyUrl?: string | null
			freeTrial?: boolean | null
			freeTrialDays?: number | null
			upfrontPrice?: string | null
			monthlyPrice?: string | null
			pricing?: string | null
			pricingType?: string | null
			supportUrl?: string | null
			installationGuideUrl?: string | null
			userGuideUrl?: string | null
			createdOn?: string | null
			appRegistryUpdatedAt?: string | null
			multiStorefront?: boolean | null
			multiLocation?: boolean | null
			similarApps?: Array<string | null> | null
			sys: { __typename?: 'Sys'; id: string }
			appTier?: {
				__typename?: 'AppTier'
				name?: string | null
				description?: string | null
				logo?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			appFeaturesCollection?: {
				__typename?: 'MarketplaceAppAppFeaturesCollection'
				items: Array<{
					__typename?: 'AppFeature'
					title?: string | null
					description?: {
						__typename?: 'AppFeatureDescription'
						json: { [key: string]: any }
					} | null
				} | null>
			} | null
			caseStudiesCollection?: {
				__typename?: 'MarketplaceAppCaseStudiesCollection'
				items: Array<{
					__typename?: 'AppCaseStudy'
					title?: string | null
					url?: string | null
					description?: {
						__typename?: 'AppCaseStudyDescription'
						json: { [key: string]: any }
					} | null
				} | null>
			} | null
			abstract?: { __typename?: 'MarketplaceAppAbstract'; json: { [key: string]: any } } | null
			summary?: { __typename?: 'MarketplaceAppSummary'; json: { [key: string]: any } } | null
		} | null>
	} | null
}

export type AppCarouselQueryVariables = Exact<{
	where?: InputMaybe<MarketplaceAppFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceAppOrder>> | InputMaybe<MarketplaceAppOrder>>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type AppCarouselQuery = {
	__typename?: 'Query'
	marketplaceAppCollection?: {
		__typename?: 'MarketplaceAppCollection'
		items: Array<{
			__typename?: 'MarketplaceApp'
			slug?: string | null
			name?: string | null
			rating?: number | null
			alternativeLogo?: string | null
			sys: { __typename?: 'Sys'; id: string }
			summary?: { __typename?: 'MarketplaceAppSummary'; json: { [key: string]: any } } | null
			appTier?: {
				__typename?: 'AppTier'
				name?: string | null
				logo?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			categoriesCollection?: {
				__typename?: 'MarketplaceAppCategoriesCollection'
				items: Array<{
					__typename?: 'Category'
					name?: string | null
					slug?: string | null
					sys: { __typename?: 'Sys'; id: string }
					parentCategory?: {
						__typename?: 'Category'
						slug?: string | null
						name?: string | null
						sys: { __typename?: 'Sys'; id: string }
					} | null
				} | null>
			} | null
		} | null>
	} | null
}

export type AppCategoriesQueryVariables = Exact<{
	where?: InputMaybe<CategoryFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	order?: InputMaybe<Array<InputMaybe<CategoryOrder>> | InputMaybe<CategoryOrder>>
}>

export type AppCategoriesQuery = {
	__typename?: 'Query'
	categoryCollection?: {
		__typename?: 'CategoryCollection'
		items: Array<{ __typename?: 'Category'; name?: string | null; slug?: string | null } | null>
	} | null
}

export type AppCollectionFragment = {
	__typename?: 'AppCollection'
	thumbnail?: string | null
	name?: string | null
	slug?: string | null
	description?: string | null
	summary?: string | null
	linkedFrom?: {
		__typename?: 'AppCollectionLinkingCollections'
		marketplaceAppCollection?: { __typename?: 'MarketplaceAppCollection'; total: number } | null
	} | null
	sys: { __typename?: 'Sys'; id: string }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
}

export type AppCollectionQueryVariables = Exact<{
	where?: InputMaybe<AppCollectionFilter>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	order?: InputMaybe<Array<InputMaybe<AppCollectionOrder>> | InputMaybe<AppCollectionOrder>>
	limit?: InputMaybe<Scalars['Int']['input']>
}>

export type AppCollectionQuery = {
	__typename?: 'Query'
	appCollectionCollection?: {
		__typename?: 'AppCollectionCollection'
		items: Array<{
			__typename?: 'AppCollection'
			thumbnail?: string | null
			name?: string | null
			slug?: string | null
			description?: string | null
			summary?: string | null
			linkedFrom?: {
				__typename?: 'AppCollectionLinkingCollections'
				marketplaceAppCollection?: { __typename?: 'MarketplaceAppCollection'; total: number } | null
			} | null
			sys: { __typename?: 'Sys'; id: string }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
		} | null>
	} | null
}

export type AppCollectionImageFragment = { __typename?: 'AppCollection'; thumbnail?: string | null }

export type AppCollectionTextFragment = {
	__typename?: 'AppCollection'
	name?: string | null
	slug?: string | null
	description?: string | null
	summary?: string | null
}

export type AppCollectionCarouselQueryVariables = Exact<{
	where?: InputMaybe<AppCollectionFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type AppCollectionCarouselQuery = {
	__typename?: 'Query'
	appCollectionCollection?: {
		__typename?: 'AppCollectionCollection'
		total: number
		items: Array<{
			__typename?: 'AppCollection'
			thumbnail?: string | null
			name?: string | null
			slug?: string | null
			description?: string | null
			summary?: string | null
			linkedFrom?: {
				__typename?: 'AppCollectionLinkingCollections'
				marketplaceAppCollection?: { __typename?: 'MarketplaceAppCollection'; total: number } | null
			} | null
			sys: { __typename?: 'Sys'; id: string }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
		} | null>
	} | null
}

export type AppCollectionCarouselPriorityQueryVariables = Exact<{
	query?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type AppCollectionCarouselPriorityQuery = {
	__typename?: 'Query'
	appCollectionCollection?: {
		__typename?: 'AppCollectionCollection'
		items: Array<{
			__typename?: 'AppCollection'
			name?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
}

export type AppCollectionListQueryVariables = Exact<{
	where?: InputMaybe<MarketplaceAppFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceAppOrder>> | InputMaybe<MarketplaceAppOrder>>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type AppCollectionListQuery = {
	__typename?: 'Query'
	marketplaceAppCollection?: {
		__typename?: 'MarketplaceAppCollection'
		total: number
		items: Array<{
			__typename?: 'MarketplaceApp'
			slug?: string | null
			name?: string | null
			rating?: number | null
			reviewCount?: number | null
			upfrontPrice?: string | null
			monthlyPrice?: string | null
			logo?: string | null
			logoIcon?: string | null
			sys: { __typename?: 'Sys'; id: string }
			summary?: { __typename?: 'MarketplaceAppSummary'; json: { [key: string]: any } } | null
			categoriesCollection?: {
				__typename?: 'MarketplaceAppCategoriesCollection'
				items: Array<{
					__typename?: 'Category'
					name?: string | null
					slug?: string | null
					sys: { __typename?: 'Sys'; id: string }
					parentCategory?: {
						__typename?: 'Category'
						slug?: string | null
						name?: string | null
						sys: { __typename?: 'Sys'; id: string }
					} | null
				} | null>
			} | null
			collectionsCollection?: {
				__typename?: 'MarketplaceAppCollectionsCollection'
				items: Array<{
					__typename?: 'AppCollection'
					name?: string | null
					slug?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			} | null
			appTier?: {
				__typename?: 'AppTier'
				name?: string | null
				logo?: { __typename?: 'Asset'; url?: string | null } | null
			} | null
		} | null>
	} | null
}

export type AppMetaFragmentFragment = {
	__typename?: 'MarketplaceApp'
	name?: string | null
	upfrontPrice?: string | null
	monthlyPrice?: string | null
	rating?: number | null
	reviewCount?: number | null
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		noindex?: boolean | null
		nofollow?: boolean | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
}

export type AppMetaQueryVariables = Exact<{
	where?: InputMaybe<MarketplaceAppFilter>
	locale?: InputMaybe<Scalars['String']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceAppOrder>> | InputMaybe<MarketplaceAppOrder>>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	limit?: InputMaybe<Scalars['Int']['input']>
}>

export type AppMetaQuery = {
	__typename?: 'Query'
	marketplaceAppCollection?: {
		__typename?: 'MarketplaceAppCollection'
		items: Array<{
			__typename?: 'MarketplaceApp'
			name?: string | null
			upfrontPrice?: string | null
			monthlyPrice?: string | null
			rating?: number | null
			reviewCount?: number | null
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				noindex?: boolean | null
				nofollow?: boolean | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
		} | null>
	} | null
}

export type AppRichTextFragment = {
	__typename?: 'MarketplaceApp'
	abstract?: { __typename?: 'MarketplaceAppAbstract'; json: { [key: string]: any } } | null
	summary?: { __typename?: 'MarketplaceAppSummary'; json: { [key: string]: any } } | null
}

export type AppRowQueryVariables = Exact<{
	where?: InputMaybe<MarketplaceAppFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceAppOrder>> | InputMaybe<MarketplaceAppOrder>>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type AppRowQuery = {
	__typename?: 'Query'
	marketplaceAppCollection?: {
		__typename?: 'MarketplaceAppCollection'
		items: Array<{
			__typename?: 'MarketplaceApp'
			slug?: string | null
			name?: string | null
			rating?: number | null
			logoIcon?: string | null
			sys: { __typename?: 'Sys'; id: string }
			summary?: { __typename?: 'MarketplaceAppSummary'; json: { [key: string]: any } } | null
		} | null>
	} | null
}

export type AppRowCardsQueryVariables = Exact<{
	where?: InputMaybe<MarketplaceAppFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceAppOrder>> | InputMaybe<MarketplaceAppOrder>>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type AppRowCardsQuery = {
	__typename?: 'Query'
	marketplaceAppCollection?: {
		__typename?: 'MarketplaceAppCollection'
		items: Array<{
			__typename?: 'MarketplaceApp'
			slug?: string | null
			name?: string | null
			rating?: number | null
			logo?: string | null
			reviewCount?: number | null
			logoIcon?: string | null
			sys: { __typename?: 'Sys'; id: string }
			summary?: { __typename?: 'MarketplaceAppSummary'; json: { [key: string]: any } } | null
			appTier?: {
				__typename?: 'AppTier'
				logo?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			categoriesCollection?: {
				__typename?: 'MarketplaceAppCategoriesCollection'
				items: Array<{
					__typename?: 'Category'
					name?: string | null
					slug?: string | null
					sys: { __typename?: 'Sys'; id: string }
					parentCategory?: {
						__typename?: 'Category'
						slug?: string | null
						name?: string | null
						sys: { __typename?: 'Sys'; id: string }
					} | null
				} | null>
			} | null
		} | null>
	} | null
}

export type AppTierFragment = {
	__typename?: 'AppTier'
	name?: string | null
	slug?: string | null
	description?: string | null
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	logo?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
}

export type AppTierQueryVariables = Exact<{
	where?: InputMaybe<AppTierFilter>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
	order?: InputMaybe<Array<InputMaybe<AppTierOrder>> | InputMaybe<AppTierOrder>>
	limit?: InputMaybe<Scalars['Int']['input']>
}>

export type AppTierQuery = {
	__typename?: 'Query'
	appTierCollection?: {
		__typename?: 'AppTierCollection'
		items: Array<{
			__typename?: 'AppTier'
			name?: string | null
			slug?: string | null
			description?: string | null
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			logo?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
		} | null>
	} | null
}

export type AppTierAppGridQueryVariables = Exact<{
	where?: InputMaybe<MarketplaceAppFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceAppOrder>> | InputMaybe<MarketplaceAppOrder>>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type AppTierAppGridQuery = {
	__typename?: 'Query'
	marketplaceAppCollection?: {
		__typename?: 'MarketplaceAppCollection'
		items: Array<{
			__typename?: 'MarketplaceApp'
			logo?: string | null
			slug?: string | null
			name?: string | null
			rating?: number | null
			reviewCount?: number | null
			sys: { __typename?: 'Sys'; id: string }
			summary?: { __typename?: 'MarketplaceAppSummary'; json: { [key: string]: any } } | null
			categoriesCollection?: {
				__typename?: 'MarketplaceAppCategoriesCollection'
				items: Array<{
					__typename?: 'Category'
					name?: string | null
					slug?: string | null
					sys: { __typename?: 'Sys'; id: string }
					parentCategory?: {
						__typename?: 'Category'
						slug?: string | null
						name?: string | null
						sys: { __typename?: 'Sys'; id: string }
					} | null
				} | null>
			} | null
			appTier?: {
				__typename?: 'AppTier'
				name?: string | null
				slug?: string | null
				logo?: { __typename?: 'Asset'; url?: string | null } | null
			} | null
		} | null>
	} | null
}

export type AppTierImageFragment = {
	__typename?: 'AppTier'
	logo?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
}

export type AppTierTextFragment = {
	__typename?: 'AppTier'
	name?: string | null
	slug?: string | null
	description?: string | null
}

export type AppsRecommendedQueryVariables = Exact<{
	where?: InputMaybe<MarketplaceAppFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	order?: InputMaybe<Array<InputMaybe<MarketplaceAppOrder>> | InputMaybe<MarketplaceAppOrder>>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type AppsRecommendedQuery = {
	__typename?: 'Query'
	marketplaceAppCollection?: {
		__typename?: 'MarketplaceAppCollection'
		items: Array<{
			__typename?: 'MarketplaceApp'
			slug?: string | null
			name?: string | null
			upfrontPrice?: string | null
			monthlyPrice?: string | null
			rating?: number | null
			reviewCount?: number | null
			logoIcon?: string | null
			sys: { __typename?: 'Sys'; id: string }
			summary?: { __typename?: 'MarketplaceAppSummary'; json: { [key: string]: any } } | null
			appTier?: {
				__typename?: 'AppTier'
				name?: string | null
				logo?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
		} | null>
	} | null
}

export type ThemeInformationFragment = {
	__typename?: 'MarketplaceThemeCollection'
	items: Array<{
		__typename?: 'MarketplaceTheme'
		publishedDate?: string | null
		registryId?: string | null
		slug?: string | null
		title?: string | null
		partnerName?: string | null
		partnerEmail?: string | null
		contactUrl?: string | null
		demoUrl?: string | null
		price?: string | null
		themeFeatures?: Array<string | null> | null
		themeOptimizations?: Array<string | null> | null
		documentationUrl?: string | null
		related?: Array<string | null> | null
		smallThumbnail?: string | null
		largeThumbnail?: string | null
		mobilePreview?: string | null
		largePreview?: string | null
		sys: { __typename?: 'Sys'; id: string }
		seoMetadata?: {
			__typename?: 'SeoMetadata'
			title?: string | null
			description?: string | null
			canonicalUrl?: string | null
			includeTitleTemplate?: boolean | null
			noindex?: boolean | null
			nofollow?: boolean | null
			readingTime?: string | null
			image?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
		} | null
		description?: {
			__typename?: 'MarketplaceThemeDescription'
			json: { [key: string]: any }
		} | null
	} | null>
}

export type ThemesInformationQueryVariables = Exact<{
	where?: InputMaybe<MarketplaceThemeFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ThemesInformationQuery = {
	__typename?: 'Query'
	marketplaceThemeCollection?: {
		__typename?: 'MarketplaceThemeCollection'
		items: Array<{
			__typename?: 'MarketplaceTheme'
			publishedDate?: string | null
			registryId?: string | null
			slug?: string | null
			title?: string | null
			partnerName?: string | null
			partnerEmail?: string | null
			contactUrl?: string | null
			demoUrl?: string | null
			price?: string | null
			themeFeatures?: Array<string | null> | null
			themeOptimizations?: Array<string | null> | null
			documentationUrl?: string | null
			related?: Array<string | null> | null
			smallThumbnail?: string | null
			largeThumbnail?: string | null
			mobilePreview?: string | null
			largePreview?: string | null
			sys: { __typename?: 'Sys'; id: string }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			description?: {
				__typename?: 'MarketplaceThemeDescription'
				json: { [key: string]: any }
			} | null
		} | null>
	} | null
}

export type ThemeImageFragment = {
	__typename?: 'MarketplaceTheme'
	smallThumbnail?: string | null
	largeThumbnail?: string | null
	mobilePreview?: string | null
	largePreview?: string | null
}

export type ThemeRelatedStyleQueryVariables = Exact<{
	relatedId?: InputMaybe<Scalars['String']['input']>
}>

export type ThemeRelatedStyleQuery = {
	__typename?: 'Query'
	marketplaceThemeCollection?: {
		__typename?: 'MarketplaceThemeCollection'
		items: Array<{
			__typename?: 'MarketplaceTheme'
			title?: string | null
			slug?: string | null
			largeThumbnail?: string | null
			smallThumbnail?: string | null
			registryId?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
}

export type ThemeRichTextFragment = {
	__typename?: 'MarketplaceTheme'
	description?: { __typename?: 'MarketplaceThemeDescription'; json: { [key: string]: any } } | null
}

export type ThemesPropertyFragment = {
	__typename?: 'ThemePropertyCollection'
	items: Array<{
		__typename?: 'ThemeProperty'
		slug?: string | null
		name?: string | null
		type?: string | null
		sys: { __typename?: 'Sys'; id: string }
		seoMetadata?: {
			__typename?: 'SeoMetadata'
			title?: string | null
			description?: string | null
			canonicalUrl?: string | null
			includeTitleTemplate?: boolean | null
			noindex?: boolean | null
			nofollow?: boolean | null
			readingTime?: string | null
			image?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
		} | null
		linkedFrom?: {
			__typename?: 'ThemePropertyLinkingCollections'
			marketplaceThemeCollection?: {
				__typename?: 'MarketplaceThemeCollection'
				total: number
			} | null
		} | null
	} | null>
}

export type ThemesCollectionsQueryVariables = Exact<{
	where?: InputMaybe<ThemePropertyFilter>
}>

export type ThemesCollectionsQuery = {
	__typename?: 'Query'
	themePropertyCollection?: {
		__typename?: 'ThemePropertyCollection'
		items: Array<{
			__typename?: 'ThemeProperty'
			slug?: string | null
			name?: string | null
			type?: string | null
			sys: { __typename?: 'Sys'; id: string }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			linkedFrom?: {
				__typename?: 'ThemePropertyLinkingCollections'
				marketplaceThemeCollection?: {
					__typename?: 'MarketplaceThemeCollection'
					total: number
				} | null
			} | null
		} | null>
	} | null
}

export type ThemesListQueryVariables = Exact<{
	where?: InputMaybe<MarketplaceThemeFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ThemesListQuery = {
	__typename?: 'Query'
	marketplaceThemeCollection?: {
		__typename?: 'MarketplaceThemeCollection'
		total: number
		items: Array<{
			__typename?: 'MarketplaceTheme'
			slug?: string | null
			title?: string | null
			price?: string | null
			largePreview?: string | null
			largeThumbnail?: string | null
			smallThumbnail?: string | null
			mobilePreview?: string | null
			related?: Array<string | null> | null
			sys: { __typename?: 'Sys'; id: string }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
		} | null>
	} | null
}

export type PersonFragment = {
	__typename?: 'Person'
	fullName?: string | null
	slug?: string | null
	jobTitle?: string | null
	email?: string | null
	linkedUrl?: string | null
	type?: string | null
	bio?: { __typename?: 'PersonBio'; json: { [key: string]: any } } | null
	additionalOptions?: {
		__typename?: 'AdditionalPersonOptions'
		bioSummary?: {
			__typename?: 'AdditionalPersonOptionsBioSummary'
			json: { [key: string]: any }
		} | null
		fullBio?: { __typename?: 'AdditionalPersonOptionsFullBio'; json: { [key: string]: any } } | null
		relatedNewsCollection?: {
			__typename?: 'AdditionalPersonOptionsRelatedNewsCollection'
			items: Array<{
				__typename?: 'ExternalLink'
				url?: string | null
				internalName?: string | null
				sys: { __typename?: 'Sys'; id: string }
			} | null>
		} | null
	} | null
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	fullSizePhoto?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	avatar?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
}

export type PersonsQueryVariables = Exact<{
	where?: InputMaybe<PersonFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type PersonsQuery = {
	__typename?: 'Query'
	personCollection?: {
		__typename?: 'PersonCollection'
		items: Array<{
			__typename?: 'Person'
			fullName?: string | null
			slug?: string | null
			jobTitle?: string | null
			email?: string | null
			linkedUrl?: string | null
			type?: string | null
			sys: { __typename?: 'Sys'; id: string }
			bio?: { __typename?: 'PersonBio'; json: { [key: string]: any } } | null
			additionalOptions?: {
				__typename?: 'AdditionalPersonOptions'
				bioSummary?: {
					__typename?: 'AdditionalPersonOptionsBioSummary'
					json: { [key: string]: any }
				} | null
				fullBio?: {
					__typename?: 'AdditionalPersonOptionsFullBio'
					json: { [key: string]: any }
				} | null
				relatedNewsCollection?: {
					__typename?: 'AdditionalPersonOptionsRelatedNewsCollection'
					items: Array<{
						__typename?: 'ExternalLink'
						url?: string | null
						internalName?: string | null
						sys: { __typename?: 'Sys'; id: string }
					} | null>
				} | null
			} | null
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			fullSizePhoto?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			avatar?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
		} | null>
	} | null
}

export type PersonFeedQueryVariables = Exact<{
	where?: InputMaybe<PersonFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type PersonFeedQuery = {
	__typename?: 'Query'
	personCollection?: {
		__typename?: 'PersonCollection'
		limit: number
		total: number
		skip: number
		items: Array<{
			__typename?: 'Person'
			fullName?: string | null
			jobTitle?: string | null
			slug?: string | null
			jobTitleInEnglish?: string | null
			sys: { __typename?: 'Sys'; id: string }
			avatar?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			additionalOptions?: {
				__typename?: 'AdditionalPersonOptions'
				avatarOverride?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
				bioSummary?: {
					__typename?: 'AdditionalPersonOptionsBioSummary'
					json: { [key: string]: any }
				} | null
			} | null
		} | null>
	} | null
}

export type PersonFeedPriorityQueryVariables = Exact<{
	query?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type PersonFeedPriorityQuery = {
	__typename?: 'Query'
	personCollection?: {
		__typename?: 'PersonCollection'
		items: Array<{
			__typename?: 'Person'
			fullName?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	} | null
}

export type PersonImageFragment = {
	__typename?: 'Person'
	fullSizePhoto?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	avatar?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
}

export type PersonMetaFragment = {
	__typename?: 'Person'
	fullName?: string | null
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
}

export type PersonRelatedNewsFragment = {
	__typename?: 'Person'
	additionalOptions?: {
		__typename?: 'AdditionalPersonOptions'
		relatedNewsCollection?: {
			__typename?: 'AdditionalPersonOptionsRelatedNewsCollection'
			items: Array<{
				__typename?: 'ExternalLink'
				url?: string | null
				internalName?: string | null
				sys: { __typename?: 'Sys'; id: string }
			} | null>
		} | null
	} | null
}

export type PersonRichTextFragment = {
	__typename?: 'Person'
	bio?: { __typename?: 'PersonBio'; json: { [key: string]: any } } | null
	additionalOptions?: {
		__typename?: 'AdditionalPersonOptions'
		bioSummary?: {
			__typename?: 'AdditionalPersonOptionsBioSummary'
			json: { [key: string]: any }
		} | null
		fullBio?: { __typename?: 'AdditionalPersonOptionsFullBio'; json: { [key: string]: any } } | null
	} | null
}

export type PersonTextFragment = {
	__typename?: 'Person'
	fullName?: string | null
	slug?: string | null
	jobTitle?: string | null
	email?: string | null
	linkedUrl?: string | null
	type?: string | null
}

export type PressFragment = {
	__typename?: 'Press'
	title?: string | null
	summary?: string | null
	publishedDate?: string | null
	slug?: string | null
	slug_en_US?: string | null
	slug_en_AU?: string | null
	slug_en_GB?: string | null
	slug_en_SG?: string | null
	slug_es_MX?: string | null
	slug_es_ES?: string | null
	slug_it_IT?: string | null
	slug_fr_FR?: string | null
	slug_nl_NL?: string | null
	slug_de_DE?: string | null
	slug_de_AT?: string | null
	slug_da_DK?: string | null
	slug_sv_SE?: string | null
	slug_nb_NO?: string | null
	enabled_en_US?: boolean | null
	enabled_en_AU?: boolean | null
	enabled_en_GB?: boolean | null
	enabled_en_SG?: boolean | null
	enabled_es_MX?: boolean | null
	enabled_es_ES?: boolean | null
	enabled_it_IT?: boolean | null
	enabled_fr_FR?: boolean | null
	enabled_nl_NL?: boolean | null
	enabled_de_DE?: boolean | null
	enabled_de_AT?: boolean | null
	enabled_da_DK?: boolean | null
	enabled_sv_SE?: boolean | null
	enabled_nb_NO?: boolean | null
	body?: {
		__typename?: 'PressBody'
		json: { [key: string]: any }
		links: {
			__typename?: 'PressBodyLinks'
			assets: {
				__typename?: 'PressBodyAssets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
		}
	} | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
}

export type PressQueryVariables = Exact<{
	where?: InputMaybe<PressFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type PressQuery = {
	__typename?: 'Query'
	pressCollection?: {
		__typename?: 'PressCollection'
		items: Array<{
			__typename?: 'Press'
			title?: string | null
			summary?: string | null
			publishedDate?: string | null
			slug?: string | null
			slug_en_US?: string | null
			slug_en_AU?: string | null
			slug_en_GB?: string | null
			slug_en_SG?: string | null
			slug_es_MX?: string | null
			slug_es_ES?: string | null
			slug_it_IT?: string | null
			slug_fr_FR?: string | null
			slug_nl_NL?: string | null
			slug_de_DE?: string | null
			slug_de_AT?: string | null
			slug_da_DK?: string | null
			slug_sv_SE?: string | null
			slug_nb_NO?: string | null
			enabled_en_US?: boolean | null
			enabled_en_AU?: boolean | null
			enabled_en_GB?: boolean | null
			enabled_en_SG?: boolean | null
			enabled_es_MX?: boolean | null
			enabled_es_ES?: boolean | null
			enabled_it_IT?: boolean | null
			enabled_fr_FR?: boolean | null
			enabled_nl_NL?: boolean | null
			enabled_de_DE?: boolean | null
			enabled_de_AT?: boolean | null
			enabled_da_DK?: boolean | null
			enabled_sv_SE?: boolean | null
			enabled_nb_NO?: boolean | null
			sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
			body?: {
				__typename?: 'PressBody'
				json: { [key: string]: any }
				links: {
					__typename?: 'PressBodyLinks'
					assets: {
						__typename?: 'PressBodyAssets'
						block: Array<{
							__typename?: 'Asset'
							url?: string | null
							title?: string | null
							width?: number | null
							height?: number | null
							description?: string | null
							contentType?: string | null
							sys: { __typename?: 'Sys'; id: string }
						} | null>
					}
				}
			} | null
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
		} | null>
	} | null
}

export type PressDateFragment = { __typename?: 'Press'; publishedDate?: string | null }

export type PressFeedQueryVariables = Exact<{
	where?: InputMaybe<PressFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type PressFeedQuery = {
	__typename?: 'Query'
	pressCollection?: {
		__typename?: 'PressCollection'
		total: number
		limit: number
		skip: number
		items: Array<{
			__typename?: 'Press'
			title?: string | null
			slug?: string | null
			type?: string | null
			summary?: string | null
			publishedDate?: string | null
			sys: { __typename?: 'Sys'; id: string }
			externalLink?: { __typename?: 'ExternalLink'; url?: string | null } | null
			image?:
				| { __typename: 'AssetExternal'; url?: string | null }
				| {
						__typename: 'AssetInternal'
						media?: {
							__typename: 'Asset'
							url?: string | null
							width?: number | null
							height?: number | null
							title?: string | null
							contentType?: string | null
						} | null
				  }
				| null
		} | null>
	} | null
}

export type PressSlugFragment = {
	__typename?: 'Press'
	slug?: string | null
	slug_en_US?: string | null
	slug_en_AU?: string | null
	slug_en_GB?: string | null
	slug_en_SG?: string | null
	slug_es_MX?: string | null
	slug_es_ES?: string | null
	slug_it_IT?: string | null
	slug_fr_FR?: string | null
	slug_nl_NL?: string | null
	slug_de_DE?: string | null
	slug_de_AT?: string | null
	slug_da_DK?: string | null
	slug_sv_SE?: string | null
	slug_nb_NO?: string | null
}

export type PressEnabledFragment = {
	__typename?: 'Press'
	enabled_en_US?: boolean | null
	enabled_en_AU?: boolean | null
	enabled_en_GB?: boolean | null
	enabled_en_SG?: boolean | null
	enabled_es_MX?: boolean | null
	enabled_es_ES?: boolean | null
	enabled_it_IT?: boolean | null
	enabled_fr_FR?: boolean | null
	enabled_nl_NL?: boolean | null
	enabled_de_DE?: boolean | null
	enabled_de_AT?: boolean | null
	enabled_da_DK?: boolean | null
	enabled_sv_SE?: boolean | null
	enabled_nb_NO?: boolean | null
}

export type PressPostMetaFragment = {
	__typename?: 'Press'
	publishedDate?: string | null
	slug?: string | null
	slug_en_US?: string | null
	slug_en_AU?: string | null
	slug_en_GB?: string | null
	slug_en_SG?: string | null
	slug_es_MX?: string | null
	slug_es_ES?: string | null
	slug_it_IT?: string | null
	slug_fr_FR?: string | null
	slug_nl_NL?: string | null
	slug_de_DE?: string | null
	slug_de_AT?: string | null
	slug_da_DK?: string | null
	slug_sv_SE?: string | null
	slug_nb_NO?: string | null
	enabled_en_US?: boolean | null
	enabled_en_AU?: boolean | null
	enabled_en_GB?: boolean | null
	enabled_en_SG?: boolean | null
	enabled_es_MX?: boolean | null
	enabled_es_ES?: boolean | null
	enabled_it_IT?: boolean | null
	enabled_fr_FR?: boolean | null
	enabled_nl_NL?: boolean | null
	enabled_de_DE?: boolean | null
	enabled_de_AT?: boolean | null
	enabled_da_DK?: boolean | null
	enabled_sv_SE?: boolean | null
	enabled_nb_NO?: boolean | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
}

export type PressRichTextFragment = {
	__typename?: 'Press'
	body?: {
		__typename?: 'PressBody'
		json: { [key: string]: any }
		links: {
			__typename?: 'PressBodyLinks'
			assets: {
				__typename?: 'PressBodyAssets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
		}
	} | null
}

export type PressAssetFragment = {
	__typename?: 'Asset'
	url?: string | null
	title?: string | null
	width?: number | null
	height?: number | null
	description?: string | null
	contentType?: string | null
	sys: { __typename?: 'Sys'; id: string }
}

export type PressTextFragment = {
	__typename?: 'Press'
	title?: string | null
	summary?: string | null
}

export type HeroSubheadingLinkFragment = {
	__typename?: 'HeroSubheadingLinks'
	assets: {
		__typename?: 'HeroSubheadingAssets'
		block: Array<{
			__typename?: 'Asset'
			url?: string | null
			title?: string | null
			width?: number | null
			height?: number | null
			description?: string | null
			contentType?: string | null
			sys: { __typename?: 'Sys'; id: string }
		} | null>
	}
	entries: {
		__typename?: 'HeroSubheadingEntries'
		block: Array<
			| { __typename?: 'AdditionalPersonOptions' }
			| { __typename?: 'AmplitudeExperiments' }
			| { __typename?: 'AppCaseStudy' }
			| { __typename?: 'AppCollection' }
			| { __typename?: 'AppFeature' }
			| { __typename?: 'AppTier' }
			| { __typename?: 'Article' }
			| {
					__typename: 'AssetExternal'
					assetType?: string | null
					url?: string | null
					internalName?: string | null
					altText?: string | null
					sys: { __typename?: 'Sys'; id: string }
			  }
			| { __typename?: 'AssetInternal' }
			| { __typename?: 'Background' }
			| { __typename?: 'BasicContentCard' }
			| { __typename?: 'BlogPost' }
			| { __typename?: 'CaseStudy' }
			| { __typename?: 'CaseStudyProperty' }
			| { __typename?: 'Category' }
			| { __typename?: 'CtaButton' }
			| { __typename?: 'CtaMatPattern' }
			| { __typename?: 'Event' }
			| { __typename?: 'ExternalLink' }
			| { __typename?: 'FaqItem' }
			| { __typename?: 'GatedContent' }
			| { __typename?: 'Glossary' }
			| { __typename?: 'Hero' }
			| { __typename?: 'KeyValue' }
			| { __typename?: 'KeyValueSet' }
			| { __typename?: 'MarketoForm' }
			| { __typename?: 'MarketplaceApp' }
			| { __typename?: 'MarketplaceTheme' }
			| { __typename?: 'Person' }
			| { __typename?: 'Press' }
			| { __typename?: 'Redirect' }
			| { __typename?: 'Resource' }
			| { __typename?: 'RichTextWrapper' }
			| { __typename?: 'Robots' }
			| { __typename?: 'SeoMetadata' }
			| { __typename?: 'Showcase' }
			| { __typename?: 'SpeakerCard' }
			| { __typename?: 'Tag' }
			| { __typename?: 'ThemeProperty' }
			| null
		>
	}
}

export type ResourceHeroFragment = {
	__typename?: 'Hero'
	headline?: string | null
	subheading?: {
		__typename?: 'HeroSubheading'
		json: { [key: string]: any }
		links: {
			__typename?: 'HeroSubheadingLinks'
			assets: {
				__typename?: 'HeroSubheadingAssets'
				block: Array<{
					__typename?: 'Asset'
					url?: string | null
					title?: string | null
					width?: number | null
					height?: number | null
					description?: string | null
					contentType?: string | null
					sys: { __typename?: 'Sys'; id: string }
				} | null>
			}
			entries: {
				__typename?: 'HeroSubheadingEntries'
				block: Array<
					| { __typename?: 'AdditionalPersonOptions' }
					| { __typename?: 'AmplitudeExperiments' }
					| { __typename?: 'AppCaseStudy' }
					| { __typename?: 'AppCollection' }
					| { __typename?: 'AppFeature' }
					| { __typename?: 'AppTier' }
					| { __typename?: 'Article' }
					| {
							__typename: 'AssetExternal'
							assetType?: string | null
							url?: string | null
							internalName?: string | null
							altText?: string | null
							sys: { __typename?: 'Sys'; id: string }
					  }
					| { __typename?: 'AssetInternal' }
					| { __typename?: 'Background' }
					| { __typename?: 'BasicContentCard' }
					| { __typename?: 'BlogPost' }
					| { __typename?: 'CaseStudy' }
					| { __typename?: 'CaseStudyProperty' }
					| { __typename?: 'Category' }
					| { __typename?: 'CtaButton' }
					| { __typename?: 'CtaMatPattern' }
					| { __typename?: 'Event' }
					| { __typename?: 'ExternalLink' }
					| { __typename?: 'FaqItem' }
					| { __typename?: 'GatedContent' }
					| { __typename?: 'Glossary' }
					| { __typename?: 'Hero' }
					| { __typename?: 'KeyValue' }
					| { __typename?: 'KeyValueSet' }
					| { __typename?: 'MarketoForm' }
					| { __typename?: 'MarketplaceApp' }
					| { __typename?: 'MarketplaceTheme' }
					| { __typename?: 'Person' }
					| { __typename?: 'Press' }
					| { __typename?: 'Redirect' }
					| { __typename?: 'Resource' }
					| { __typename?: 'RichTextWrapper' }
					| { __typename?: 'Robots' }
					| { __typename?: 'SeoMetadata' }
					| { __typename?: 'Showcase' }
					| { __typename?: 'SpeakerCard' }
					| { __typename?: 'Tag' }
					| { __typename?: 'ThemeProperty' }
					| null
				>
			}
		}
	} | null
	image?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	backgroundOptions?: {
		__typename?: 'Background'
		solidColor?: string | null
		gradient?: string | null
		textColor?: string | null
		backgroundImage?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	cta?: {
		__typename?: 'CtaButton'
		displayText?: string | null
		color?: string | null
		variant?: string | null
		formId?: string | null
		link?: { __typename?: 'ExternalLink'; url?: string | null } | null
	} | null
}

export type ResourceSpeakersFragment = {
	__typename?: 'Resource'
	speakersCollection?: {
		__typename?: 'ResourceSpeakersCollection'
		items: Array<{
			__typename?: 'SpeakerCard'
			title?: string | null
			jobTitle?: string | null
			description?: string | null
			image?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
		} | null>
	} | null
}

export type ResourceSlugFragment = {
	__typename?: 'Resource'
	slug?: string | null
	slug_en_US?: string | null
	slug_en_AU?: string | null
	slug_en_GB?: string | null
	slug_es_MX?: string | null
	slug_es_ES?: string | null
	slug_it_IT?: string | null
	slug_fr_FR?: string | null
	slug_nl_NL?: string | null
	slug_de_DE?: string | null
}

export type ResourceFragment = {
	__typename?: 'Resource'
	title?: string | null
	formButtonText?: string | null
	formId?: string | null
	type?: string | null
	slug?: string | null
	slug_en_US?: string | null
	slug_en_AU?: string | null
	slug_en_GB?: string | null
	slug_es_MX?: string | null
	slug_es_ES?: string | null
	slug_it_IT?: string | null
	slug_fr_FR?: string | null
	slug_nl_NL?: string | null
	slug_de_DE?: string | null
	featuredImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	cardImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	hero?: {
		__typename?: 'Hero'
		headline?: string | null
		subheading?: {
			__typename?: 'HeroSubheading'
			json: { [key: string]: any }
			links: {
				__typename?: 'HeroSubheadingLinks'
				assets: {
					__typename?: 'HeroSubheadingAssets'
					block: Array<{
						__typename?: 'Asset'
						url?: string | null
						title?: string | null
						width?: number | null
						height?: number | null
						description?: string | null
						contentType?: string | null
						sys: { __typename?: 'Sys'; id: string }
					} | null>
				}
				entries: {
					__typename?: 'HeroSubheadingEntries'
					block: Array<
						| { __typename?: 'AdditionalPersonOptions' }
						| { __typename?: 'AmplitudeExperiments' }
						| { __typename?: 'AppCaseStudy' }
						| { __typename?: 'AppCollection' }
						| { __typename?: 'AppFeature' }
						| { __typename?: 'AppTier' }
						| { __typename?: 'Article' }
						| {
								__typename: 'AssetExternal'
								assetType?: string | null
								url?: string | null
								internalName?: string | null
								altText?: string | null
								sys: { __typename?: 'Sys'; id: string }
						  }
						| { __typename?: 'AssetInternal' }
						| { __typename?: 'Background' }
						| { __typename?: 'BasicContentCard' }
						| { __typename?: 'BlogPost' }
						| { __typename?: 'CaseStudy' }
						| { __typename?: 'CaseStudyProperty' }
						| { __typename?: 'Category' }
						| { __typename?: 'CtaButton' }
						| { __typename?: 'CtaMatPattern' }
						| { __typename?: 'Event' }
						| { __typename?: 'ExternalLink' }
						| { __typename?: 'FaqItem' }
						| { __typename?: 'GatedContent' }
						| { __typename?: 'Glossary' }
						| { __typename?: 'Hero' }
						| { __typename?: 'KeyValue' }
						| { __typename?: 'KeyValueSet' }
						| { __typename?: 'MarketoForm' }
						| { __typename?: 'MarketplaceApp' }
						| { __typename?: 'MarketplaceTheme' }
						| { __typename?: 'Person' }
						| { __typename?: 'Press' }
						| { __typename?: 'Redirect' }
						| { __typename?: 'Resource' }
						| { __typename?: 'RichTextWrapper' }
						| { __typename?: 'Robots' }
						| { __typename?: 'SeoMetadata' }
						| { __typename?: 'Showcase' }
						| { __typename?: 'SpeakerCard' }
						| { __typename?: 'Tag' }
						| { __typename?: 'ThemeProperty' }
						| null
					>
				}
			}
		} | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
		backgroundOptions?: {
			__typename?: 'Background'
			solidColor?: string | null
			gradient?: string | null
			textColor?: string | null
			backgroundImage?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
		} | null
		cta?: {
			__typename?: 'CtaButton'
			displayText?: string | null
			color?: string | null
			variant?: string | null
			formId?: string | null
			link?: { __typename?: 'ExternalLink'; url?: string | null } | null
		} | null
	} | null
	statsCollection?: {
		__typename?: 'ResourceStatsCollection'
		items: Array<{ __typename?: 'KeyValue'; key?: string | null; value?: string | null } | null>
	} | null
	aboveFormContent?: {
		__typename?: 'ResourceAboveFormContent'
		json: { [key: string]: any }
	} | null
	sideFormContent?: { __typename?: 'ResourceSideFormContent'; json: { [key: string]: any } } | null
	content?: { __typename?: 'ResourceContent'; json: { [key: string]: any } } | null
	contentImage?: {
		__typename: 'Asset'
		url?: string | null
		width?: number | null
		height?: number | null
		title?: string | null
		contentType?: string | null
	} | null
	disclaimer?: { __typename?: 'ResourceDisclaimer'; json: { [key: string]: any } } | null
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	recommendedContentCollection?: {
		__typename?: 'ResourceRecommendedContentCollection'
		items: Array<
			| {
					__typename: 'Article'
					slug?: string | null
					title?: string | null
					featuredImage?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
					parentArticle?: { __typename?: 'Article'; slug?: string | null } | null
			  }
			| {
					__typename: 'BasicContentCard'
					title?: string | null
					overline?: string | null
					description?: string | null
					image?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
					ctaButtonCollection?: {
						__typename?: 'BasicContentCardCtaButtonCollection'
						items: Array<{
							__typename?: 'CtaButton'
							displayText?: string | null
							link?: { __typename?: 'ExternalLink'; url?: string | null } | null
						} | null>
					} | null
			  }
			| {
					__typename: 'BlogPost'
					slug?: string | null
					title?: string | null
					featuredImage?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
			  }
			| {
					__typename: 'CaseStudy'
					slug?: string | null
					title?: string | null
					image?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
			  }
			| {
					__typename: 'Resource'
					title?: string | null
					slug?: string | null
					type?: string | null
					cardImage?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
			  }
			| null
		>
	} | null
	speakersCollection?: {
		__typename?: 'ResourceSpeakersCollection'
		items: Array<{
			__typename?: 'SpeakerCard'
			title?: string | null
			jobTitle?: string | null
			description?: string | null
			image?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
		} | null>
	} | null
}

export type ResourceQueryVariables = Exact<{
	where?: InputMaybe<ResourceFilter>
	order?: InputMaybe<Array<InputMaybe<ResourceOrder>> | InputMaybe<ResourceOrder>>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ResourceQuery = {
	__typename?: 'Query'
	resourceCollection?: {
		__typename?: 'ResourceCollection'
		items: Array<{
			__typename?: 'Resource'
			title?: string | null
			formButtonText?: string | null
			formId?: string | null
			type?: string | null
			slug?: string | null
			slug_en_US?: string | null
			slug_en_AU?: string | null
			slug_en_GB?: string | null
			slug_es_MX?: string | null
			slug_es_ES?: string | null
			slug_it_IT?: string | null
			slug_fr_FR?: string | null
			slug_nl_NL?: string | null
			slug_de_DE?: string | null
			sys: { __typename?: 'Sys'; id: string }
			featuredImage?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			cardImage?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			hero?: {
				__typename?: 'Hero'
				headline?: string | null
				subheading?: {
					__typename?: 'HeroSubheading'
					json: { [key: string]: any }
					links: {
						__typename?: 'HeroSubheadingLinks'
						assets: {
							__typename?: 'HeroSubheadingAssets'
							block: Array<{
								__typename?: 'Asset'
								url?: string | null
								title?: string | null
								width?: number | null
								height?: number | null
								description?: string | null
								contentType?: string | null
								sys: { __typename?: 'Sys'; id: string }
							} | null>
						}
						entries: {
							__typename?: 'HeroSubheadingEntries'
							block: Array<
								| { __typename?: 'AdditionalPersonOptions' }
								| { __typename?: 'AmplitudeExperiments' }
								| { __typename?: 'AppCaseStudy' }
								| { __typename?: 'AppCollection' }
								| { __typename?: 'AppFeature' }
								| { __typename?: 'AppTier' }
								| { __typename?: 'Article' }
								| {
										__typename: 'AssetExternal'
										assetType?: string | null
										url?: string | null
										internalName?: string | null
										altText?: string | null
										sys: { __typename?: 'Sys'; id: string }
								  }
								| { __typename?: 'AssetInternal' }
								| { __typename?: 'Background' }
								| { __typename?: 'BasicContentCard' }
								| { __typename?: 'BlogPost' }
								| { __typename?: 'CaseStudy' }
								| { __typename?: 'CaseStudyProperty' }
								| { __typename?: 'Category' }
								| { __typename?: 'CtaButton' }
								| { __typename?: 'CtaMatPattern' }
								| { __typename?: 'Event' }
								| { __typename?: 'ExternalLink' }
								| { __typename?: 'FaqItem' }
								| { __typename?: 'GatedContent' }
								| { __typename?: 'Glossary' }
								| { __typename?: 'Hero' }
								| { __typename?: 'KeyValue' }
								| { __typename?: 'KeyValueSet' }
								| { __typename?: 'MarketoForm' }
								| { __typename?: 'MarketplaceApp' }
								| { __typename?: 'MarketplaceTheme' }
								| { __typename?: 'Person' }
								| { __typename?: 'Press' }
								| { __typename?: 'Redirect' }
								| { __typename?: 'Resource' }
								| { __typename?: 'RichTextWrapper' }
								| { __typename?: 'Robots' }
								| { __typename?: 'SeoMetadata' }
								| { __typename?: 'Showcase' }
								| { __typename?: 'SpeakerCard' }
								| { __typename?: 'Tag' }
								| { __typename?: 'ThemeProperty' }
								| null
							>
						}
					}
				} | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
				backgroundOptions?: {
					__typename?: 'Background'
					solidColor?: string | null
					gradient?: string | null
					textColor?: string | null
					backgroundImage?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
				} | null
				cta?: {
					__typename?: 'CtaButton'
					displayText?: string | null
					color?: string | null
					variant?: string | null
					formId?: string | null
					link?: { __typename?: 'ExternalLink'; url?: string | null } | null
				} | null
			} | null
			statsCollection?: {
				__typename?: 'ResourceStatsCollection'
				items: Array<{ __typename?: 'KeyValue'; key?: string | null; value?: string | null } | null>
			} | null
			aboveFormContent?: {
				__typename?: 'ResourceAboveFormContent'
				json: { [key: string]: any }
			} | null
			sideFormContent?: {
				__typename?: 'ResourceSideFormContent'
				json: { [key: string]: any }
			} | null
			content?: { __typename?: 'ResourceContent'; json: { [key: string]: any } } | null
			contentImage?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
			disclaimer?: { __typename?: 'ResourceDisclaimer'; json: { [key: string]: any } } | null
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			recommendedContentCollection?: {
				__typename?: 'ResourceRecommendedContentCollection'
				items: Array<
					| {
							__typename: 'Article'
							slug?: string | null
							title?: string | null
							featuredImage?: {
								__typename: 'Asset'
								url?: string | null
								width?: number | null
								height?: number | null
								title?: string | null
								contentType?: string | null
							} | null
							parentArticle?: { __typename?: 'Article'; slug?: string | null } | null
					  }
					| {
							__typename: 'BasicContentCard'
							title?: string | null
							overline?: string | null
							description?: string | null
							image?: {
								__typename: 'Asset'
								url?: string | null
								width?: number | null
								height?: number | null
								title?: string | null
								contentType?: string | null
							} | null
							ctaButtonCollection?: {
								__typename?: 'BasicContentCardCtaButtonCollection'
								items: Array<{
									__typename?: 'CtaButton'
									displayText?: string | null
									link?: { __typename?: 'ExternalLink'; url?: string | null } | null
								} | null>
							} | null
					  }
					| {
							__typename: 'BlogPost'
							slug?: string | null
							title?: string | null
							featuredImage?: {
								__typename: 'Asset'
								url?: string | null
								width?: number | null
								height?: number | null
								title?: string | null
								contentType?: string | null
							} | null
					  }
					| {
							__typename: 'CaseStudy'
							slug?: string | null
							title?: string | null
							image?: {
								__typename: 'Asset'
								url?: string | null
								width?: number | null
								height?: number | null
								title?: string | null
								contentType?: string | null
							} | null
					  }
					| {
							__typename: 'Resource'
							title?: string | null
							slug?: string | null
							type?: string | null
							cardImage?: {
								__typename: 'Asset'
								url?: string | null
								width?: number | null
								height?: number | null
								title?: string | null
								contentType?: string | null
							} | null
					  }
					| null
				>
			} | null
			speakersCollection?: {
				__typename?: 'ResourceSpeakersCollection'
				items: Array<{
					__typename?: 'SpeakerCard'
					title?: string | null
					jobTitle?: string | null
					description?: string | null
					image?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
				} | null>
			} | null
		} | null>
	} | null
}

export type ResourceRecommendedFeedFragment = {
	__typename?: 'Resource'
	recommendedContentCollection?: {
		__typename?: 'ResourceRecommendedContentCollection'
		items: Array<
			| {
					__typename: 'Article'
					slug?: string | null
					title?: string | null
					featuredImage?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
					parentArticle?: { __typename?: 'Article'; slug?: string | null } | null
			  }
			| {
					__typename: 'BasicContentCard'
					title?: string | null
					overline?: string | null
					description?: string | null
					image?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
					ctaButtonCollection?: {
						__typename?: 'BasicContentCardCtaButtonCollection'
						items: Array<{
							__typename?: 'CtaButton'
							displayText?: string | null
							link?: { __typename?: 'ExternalLink'; url?: string | null } | null
						} | null>
					} | null
			  }
			| {
					__typename: 'BlogPost'
					slug?: string | null
					title?: string | null
					featuredImage?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
			  }
			| {
					__typename: 'CaseStudy'
					slug?: string | null
					title?: string | null
					image?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
			  }
			| {
					__typename: 'Resource'
					title?: string | null
					slug?: string | null
					type?: string | null
					cardImage?: {
						__typename: 'Asset'
						url?: string | null
						width?: number | null
						height?: number | null
						title?: string | null
						contentType?: string | null
					} | null
			  }
			| null
		>
	} | null
}

export type ResourceFeedQueryVariables = Exact<{
	where?: InputMaybe<ResourceFilter>
	order?: InputMaybe<Array<InputMaybe<ResourceOrder>> | InputMaybe<ResourceOrder>>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ResourceFeedQuery = {
	__typename?: 'Query'
	resourceCollection?: {
		__typename?: 'ResourceCollection'
		total: number
		items: Array<{
			__typename: 'Resource'
			title?: string | null
			slug?: string | null
			publishedDate?: string | null
			cardImage?: {
				__typename: 'Asset'
				url?: string | null
				width?: number | null
				height?: number | null
				title?: string | null
				contentType?: string | null
			} | null
		} | null>
	} | null
}

export type ShowcaseItemFragment = {
	__typename: 'Showcase'
	slug?: string | null
	title?: string | null
	slug_en_US?: string | null
	sys: { __typename?: 'Sys'; id: string }
	description?: { __typename?: 'ShowcaseDescription'; json: { [key: string]: any } } | null
	caseStudy?: { __typename?: 'ExternalLink'; url?: string | null } | null
	image?:
		| { __typename?: 'AssetExternal' }
		| {
				__typename: 'AssetInternal'
				media?: {
					__typename: 'Asset'
					url?: string | null
					description?: string | null
					width?: number | null
					height?: number | null
				} | null
		  }
		| null
	externalUrl?: { __typename?: 'ExternalLink'; url?: string | null } | null
}

export type ShowcaseFeedQueryVariables = Exact<{
	where?: InputMaybe<ShowcaseFilter>
	order?: InputMaybe<Array<InputMaybe<ShowcaseOrder>> | InputMaybe<ShowcaseOrder>>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ShowcaseFeedQuery = {
	__typename?: 'Query'
	showcaseCollection?: {
		__typename?: 'ShowcaseCollection'
		limit: number
		skip: number
		total: number
		items: Array<{
			__typename: 'Showcase'
			slug?: string | null
			title?: string | null
			slug_en_US?: string | null
			sys: { __typename?: 'Sys'; id: string }
			description?: { __typename?: 'ShowcaseDescription'; json: { [key: string]: any } } | null
			caseStudy?: { __typename?: 'ExternalLink'; url?: string | null } | null
			image?:
				| { __typename?: 'AssetExternal' }
				| {
						__typename: 'AssetInternal'
						media?: {
							__typename: 'Asset'
							url?: string | null
							description?: string | null
							width?: number | null
							height?: number | null
						} | null
				  }
				| null
			externalUrl?: { __typename?: 'ExternalLink'; url?: string | null } | null
		} | null>
	} | null
}

export type ShowcaseCategorySlugsFragment = {
	__typename?: 'Category'
	slug?: string | null
	slug_en_US?: string | null
	slug_en_AU?: string | null
	slug_en_GB?: string | null
	slug_en_SG?: string | null
	slug_es_MX?: string | null
	slug_es_ES?: string | null
	slug_it_IT?: string | null
	slug_fr_FR?: string | null
	slug_nl_NL?: string | null
	slug_de_DE?: string | null
	slug_de_AT?: string | null
	slug_da_DK?: string | null
	slug_sv_SE?: string | null
	slug_nb_NO?: string | null
}

export type ShowcaseCategoryFragment = {
	__typename: 'Category'
	name?: string | null
	slug?: string | null
	slug_en_US?: string | null
	slug_en_AU?: string | null
	slug_en_GB?: string | null
	slug_en_SG?: string | null
	slug_es_MX?: string | null
	slug_es_ES?: string | null
	slug_it_IT?: string | null
	slug_fr_FR?: string | null
	slug_nl_NL?: string | null
	slug_de_DE?: string | null
	slug_de_AT?: string | null
	slug_da_DK?: string | null
	slug_sv_SE?: string | null
	slug_nb_NO?: string | null
	sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
	parentCategory?: {
		__typename?: 'Category'
		name?: string | null
		slug?: string | null
		slug_en_US?: string | null
		slug_en_AU?: string | null
		slug_en_GB?: string | null
		slug_en_SG?: string | null
		slug_es_MX?: string | null
		slug_es_ES?: string | null
		slug_it_IT?: string | null
		slug_fr_FR?: string | null
		slug_nl_NL?: string | null
		slug_de_DE?: string | null
		slug_de_AT?: string | null
		slug_da_DK?: string | null
		slug_sv_SE?: string | null
		slug_nb_NO?: string | null
	} | null
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		title?: string | null
		description?: string | null
		canonicalUrl?: string | null
		includeTitleTemplate?: boolean | null
		noindex?: boolean | null
		nofollow?: boolean | null
		readingTime?: string | null
		image?: {
			__typename: 'Asset'
			url?: string | null
			width?: number | null
			height?: number | null
			title?: string | null
			contentType?: string | null
		} | null
	} | null
	linkedFrom?: {
		__typename?: 'CategoryLinkingCollections'
		showcaseCollection?: { __typename?: 'ShowcaseCollection'; total: number } | null
	} | null
}

export type ShowcaseCategoriesQueryVariables = Exact<{
	where?: InputMaybe<CategoryFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ShowcaseCategoriesQuery = {
	__typename?: 'Query'
	categoryCollection?: {
		__typename?: 'CategoryCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename: 'Category'
			name?: string | null
			slug?: string | null
			slug_en_US?: string | null
			slug_en_AU?: string | null
			slug_en_GB?: string | null
			slug_en_SG?: string | null
			slug_es_MX?: string | null
			slug_es_ES?: string | null
			slug_it_IT?: string | null
			slug_fr_FR?: string | null
			slug_nl_NL?: string | null
			slug_de_DE?: string | null
			slug_de_AT?: string | null
			slug_da_DK?: string | null
			slug_sv_SE?: string | null
			slug_nb_NO?: string | null
			sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
			parentCategory?: {
				__typename?: 'Category'
				name?: string | null
				slug?: string | null
				slug_en_US?: string | null
				slug_en_AU?: string | null
				slug_en_GB?: string | null
				slug_en_SG?: string | null
				slug_es_MX?: string | null
				slug_es_ES?: string | null
				slug_it_IT?: string | null
				slug_fr_FR?: string | null
				slug_nl_NL?: string | null
				slug_de_DE?: string | null
				slug_de_AT?: string | null
				slug_da_DK?: string | null
				slug_sv_SE?: string | null
				slug_nb_NO?: string | null
			} | null
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				title?: string | null
				description?: string | null
				canonicalUrl?: string | null
				includeTitleTemplate?: boolean | null
				noindex?: boolean | null
				nofollow?: boolean | null
				readingTime?: string | null
				image?: {
					__typename: 'Asset'
					url?: string | null
					width?: number | null
					height?: number | null
					title?: string | null
					contentType?: string | null
				} | null
			} | null
			linkedFrom?: {
				__typename?: 'CategoryLinkingCollections'
				showcaseCollection?: { __typename?: 'ShowcaseCollection'; total: number } | null
			} | null
		} | null>
	} | null
}

export type AlgoliaBlogPostFragment = {
	__typename?: 'BlogPost'
	title?: string | null
	slug?: string | null
	status?: boolean | null
	sys: { __typename?: 'Sys'; id: string }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		canonicalUrl?: string | null
		image?: { __typename?: 'Asset'; url?: string | null } | null
	} | null
	author?: {
		__typename?: 'Person'
		fullName?: string | null
		slug?: string | null
		avatar?: { __typename?: 'Asset'; url?: string | null } | null
	} | null
	categoriesCollection?: {
		__typename?: 'BlogPostCategoriesCollection'
		items: Array<{ __typename?: 'Category'; name?: string | null; slug?: string | null } | null>
	} | null
	summary?: { __typename?: 'BlogPostSummary'; json: { [key: string]: any } } | null
	body?: { __typename?: 'BlogPostBody'; json: { [key: string]: any } } | null
	tagsCollection?: {
		__typename?: 'BlogPostTagsCollection'
		items: Array<{ __typename?: 'Tag'; name?: string | null; slug?: string | null } | null>
	} | null
}

export type AlgoliaBlogPostsQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale: Scalars['String']['input']
}>

export type AlgoliaBlogPostsQuery = {
	__typename?: 'Query'
	blogPostCollection?: {
		__typename?: 'BlogPostCollection'
		limit: number
		total: number
		skip: number
		items: Array<{
			__typename?: 'BlogPost'
			title?: string | null
			slug?: string | null
			status?: boolean | null
			sys: { __typename?: 'Sys'; id: string }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				canonicalUrl?: string | null
				image?: { __typename?: 'Asset'; url?: string | null } | null
			} | null
			author?: {
				__typename?: 'Person'
				fullName?: string | null
				slug?: string | null
				avatar?: { __typename?: 'Asset'; url?: string | null } | null
			} | null
			categoriesCollection?: {
				__typename?: 'BlogPostCategoriesCollection'
				items: Array<{ __typename?: 'Category'; name?: string | null; slug?: string | null } | null>
			} | null
			summary?: { __typename?: 'BlogPostSummary'; json: { [key: string]: any } } | null
			body?: { __typename?: 'BlogPostBody'; json: { [key: string]: any } } | null
			tagsCollection?: {
				__typename?: 'BlogPostTagsCollection'
				items: Array<{ __typename?: 'Tag'; name?: string | null; slug?: string | null } | null>
			} | null
		} | null>
	} | null
}

export type AlgoliaBlogPostByIdQueryVariables = Exact<{
	postID: Scalars['String']['input']
	locale?: Scalars['String']['input']
}>

export type AlgoliaBlogPostByIdQuery = {
	__typename?: 'Query'
	blogPost?: {
		__typename?: 'BlogPost'
		title?: string | null
		slug?: string | null
		status?: boolean | null
		sys: { __typename?: 'Sys'; id: string }
		seoMetadata?: {
			__typename?: 'SeoMetadata'
			canonicalUrl?: string | null
			image?: { __typename?: 'Asset'; url?: string | null } | null
		} | null
		author?: {
			__typename?: 'Person'
			fullName?: string | null
			slug?: string | null
			avatar?: { __typename?: 'Asset'; url?: string | null } | null
		} | null
		categoriesCollection?: {
			__typename?: 'BlogPostCategoriesCollection'
			items: Array<{ __typename?: 'Category'; name?: string | null; slug?: string | null } | null>
		} | null
		summary?: { __typename?: 'BlogPostSummary'; json: { [key: string]: any } } | null
		body?: { __typename?: 'BlogPostBody'; json: { [key: string]: any } } | null
		tagsCollection?: {
			__typename?: 'BlogPostTagsCollection'
			items: Array<{ __typename?: 'Tag'; name?: string | null; slug?: string | null } | null>
		} | null
	} | null
}

export type AlgoliaMarketplaceAppFragment = {
	__typename?: 'MarketplaceApp'
	algoliaKeywords?: Array<string | null> | null
	slug?: string | null
	name?: string | null
	logo?: string | null
	logoIcon?: string | null
	rating?: number | null
	reviewCount?: number | null
	unifiedBilling?: boolean | null
	catalystCompatible?: boolean | null
	freeTrial?: boolean | null
	freeTrialDays?: number | null
	upfrontPrice?: string | null
	monthlyPrice?: string | null
	pricing?: string | null
	pricingType?: string | null
	createdOn?: string | null
	appRegistryUpdatedAt?: string | null
	multiStorefront?: boolean | null
	multiLocation?: boolean | null
	termsOfServiceUrl?: string | null
	privacyPolicyUrl?: string | null
	similarApps?: Array<string | null> | null
	featured?: boolean | null
	featuredOrder?: number | null
	appRegistryId?: string | null
	rank?: number | null
	type?: string | null
	sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
	summary?: { __typename?: 'MarketplaceAppSummary'; json: { [key: string]: any } } | null
	abstract?: { __typename?: 'MarketplaceAppAbstract'; json: { [key: string]: any } } | null
	appTier?: {
		__typename?: 'AppTier'
		slug?: string | null
		name?: string | null
		sort?: number | null
		logo?: { __typename?: 'Asset'; url?: string | null } | null
	} | null
	appFeaturesCollection?: {
		__typename?: 'MarketplaceAppAppFeaturesCollection'
		items: Array<{
			__typename?: 'AppFeature'
			title?: string | null
			description?: { __typename?: 'AppFeatureDescription'; json: { [key: string]: any } } | null
		} | null>
	} | null
	categoriesCollection?: {
		__typename?: 'MarketplaceAppCategoriesCollection'
		items: Array<{
			__typename?: 'Category'
			name?: string | null
			slug?: string | null
			parentCategory?: {
				__typename?: 'Category'
				name?: string | null
				slug?: string | null
				contentfulMetadata: {
					__typename?: 'ContentfulMetadata'
					tags: Array<{ __typename?: 'ContentfulTag'; id?: string | null } | null>
				}
			} | null
		} | null>
	} | null
	collectionsCollection?: {
		__typename?: 'MarketplaceAppCollectionsCollection'
		items: Array<{
			__typename?: 'AppCollection'
			name?: string | null
			slug?: string | null
		} | null>
	} | null
}

export type AlgoliaMarketplaceAppsQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	where?: InputMaybe<MarketplaceAppFilter>
}>

export type AlgoliaMarketplaceAppsQuery = {
	__typename?: 'Query'
	marketplaceAppCollection?: {
		__typename?: 'MarketplaceAppCollection'
		limit: number
		total: number
		skip: number
		items: Array<{
			__typename?: 'MarketplaceApp'
			algoliaKeywords?: Array<string | null> | null
			slug?: string | null
			name?: string | null
			logo?: string | null
			logoIcon?: string | null
			rating?: number | null
			reviewCount?: number | null
			unifiedBilling?: boolean | null
			catalystCompatible?: boolean | null
			freeTrial?: boolean | null
			freeTrialDays?: number | null
			upfrontPrice?: string | null
			monthlyPrice?: string | null
			pricing?: string | null
			pricingType?: string | null
			createdOn?: string | null
			appRegistryUpdatedAt?: string | null
			multiStorefront?: boolean | null
			multiLocation?: boolean | null
			termsOfServiceUrl?: string | null
			privacyPolicyUrl?: string | null
			similarApps?: Array<string | null> | null
			featured?: boolean | null
			featuredOrder?: number | null
			appRegistryId?: string | null
			rank?: number | null
			type?: string | null
			sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
			summary?: { __typename?: 'MarketplaceAppSummary'; json: { [key: string]: any } } | null
			abstract?: { __typename?: 'MarketplaceAppAbstract'; json: { [key: string]: any } } | null
			appTier?: {
				__typename?: 'AppTier'
				slug?: string | null
				name?: string | null
				sort?: number | null
				logo?: { __typename?: 'Asset'; url?: string | null } | null
			} | null
			appFeaturesCollection?: {
				__typename?: 'MarketplaceAppAppFeaturesCollection'
				items: Array<{
					__typename?: 'AppFeature'
					title?: string | null
					description?: {
						__typename?: 'AppFeatureDescription'
						json: { [key: string]: any }
					} | null
				} | null>
			} | null
			categoriesCollection?: {
				__typename?: 'MarketplaceAppCategoriesCollection'
				items: Array<{
					__typename?: 'Category'
					name?: string | null
					slug?: string | null
					parentCategory?: {
						__typename?: 'Category'
						name?: string | null
						slug?: string | null
						contentfulMetadata: {
							__typename?: 'ContentfulMetadata'
							tags: Array<{ __typename?: 'ContentfulTag'; id?: string | null } | null>
						}
					} | null
				} | null>
			} | null
			collectionsCollection?: {
				__typename?: 'MarketplaceAppCollectionsCollection'
				items: Array<{
					__typename?: 'AppCollection'
					name?: string | null
					slug?: string | null
				} | null>
			} | null
		} | null>
	} | null
}

export type AlgoliaMarketplaceAppByIdQueryVariables = Exact<{
	appID: Scalars['String']['input']
	locale?: Scalars['String']['input']
}>

export type AlgoliaMarketplaceAppByIdQuery = {
	__typename?: 'Query'
	marketplaceApp?: {
		__typename?: 'MarketplaceApp'
		algoliaKeywords?: Array<string | null> | null
		slug?: string | null
		name?: string | null
		logo?: string | null
		logoIcon?: string | null
		rating?: number | null
		reviewCount?: number | null
		unifiedBilling?: boolean | null
		catalystCompatible?: boolean | null
		freeTrial?: boolean | null
		freeTrialDays?: number | null
		upfrontPrice?: string | null
		monthlyPrice?: string | null
		pricing?: string | null
		pricingType?: string | null
		createdOn?: string | null
		appRegistryUpdatedAt?: string | null
		multiStorefront?: boolean | null
		multiLocation?: boolean | null
		termsOfServiceUrl?: string | null
		privacyPolicyUrl?: string | null
		similarApps?: Array<string | null> | null
		featured?: boolean | null
		featuredOrder?: number | null
		appRegistryId?: string | null
		rank?: number | null
		type?: string | null
		sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
		summary?: { __typename?: 'MarketplaceAppSummary'; json: { [key: string]: any } } | null
		abstract?: { __typename?: 'MarketplaceAppAbstract'; json: { [key: string]: any } } | null
		appTier?: {
			__typename?: 'AppTier'
			slug?: string | null
			name?: string | null
			sort?: number | null
			logo?: { __typename?: 'Asset'; url?: string | null } | null
		} | null
		appFeaturesCollection?: {
			__typename?: 'MarketplaceAppAppFeaturesCollection'
			items: Array<{
				__typename?: 'AppFeature'
				title?: string | null
				description?: { __typename?: 'AppFeatureDescription'; json: { [key: string]: any } } | null
			} | null>
		} | null
		categoriesCollection?: {
			__typename?: 'MarketplaceAppCategoriesCollection'
			items: Array<{
				__typename?: 'Category'
				name?: string | null
				slug?: string | null
				parentCategory?: {
					__typename?: 'Category'
					name?: string | null
					slug?: string | null
					contentfulMetadata: {
						__typename?: 'ContentfulMetadata'
						tags: Array<{ __typename?: 'ContentfulTag'; id?: string | null } | null>
					}
				} | null
			} | null>
		} | null
		collectionsCollection?: {
			__typename?: 'MarketplaceAppCollectionsCollection'
			items: Array<{
				__typename?: 'AppCollection'
				name?: string | null
				slug?: string | null
			} | null>
		} | null
	} | null
}

export type AlgoliaMarketplaceThemeFragment = {
	__typename?: 'MarketplaceTheme'
	slug?: string | null
	title?: string | null
	partnerName?: string | null
	partnerId?: string | null
	externalId?: string | null
	featuredOrder?: number | null
	publishedDate?: string | null
	partnerEmail?: string | null
	contactUrl?: string | null
	demoUrl?: string | null
	price?: string | null
	registryId?: string | null
	versionId?: string | null
	largePreview?: string | null
	largeThumbnail?: string | null
	smallThumbnail?: string | null
	mobilePreview?: string | null
	themeFeatures?: Array<string | null> | null
	themeOptimizations?: Array<string | null> | null
	themeCollections?: Array<string | null> | null
	documentationUrl?: string | null
	related?: Array<string | null> | null
	sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
	description?: { __typename?: 'MarketplaceThemeDescription'; json: { [key: string]: any } } | null
	themeIndustriesCollection?: {
		__typename?: 'MarketplaceThemeThemeIndustriesCollection'
		items: Array<{ __typename?: 'ThemeProperty'; name?: string | null } | null>
	} | null
}

export type AlgoliaMarketplaceThemesQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type AlgoliaMarketplaceThemesQuery = {
	__typename?: 'Query'
	marketplaceThemeCollection?: {
		__typename?: 'MarketplaceThemeCollection'
		limit: number
		total: number
		skip: number
		items: Array<{
			__typename?: 'MarketplaceTheme'
			slug?: string | null
			title?: string | null
			partnerName?: string | null
			partnerId?: string | null
			externalId?: string | null
			featuredOrder?: number | null
			publishedDate?: string | null
			partnerEmail?: string | null
			contactUrl?: string | null
			demoUrl?: string | null
			price?: string | null
			registryId?: string | null
			versionId?: string | null
			largePreview?: string | null
			largeThumbnail?: string | null
			smallThumbnail?: string | null
			mobilePreview?: string | null
			themeFeatures?: Array<string | null> | null
			themeOptimizations?: Array<string | null> | null
			themeCollections?: Array<string | null> | null
			documentationUrl?: string | null
			related?: Array<string | null> | null
			sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
			description?: {
				__typename?: 'MarketplaceThemeDescription'
				json: { [key: string]: any }
			} | null
			themeIndustriesCollection?: {
				__typename?: 'MarketplaceThemeThemeIndustriesCollection'
				items: Array<{ __typename?: 'ThemeProperty'; name?: string | null } | null>
			} | null
		} | null>
	} | null
}

export type AlgoliaMarketplaceThemeByIdQueryVariables = Exact<{
	themeID: Scalars['String']['input']
	locale?: Scalars['String']['input']
}>

export type AlgoliaMarketplaceThemeByIdQuery = {
	__typename?: 'Query'
	marketplaceTheme?: {
		__typename?: 'MarketplaceTheme'
		slug?: string | null
		title?: string | null
		partnerName?: string | null
		partnerId?: string | null
		externalId?: string | null
		featuredOrder?: number | null
		publishedDate?: string | null
		partnerEmail?: string | null
		contactUrl?: string | null
		demoUrl?: string | null
		price?: string | null
		registryId?: string | null
		versionId?: string | null
		largePreview?: string | null
		largeThumbnail?: string | null
		smallThumbnail?: string | null
		mobilePreview?: string | null
		themeFeatures?: Array<string | null> | null
		themeOptimizations?: Array<string | null> | null
		themeCollections?: Array<string | null> | null
		documentationUrl?: string | null
		related?: Array<string | null> | null
		sys: { __typename?: 'Sys'; id: string; publishedAt?: string | null }
		description?: {
			__typename?: 'MarketplaceThemeDescription'
			json: { [key: string]: any }
		} | null
		themeIndustriesCollection?: {
			__typename?: 'MarketplaceThemeThemeIndustriesCollection'
			items: Array<{ __typename?: 'ThemeProperty'; name?: string | null } | null>
		} | null
	} | null
}

export type BlogPostPathsQueryVariables = Exact<{
	where?: InputMaybe<BlogPostFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type BlogPostPathsQuery = {
	__typename?: 'Query'
	blogPostCollection?: {
		__typename?: 'BlogPostCollection'
		items: Array<{ __typename?: 'BlogPost'; slug?: string | null } | null>
	} | null
}

export type BlogContentForPdfQueryVariables = Exact<{
	postID: Scalars['String']['input']
	locale?: Scalars['String']['input']
}>

export type BlogContentForPdfQuery = {
	__typename?: 'Query'
	blogPost?: {
		__typename?: 'BlogPost'
		title?: string | null
		slug?: string | null
		featuredImage?: { __typename?: 'Asset'; url?: string | null } | null
		body?: {
			__typename?: 'BlogPostBody'
			json: { [key: string]: any }
			links: {
				__typename?: 'BlogPostBodyLinks'
				assets: {
					__typename?: 'BlogPostBodyAssets'
					block: Array<{
						__typename?: 'Asset'
						url?: string | null
						title?: string | null
						width?: number | null
						height?: number | null
						description?: string | null
						contentType?: string | null
						sys: { __typename?: 'Sys'; id: string }
					} | null>
				}
				entries: {
					__typename?: 'BlogPostBodyEntries'
					block: Array<
						| { __typename?: 'AdditionalPersonOptions' }
						| { __typename?: 'AmplitudeExperiments' }
						| { __typename?: 'AppCaseStudy' }
						| { __typename?: 'AppCollection' }
						| { __typename?: 'AppFeature' }
						| { __typename?: 'AppTier' }
						| { __typename?: 'Article' }
						| {
								__typename: 'AssetExternal'
								assetType?: string | null
								url?: string | null
								internalName?: string | null
								altText?: string | null
								sys: { __typename?: 'Sys'; id: string }
						  }
						| { __typename?: 'AssetInternal' }
						| { __typename?: 'Background' }
						| { __typename?: 'BasicContentCard' }
						| { __typename?: 'BlogPost' }
						| { __typename?: 'CaseStudy' }
						| { __typename?: 'CaseStudyProperty' }
						| { __typename?: 'Category' }
						| { __typename?: 'CtaButton' }
						| { __typename?: 'CtaMatPattern' }
						| { __typename?: 'Event' }
						| { __typename?: 'ExternalLink' }
						| { __typename?: 'FaqItem' }
						| { __typename?: 'GatedContent' }
						| { __typename?: 'Glossary' }
						| { __typename?: 'Hero' }
						| { __typename?: 'KeyValue' }
						| { __typename?: 'KeyValueSet' }
						| { __typename?: 'MarketoForm' }
						| { __typename?: 'MarketplaceApp' }
						| { __typename?: 'MarketplaceTheme' }
						| { __typename?: 'Person' }
						| { __typename?: 'Press' }
						| { __typename?: 'Redirect' }
						| { __typename?: 'Resource' }
						| {
								__typename: 'RichTextWrapper'
								type?: string | null
								sys: { __typename?: 'Sys'; id: string }
								content?: {
									__typename?: 'RichTextWrapperContent'
									json: { [key: string]: any }
								} | null
						  }
						| { __typename?: 'Robots' }
						| { __typename?: 'SeoMetadata' }
						| { __typename?: 'Showcase' }
						| { __typename?: 'SpeakerCard' }
						| { __typename?: 'Tag' }
						| { __typename?: 'ThemeProperty' }
						| null
					>
				}
			}
		} | null
		gatedContentSettings?: {
			__typename?: 'GatedContent'
			sys: { __typename?: 'Sys'; id: string }
		} | null
	} | null
}

export type ArticleContentForPdfQueryVariables = Exact<{
	postID: Scalars['String']['input']
	locale?: Scalars['String']['input']
}>

export type ArticleContentForPdfQuery = {
	__typename?: 'Query'
	article?: {
		__typename?: 'Article'
		title?: string | null
		slug?: string | null
		featuredImage?: { __typename?: 'Asset'; url?: string | null } | null
		body?: {
			__typename?: 'ArticleBody'
			json: { [key: string]: any }
			links: {
				__typename?: 'ArticleBodyLinks'
				assets: {
					__typename?: 'ArticleBodyAssets'
					block: Array<{
						__typename?: 'Asset'
						url?: string | null
						title?: string | null
						width?: number | null
						height?: number | null
						description?: string | null
						contentType?: string | null
						sys: { __typename?: 'Sys'; id: string }
					} | null>
				}
				entries: {
					__typename?: 'ArticleBodyEntries'
					block: Array<
						| { __typename?: 'AdditionalPersonOptions' }
						| { __typename?: 'AmplitudeExperiments' }
						| { __typename?: 'AppCaseStudy' }
						| { __typename?: 'AppCollection' }
						| { __typename?: 'AppFeature' }
						| { __typename?: 'AppTier' }
						| { __typename?: 'Article' }
						| {
								__typename: 'AssetExternal'
								assetType?: string | null
								url?: string | null
								internalName?: string | null
								altText?: string | null
								sys: { __typename?: 'Sys'; id: string }
						  }
						| { __typename?: 'AssetInternal' }
						| { __typename?: 'Background' }
						| {
								__typename: 'BasicContentCard'
								title?: string | null
								description?: string | null
								sys: { __typename?: 'Sys'; id: string }
								ctaButtonCollection?: {
									__typename?: 'BasicContentCardCtaButtonCollection'
									items: Array<{
										__typename?: 'CtaButton'
										displayText?: string | null
										sys: { __typename?: 'Sys'; id: string }
										link?: { __typename: 'ExternalLink'; url?: string | null } | null
									} | null>
								} | null
						  }
						| { __typename?: 'BlogPost' }
						| { __typename?: 'CaseStudy' }
						| { __typename?: 'CaseStudyProperty' }
						| { __typename?: 'Category' }
						| { __typename?: 'CtaButton' }
						| { __typename?: 'CtaMatPattern' }
						| { __typename?: 'Event' }
						| { __typename?: 'ExternalLink' }
						| { __typename?: 'FaqItem' }
						| { __typename?: 'GatedContent' }
						| { __typename?: 'Glossary' }
						| { __typename?: 'Hero' }
						| { __typename?: 'KeyValue' }
						| { __typename?: 'KeyValueSet' }
						| { __typename?: 'MarketoForm' }
						| { __typename?: 'MarketplaceApp' }
						| { __typename?: 'MarketplaceTheme' }
						| { __typename?: 'Person' }
						| { __typename?: 'Press' }
						| { __typename?: 'Redirect' }
						| { __typename?: 'Resource' }
						| {
								__typename: 'RichTextWrapper'
								type?: string | null
								sys: { __typename?: 'Sys'; id: string }
								content?: {
									__typename?: 'RichTextWrapperContent'
									json: { [key: string]: any }
								} | null
						  }
						| { __typename?: 'Robots' }
						| { __typename?: 'SeoMetadata' }
						| { __typename?: 'Showcase' }
						| { __typename?: 'SpeakerCard' }
						| { __typename?: 'Tag' }
						| { __typename?: 'ThemeProperty' }
						| null
					>
				}
			}
		} | null
		gatedContentSettings?: {
			__typename?: 'GatedContent'
			sys: { __typename?: 'Sys'; id: string }
		} | null
		faqsCollection?: {
			__typename?: 'ArticleFaqsCollection'
			items: Array<{
				__typename?: 'FaqItem'
				question?: string | null
				answer?: { __typename?: 'FaqItemAnswer'; json: { [key: string]: any } } | null
			} | null>
		} | null
	} | null
}

export type RobotsQueryVariables = Exact<{
	where?: InputMaybe<RobotsFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type RobotsQuery = {
	__typename?: 'Query'
	robotsCollection?: {
		__typename?: 'RobotsCollection'
		items: Array<{ __typename?: 'Robots'; robots?: string | null } | null>
	} | null
}

export type AppCollectionSitemapFragment = {
	__typename?: 'AppCollection'
	slug?: string | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		noindex?: boolean | null
		nofollow?: boolean | null
	} | null
}

export type AppCollectionSitemapQueryVariables = Exact<{
	where?: InputMaybe<AppCollectionFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type AppCollectionSitemapQuery = {
	__typename?: 'Query'
	appCollectionCollection?: {
		__typename?: 'AppCollectionCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename?: 'AppCollection'
			slug?: string | null
			sys: { __typename?: 'Sys'; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				noindex?: boolean | null
				nofollow?: boolean | null
			} | null
		} | null>
	} | null
}

export type AppSitemapFragment = {
	__typename?: 'MarketplaceApp'
	slug?: string | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		noindex?: boolean | null
		nofollow?: boolean | null
	} | null
}

export type AppsSitemapQueryVariables = Exact<{
	where?: InputMaybe<MarketplaceAppFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type AppsSitemapQuery = {
	__typename?: 'Query'
	marketplaceAppCollection?: {
		__typename?: 'MarketplaceAppCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename?: 'MarketplaceApp'
			slug?: string | null
			sys: { __typename?: 'Sys'; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				noindex?: boolean | null
				nofollow?: boolean | null
			} | null
		} | null>
	} | null
}

export type ArticleSitemapFragment = {
	__typename?: 'Article'
	slug?: string | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		noindex?: boolean | null
		nofollow?: boolean | null
	} | null
	parentArticle?: { __typename?: 'Article'; slug?: string | null } | null
}

export type ArticlesSitemapQueryVariables = Exact<{
	where?: InputMaybe<ArticleFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type ArticlesSitemapQuery = {
	__typename?: 'Query'
	articleCollection?: {
		__typename?: 'ArticleCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename?: 'Article'
			slug?: string | null
			sys: { __typename?: 'Sys'; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				noindex?: boolean | null
				nofollow?: boolean | null
			} | null
			parentArticle?: { __typename?: 'Article'; slug?: string | null } | null
		} | null>
	} | null
}

export type BlogPostAuthorsFragment = {
	__typename?: 'Person'
	slug?: string | null
	linkedFrom?: {
		__typename?: 'PersonLinkingCollections'
		blogPostCollection?: { __typename?: 'BlogPostCollection'; total: number } | null
	} | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		noindex?: boolean | null
		nofollow?: boolean | null
	} | null
}

export type BlogPostAuthorsSitemapQueryVariables = Exact<{
	where?: InputMaybe<PersonFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type BlogPostAuthorsSitemapQuery = {
	__typename?: 'Query'
	personCollection?: {
		__typename?: 'PersonCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename?: 'Person'
			slug?: string | null
			linkedFrom?: {
				__typename?: 'PersonLinkingCollections'
				blogPostCollection?: { __typename?: 'BlogPostCollection'; total: number } | null
			} | null
			sys: { __typename?: 'Sys'; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				noindex?: boolean | null
				nofollow?: boolean | null
			} | null
		} | null>
	} | null
}

export type BlogPostCategoriesSitemapFragment = {
	__typename?: 'Category'
	slug?: string | null
	linkedFrom?: {
		__typename?: 'CategoryLinkingCollections'
		blogPostCollection?: { __typename?: 'BlogPostCollection'; total: number } | null
	} | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		noindex?: boolean | null
		nofollow?: boolean | null
	} | null
}

export type BlogPostCategoriesSitemapQueryVariables = Exact<{
	where?: InputMaybe<CategoryFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type BlogPostCategoriesSitemapQuery = {
	__typename?: 'Query'
	categoryCollection?: {
		__typename?: 'CategoryCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename?: 'Category'
			slug?: string | null
			linkedFrom?: {
				__typename?: 'CategoryLinkingCollections'
				blogPostCollection?: { __typename?: 'BlogPostCollection'; total: number } | null
			} | null
			sys: { __typename?: 'Sys'; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				noindex?: boolean | null
				nofollow?: boolean | null
			} | null
		} | null>
	} | null
}

export type BlogPostSitemapFragment = {
	__typename?: 'BlogPost'
	slug?: string | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		noindex?: boolean | null
		nofollow?: boolean | null
	} | null
}

export type BlogPostsSitemapQueryVariables = Exact<{
	where?: InputMaybe<BlogPostFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type BlogPostsSitemapQuery = {
	__typename?: 'Query'
	blogPostCollection?: {
		__typename?: 'BlogPostCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename?: 'BlogPost'
			slug?: string | null
			sys: { __typename?: 'Sys'; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				noindex?: boolean | null
				nofollow?: boolean | null
			} | null
		} | null>
	} | null
}

export type CaseStudySitemapFragment = {
	__typename?: 'CaseStudy'
	slug?: string | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		noindex?: boolean | null
		nofollow?: boolean | null
	} | null
}

export type CaseStudySitemapQueryVariables = Exact<{
	where?: InputMaybe<CaseStudyFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type CaseStudySitemapQuery = {
	__typename?: 'Query'
	caseStudyCollection?: {
		__typename?: 'CaseStudyCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename?: 'CaseStudy'
			slug?: string | null
			sys: { __typename?: 'Sys'; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				noindex?: boolean | null
				nofollow?: boolean | null
			} | null
		} | null>
	} | null
}

export type CategoriesSitemapFragment = {
	__typename?: 'Category'
	slug?: string | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		noindex?: boolean | null
		nofollow?: boolean | null
	} | null
	parentCategory?: { __typename?: 'Category'; slug?: string | null } | null
}

export type CategoriesSitemapQueryVariables = Exact<{
	where?: InputMaybe<CategoryFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type CategoriesSitemapQuery = {
	__typename?: 'Query'
	categoryCollection?: {
		__typename?: 'CategoryCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename?: 'Category'
			slug?: string | null
			sys: { __typename?: 'Sys'; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				noindex?: boolean | null
				nofollow?: boolean | null
			} | null
			parentCategory?: { __typename?: 'Category'; slug?: string | null } | null
		} | null>
	} | null
}

export type GlossarySitemapFragment = {
	__typename?: 'Glossary'
	slug?: string | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		noindex?: boolean | null
		nofollow?: boolean | null
	} | null
}

export type GlossarySitemapQueryVariables = Exact<{
	where?: InputMaybe<GlossaryFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type GlossarySitemapQuery = {
	__typename?: 'Query'
	glossaryCollection?: {
		__typename?: 'GlossaryCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename?: 'Glossary'
			slug?: string | null
			sys: { __typename?: 'Sys'; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				noindex?: boolean | null
				nofollow?: boolean | null
			} | null
		} | null>
	} | null
}

export type LeadersSitemapQueryVariables = Exact<{
	where?: InputMaybe<PersonFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type LeadersSitemapQuery = {
	__typename?: 'Query'
	personCollection?: {
		__typename?: 'PersonCollection'
		items: Array<{
			__typename?: 'Person'
			slug?: string | null
			sys: { __typename?: 'Sys'; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				noindex?: boolean | null
				nofollow?: boolean | null
			} | null
		} | null>
	} | null
}

export type PressSitemapFragment = {
	__typename?: 'Press'
	slug?: string | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		noindex?: boolean | null
		nofollow?: boolean | null
	} | null
}

export type PressSitemapQueryVariables = Exact<{
	where?: InputMaybe<PressFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type PressSitemapQuery = {
	__typename?: 'Query'
	pressCollection?: {
		__typename?: 'PressCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename?: 'Press'
			slug?: string | null
			sys: { __typename?: 'Sys'; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				noindex?: boolean | null
				nofollow?: boolean | null
			} | null
		} | null>
	} | null
}

export type ResourcesSitemapFragment = {
	__typename?: 'Resource'
	slug?: string | null
	type?: string | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		noindex?: boolean | null
		nofollow?: boolean | null
	} | null
}

export type ResourcesSitemapQueryVariables = Exact<{
	where?: InputMaybe<ResourceFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type ResourcesSitemapQuery = {
	__typename?: 'Query'
	resourceCollection?: {
		__typename?: 'ResourceCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename?: 'Resource'
			slug?: string | null
			type?: string | null
			sys: { __typename?: 'Sys'; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				noindex?: boolean | null
				nofollow?: boolean | null
			} | null
		} | null>
	} | null
}

export type RootSitemapQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type RootSitemapQuery = {
	__typename?: 'Query'
	pressCollection?: { __typename?: 'PressCollection'; total: number } | null
	articleCollection?: { __typename?: 'ArticleCollection'; total: number } | null
	leaders?: { __typename?: 'PersonCollection'; total: number } | null
	showcaseCollection?: { __typename?: 'ShowcaseCollection'; total: number } | null
	marketplaceAppCollection?: { __typename?: 'MarketplaceAppCollection'; total: number } | null
	marketplaceThemeCollection?: { __typename?: 'MarketplaceThemeCollection'; total: number } | null
	themePropertyCollection?: { __typename?: 'ThemePropertyCollection'; total: number } | null
	appCollectionCollection?: { __typename?: 'AppCollectionCollection'; total: number } | null
}

export type ThemeIndustryFragment = {
	__typename?: 'ThemeProperty'
	slug?: string | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		noindex?: boolean | null
		nofollow?: boolean | null
	} | null
}

export type ThemeIndustriesSitemapQueryVariables = Exact<{
	where?: InputMaybe<ThemePropertyFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type ThemeIndustriesSitemapQuery = {
	__typename?: 'Query'
	themePropertyCollection?: {
		__typename?: 'ThemePropertyCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename?: 'ThemeProperty'
			slug?: string | null
			sys: { __typename?: 'Sys'; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				noindex?: boolean | null
				nofollow?: boolean | null
			} | null
		} | null>
	} | null
}

export type ThemeSitemapFragment = {
	__typename?: 'MarketplaceTheme'
	slug?: string | null
	sys: { __typename?: 'Sys'; publishedAt?: string | null }
	seoMetadata?: {
		__typename?: 'SeoMetadata'
		noindex?: boolean | null
		nofollow?: boolean | null
	} | null
}

export type ThemesSitemapQueryVariables = Exact<{
	where?: InputMaybe<MarketplaceThemeFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type ThemesSitemapQuery = {
	__typename?: 'Query'
	marketplaceThemeCollection?: {
		__typename?: 'MarketplaceThemeCollection'
		total: number
		skip: number
		limit: number
		items: Array<{
			__typename?: 'MarketplaceTheme'
			slug?: string | null
			sys: { __typename?: 'Sys'; publishedAt?: string | null }
			seoMetadata?: {
				__typename?: 'SeoMetadata'
				noindex?: boolean | null
				nofollow?: boolean | null
			} | null
		} | null>
	} | null
}

export type BlogPostPreviewQueryVariables = Exact<{
	where?: InputMaybe<BlogPostFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type BlogPostPreviewQuery = {
	__typename?: 'Query'
	blogPostCollection?: {
		__typename?: 'BlogPostCollection'
		items: Array<{
			__typename?: 'BlogPost'
			us?: string | null
			gb?: string | null
			au?: string | null
		} | null>
	} | null
}

export type ArticleSlugFragment = {
	__typename?: 'Article'
	en_US?: string | null
	en_GB?: string | null
	en_AU?: string | null
	de_AT?: string | null
	nb_NO?: string | null
	da_DK?: string | null
	sv_SE?: string | null
	fr_FR?: string | null
	it_IT?: string | null
	es_ES?: string | null
	es_MX?: string | null
	nl_NL?: string | null
	de_DE?: string | null
}

export type ArticlePreviewQueryVariables = Exact<{
	where?: InputMaybe<ArticleFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
	preview?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ArticlePreviewQuery = {
	__typename?: 'Query'
	articleCollection?: {
		__typename?: 'ArticleCollection'
		items: Array<{
			__typename?: 'Article'
			en_US?: string | null
			en_GB?: string | null
			en_AU?: string | null
			de_AT?: string | null
			nb_NO?: string | null
			da_DK?: string | null
			sv_SE?: string | null
			fr_FR?: string | null
			it_IT?: string | null
			es_ES?: string | null
			es_MX?: string | null
			nl_NL?: string | null
			de_DE?: string | null
			parentArticle?: {
				__typename?: 'Article'
				en_US?: string | null
				en_GB?: string | null
				en_AU?: string | null
				de_AT?: string | null
				nb_NO?: string | null
				da_DK?: string | null
				sv_SE?: string | null
				fr_FR?: string | null
				it_IT?: string | null
				es_ES?: string | null
				es_MX?: string | null
				nl_NL?: string | null
				de_DE?: string | null
			} | null
		} | null>
	} | null
}

export type RedirectsQueryVariables = Exact<{
	where?: InputMaybe<RedirectFilter>
	limit?: InputMaybe<Scalars['Int']['input']>
	skip?: InputMaybe<Scalars['Int']['input']>
	locale?: InputMaybe<Scalars['String']['input']>
}>

export type RedirectsQuery = {
	__typename?: 'Query'
	redirectCollection?: {
		__typename?: 'RedirectCollection'
		total: number
		items: Array<{
			__typename?: 'Redirect'
			sourceUrl?: string | null
			destinationUrl?: string | null
		} | null>
	} | null
}
