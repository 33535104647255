import { lazy } from 'react'

import { Checkbox, Image, Select, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { FolderStructure } from '@/utils/makeswift/constants'

export const props = {
	className: Style({
		properties: [Style.Width, Style.Margin, Style.Padding, Style.Border, Style.BorderRadius]
	}),
	scene: TextInput({
		label: `${FolderStructure.UnderDevelopment}/Scene ID`,
		defaultValue: 'wwMgBvngBspaAAra',
		selectAll: true
	}),
	mobileStaticBehavior: Checkbox({ label: 'Mobile Static Behavior', defaultValue: true }),
	mobileStaticImage: Image({
		label: 'Mobile Image',
		format: Image.Format.WithDimensions
	}),
	aspectRatio: Select({
		label: 'Aspect Ratio',
		labelOrientation: 'horizontal',
		options: [
			{ value: '16/9', label: '16:9' },
			{ value: '4/3', label: '4:3' },
			{ value: '1/1', label: '1:1' },
			{ value: '9/16', label: '9:16' },
			{ value: '3/4', label: '3:4' }
		],
		defaultValue: '16/9'
	}),
	scaling: Select({
		label: 'Scaling',
		labelOrientation: 'horizontal',
		options: [
			{ value: 'scale-100', label: '100%' },
			{ value: 'scale-105', label: '105%' },
			{ value: 'scale-110', label: '110%' },
			{ value: 'scale-125', label: '125%' },
			{ value: 'scale-150', label: '150%' }
		],
		defaultValue: 'scale-100'
	}),
	pointerEvents: Checkbox({ label: 'Enable Pointer Events', defaultValue: true }),
	fadeEffect: Checkbox({ label: 'Fade In', defaultValue: false })
}

runtime.registerComponent(
	lazy(() => import('./Spline3D').then((module) => ({ default: module.Spline3D }))),
	{
		type: 'spline',
		label: `/Spline`,
		props
	}
)
