import { lazy } from 'react'

import { TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '../../../common/ContentfulText/ContentfulText.makeswift'

runtime.registerComponent(
	lazy(() =>
		import('./ArticleChildrenHeading').then((module) => ({
			default: module.ArticleChildrenHeading
		}))
	),
	{
		type: 'article-children-heading',
		label: 'Contentful/Article/Article Children Heading',
		props: {
			...props,
			prefix: TextInput({
				label: 'Prefix',
				defaultValue: 'Featured',
				selectAll: true
			}),
			suffix: TextInput({
				label: 'Suffix',
				defaultValue: 'Resources',
				selectAll: true
			})
		}
	}
)
