import { useEffect } from 'react'

/*
	Adds the Vercel Preview Deployment URL to the window object
	Used for Playwright tests as we don't have access to the process object there
*/

export const useVercelEnvs = () => {
	useEffect(() => {
		// Adds Vercel URL to window object
		const setVercelUrl = () => {
			const vercelUrl = process.env.NEXT_PUBLIC_VERCEL_URL

			if (!vercelUrl) return

			const isLocalhost = vercelUrl.includes('localhost')
			const hasProtocol = vercelUrl.startsWith('http://') || vercelUrl.startsWith('https://')

			if (hasProtocol) {
				window.NEXT_PUBLIC_VERCEL_URL = vercelUrl
			} else if (isLocalhost) {
				window.NEXT_PUBLIC_VERCEL_URL = `http://${vercelUrl}`
			} else {
				window.NEXT_PUBLIC_VERCEL_URL = `https://${vercelUrl}`
			}
		}

		const setPort = () => {
			const port = process.env.NEXT_PUBLIC_PORT
			if (port) {
				window.PORT = Number(port)
			}
		}

		if (!window.NEXT_PUBLIC_VERCEL_URL) {
			setVercelUrl()
		}

		if (!window.PORT) {
			setPort()
		}
	}, [])
}
