import { lazy } from 'react'

import { Number, Select, Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./EventFeed').then((module) => ({ default: module.EventFeed }))),
	{
		type: 'event-feed',
		label: 'Contentful/Event/Event Feed',
		props: {
			className: Style(),
			type: Select({
				label: 'Type',
				options: [
					{ value: 'Regular', label: 'Regular' },
					{ value: 'Featured', label: 'Featured' }
				],
				defaultValue: 'Regular'
			}),
			count: Number({
				label: 'Amount of Events',
				defaultValue: 20,
				step: 1,
				min: 1,
				max: 100
			})
		}
	}
)
