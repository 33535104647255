import { lazy } from 'react'

import {
	Checkbox,
	Image,
	Link,
	List,
	Number,
	Select,
	Shape,
	Style,
	TextArea,
	TextInput
} from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { FolderStructure } from '@/utils/makeswift/constants'

export const props = {
	theme: Select({
		label: 'Theme',
		options: [
			{ label: 'Default', value: 'default' },
			{ label: 'Dark', value: 'dark' },
			{ label: 'blue', value: 'blue' },
			{ label: 'blur', value: 'blur' }
		],
		defaultValue: 'default'
	}),
	stickyNavbar: Checkbox({ label: 'Sticky Navbar', defaultValue: false }),
	showBorders: Checkbox({ label: 'Show Borders', defaultValue: false }),
	navbarBoxShadow: Checkbox({ label: 'Navbar Box Shadow', defaultValue: false }),
	height: Number({ label: 'Navbar Height (px)', defaultValue: 60 }),
	menuGap: Number({ label: 'Menu Gap', defaultValue: 8 }),
	borderRadius: Style({ properties: [Style.BorderRadius] }),
	centerIcon: Image({
		label: 'Center Icon',
		format: Image.Format.URL
	}),
	centerIconLink: Link(),
	leftMenuLabel: TextInput({ label: 'Left Menu Label', defaultValue: 'Menu Label' }),
	leftMenuItems: List({
		label: 'Left Menu Items',
		type: Shape({
			type: {
				label: TextInput({ label: 'Label', defaultValue: 'Item Label' }),
				description: TextArea({ label: 'Description' }),
				icon: Image({
					label: 'Icon',
					format: Image.Format.URL
				}),
				link: Link()
			}
		}),
		getItemLabel(item) {
			return item?.label ?? 'Left Menu Item Label'
		}
	}),
	leftMenuStartIcon: Image({
		label: 'Left Menu Start Icon',
		format: Image.Format.URL
	}),
	leftMenuCaret: Checkbox({ label: 'Show Left Menu Caret', defaultValue: true }),
	leftMenuContainer: Checkbox({ label: 'Show Left Menu Container', defaultValue: true }),
	rightMenuLabel: TextInput({ label: 'Right Menu Label', defaultValue: 'Menu Label' }),
	rightMenuItems: List({
		label: 'Right Menu Items',
		type: Shape({
			type: {
				label: TextInput({ label: 'Label', defaultValue: 'Item Label' }),
				description: TextArea({ label: 'Description' }),
				icon: Image({
					label: 'Icon',
					format: Image.Format.URL
				}),
				link: Link()
			}
		}),
		getItemLabel(item) {
			return item?.label ?? 'Right Menu Item Label'
		}
	}),
	rightMenuStartIcon: Image({
		label: 'Right Menu Start Icon',
		format: Image.Format.URL
	}),
	rightMenuCaret: Checkbox({ label: 'Show Right Menu Caret', defaultValue: true }),
	rightMenuContainer: Checkbox({ label: 'Show Right Menu Container', defaultValue: true })
}

runtime.registerComponent(
	lazy(() => import('./NavBar').then((module) => ({ default: module.NavBar }))),
	{
		type: 'NavBar',
		label: `${FolderStructure.UnderDevelopment}/NavBar`,
		props
	}
)
