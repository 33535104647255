import { lazy } from 'react'

import { Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() =>
		import('./PersonRelatedNews').then((module) => ({ default: module.PersonRelatedNews }))
	),
	{
		type: 'person-related-news',
		label: 'Contentful/Person/Person Related News',
		props: {
			className: Style()
		}
	}
)
