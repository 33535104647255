import { lazy } from 'react'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Breadcrumbs/Breadcrumbs.makeswift'

const { ...rest } = props

runtime.registerComponent(
	lazy(() =>
		import('./CategoryBreadcrumbs').then((module) => ({ default: module.CategoryBreadcrumbs }))
	),
	{
		type: 'category-breadcrumbs',
		label: 'Contentful/Category/Category Breadcrumbs',
		props: rest
	}
)
