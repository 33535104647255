import { lazy } from 'react'

import { Checkbox, Link, List, Select, Shape, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./NavigationLinks').then((module) => ({ default: module.NavigationLinks }))),
	{
		type: 'navigation-links',
		label: 'Navigation Links',
		props: {
			className: Style({ properties: [Style.Width, Style.Margin] }),
			color: Select({
				label: 'Variant',
				options: [
					{ label: 'Light', value: 'light' },
					{ label: 'Dark', value: 'dark' }
				],
				defaultValue: 'light'
			}),
			links: List({
				label: 'Links',
				type: Shape({
					type: {
						text: TextInput({ label: 'Text', defaultValue: 'Link', selectAll: true }),
						link: Link({ label: 'On click' }),
						uppercase: Checkbox({ label: 'Uppercase' }),
						divider: Checkbox({ label: 'Divider' }),
						forceActiveLink: Checkbox({ label: 'Force active link', defaultValue: false })
					}
				}),
				getItemLabel(navLink) {
					return navLink?.text || 'Link'
				}
			})
		}
	}
)
