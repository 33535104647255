import { lazy } from 'react'

import { Link, Slot, Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	link: Link({ label: 'Link' }),
	className: Style({
		properties: [Style.Width, Style.Margin, Style.Padding, Style.Border, Style.BorderRadius]
	}),
	children: Slot()
}

runtime.registerComponent(
	lazy(() => import('./LinkBox').then((module) => ({ default: module.LinkBox }))),
	{ type: 'LinkBox', label: 'LinkBox', props }
)
