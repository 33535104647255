import { lazy } from 'react'

import { TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Breadcrumbs/Breadcrumbs.makeswift'

runtime.registerComponent(
	lazy(() =>
		import('./CaseStudyBreadcrumbs').then((module) => ({ default: module.CaseStudyBreadcrumbs }))
	),
	{
		type: 'case-study-breadcrumbs',
		label: 'Contentful/Case Study/Case Study Breadcrumbs',
		props: {
			...props,
			caseStudyHomepageLabel: TextInput({
				label: 'Case Study Homepage Label',
				defaultValue: 'Case Studies',
				selectAll: true
			})
		}
	}
)
