import { lazy } from 'react'

import { Checkbox, Combobox, List } from '@makeswift/runtime/controls'

import { AppCollectionCarouselPriorityDocument } from '@/generated/contentful'

import { client } from '@/lib/contentful/client'
import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/CardCarousel/CardCarousel.makeswift'

const { ...rest } = props

runtime.registerComponent(
	lazy(() =>
		import('./AppCollectionCarousel').then((module) => ({ default: module.AppCollectionCarousel }))
	),
	{
		type: 'marketplace-app-collection-carousel',
		label: 'Contentful/Marketplace/Apps/App Collection Carousel',
		props: {
			...rest,
			priority: List({
				label: 'Priority',
				type: Combobox({
					label: 'App Collection',
					async getOptions(query: string) {
						const { appCollectionCollection } = await client().request(
							AppCollectionCarouselPriorityDocument,
							{
								query
							}
						)

						return (appCollectionCollection?.items ?? []).filter(Boolean).map((item) => ({
							id: item?.sys.id ?? '',
							label: item?.name ?? '',
							value: item?.sys.id
						}))
					}
				}),
				getItemLabel(item) {
					return item?.label ?? 'No name'
				}
			}),
			onlyShowPriority: Checkbox({ label: 'Only show priority' })
		}
	}
)
