import { lazy } from 'react'

import { Combobox } from '@makeswift/runtime/controls'

import { getFieldOptions } from '@/lib/contentful/utils'
import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/WistiaVideo/WistiaVideo.makeswift'

// eslint-disable-next-line no-unused-vars
const { url, ...rest } = props

runtime.registerComponent(
	lazy(() => import('./CaseStudyVideo').then((module) => ({ default: module.CaseStudyVideo }))),
	{
		type: 'case-study-video',
		label: 'Contentful/Case Study/Case Study Video',
		props: {
			...rest,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'CaseStudy',
						filter: (name) => name === 'AssetExternal',
						query
					})
				}
			})
		}
	}
)
