import { lazy } from 'react'

import { Checkbox, Slot, Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./WebinarSpeakers').then((module) => ({ default: module.WebinarSpeakers }))),
	{
		type: 'resource-webinar-speakers',
		label: 'Contentful/Resource/Webinar Speakers',
		props: {
			containerStyle: Style({ properties: [Style.Width, Style.Margin, Style.Padding] }),
			className: Style(),
			isHeaderActive: Checkbox({ label: 'Include header', defaultValue: false }),
			children: Slot()
		}
	}
)
