import { lazy } from 'react'

import { Combobox, Number, Select } from '@makeswift/runtime/controls'

import { getFieldOptions } from '@/lib/contentful/utils'
import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() =>
		import('./AppCollectionImage').then((module) => ({ default: module.AppCollectionImage }))
	),
	{
		type: 'marketplace-app-collection-image',
		label: 'Contentful/Marketplace/Apps/App Collection Image',
		props: {
			fieldPath: Combobox({
				label: 'Field',
				getOptions: () => [
					{
						id: 'thumbnail',
						label: 'Thumbnail',
						value: 'thumbnail'
					}
				]
			}),
			alt: Combobox({
				label: 'Alt',
				async getOptions(query) {
					return getFieldOptions({
						type: 'AppCollection',
						filter: (name) => name === 'String',
						query
					})
				}
			}),
			width: Number({
				label: 'Width',
				defaultValue: 400,
				suffix: 'px'
			}),
			height: Number({
				label: 'Height',
				defaultValue: 225,
				suffix: 'px'
			}),
			imageStyle: Select({
				label: 'Image style',
				options: [
					{ value: 'object-cover', label: 'Cover image to dimensions' },
					{ value: 'object-contain', label: 'Contain image within dimensions' }
				],
				defaultValue: 'object-cover'
			})
		}
	}
)
