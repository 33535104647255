import { lazy } from 'react'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./AppCategoryList').then((module) => ({ default: module.AppCategoryList }))),
	{
		type: 'marketplace-app-category-list',
		label: 'Contentful/Marketplace/Apps/App Category List'
	}
)
