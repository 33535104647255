import { lazy } from 'react'

import { Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() =>
		import('./CaseStudyRelatedCaseStudies').then((module) => ({
			default: module.CaseStudyRelatedCaseStudies
		}))
	),
	{
		type: 'case-study-related-case-studies',
		label: 'Contentful/Case Study/Case Study Related Case Studies',
		props: {
			className: Style(),
			cardCtaText: TextInput({
				label: 'Case Study CTA Text',
				defaultValue: 'Read Their Story'
			})
		}
	}
)
