import { lazy } from 'react'

import { Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./CaseStudySideBar').then((module) => ({ default: module.CaseStudySideBar }))),
	{
		type: 'case-study-sidebar',
		label: 'Contentful/Case Study/Case Study Side Bar',
		props: {
			className: Style(),
			filterButtonLabel: TextInput({
				label: 'Filter Button Label',
				defaultValue: 'Filters'
			}),
			industryColumnHeader: TextInput({
				label: 'Industry Column Header',
				defaultValue: 'By Industry'
			}),
			topicColumnHeader: TextInput({
				label: 'Topic Column Header',
				defaultValue: 'By Topic'
			}),
			competitorColumnHeader: TextInput({
				label: 'Competitor Column Header',
				defaultValue: 'By Migration Story'
			}),
			applyButtonLabel: TextInput({
				label: 'Apply Button Label',
				defaultValue: 'Apply'
			})
		}
	}
)
