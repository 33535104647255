import { lazy } from 'react'

import { Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

const props = {
	className: Style()
}

runtime.registerComponent(
	lazy(() => import('./AppCollectionList').then((module) => ({ default: module.CollectionList }))),
	{
		type: 'marketplace-app-collection-list',
		label: 'Contentful/Marketplace/Apps/App Collection List',
		props
	}
)
