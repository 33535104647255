import { lazy } from 'react'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Breadcrumbs/Breadcrumbs.makeswift'

runtime.registerComponent(
	lazy(() =>
		import('./PressReleasesBreadcrumbs').then((module) => ({
			default: module.PressReleasesBreadcrumbs
		}))
	),
	{
		type: 'press-releases-breadcrumbs',
		label: 'Contentful/Press/Press Releases Breadcrumbs',
		props
	}
)
