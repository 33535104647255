import { lazy } from 'react'

import { Checkbox, Select, Slot, Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { Bucket } from '../../lib/makeswift/experiments'

runtime.registerComponent(
	lazy(() =>
		import('./ExperimentProvider').then((module) => ({ default: module.ExperimentProvider }))
	),
	{
		type: 'experiment-provider',
		label: 'Experiment Provider',
		props: {
			className: Style({ properties: Style.Default }),
			a: Slot(),
			b: Slot(),
			enableAmplitudeAllocation: Checkbox({
				label: 'Lock Variants/Enable Allocation',
				defaultValue: false
			}),
			previewBucket: Select({
				label: 'Bucket',
				options: [
					{ value: Bucket.A, label: 'A' },
					{ value: Bucket.B, label: 'B' }
				],
				defaultValue: Bucket.A
			})
		}
	}
)
