import type { MiddlewareFunction } from '@segment/analytics-next'

export const appVersionMiddleware: MiddlewareFunction = async ({ payload, next }) => {
	// Check for build ID
	if (!process.env.NEXT_PUBLIC_BUILD_ID) {
		console.warn('NEXT_PUBLIC_BUILD_ID is not set, analytics context will not include app version')
		next(payload)
		return
	}

	// Add app version to analytics context
	payload.obj.context = {
		...payload.obj.context,
		app: {
			name: 'BigCommerce Website',
			version: process.env.NEXT_PUBLIC_APP_VERSION,
			build: process.env.NEXT_PUBLIC_BUILD_ID
		}
	}

	next(payload)
}
