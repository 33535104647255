import { lazy } from 'react'

import { Combobox } from '@makeswift/runtime/controls'

import { getFieldOptions } from '@/lib/contentful/utils'
import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Contentful/common/ContentfulText/ContentfulText.makeswift'

runtime.registerComponent(
	lazy(() =>
		import('./AppCollectionText').then((module) => ({ default: module.AppCollectionText }))
	),
	{
		type: 'app-collection-text',
		label: 'Contentful/Marketplace/Apps/App Collection Text',
		props: {
			...props,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'AppCollection',
						filter: (name) => name === 'String',
						query
					})
				}
			})
		}
	}
)
