import { lazy } from 'react'

import { Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

const props = {
	className: Style(),
	ctaText: TextInput({ label: 'CTA Text', defaultValue: 'Read their story', selectAll: true }),
	title: TextInput({ label: 'Section Title', defaultValue: 'Case Studies', selectAll: true })
}

runtime.registerComponent(
	lazy(() => import('./AppCaseStudies').then((module) => ({ default: module.AppCaseStudies }))),
	{
		type: 'marketplace-app-case-studies',
		label: 'Contentful/Marketplace/Apps/App Case Studies',
		props
	}
)
