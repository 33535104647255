import { lazy } from 'react'

import { Select, Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() =>
		import('./ArticleSecondaryCTAs').then((module) => ({ default: module.ArticleSecondaryCTAs }))
	),
	{
		type: 'article-secondary-ctas',
		label: 'Contentful/Article/Secondary CTAs',
		props: {
			className: Style(),
			buttonColor: Select({
				label: 'Color',
				options: [
					{ value: 'primary', label: 'Blue' },
					{ value: 'secondary', label: 'Black' },
					{ value: 'tertiary', label: 'White' }
				],
				defaultValue: 'secondary'
			})
		}
	}
)
