import { lazy } from 'react'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() =>
		import('./AppDetailsSidebar').then((module) => ({ default: module.AppDetailsSidebar }))
	),
	{
		type: 'marketplace-app-details-sidebar',
		label: 'Contentful/Marketplace/Apps/App Details Sidebar'
	}
)
