import { lazy } from 'react'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '../../BlogPost/BlogPostFeed/BlogPostFeed.makeswift'

const { ...rest } = props

runtime.registerComponent(
	lazy(() =>
		import('./PersonBlogPostFeed').then((module) => ({ default: module.PersonBlogPostFeed }))
	),
	{
		type: 'person-blog-post-feed',
		label: 'Contentful/Person/Person Blog Post Feed',
		props: rest
	}
)
