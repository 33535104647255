import { lazy } from 'react'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/CardCarousel/CardCarousel.makeswift'

runtime.registerComponent(
	lazy(() =>
		import('./AppsRecommendedCarousel').then((module) => ({
			default: module.AppsRecommendedCarousel
		}))
	),
	{
		type: 'marketplace-app-recommended-carousel',
		label: 'Contentful/Marketplace/Apps/Apps Recommended Carousel',
		props
	}
)
