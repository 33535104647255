import { lazy } from 'react'

import { Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

const props = {
	className: Style()
}

runtime.registerComponent(
	lazy(() => import('./ThemesList').then((module) => ({ default: module.ThemesList }))),
	{
		type: 'marketplace-theme-list',
		label: 'Contentful/Marketplace/Themes/Themes List',
		props
	}
)
