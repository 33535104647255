import { GraphQLClient } from 'graphql-request'

type Args = {
	preview?: boolean
}

export const client = ({ preview }: Args = {}) => {
	return new GraphQLClient(
		`https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT}`,
		{
			headers: {
				Authorization: `Bearer ${
					Boolean(preview)
						? process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN
						: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN
				}`
			}
		}
	)
}
