import { lazy } from 'react'

import { Combobox } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulText/ContentfulText.makeswift'

runtime.registerComponent(
	lazy(() => import('./ResourceText').then((module) => ({ default: module.ResourceText }))),
	{
		type: 'resource-text',
		label: 'Contentful/Resource/Resource Text',
		props: {
			...props,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'Resource',
						filter: (name) => name === 'String',
						query
					})
				}
			})
		}
	}
)
