import { lazy } from 'react'

import { Color, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./ToolTip').then((module) => ({ default: module.ToolTip }))),
	{
		type: 'tool-tip',
		label: 'Tool Tip Monogram',
		props: {
			className: Style({ properties: [Style.TextStyle] }),
			color: Color({ defaultValue: '#121118' }),
			text: TextInput({ label: 'ToolTip Text', selectAll: true }),
			toolTipName: TextInput({ selectAll: true, label: 'Accesibility Name (#id)' })
		}
	}
)
