import { lazy } from 'react'

import { Checkbox, Select, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style({
		properties: [Style.Width, Style.Margin, Style.Padding, Style.Border, Style.BorderRadius]
	}),
	url: TextInput({
		label: 'Share URL',
		defaultValue: '',
		selectAll: true
	}),
	aspectRatio: Select({
		label: 'Aspect Ratio',
		options: [
			{ value: '16:10', label: '16:10' },
			{ value: '16:9', label: '16:9' },
			{ value: '4:3', label: '4:3' },
			{ value: '1:1', label: '1:1' }
		],
		defaultValue: '16:9'
	}),
	popover: Checkbox({ label: 'Popover' })
}

runtime.registerComponent(
	lazy(() => import('./WistiaVideo').then((module) => ({ default: module.WistiaVideo }))),
	{
		type: 'wistia-video',
		label: 'Wistia Video',
		props
	}
)
