import { lazy } from 'react'

import { Link, List, Shape, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./Sidebar').then((module) => ({ default: module.Sidebar }))),
	{
		type: 'sidebar',
		label: 'Sidebar',
		props: {
			className: Style(),
			links: List({
				label: 'Sidebar links',
				type: Shape({
					type: {
						text: TextInput({ label: 'Text', defaultValue: 'Press Center', selectAll: true }),
						link: Link({ label: 'On click' })
					}
				}),
				getItemLabel(link) {
					return link?.text || 'Link'
				}
			}),
			contact: Shape({
				type: {
					text: TextInput({
						label: 'Contact Text',
						defaultValue: 'General press inquiries',
						selectAll: true
					}),
					email: TextInput({ label: 'Email', defaultValue: 'pr@bigcommerce.com', selectAll: true })
				}
			}),
			extraSection: List({
				label: 'Sidebar Extra Section',
				type: Shape({
					type: {
						text: TextInput({ label: 'Text', defaultValue: 'Case Studies', selectAll: true }),
						link: Link({ label: 'On click' })
					}
				}),
				getItemLabel(link) {
					return link?.text || 'Link'
				}
			})
		}
	}
)
