import { lazy } from 'react'

import { Combobox } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulLink/ContentfulLink.makeswift'

runtime.registerComponent(
	lazy(() => import('./ArticleLink').then((module) => ({ default: module.ArticleLink }))),
	{
		type: 'article-link',
		label: 'Contentful/Article/Article Link',
		props: {
			...props,
			textFieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'Article',
						filter: (name) => name === 'String',
						query
					})
				}
			}),
			slugFieldPath: Combobox({
				label: 'Slug field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'Article',
						filter: (name) => name === 'String',
						query
					})
				}
			})
		}
	}
)
