import { lazy } from 'react'

import { Checkbox, Combobox, List, Select, Style } from '@makeswift/runtime/controls'

import { PersonFeedPriorityDocument } from '@/generated/contentful'

import { client } from '@/lib/contentful/client'
import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./PersonFeed').then((module) => ({ default: module.PersonFeed }))),
	{
		type: 'person-feed',
		label: 'Contentful/Person/Person Feed',
		props: {
			className: Style(),
			type: Select({
				label: 'Type',
				options: [
					{ label: 'Leader', value: 'Leader' },
					{ label: 'Advisor', value: 'Advisor' }
				],
				defaultValue: 'Leader'
			}),
			size: Select({
				label: 'Type',
				options: [
					{ label: 'Small', value: 'small' },
					{ label: 'Large', value: 'large' }
				],
				defaultValue: 'large'
			}),
			priority: List({
				label: 'Priority',
				type: Combobox({
					label: 'Person',
					async getOptions(query: string) {
						const { personCollection } = await client().request(PersonFeedPriorityDocument, {
							query
						})

						return (personCollection?.items ?? []).filter(Boolean).map((item) => ({
							id: item?.sys.id ?? '',
							label: item?.fullName ?? '',
							value: item?.sys.id
						}))
					}
				}),
				getItemLabel(item) {
					return item?.label ?? 'No name'
				}
			}),
			onlyShowPriority: Checkbox({ label: 'Only show priority' })
		}
	}
)
