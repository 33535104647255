import { addTrailingSlash } from './helpers/addTrailingSlash'
import { getByMapValue } from './helpers/getByMapValue'
import { makeswiftRedirectMap } from './makeswiftRedirectMap'
import { routesMap } from './routesMap'

export function getLocalizedFromEnglish(locale: Locale, route: string) {
	if (route === '/') {
		return route
	}

	if (route.startsWith('http') || route.startsWith('tel')) {
		return route
	}

	if (!routesMap.has(locale)) {
		return addTrailingSlash(route)
	}

	const paths = route.split('/').filter(Boolean)

	let localized = paths.map((path) =>
		getByMapValue({ map: routesMap.get(locale), searchValue: path })
	)
	if (!localized.filter(Boolean).length) {
		localized = paths.map((path) =>
			getByMapValue({ map: makeswiftRedirectMap.get(locale), searchValue: path })
		)
	}

	return addTrailingSlash(paths.map((path, index) => localized[index] || path).join('/'))
}
