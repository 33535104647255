import { lazy } from 'react'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '../../BlogPost/BlogPostFeed/BlogPostFeed.makeswift'

const { ...rest } = props

runtime.registerComponent(
	lazy(() =>
		import('./CategoryBlogPostFeed').then((module) => ({ default: module.CategoryBlogPostFeed }))
	),
	{
		type: 'category-blog-post-feed',
		label: 'Contentful/Category/Category Blog Post Feed',
		props: rest
	}
)
