import { lazy } from 'react'

import { Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./GlossaryFeed').then((module) => ({ default: module.GlossaryFeed }))),
	{
		type: 'glossary-feed',
		label: 'Contentful/Glossary/Glossary Feed',
		props: {
			className: Style(),
			ctaText: TextInput({
				label: 'Glossary CTA Text',
				defaultValue: 'Read story'
			})
		}
	}
)
