import { lazy } from 'react'

import { Number } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { props as CardCarouselProps } from '@/components/CardCarousel/CardCarousel.makeswift'

import { props } from '../AppRow/AppRow.makeswift'

const { ...rest } = CardCarouselProps

runtime.registerComponent(
	lazy(() => import('./AppCarousel').then((module) => ({ default: module.AppsCarousel }))),
	{
		type: 'marketplace-app-carousel',
		label: 'Contentful/Marketplace/Apps/App Carousel',
		props: {
			...props,
			limit: Number({
				label: 'Max Number of Apps Shown',
				defaultValue: 16,
				min: 1,
				max: 48,
				step: 1,
				suffix: 'apps'
			}),
			...rest
		}
	}
)
