import { lazy } from 'react'

import { Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./ThemesSearch').then((module) => ({ default: module.ThemesSearch }))),
	{
		type: 'themes-search-results',
		label: 'Contentful/Marketplace/Themes/Themes Search',
		props: {
			className: Style(),
			searchInputPlaceholder: TextInput({
				label: 'Search Input Placeholder',
				defaultValue: 'Search for...'
			})
		}
	}
)
