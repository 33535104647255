import { lazy } from 'react'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Breadcrumbs/Breadcrumbs.makeswift'

const { ...rest } = props

runtime.registerComponent(
	lazy(() =>
		import('./AppTierBreadcrumbs').then((module) => ({ default: module.AppTierBreadcrumbs }))
	),
	{
		type: 'marketplace-app-tier-breadcrumbs',
		label: 'Contentful/Marketplace/Apps/App Tier Breadcrumbs',
		props: rest
	}
)
