import { lazy } from 'react'

import { Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./CPMCalculator').then((module) => ({ default: module.CPMCalculator }))),
	{
		type: 'cpmcalculator',
		label: 'CPM Calculator',
		props: {
			className: Style(),
			dataTestId: TextInput({
				label: 'dataTestId',
				defaultValue: 'CPMCalculator'
			})
		}
	}
)
