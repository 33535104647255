import { lazy } from 'react'

import { Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() =>
		import('./CaseStudyCategoriesList').then((module) => ({
			default: module.CaseStudyCategoriesList
		}))
	),
	{
		type: 'case-study-categories-list',
		label: 'Contentful/Case Study/Case Study Categories List',
		props: {
			className: Style()
		}
	}
)
