import { lazy } from 'react'

import {
	Checkbox,
	Group,
	List,
	Number,
	Select,
	Slot,
	Style,
	TextInput
} from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./TabsCarousel').then((module) => ({ default: module.TabsCarousel }))),
	{
		type: 'tabs-carousel',
		label: 'Tabs Carousel',
		props: {
			className: Style(),
			tabs: List({
				label: 'Tabs',
				type: Group({
					props: {
						label: TextInput({ label: 'Tab Title' }),
						tab: Slot(),
						content: Slot()
					}
				}),
				getItemLabel(tab) {
					return tab?.label || 'Tab Title'
				}
			}),
			autoPlay: Checkbox({ label: 'Auto Play (Requires page reload)', defaultValue: false }),
			progressBarDuration: Number({
				label: 'Progress Duration (ms)',
				defaultValue: 7000,
				min: 100
			}),
			theme: Select({
				label: 'Theme',
				options: [
					{ label: 'Light', value: 'light' },
					{ label: 'Dark', value: 'dark' }
				],
				defaultValue: 'light'
			})
		}
	}
)
