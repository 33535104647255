import { lazy } from 'react'

import { Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./AppFeed').then((module) => ({ default: module.AppFeed }))),
	{
		type: 'showcase-marketplace-app-category-list',
		label: 'Contentful/Marketplace/Apps/App Feed',
		props: {
			className: Style()
		}
	}
)
