type RouteMap = Map<Locale, Map<string, string>>

export const makeswiftRedirectMap: RouteMap = new Map([
	[
		'es-ES',
		new Map([
			['comienza-tu-prueba', 'start-your-trial'],
			['comparar', 'compare'],
			['demo-del-producto', 'product-tour'],
			['precios-de-enterprise', 'enterprise-pricing'],
			['plataforma', 'platform'],
			['producto', 'product'],
			['servicios', 'services'],
			['socios', 'partners'],
			['soluciones', 'solutions'],
			['solicitar-una-demostracion', 'request-a-demo']
		])
	],
	[
		'es-MX',
		new Map([
			['comienza-tu-prueba', 'start-your-trial'],
			['comparar', 'compare'],
			['demo-del-producto', 'product-tour'],
			['precios-de-enterprise', 'enterprise-pricing'],
			['plataforma', 'platform'],
			['socios', 'partners'],
			['producto', 'product'],
			['servicios', 'services'],
			['soluciones', 'solutions'],
			['solicitar-una-demostracion', 'request-a-demo']
		])
	],
	[
		'fr-FR',
		new Map([
			['commencez-votre-periode-essai', 'start-your-trial'],
			['comparer', 'compare'],
			['partenaires', 'partners'],
			['plateforme', 'platform'],
			['presentation-produit', 'product-tour'],
			['produit', 'product'],
			['tarification-enterprise', 'enterprise-pricing'],
			['demander-une-demo', 'request-a-demo']
		])
	],
	[
		'it-IT',
		new Map([
			['confronta', 'compare'],
			['inizia-il-periodo-di-prova', 'start-your-trial'],
			['prezzi-enterprise', 'enterprise-pricing'],
			['partner', 'partners'],
			['piattaforma', 'platform'],
			['prodotto', 'product'],
			['servizi', 'services'],
			['tour-dei-prodotti', 'product-tour'],
			['richiedi-una-demo', 'request-a-demo']
		])
	],
	[
		'nb-NO',
		new Map([
			['enterprise-priser', 'enterprise-pricing'],
			['partnere', 'partners'],
			['plattform', 'platform'],
			['produkt', 'product'],
			['produktomvisning', 'product-tour'],
			['start-proveperioden', 'start-your-trial'],
			['tjenester', 'services'],
			['be-en-demonstrasjon', 'request-a-demo']
		])
	],
	[
		'da-DK',
		new Map([
			['enterprise-prissaetning', 'enterprise-pricing'],
			['partenaires', 'partners'],
			['plattform', 'platform'],
			['produkt', 'product'],
			['produktfremvisning', 'product-tour'],
			['start-din-proveversion', 'start-your-trial'],
			['tjenester', 'services'],
			['bed-om-en-demo', 'request-a-demo']
		])
	],
	[
		'sv-SE',
		new Map([
			['foretagspriser', 'enterprise-pricing'],
			['partner', 'partners'],
			['plattform', 'platform'],
			['produkt', 'product'],
			['produktrundtur', 'product-tour'],
			['starta-din-provperiod', 'start-your-trial'],
			['tjanster', 'services'],
			['begar-en-demo', 'request-a-demo']
		])
	],
	[
		'nl-NL',
		new Map([
			['verkennen', 'platform'],
			['productrondleiding', 'product-tour'],
			['proefperiode-starten', 'start-your-trial'],
			['demo-aanvragen', 'request-a-demo']
		])
	],
	[
		'de-AT',
		new Map([
			['dienstleistungen', 'services'],
			['enterprise-preise', 'enterprise-pricing'],
			['fallstudie', 'case-study'],
			['partner', 'partners'],
			['plattform', 'platform'],
			['produkt', 'product'],
			['produkttour', 'product-tour'],
			['starten-sie-ihre-testversion', 'start-your-trial'],
			['vergleichen', 'compare'],
			['demo-anfordern', 'request-a-demo']
		])
	],
	[
		'de-DE',
		new Map([
			['dienstleistungen', 'services'],
			['enterprise-preise', 'enterprise-pricing'],
			['partner', 'partners'],
			['plataforma', 'platform'],
			['produkt', 'product'],
			['produkttour', 'product-tour'],
			['starten-sie-ihre-testversion', 'start-your-trial'],
			['vergleichen', 'compare'],
			['demo-anfordern', 'request-a-demo']
		])
	]
])
