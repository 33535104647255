import { lazy } from 'react'

import { Checkbox, Image, Select, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style({ properties: [Style.Width, Style.Border, Style.BorderRadius] }),
	srcMp4: TextInput({ label: 'MP4 URL' }),
	srcWebm: TextInput({ label: 'WebM URL' }),
	thumbnail: Image({ format: Image.Format.URL }),
	autoplay: Checkbox({ label: 'Auto play', defaultValue: true }),
	loop: Checkbox({ label: 'Loop', defaultValue: false }),
	muted: Checkbox({ label: 'Muted', defaultValue: true }),
	islazyLoading: Checkbox({ label: 'Lazy Loading', defaultValue: true }),
	aspectRatio: Select({
		label: 'Aspect Ratio',
		labelOrientation: 'horizontal',
		options: [
			{ value: '16/9', label: '16:9' },
			{ value: '4/3', label: '4:3' },
			{ value: '1/1', label: '1:1' },
			{ value: '9/16', label: '9:16' },
			{ value: '3/4', label: '3:4' }
		],
		defaultValue: '16/9'
	}),
	controlsBehavior: Select({
		label: 'Controls Behavior',
		labelOrientation: 'horizontal',
		options: [
			{
				value: 'static',
				label: 'Static'
			},
			{ value: 'onHover', label: 'Show on hover' },
			{ value: 'hidden', label: 'Hidden' }
		],
		defaultValue: 'static'
	})
}

runtime.registerComponent(
	lazy(() => import('./CustomVideo').then((module) => ({ default: module.CustomVideo }))),
	{ type: 'CustomVideo', label: `Custom Video`, props }
)
