import { lazy } from 'react'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '../../../../common/ContentfulBox/ContentfulBox.makeswift'

runtime.registerComponent(
	lazy(() =>
		import('./ThemesIndustriesList').then((module) => ({ default: module.ThemesIndustriesList }))
	),
	{
		type: 'marketplace-themes-industries-list',
		label: 'Contentful/Marketplace/Themes/Themes Industries List',
		props: {
			...props
		}
	}
)
