import { lazy } from 'react'

import {
	Checkbox,
	Color,
	Image,
	Link,
	List,
	Number,
	Select,
	Shape,
	Style,
	TextArea,
	TextInput
} from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	cards: List({
		label: 'Cards',
		type: Shape({
			type: {
				imageSrc: Image({ label: 'Image', format: Image.Format.WithDimensions }),
				imageAlt: TextInput({
					label: 'Alt text',
					defaultValue: 'Image',
					selectAll: true
				}),
				heading: TextInput({
					label: 'Heading',
					defaultValue: 'Case study',
					selectAll: true
				}),
				title: TextInput({
					label: 'Title',
					defaultValue: 'This is a title',
					selectAll: true
				}),
				text: TextArea({
					label: 'Text',
					defaultValue:
						'Lorem ipsum dolor sit amet, vix id suas harum tacimates, enim doming prompta vim antial.'
				}),
				linkText: TextInput({
					label: 'Link text',
					defaultValue: 'Read more',
					selectAll: true
				}),
				linkURL: Link({ label: 'On click' })
			}
		}),
		getItemLabel(item) {
			return item?.title ?? 'This is a title'
		}
	}),
	bgColor: Color({ label: 'Card color', defaultValue: '#121118' }),
	variant: Select({
		label: 'Text color',
		options: [
			{ value: 'light', label: 'White' },
			{ value: 'dark', label: 'Black' }
		],
		defaultValue: 'light'
	}),
	slidesGap: Number({
		label: 'Slides gap',
		defaultValue: 16,
		suffix: 'px'
	}),
	theme: Select({
		label: 'Theme',
		options: [
			{ label: 'Default', value: 'default' },
			{ label: 'Dark', value: 'dark' }
		],
		defaultValue: 'default'
	}),
	buttonsAlignment: Select({
		label: 'Alignment',
		options: [
			{ label: 'Left', value: 'left' },
			{ label: 'Center', value: 'center' }
		],
		defaultValue: 'left'
	}),
	itemsShown: Number({ label: 'Items shown (desktop)', defaultValue: 4 }),
	showControlsDesktop: Checkbox({ label: 'Show Controls UI (Desktop)', defaultValue: true }),
	showControlsMobile: Checkbox({ label: 'Show Controls UI (Mobile)', defaultValue: true }),
	carouselBehavior: Select({
		label: 'Carousel Behavior',
		options: [
			{ label: 'Manual', value: 'manual' },
			{ label: 'Timed', value: 'timed' },
			{ label: 'Continuous Scroll', value: 'continuous' }
		],
		defaultValue: 'manual'
	}),
	scrollBehavior: Select({
		label: 'Scroll Behavior',
		options: [
			{ label: 'Right to left', value: 'right' },
			{ label: 'Left to right', value: 'left' }
		],
		defaultValue: 'right'
	})
}

runtime.registerComponent(
	lazy(() => import('./CardCarousel').then((module) => ({ default: module.CardCarousel }))),
	{
		type: 'card-carousel',
		label: 'Card Carousel',
		props
	}
)
