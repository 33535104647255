import { lazy } from 'react'

import { Combobox } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulImage/ContentfulImage.makeswift'

runtime.registerComponent(
	lazy(() => import('./ResourceImage').then((module) => ({ default: module.ResourceImage }))),
	{
		type: 'resource-image',
		label: 'Contentful/Resource/Resource Image',
		props: {
			...props,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'Resource',
						filter: (name) => name === 'Asset',
						query
					})
				}
			})
		}
	}
)
