import { lazy } from 'react'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Breadcrumbs/Breadcrumbs.makeswift'

const { ...rest } = props

runtime.registerComponent(
	lazy(() =>
		import('./BlogPostBreadcrumbs').then((module) => ({ default: module.BlogPostBreadcrumbs }))
	),
	{
		type: 'blog-post-breadcrumbs',
		label: 'Contentful/Blog/Blog Breadcrumbs',
		props: rest
	}
)
