import { lazy } from 'react'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Breadcrumbs/Breadcrumbs.makeswift'

const { ...rest } = props

runtime.registerComponent(
	lazy(() => import('./ThemeBreadcrumbs').then((module) => ({ default: module.ThemeBreadcrumbs }))),
	{
		type: 'marketplace-theme-breadcrumbs',
		label: 'Contentful/Marketplace/Themes/Theme Breadcrumbs',
		props: rest
	}
)
