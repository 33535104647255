import { lazy } from 'react'

import { Link, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./Tag').then((module) => ({ default: module.Tag }))),
	{
		type: 'tag',
		label: 'Tag',
		props: {
			className: Style({ properties: [Style.Margin] }),
			link: Link(),
			children: TextInput({ defaultValue: 'Tag', selectAll: true })
		}
	}
)
