import { lazy } from 'react'

import { Combobox } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulRichText/ContentfulRichText.makeswift'

runtime.registerComponent(
	lazy(() => import('./GlossaryRichText').then((module) => ({ default: module.GlossaryRichText }))),
	{
		type: 'glossary-rich-text',
		label: 'Contentful/Glossary/Glossary Rich Text',
		props: {
			...props,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'Glossary',
						filter: (name) => name === 'RichText',
						query
					})
				}
			})
		}
	}
)
