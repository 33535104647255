import { lazy } from 'react'

import { Combobox } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulShareLinks/ContentfulShareLinks.makeswift'

runtime.registerComponent(
	lazy(() =>
		import('./BlogPostShareLinks').then((module) => ({ default: module.BlogPostShareLinks }))
	),
	{
		type: 'blog-post-share-links',
		label: 'Contentful/Blog/Blog Share Links',
		props: {
			...props,
			titleFieldPath: Combobox({
				label: 'Title Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'BlogPost',
						filter: (name) => name === 'String',
						query
					})
				}
			}),
			slugFieldPath: Combobox({
				label: 'Slug Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'BlogPost',
						filter: (name) => name === 'String',
						query
					})
				}
			})
		}
	}
)
