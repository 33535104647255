import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

import { useRouter } from 'next/router'

import { withOneTrust } from '@segment/analytics-consent-wrapper-onetrust'
import { AnalyticsBrowser, ID, UserTraits } from '@segment/analytics-next'
import * as Sentry from '@sentry/nextjs'
import Cookies from 'js-cookie'

import { Experiment } from '@/lib/amplitude/create-amplitude-paths'
// @ts-ignore
import experiments from '@/lib/amplitude/paths.json'

import {
	CookiesEnum,
	SourceEnum,
	TreatmentEnum,
	TypeEnum
} from '@/utils/amplitude/experiment-constants'
import { appVersionMiddleware } from '@/utils/analytics/appVersionMiddleware'
import { trackExperienceViewed } from '@/utils/analytics/trackExperienceViewed'

import { useConsentContext } from '@/components/Scripts/OneTrust/ConsentProvider'

interface AnalyticsContextValue {
	analytics: AnalyticsBrowser | any
}

const AnalyticsContext = createContext<AnalyticsContextValue | undefined>(undefined)

export function useAnalyticsContext() {
	const analyticsContext = useContext(AnalyticsContext)

	if (analyticsContext === undefined) {
		throw new Error('useAnalyticsContext must be used within a AnalyticsProvider')
	}

	return analyticsContext
}

const setSegmentAnonIdCookie = async (analytics: AnalyticsBrowser) => {
	const date = new Date()
	const time = date.getTime()
	const expTime = time + 3600 * 1000 * 87660

	date.setTime(expTime)

	const anonymousId: ID = (await analytics.user()).anonymousId()

	if (anonymousId) {
		Cookies.set('segmentAnonId', String(anonymousId), {
			expires: date,
			path: '/'
		})
	}
	return
}

// Parsing cookie set from middleware, contains experiment data (variant, payload variant, anonymousId )
const getParsedExperimentData = () => {
	const experimentData = Cookies.get(CookiesEnum.AmplitudeExperimentation)
	if (!experimentData) return []

	try {
		return JSON.parse(experimentData)
	} catch (error) {
		console.error('Error parsing CookiesEnum.AmplitudeExperimentation cookie:', error)
		return []
	}
}

// Overriding Segment anonymousId value to use middleware UUID based on parsed cookie data
const initializeWithAjsAnonymousId = async (analytics: AnalyticsBrowser) => {
	const parsedData = getParsedExperimentData()

	if (parsedData && parsedData[0]?.newUserId) {
		;(await analytics.user()).anonymousId(parsedData[0].newUserId)
		return parsedData[0].newUserId
	}
}

// Fetch data to track Experience Viewed event in Segment debugger according to the data required by CRO
const fetchExperimentData = async (analytics: AnalyticsBrowser, pathname: string) => {
	// Get cookie parsed data (pathname) to track events that match
	const parsedData = getParsedExperimentData()
	// For each experiment in bc_amplitude_experimentation cookie verify if the path match current path
	if (Array.isArray(parsedData) && parsedData.length > 0) {
		for (const experiment of parsedData) {
			if (experiment.pathname === pathname || experiment.experimentProvider) {
				// Get dynamic JSON data to populate experiment details properties if flag key matches
				const matchingExperiment = experiments.find(
					(item: any) =>
						item.key === experiment.flagKey && experiment.variant !== TreatmentEnum.EXCLUDED
				) as Experiment | undefined

				if (matchingExperiment) {
					const experimentDetails = {
						experience_id: matchingExperiment.id,
						experience_name: matchingExperiment.name,
						variant_name: experiment.variant,
						type: TypeEnum.ExperimentOrPersonalization,
						evaluation: matchingExperiment.evaluationMode,
						source: SourceEnum.MarketingWebsites,
						state: matchingExperiment.state
					}

					const url = window.location.href
					const path = window.location.pathname
					const referrer = document.referrer
					const hostname = window.location.hostname
					const anonymousId = await initializeWithAjsAnonymousId(analytics)

					trackExperienceViewed(
						analytics,
						experimentDetails,
						url,
						path,
						referrer,
						hostname,
						anonymousId
					)
				}
			}
		}
	}
}

export const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
	const router = useRouter()
	const [analytics, setAnalytics] = useState<AnalyticsBrowser | any>()

	let { strictlyNecessary } = useConsentContext()

	useEffect(() => {
		const analyticsBrowser = new AnalyticsBrowser()

		// Add app version to analytics context
		analyticsBrowser.addSourceMiddleware(appVersionMiddleware)

		if (!window.analytics) {
			// Make buffered analytics available in the browser for Google Tag Manager
			// Docs: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#load
			if (!window.analytics) window.analytics = analyticsBrowser
		}

		// Initialize the OneTrust consent wrapper for analytics-next once library is loaded
		// Docs: https://www.npmjs.com/package/@segment/analytics-consent-wrapper-onetrust
		if (!analytics && strictlyNecessary) {
			withOneTrust(analyticsBrowser, {
				disableConsentChangedEvent: true
			}).load(
				{
					writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY!,
					cdnURL: `https://${process.env.NEXT_PUBLIC_SEGMENT_CDN_DOMAIN}`
				},
				{
					integrations: {
						'Segment.io': {
							apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY!,
							apiHost: `${process.env.NEXT_PUBLIC_SEGMENT_API_DOMAIN}/v1`
						}
					}
				}
			)

			analyticsBrowser.ready(async () => {
				// Make analytics instance available in the browser for Google Tag Manager
				window.analytics = analyticsBrowser.instance

				// Set cookie Segment anonymous Id to populate in Marketo forms
				// TODO: Confirm if this cookie is still needed and remove if not
				if (Cookies.get('segmentAnonId') === undefined) setSegmentAnonIdCookie(analyticsBrowser)
				const ajsAnonymousId =
					(await initializeWithAjsAnonymousId(analyticsBrowser)) || Cookies.get('ajs_anonymous_id')
				if (ajsAnonymousId) {
					analyticsBrowser.setAnonymousId(ajsAnonymousId)
					Sentry.setUser({ id: ajsAnonymousId })
				}
				setAnalytics(analyticsBrowser)
			})
		}
	}, [analytics, setAnalytics, strictlyNecessary])

	useEffect(() => {
		const handleRouteChange = () => {
			// Segment Page event call
			analytics.page()
			// Segment Identify: Pass identifiers found in query parameters to Segment Identity Resolution
			const identifers = [
				'gclid',
				'msclkid',
				'gbraid',
				'msclkid',
				'wbraid',
				'_gl',
				'mkt_tok',
				'li_fat_id',
				'fbclid',
				'6si_xuid',
				'_ga'
			]
			const urlParams = new URLSearchParams(window.location.search)
			const traits: UserTraits = {}
			urlParams.forEach((value, key) => {
				if (identifers.indexOf(key) >= 0) traits[key] = value
			})
			if (Object.keys(traits).length > 0) analytics.identify(traits)

			//Experiment
			const path = window.location.pathname
			fetchExperimentData(analytics, path)
		}

		if (!router.isReady || !analytics) return

		router.events.on('routeChangeComplete', handleRouteChange)
		handleRouteChange()

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [analytics, router])

	/* Tracks any clicks on the website and sends a marketing event */
	// useEffect(() => {
	// 	const segmentTrackClick = (e: any) => {
	// 		e.stopPropagation()

	// 		const element = e.target as HTMLElement

	// 		return window.analytics.track('Marketing Click', {
	// 			page_url:
	// 				element.baseURI ||
	// 				`${window?.location?.origin}/${
	// 					router.asPath.startsWith('/') ? router.asPath.slice(1) : router.asPath
	// 				}`,

	// 			page_path: router.asPath,
	// 			page_title: document.title,
	// 			page_name: router.asPath,
	// 			page_referrer: document.referrer,
	// 			page_search: window.location.search,
	// 			store_id: null,
	// 			subsection: null,
	// 			label: (element.innerText || element.textContent || element.outerText)
	// 				?.toLowerCase()
	// 				.replace(/\s+/g, '_'),
	// 			text: element.innerText || element.textContent || element.outerText,
	// 			element_class: element.className,
	// 			link_url: 'href' in element && typeof element.href === 'string' ? element.href : null
	// 		})
	// 	}
	// 	document.body.addEventListener('click', segmentTrackClick)

	// 	return () => {
	// 		document.body.removeEventListener('click', segmentTrackClick)
	// 	}
	// }, [])

	return <AnalyticsContext.Provider value={{ analytics }}>{children}</AnalyticsContext.Provider>
}
