import { lazy } from 'react'

import { Checkbox, Slot } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() =>
		import('./NavigationGridContainer').then((module) => ({
			default: module.NavigationGridContainer
		}))
	),
	{
		type: 'navigation-grid-container',
		label: 'Primary Navigation Grid Container',
		props: {
			mainContent: Slot(),
			secondaryContent: Slot(),
			hasFeaturedSection: Checkbox({ label: 'Enable Featured Section', defaultValue: false }),
			hasFeaturedBackgroundColor: Checkbox({ label: 'Featured Background', defaultValue: false })
		}
	}
)
