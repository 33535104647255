import { lazy } from 'react'

import { Combobox, Select } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulRichText/ContentfulRichText.makeswift'

runtime.registerComponent(
	lazy(() => import('./CategoryRichText').then((module) => ({ default: module.CategoryRichText }))),
	{
		type: 'category-rich-text',
		label: 'Contentful/Category/Category Rich Text',
		props: {
			...props,
			fontSize: Select({
				label: 'Font size',
				options: [
					{ value: '', label: 'Default' },
					{ value: 'prose-p:text-lg', label: 'Large' }
				],
				defaultValue: ''
			}),
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'Category',
						filter: (name) => name === 'RichText',
						query
					})
				}
			})
		}
	}
)
