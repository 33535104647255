import { lazy } from 'react'

import { Color, Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./CaseStudyStats').then((module) => ({ default: module.CaseStudyStats }))),
	{
		type: 'case-study-stats',
		label: 'Contentful/Case Study/Case Study Stats',
		props: {
			className: Style(),
			textColor: Color({
				label: 'Text Color',
				defaultValue: '#121118'
			})
		}
	}
)
