import { lazy } from 'react'

import { Select, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/CardCarousel/CardCarousel.makeswift'

const { ...rest } = props

runtime.registerComponent(
	lazy(() =>
		import('./ArticleFeedCarousel').then((module) => ({ default: module.ArticleFeedCarousel }))
	),
	{
		type: 'article-carousel',
		label: 'Contentful/Article/Article Carousel',
		props: {
			...rest,
			type: Select({
				label: 'Type',
				options: [
					{ label: 'Root', value: 'root' },
					{ label: 'Popular', value: 'popular' },
					{ label: 'Latest', value: 'latest' }
				],
				defaultValue: 'root'
			}),
			ctaText: TextInput({
				label: 'CTA Text',
				defaultValue: 'Read Article',
				selectAll: true
			})
		}
	}
)
