import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'

import { Drift, useChat } from 'react-live-chat-loader'

import { DRIFT_PRIORITY_PATHS } from '@/lib/drift/priority-paths'
import { DriftLoadStates } from '@/lib/drift/types'

import { sendDriftUserAttributes } from '@/utils/drift/events'

import { useConsentContext } from '@/components/Scripts/OneTrust/ConsentProvider'
import { useAnalyticsContext } from '@/components/Scripts/Segment/AnalyticsProvider'

export function DriftWidget({ liveChatLoaded }: { liveChatLoaded: boolean }) {
	const router = useRouter()
	const { analytics } = useAnalyticsContext()
	let { functional } = useConsentContext()
	const [loadingState, setLoadingState] = useState<DriftLoadStates>(DriftLoadStates.PENDING_CONSENT)
	const [state, loadChat] = useChat()
	const [isDriftIconClicked, setIsDriftIconClicked] = useState(false)

	useEffect(() => {
		if (loadingState === DriftLoadStates.PENDING_CONSENT && functional) {
			setLoadingState(DriftLoadStates.CONSENT_PROVIDED)
		} else if (loadingState === DriftLoadStates.CONSENT_PROVIDED && liveChatLoaded) {
			setLoadingState(DriftLoadStates.LOADED)
		} else if (!functional) {
			setLoadingState(DriftLoadStates.PENDING_CONSENT)
		}
	}, [functional, liveChatLoaded, loadChat, loadingState, state])

	// Add listeners for Drift events after script is loaded and send to DataLayer
	const fireDriftEvent = function (eventName: string, data?: any) {
		// Fire Segment Event
		try {
			const props = data ?? {}

			// Add page properties
			props.hostname = window.location.hostname
			props.path = window.location.pathname
			props.referrer = document.referrer
			props.search = window.location.search
			props.title = document.title
			props.url = window.location.href

			window.analytics.track(eventName, props)
		} catch (error) {
			throw new Error(
				`Error sending Drift event "${eventName}" to Segment: ${JSON.stringify(error)}`
			)
		}

		// Fire DataLayer Event
		try {
			window.dataLayer.push({
				event: 'driftInteraction',
				eventCategory: 'drift',
				eventAction: eventName
			})
		} catch (error) {
			throw new Error(
				`Error sending Drift event "${eventName}" to DataLayer: ${JSON.stringify(error)}`
			)
		}
	}
	useEffect(() => {
		const initDriftEvents = function (api: any) {
			api.on('startConversation', function (data: any) {
				fireDriftEvent('drift_start_convo', data)
			})
			api.on('campaign:open', function (data: any) {
				fireDriftEvent('drift_campaign_open', data)
			})
			api.on('campaign:submit', function (data: any) {
				fireDriftEvent('drift_campaign_submit', data)
			})
			api.on('sidebarOpen', function () {
				fireDriftEvent('drift_sidebar_open')
			})
			api.on('sidebarClose', function () {
				fireDriftEvent('drift_sidebar_close')
			})
			api.on('conversation:playbookFired', function (data: any) {
				fireDriftEvent('drift_convo_playbook_fired', data)
			})
			api.on('phoneCapture', function (data: any) {
				fireDriftEvent('drift_convo_phone_capture', data)
			})
			api.on('conversation:firstInteraction', function (data: any) {
				fireDriftEvent('drift_convo_first_interaction', data)
			})
			api.on('welcomeMessage:close', function () {
				fireDriftEvent('drift_welcome_close', {})
			})
			api.on('conversation:buttonClicked', function (data: any) {
				fireDriftEvent('drift_convo_btn_click', data)
			})
			api.on('emailCapture', function (e: any) {
				fireDriftEvent('drift_email_capture', e.data)
			})
			api.on('message:sent', function (data: any) {
				fireDriftEvent('drift_message_sent', data)
			})
			api.on('message', function (data: any) {
				fireDriftEvent('drift_message_received', data)
			})
			api.on('scheduling:meetingBooked', function (data: any) {
				fireDriftEvent('drift_scheduling_meeting_booked', data)
			})
		}

		if (loadingState === DriftLoadStates.LOADED && window.drift) {
			setLoadingState(DriftLoadStates.INITIALIZED)
			window.drift.on('ready', function (api: any) {
				try {
					initDriftEvents(api)
				} catch (error: any) {
					throw new Error(error.message)
				}

				try {
					sendDriftUserAttributes(window.analytics)
				} catch (error: any) {
					throw new Error(error.message)
				}
			})
		}
	}, [analytics, loadingState])

	// Fire Drift page event on route change
	useEffect(() => {
		const handleRouteChange = () => {
			window?.drift.page()
		}

		if (!router.isReady || !window.drift) return

		router.events.on('routeChangeComplete', handleRouteChange)
		handleRouteChange()

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [router])

	useEffect(() => {
		if (liveChatLoaded && isDriftIconClicked) {
			window.drift.openChat()
			fireDriftEvent('drift_sidebar_open')
		}
	}, [isDriftIconClicked, liveChatLoaded])

	const handleDriftClick = () => {
		setIsDriftIconClicked(true)
	}
	useEffect(() => {
		let timeoutId: null | ReturnType<typeof setTimeout> = null
		if (
			DRIFT_PRIORITY_PATHS.includes(router.asPath) &&
			loadingState !== DriftLoadStates.PENDING_CONSENT &&
			!liveChatLoaded
		) {
			loadChat({ open: false })
		} else {
			//Wait for critical JS to be load
			timeoutId = setTimeout(() => {
				loadChat({ open: false })
			}, 10000)
		}
		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId)
			}
		}
	}, [router])

	return (
		loadingState !== DriftLoadStates.PENDING_CONSENT &&
		!liveChatLoaded && (
			<div onClick={handleDriftClick} className="drift-wrapper">
				<Drift color="#0d52ff" icon="B" />
			</div>
		)
	)
}
