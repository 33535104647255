import { lazy } from 'react'

import { Checkbox, Select, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./PressFeed').then((module) => ({ default: module.PressFeed }))),
	{
		type: 'press-feed',
		label: 'Contentful/Press/Press Feed',
		props: {
			className: Style(),
			hideImage: Checkbox({ label: 'Hide image' }),
			type: Select({
				label: 'Type',
				options: [
					{ value: 'News', label: 'News' },
					{ value: 'Press Release', label: 'Release' }
				],
				defaultValue: 'Press Release'
			}),
			ctaText: TextInput({
				label: 'CTA Text',
				defaultValue: 'Continue Reading',
				selectAll: true
			})
		}
	}
)
