import { lazy } from 'react'

import { ElementID, Margin, Width } from '@makeswift/prop-controllers'
import { MakeswiftComponentType } from '@makeswift/runtime/components'
import { RichText, Select } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./Text').then((module) => ({ default: module.Text }))),
	{
		type: MakeswiftComponentType.Text,
		label: 'Text',
		props: {
			id: ElementID(),
			text: RichText(),
			width: Width({
				format: Width.Format.ClassName,
				preset: [{ deviceId: 'desktop', value: { value: 700, unit: 'px' } }],
				defaultValue: { value: 100, unit: '%' }
			}),
			margin: Margin({
				format: Margin.Format.ClassName,
				preset: [
					{
						deviceId: 'desktop',
						value: {
							marginTop: null,
							marginRight: 'auto',
							marginBottom: { value: 20, unit: 'px' },
							marginLeft: 'auto'
						}
					}
				]
			}),
			linkColor: Select({
				label: 'Link color',
				options: [
					{ value: 'blue', label: 'Blue' },
					{ value: 'green', label: 'Green' }
				],
				defaultValue: 'blue'
			}),
			listMarkerColor: Select({
				label: 'List marker color',
				options: [
					{ value: 'blue', label: 'Blue' },
					{ value: 'black', label: 'Black' },
					{ value: 'white', label: 'White' }
				],
				defaultValue: 'blue'
			})
		}
	}
)
