import { lazy } from 'react'

import { Combobox } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulDate/ContentfulDate.makeswift'

runtime.registerComponent(
	lazy(() => import('./PressDate').then((module) => ({ default: module.PressDate }))),
	{
		type: 'press-date',
		label: 'Contentful/Press/Press Date',
		props: {
			...props,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'BlogPost',
						filter: (name) => name === 'DateTime',
						query
					})
				}
			})
		}
	}
)
