import { lazy } from 'react'

import { Combobox } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/MarketoForm/MarketoForm.makeswift'

import { getFieldOptions } from '../../../../../lib/contentful/utils'

runtime.registerComponent(
	lazy(() => import('./ResourceForm').then((module) => ({ default: module.ResourceForm }))),
	{
		type: 'resource-form',
		label: 'Contentful/Resource/Resource Form',
		props: {
			...props,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'Resource',
						filter: (name) => name === 'String',
						query
					})
				}
			})
		}
	}
)
