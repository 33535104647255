import { lazy } from 'react'

import { Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./GlossarySidebar').then((module) => ({ default: module.GlossarySidebar }))),
	{
		type: 'glossary-sidebar',
		label: 'Contentful/Glossary/Glossary Sidebar',
		props: {
			className: Style()
		}
	}
)
