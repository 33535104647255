import { lazy } from 'react'

import { Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./BlogPostSearch').then((module) => ({ default: module.BlogPostSearch }))),
	{
		type: 'search-results',
		label: 'Contentful/Blog/Blog Search',
		props: {
			className: Style(),
			placeholder: TextInput({
				label: 'Placeholder',
				defaultValue: 'Search for...',
				selectAll: true
			})
		}
	}
)
