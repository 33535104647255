import { lazy } from 'react'

import { Select, Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	type: Select({
		label: 'Type',
		options: [
			{ label: 'Featured', value: 'featured' },
			{ label: 'Recommended', value: 'recommended' },
			{ label: 'Top Free', value: 'top-free' },
			{ label: 'Top Paid', value: 'top-paid' },
			{ label: 'Newest', value: 'newest' }
		],
		defaultValue: 'featured'
	})
}

runtime.registerComponent(
	lazy(() => import('./AppRow').then((module) => ({ default: module.AppRow }))),
	{
		type: 'marketplace-app-row',
		label: 'Contentful/Marketplace/Apps/App Row',
		props
	}
)
