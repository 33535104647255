import { lazy } from 'react'

import { Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./WistiaChannel').then((module) => ({ default: module.WistiaChannel }))),
	{
		type: 'wistia-channel',
		label: 'Wistia Channel',
		props: {
			className: Style(),
			url: TextInput({
				label: 'Share URL',
				defaultValue: 'https://fast.wistia.com/embed/channel/dmlbcuw9qt',
				selectAll: true
			})
		}
	}
)
