import { lazy } from 'react'

import { Select, Slot, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./TableOfContents').then((module) => ({ default: module.TableOfContents }))),
	{
		type: 'table-of-contents',
		label: 'Table of Contents',
		props: {
			className: Style(),
			title: TextInput({
				label: 'Table of Contents Title',
				defaultValue: 'Table of Contents',
				selectAll: true
			}),
			target: Select({
				label: 'Target Element',
				options: [
					{ value: 'h2', label: 'Heading 2' },
					{ value: 'h3', label: 'Heading 3' },
					{ value: 'h4', label: 'Heading 4' },
					{ value: 'h5', label: 'Heading 5' },
					{ value: 'h6', label: 'Heading 6' }
				],
				defaultValue: 'h2'
			}),
			targetId: TextInput({
				label: 'Target ID for selector',
				defaultValue: 'article-content',
				selectAll: true
			}),
			slot: Slot()
		}
	}
)
