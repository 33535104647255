import { lazy } from 'react'

import { Checkbox, List, Number, Select, Slot, Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./Carousel').then((module) => ({ default: module.Carousel }))),
	{
		type: 'carousel',
		label: 'Carousel',
		props: {
			className: Style(),
			slidesGap: Number({
				label: 'Slides gap',
				defaultValue: 16,
				suffix: 'px'
			}),
			theme: Select({
				label: 'Theme',
				options: [
					{ label: 'Default', value: 'default' },
					{ label: 'Dark', value: 'dark' }
				],
				defaultValue: 'default'
			}),
			buttonsAlignment: Select({
				label: 'Alignment',
				options: [
					{ label: 'Left', value: 'left' },
					{ label: 'Center', value: 'center' }
				],
				defaultValue: 'left'
			}),
			itemsShownDesktop: Number({
				label: 'Items shown (Desktop)',
				defaultValue: 3
			}),
			showControlsDesktop: Checkbox({ label: 'Show Controls UI (Desktop)', defaultValue: true }),
			showControlsMobile: Checkbox({ label: 'Show Controls UI (Mobile)', defaultValue: true }),
			carouselBehavior: Select({
				label: 'Carousel Behavior',
				options: [
					{ label: 'Manual', value: 'manual' },
					{ label: 'Timed', value: 'timed' },
					{ label: 'Continuous Scroll', value: 'continuous' }
				],
				defaultValue: 'manual'
			}),
			scrollBehavior: Select({
				label: 'Scroll Behavior',
				options: [
					{ label: 'Right to left', value: 'right' },
					{ label: 'Left to right', value: 'left' }
				],
				defaultValue: 'right'
			}),
			children: List({
				label: 'Slides',
				type: Slot(),
				getItemLabel(slide) {
					return `Slot`
				}
			})
		}
	}
)
