import { lazy } from 'react'

import { Checkbox, Image, Number, RichText, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	gradientBorder: Checkbox({ label: 'Show Border Badge', defaultValue: false }),
	gradientBadgeText: TextInput({
		label: 'Badge Text',
		defaultValue: 'NEW'
	}),
	imageSrc: Image({
		label: 'Logo',
		format: Image.Format.WithDimensions
	}),
	imageAlt: TextInput({ label: 'Image Alt' }),
	imageWidth: Number({ step: 1, label: 'Image Width', defaultValue: 100, suffix: 'px' }),
	text: RichText()
}

runtime.registerComponent(
	lazy(() =>
		import('./GradientBorderCard').then((module) => ({ default: module.GradientBorderCard }))
	),
	{ type: 'GradientBorderCard', label: 'Gradient Border Card', props }
)
