import { lazy } from 'react'

import { Checkbox, Group, List, Select, Slot, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./PillTabs').then((module) => ({ default: module.PillTabs }))),
	{
		type: 'PillTabs',
		label: `Pill Tabs`,
		props: {
			className: Style(),
			pills: List({
				label: 'Pill',
				type: Group({
					props: {
						title: TextInput({
							label: 'Pill title',
							defaultValue: 'Pill title',
							selectAll: true
						}),
						content: Slot()
					}
				}),
				getItemLabel(pill) {
					return pill?.title || 'Pill title'
				}
			}),
			containerAlignment: Select({
				label: 'Container alignment',
				options: [
					{ value: 'left', label: 'Left' },
					{ value: 'center', label: 'Center' },
					{ value: 'right', label: 'Right' }
				],
				defaultValue: 'left'
			}),
			styles: Group({
				label: 'Styles',
				preferredLayout: Group.Layout.Popover,
				props: {
					theme: Select({
						label: 'Theme',
						options: [
							{
								value: 'filled',
								label: 'Filled'
							},
							{
								value: 'outlined',
								label: 'Outlined'
							}
						],
						defaultValue: 'filled'
					}),
					color: Select({
						label: 'Color',
						options: [
							{
								value: 'blue',
								label: 'Blue'
							},
							{
								value: 'monochrome',
								label: 'Monochrome'
							}
						],
						defaultValue: 'blue'
					}),
					inverse: Checkbox({ label: 'Inverse', defaultValue: false }),
					size: Select({
						label: 'Size',
						options: [
							{
								label: 'Small',
								value: 'small'
							},
							{
								label: 'Medium',
								value: 'medium'
							},
							{
								label: 'Large',
								value: 'large'
							}
						],
						defaultValue: 'medium'
					})
				}
			}),
			overflowFade: Group({
				label: 'Overflow Fade',
				preferredLayout: Group.Layout.Popover,
				props: {
					showFade: Checkbox({ label: 'Overflow Fade', defaultValue: false }),
					fadeTheme: Select({
						label: 'Fade Color',
						options: [
							{
								label: 'BC Black',
								value: '#121118'
							},
							{
								label: 'True Black',
								value: '#000'
							},
							{
								label: 'Gray 200',
								value: '#EDEFF3'
							},
							{
								label: 'Gray 100',
								value: '#F6F7F9'
							},
							{
								label: 'True White',
								value: '#FFF'
							}
						],
						defaultValue: '#121118'
					})
				}
			})
		}
	}
)
