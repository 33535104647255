import { lazy } from 'react'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Accordions/Accordions.makeswift'

const { ...rest } = props

runtime.registerComponent(
	lazy(() =>
		import('./ArticleFAQAccordions').then((module) => ({ default: module.ArticleFAQAccordions }))
	),
	{
		type: 'article-faq-accordions',
		label: 'Contentful/Article/Article FAQ Accordions',
		props: rest
	}
)
