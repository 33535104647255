import { lazy } from 'react'

import { TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/CardCarousel/CardCarousel.makeswift'

const { ...rest } = props

runtime.registerComponent(
	lazy(() =>
		import('./ArticleChildrenCarousel').then((module) => ({
			default: module.ArticleChildrenCarousel
		}))
	),
	{
		type: 'article-children-carousel',
		label: 'Contentful/Article/Article Children Carousel',
		props: {
			...rest,
			ctaText: TextInput({
				label: 'CTA Text',
				defaultValue: 'Read Article',
				selectAll: true
			})
		}
	}
)
