import { lazy } from 'react'

import { Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style()
}

runtime.registerComponent(
	lazy(() => import('./HeaderLanding').then((module) => ({ default: module.HeaderLanding }))),
	{
		type: 'Header Landing',
		label: 'Header Landing',
		props
	}
)
