import { lazy } from 'react'

import {
	Checkbox,
	Color,
	Image,
	Link,
	List,
	Shape,
	Style,
	TextInput
} from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() =>
		import('./NavigationContentColumn').then((module) => ({
			default: module.NavigationContentColumn
		}))
	),
	{
		type: 'mega-menu-column',
		label: 'Primary Navigation Content Column',
		props: {
			className: Style({ properties: [Style.Width, Style.Margin, Style.Padding] }),
			subtitle: TextInput({ label: 'Heading', defaultValue: 'Heading' }),
			divider: Checkbox({
				label: 'Divider',
				defaultValue: true
			}),
			subnavLinks: List({
				label: 'Sub Items',
				type: Shape({
					type: {
						icon: Image({
							label: 'Icon',
							format: Image.Format.WithDimensions
						}),
						text: TextInput({ label: 'Sub Item', defaultValue: 'Link', selectAll: true }),
						LinkHoverColor: Color({
							label: 'Link hover color',
							defaultValue: '#0d52ff'
						}),
						subtext: TextInput({
							label: 'Sub Item Description',
							defaultValue: ''
						}),
						link: Link({ label: 'On click' }),
						badgeText: TextInput({
							label: 'Badge'
						})
					}
				}),
				getItemLabel(subnavLink) {
					return subnavLink?.text || 'Link'
				}
			})
		}
	}
)
