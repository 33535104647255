import { lazy } from 'react'

import { Checkbox, Image, Select, Slot, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	image: Image({ format: Image.Format.WithDimensions }),
	alt: TextInput({ label: 'Alt Text', defaultValue: 'Image Description', selectAll: true }),
	content: Slot(),
	contentPosition: Select({
		label: 'Content Position',
		options: [
			{ value: 'top', label: 'Top' },
			{ value: 'center', label: 'Center' },
			{ value: 'bottom', label: 'Bottom' }
		],
		defaultValue: 'top'
	}),
	flip: Checkbox({ label: 'Flip' })
}

runtime.registerComponent(
	lazy(() => import('./TwelveColumnCard').then((module) => ({ default: module.TwelveColumnCard }))),
	{ type: 'TwelveColumnCard', label: 'Twelve Column Card', props }
)
