import { lazy } from 'react'

import { Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

const props = {
	className: Style()
}

runtime.registerComponent(
	lazy(() =>
		import('./ThemeImagePreview').then((module) => ({ default: module.ThemeImagePreview }))
	),
	{
		type: 'marketplace-theme-image-preview',
		label: 'Contentful/Marketplace/Themes/Theme Image Preview',
		props
	}
)
