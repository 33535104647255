import { lazy } from 'react'

import { Combobox } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulButton/ContentfulButton.makeswift'

runtime.registerComponent(
	lazy(() => import('./CaseStudyButton').then((module) => ({ default: module.CaseStudyButton }))),
	{
		type: 'case-study-button',
		label: 'Contentful/Case Study/Case Study Button',
		props: {
			...props,
			textFieldPath: Combobox({
				label: 'Text Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'CaseStudy',
						filter: (name) => name === 'String',
						query
					})
				}
			}),
			urlFieldPath: Combobox({
				label: 'URL Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'CaseStudy',
						filter: (name) => name === 'ExternalLink',
						query
					})
				}
			})
		}
	}
)
