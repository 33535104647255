import { lazy } from 'react'

import { Number, Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./AppTierAppGrid').then((module) => ({ default: module.AppTierAppGrid }))),
	{
		type: 'marketplace-app-tier-app-grid',
		label: 'Contentful/Marketplace/Apps/App Tier App Grid',
		props: {
			className: Style(),
			appCount: Number({
				label: 'App Count',
				defaultValue: 12
			})
		}
	}
)
