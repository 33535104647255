import { lazy } from 'react'

import { Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./ResourceHero').then((module) => ({ default: module.ResourceHero }))),
	{
		type: 'resource-hero',
		label: 'Contentful/Resource/Resource Hero',
		props: {
			className: Style()
		}
	}
)
