import { lazy } from 'react'

import { Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./ResourceStats').then((module) => ({ default: module.ResourceStats }))),
	{
		type: 'resource-stats',
		label: 'Contentful/Resource/Resource Stats',
		props: {
			className: Style(),
			disclaimer: TextInput({
				label: 'Disclaimer Text',
				defaultValue: '*Based on a composite organization'
			})
		}
	}
)
