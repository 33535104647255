import { lazy } from 'react'

import { Link, List, Shape, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	extraSection: List({
		label: 'Sidebar Extra Section',
		type: Shape({
			type: {
				text: TextInput({ label: 'Text', defaultValue: 'Case Studies', selectAll: true }),
				link: Link({ label: 'On click' })
			}
		}),
		getItemLabel(link) {
			return link?.text || 'Link'
		}
	})
}

runtime.registerComponent(
	lazy(() => import('./ShowcaseSidebar').then((module) => ({ default: module.ShowcaseSidebar }))),
	{
		type: 'showcase-sidebar',
		label: 'Contentful/Showcase/Showcase Sidebar',
		props
	}
)
