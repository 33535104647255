import { lazy } from 'react'

import { Combobox } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulImage/ContentfulImage.makeswift'

runtime.registerComponent(
	lazy(() => import('./BlogPostImage').then((module) => ({ default: module.BlogPostImage }))),
	{
		type: 'blog-post-image',
		label: 'Contentful/Blog/Blog Image',
		props: {
			...props,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'BlogPost',
						filter: (name) => name === 'Asset',
						query
					})
				}
			})
		}
	}
)
