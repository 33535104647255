import { lazy } from 'react'

import { Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() =>
		import('./ArticleChildFeedGrid').then((module) => ({ default: module.ArticleChildFeedGrid }))
	),
	{
		type: 'article-child-feed-grid',
		label: 'Contentful/Article/Article Child Feed Grid',
		props: {
			className: Style()
		}
	}
)
