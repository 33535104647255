import { lazy } from 'react'

import { Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() =>
		import('./BlogPostCategories').then((module) => ({ default: module.BlogPostCategories }))
	),
	{
		type: 'blog-post-categories',
		label: 'Contentful/Blog/Blog Categories',
		props: {
			className: Style()
		}
	}
)
