import { lazy } from 'react'

import { Link, List, Shape, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./LinkSelect').then((module) => ({ default: module.LinkSelect }))),
	{
		type: 'link-select',
		label: 'Link Select',
		props: {
			className: Style(),
			buttonText: TextInput({
				label: 'Button text',
				defaultValue: 'Select an option',
				selectAll: true
			}),
			links: List({
				label: 'Links',
				type: Shape({
					type: {
						text: TextInput({ label: 'Text', defaultValue: 'Link', selectAll: true }),
						link: Link({ label: 'On click' })
					}
				}),
				getItemLabel(link) {
					return link?.text || 'Link'
				}
			})
		}
	}
)
