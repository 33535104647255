import { lazy } from 'react'

import { List, Number, Select, Slot, Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./Flexbox').then((module) => ({ default: module.Flexbox }))),
	{
		type: 'Flexbox',
		label: 'Flexbox',
		props: {
			className: Style({
				properties: [Style.Width]
			}),
			boxes: List({
				label: 'Boxes',
				type: Slot(),
				getItemLabel(slide) {
					return `Slot`
				}
			}),
			flexVertical: Select({
				label: 'Flex Vertical',
				options: [
					{ label: 'None', value: 'flex-row' },
					{ label: 'All', value: 'flex-col' },
					{ label: 'Start at XLarge', value: 'flex-col 2xl:flex-row' },
					{ label: 'Start at Large', value: 'flex-col xl:flex-row' },
					{ label: 'Start at Medium', value: 'flex-col md:flex-row' },
					{ label: 'Only Small', value: 'flex-col sm:flex-row' }
				],
				defaultValue: 'flex-row'
			}),

			horizontalAlign: Select({
				label: 'Horizontal Align',
				options: [
					{ label: 'Start', value: 'start' },
					{ label: 'Center', value: 'center' },
					{ label: 'End', value: 'end' }
				],
				defaultValue: 'start'
			}),
			verticalAlign: Select({
				label: 'Vertical Align',
				options: [
					{ label: 'Start', value: 'start' },
					{ label: 'Center', value: 'center' },
					{ label: 'End', value: 'end' }
				],
				defaultValue: 'start'
			}),

			horizontalGap: Number({
				label: 'Horizontal Gap',
				defaultValue: 4,
				step: 2,
				suffix: 'px'
			}),
			verticalGap: Number({
				label: 'Vertical Gap',
				defaultValue: 4,
				step: 2,
				suffix: 'px'
			})
		}
	}
)
