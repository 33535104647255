import { lazy } from 'react'

import { Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./CaseStudyFeed').then((module) => ({ default: module.CaseStudyFeed }))),
	{
		type: 'case-study-feed',
		label: 'Contentful/Case Study/Case Study Feed',
		props: {
			className: Style(),
			cardCtaText: TextInput({
				label: 'Case Study CTA Text',
				defaultValue: 'Read story'
			}),
			loadMoreCtaText: TextInput({
				label: 'Load More CTA Text',
				defaultValue: 'See more'
			})
		}
	}
)
