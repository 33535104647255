import { lazy } from 'react'

import { Checkbox, Image, Select, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style({
		properties: [Style.Width, Style.Margin, Style.Padding, Style.Border, Style.BorderRadius]
	}),
	url: TextInput({
		label: 'Share URL',
		defaultValue: 'https://makeswift.wistia.com/medias/460gpav9kl',
		selectAll: true
	}),
	image: Image({ label: 'Custom thumbnail', format: Image.Format.WithDimensions }),
	imageAlt: TextInput({
		label: 'Alt text',
		defaultValue: 'Video thumbnail',
		selectAll: true
	}),
	aspectRatio: Select({
		label: 'Aspect Ratio',
		options: [
			{ value: '16:10', label: '16:10' },
			{ value: '16:9', label: '16:9' },
			{ value: '4:3', label: '4:3' },
			{ value: '1:1', label: '1:1' }
		],
		defaultValue: '16:9'
	}),
	playIcon: Checkbox({ label: 'Add Play Icon', defaultValue: false }),
	popover: Checkbox({ label: 'Popover', defaultValue: true }),
	priority: Checkbox({ label: 'Priority', defaultValue: false })
}

runtime.registerComponent(
	lazy(() => import('./WistiaImage').then((module) => ({ default: module.WistiaImage }))),
	{
		type: 'wistia-image',
		label: 'Wistia Image',
		props
	}
)
