import { lazy } from 'react'

import { Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

const props = {
	className: Style()
}

runtime.registerComponent(
	lazy(() => import('./AppReviews').then((module) => ({ default: module.AppReviews }))),
	{
		type: 'marketplace-app-reviews',
		label: 'Contentful/Marketplace/Apps/App Reviews',
		props
	}
)
