import { lazy } from 'react'

import { Checkbox, Combobox, Slot, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { SKU_OPTIONS } from '@/utils/data/trials/skus'

runtime.registerComponent(
	lazy(() => import('./TrialForm').then((module) => ({ default: module.TrialForm }))),
	{
		type: 'trial-form',
		label: 'Contentful/Trial/Trial Form',
		props: {
			driftPlaybookId: TextInput({
				label: 'Drift Playbook ID',
				defaultValue: ''
			}),
			sku: Combobox({
				label: 'SKU/BMP Package',
				getOptions: () => SKU_OPTIONS
			}),
			switchToEnterpriseSku: Checkbox({
				label: 'Switch to Enterprise SKU',
				defaultValue: true
			}),
			form1CTAText: TextInput({
				label: 'Form 1 CTA Text'
			}),
			form2CTAText: TextInput({
				label: 'Form 2 CTA Text'
			}),
			legalText: Slot(),
			loadingText: TextInput({
				label: 'Loading Text'
			}),
			loadingSecondaryText: TextInput({
				label: 'Loading Secondary Text'
			})
		}
	}
)
