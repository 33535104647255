import { lazy } from 'react'

import { Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

const props = {
	className: Style(),
	supportTitle: TextInput({
		label: 'Support Title',
		defaultValue: 'Support & Documentation'
	}),
	supportText: TextInput({
		label: 'Support Text',
		defaultValue: 'Documentation for this theme can be found here:'
	}),
	mainSiderbarTitle: TextInput({
		label: 'Main Sidebar Title',
		defaultValue: 'Featured:'
	}),
	secondarySiderbarTitle: TextInput({
		label: 'Secondary Sidebar Title',
		defaultValue: 'Optimized for:'
	})
}

runtime.registerComponent(
	lazy(() =>
		import('./ThemeDetailsSidebar').then((module) => ({ default: module.ThemeDetailsSidebar }))
	),
	{
		type: 'marketplace-theme-details-sidebar',
		label: 'Contentful/Marketplace/Themes/Theme Detailed Sidebar',
		props
	}
)
