import { lazy } from 'react'

import { Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() =>
		import('./ResourceRecommendedFeed').then((module) => ({
			default: module.ResourceRecommendedFeed
		}))
	),
	{
		type: 'resource-recommended-feed',
		label: 'Contentful/Resource/Resource Recommended Feed',
		props: {
			className: Style(),
			ctaText: TextInput({
				label: 'CTA Text',
				defaultValue: 'Learn More',
				selectAll: true
			}),
			title: TextInput({
				label: 'Section Title',
				defaultValue: 'Recommended for you',
				selectAll: true
			})
		}
	}
)
