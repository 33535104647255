import { lazy } from 'react'

import { Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./AppMediaCarousel').then((module) => ({ default: module.AppMediaCarousel }))),
	{
		type: 'marketplace-app-media-carousel',
		label: 'Contentful/Marketplace/Apps/App Media Carousel',
		props: {
			className: Style()
		}
	}
)
