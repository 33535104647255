import { lazy } from 'react'

import { Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./AppsSearch').then((module) => ({ default: module.AppsSearch }))),
	{
		type: 'apps-search-results',
		label: 'Contentful/Marketplace/Apps/Apps Search',
		props: {
			className: Style(),
			searchInputPlaceholder: TextInput({
				label: 'Search Input Placeholder',
				defaultValue: 'Search for...'
			})
		}
	}
)
