import { lazy } from 'react'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '../AppRow/AppRow.makeswift'

runtime.registerComponent(
	lazy(() => import('./AppRowCards').then((module) => ({ default: module.AppRowCards }))),
	{
		type: 'marketplace-app-row-cards',
		label: 'Contentful/Marketplace/Apps/App Row Cards',
		props
	}
)
