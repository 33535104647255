import { lazy } from 'react'

import { Image, Slot, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	image: Image({ format: Image.Format.WithDimensions }),
	alt: TextInput({ label: 'Alt Text', defaultValue: 'Image Description', selectAll: true }),
	content: Slot()
}

runtime.registerComponent(
	lazy(() =>
		import('./CardImageFullWidth').then((module) => ({ default: module.CardImageFullWidth }))
	),
	{ type: 'CardImageFullWidth', label: 'Card Image (Full Width)', props }
)
