import { lazy } from 'react'

import { Style } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

const props = {
	className: Style()
}

runtime.registerComponent(
	lazy(() => import('./ThemeStyles').then((module) => ({ default: module.ThemeStyles }))),
	{
		type: 'marketplace-theme-about',
		label: 'Contentful/Marketplace/Themes/Theme Related Styles',
		props
	}
)
