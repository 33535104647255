import { lazy } from 'react'

import { Number, Select, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import {
	FEATURED_LOGO_CAROUSEL_DEFAULT_COUNT,
	FEATURED_LOGO_CAROUSEL_DEFAULT_TAG
} from './FeaturedLogoCarousel'

runtime.registerComponent(
	lazy(() =>
		import('./FeaturedLogoCarousel').then((module) => ({ default: module.FeaturedLogoCarousel }))
	),
	{
		type: 'featured-logo-carousel',
		label: 'Contentful/Featured Logo Carousel',
		props: {
			className: Style(),
			tag: TextInput({
				label: 'Contentful tag',
				defaultValue: FEATURED_LOGO_CAROUSEL_DEFAULT_TAG,
				selectAll: true
			}),
			count: Number({
				label: 'Shown contents',
				defaultValue: FEATURED_LOGO_CAROUSEL_DEFAULT_COUNT,
				step: 1,
				min: 1,
				max: 10
			}),
			variant: Select({
				label: 'Appearance',
				options: [
					{ value: 'light', label: 'Light mode' },
					{ value: 'dark', label: 'Dark mode' }
				],
				defaultValue: 'light'
			})
		}
	}
)
