import { lazy } from 'react'

import { Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

const props = {
	className: Style(),
	title: TextInput({ label: 'Section Title', defaultValue: 'App Features', selectAll: true })
}

runtime.registerComponent(
	lazy(() => import('./AppFeatures').then((module) => ({ default: module.AppFeatures }))),
	{
		type: 'marketplace-app-features',
		label: 'Contentful/Marketplace/Apps/App Features',
		props
	}
)
