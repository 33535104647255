import { lazy } from 'react'

import {
	Checkbox,
	Image,
	Link,
	Number,
	Select,
	Style,
	TextArea,
	TextInput
} from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	heightStretch: Checkbox({ label: 'Height Stretch', defaultValue: false }),
	className: Style({ properties: Style.All }),
	imageSrc: Image({
		label: 'Icon',
		format: Image.Format.WithDimensions
	}),
	imageAlt: TextInput({ label: 'Image Alt', selectAll: true }),
	imageWidth: Number({ step: 4, label: 'Image Width', defaultValue: 48, suffix: 'px' }),
	variant: Select({
		label: 'Appearance',
		options: [
			{ value: 'light', label: 'Light mode' },
			{ value: 'dark', label: 'Dark mode' }
		],
		defaultValue: 'dark'
	}),
	title: TextInput({ label: 'Title', defaultValue: 'Title', selectAll: true }),
	text: TextArea({
		label: 'Text',
		defaultValue:
			'Lorem ipsum dolor sit amet, vix id suas harum tacimates, enim doming prompta vim antial.'
	}),
	buttonText: TextInput({ label: 'Button Text', defaultValue: '', selectAll: true }),
	link: Link({ label: 'Link' }),
	borderRadius: Checkbox({ label: 'Border Radius', defaultValue: false }),
	gradientColorStop1: TextInput({
		label: 'Gradient Color Stop 1',
		defaultValue: '#000000',
		selectAll: true
	}),
	gradientColorStop2: TextInput({
		label: 'Gradient Color Stop 2',
		defaultValue: '#FFFFFF',
		selectAll: true
	}),
	gradientDirection: Select({
		label: 'Gradient Direction',
		options: [
			{ label: 'Top to Bottom', value: 'to bottom' },
			{ label: 'Bottom to Top', value: 'to top' },
			{ label: 'Left to Right', value: 'to right' },
			{ label: 'Right to Left', value: 'to left' },
			{ label: 'Top Left to Bottom Right', value: 'to bottom right' },
			{ label: 'Top Right to Bottom Left', value: 'to bottom left' },
			{ label: 'Bottom Left to Top Right', value: 'to top right' },
			{ label: 'Bottom Right to Top Left', value: 'to top left' }
		],
		defaultValue: 'to bottom'
	}),
	hoverEffect: Checkbox({ label: 'Apply hover effect?', defaultValue: false })
}

runtime.registerComponent(
	lazy(() => import('./GradientCard').then((module) => ({ default: module.GradientCard }))),
	{ type: 'GradientCard', label: 'Gradient Card', props }
)
