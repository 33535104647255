import { useEffect } from 'react'

export function useRemoveExtraRobots() {
	useEffect(() => {
		// Function to check and remove the extra robots meta tag
		const removeExtraRobotsMeta = () => {
			const robotsMetaTags = document.querySelectorAll('meta[name="robots"]')
			for (const robotMetaTag of robotsMetaTags) {
				if (robotMetaTag.getAttribute('content') === 'noindex') robotMetaTag.remove() // Remove extra meta tag
			}
		}

		// Initial check in case the meta tags are already present
		removeExtraRobotsMeta()

		// Set up the MutationObserver to watch for changes in the head element
		const observer = new MutationObserver(() => {
			removeExtraRobotsMeta()
		})

		// Observe changes to the head element
		observer.observe(document.head, { childList: true, subtree: true })

		// Clean up the observer when the component unmounts
		return () => {
			observer.disconnect()
		}
	}, [])
}
