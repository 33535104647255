import { lazy } from 'react'

import { Combobox, List } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../../lib/contentful/utils'
import { props } from '../../../../common/ContentfulBox/ContentfulBox.makeswift'

runtime.registerComponent(
	lazy(() => import('./ThemeCard').then((module) => ({ default: module.ThemeCard }))),
	{
		type: 'marketplace-theme-card',
		label: 'Contentful/Marketplace/Themes/Theme Card',
		props: {
			...props,
			requiredFieldPaths: List({
				label: 'Required fields',
				type: Combobox({
					label: 'Field',
					async getOptions(query: string) {
						return getFieldOptions({
							type: 'BlogPost',
							query
						})
					}
				}),
				getItemLabel(item) {
					return item?.label ?? 'Untitled'
				}
			})
		}
	}
)
