import { lazy } from 'react'

import {
	Checkbox,
	Image,
	List,
	Number,
	Select,
	Shape,
	Slot,
	Style,
	TextInput
} from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./Tabs').then((module) => ({ default: module.Tabs }))),
	{
		type: 'tabs',
		label: 'Tabs',
		props: {
			className: Style(),
			tabs: List({
				label: 'Tabs',
				type: Shape({
					type: {
						image: Image({
							label: 'Tab image',
							format: Image.Format.WithDimensions
						}),
						imageAlt: TextInput({
							label: 'Alt text',
							defaultValue: 'Image',
							selectAll: true
						}),
						priorityImage: Checkbox({
							label: 'Priority Image?',
							defaultValue: false
						}),
						title: TextInput({
							label: 'Tab title',
							defaultValue: 'Tab title',
							selectAll: true
						}),
						text: TextInput({
							label: 'Tab text',
							defaultValue: 'This is text for this tab',
							selectAll: true
						}),
						header: TextInput({
							label: 'Tab content header',
							defaultValue: 'This is a header for this tab',
							selectAll: true
						}),
						content: Slot()
					}
				}),
				getItemLabel(tab) {
					return tab?.title || 'Tab title'
				}
			}),
			imageHeight: Number({ label: 'Tab image height', defaultValue: 36, suffix: 'px' }),
			alignment: Select({
				label: 'Tab text alignment',
				options: [
					{ value: 'left', label: 'Left' },
					{ value: 'center', label: 'Center' },
					{ value: 'right', label: 'Right' }
				],
				defaultValue: 'left'
			})
		}
	}
)
